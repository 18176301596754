import useCommand from '@core/hooks/useCommand';
import CliCommandService, {TCliCommandConfig} from '@core/services/CliCommand.service';
import {useEnvironment} from '@providers/Environment.provider';
import {useCallback, useState} from 'react';

// TODO: we can have a cloud custom hook override logic instead of the component override logic
const useCliCommand = () => {
  const {environment} = useEnvironment();
  const configureCommand = useCommand(
    `tracetest configure --organization ${environment?.organizationID} --environment ${environment?.id}`
  );
  const [command, setCommand] = useState<string>('');

  const onGetCommand = useCallback((config: TCliCommandConfig) => {
    setCommand(CliCommandService.getCommand(config));
  }, []);

  return {command, onGetCommand, configureCommand};
};

export default useCliCommand;
