import {Model, TRunGroupSchemas} from '@core/types/Common.types';
import RunMetadata from './RunMetadata.model';
import MonitorRun from './MonitorRun.model';

export type TRawRunGroup = TRunGroupSchemas['RunGroup'];
type RunGroup = Model<
  TRawRunGroup,
  {
    monitorRun?: MonitorRun;
  }
>;

export type TRunGroupStatus = RunGroup['status'] | '';

const defaultRunGroup: TRawRunGroup = {
  metadata: {},
  id: '',
  createdAt: '',
  status: 'created',
  summary: {
    succeed: 0,
    failed: 0,
    pending: 0,
  },
};

const RunGroup = ({
  id,
  metadata: rawMetadata = {},
  createdAt = '',
  status = 'created',
  summary = {
    succeed: 0,
    failed: 0,
    pending: 0,
  },

  monitorId = '',
  monitorRunId = '',
  monitorVersion = 0,
} = defaultRunGroup): RunGroup => {
  const metadata = RunMetadata(rawMetadata, createdAt);
  const monitorRun = MonitorRun.fromMetadata(metadata);

  return {
    id,
    metadata,
    createdAt,
    status,
    summary,
    monitorId,
    monitorRunId,
    monitorVersion,
    monitorRun,
  };
};

export default RunGroup;
