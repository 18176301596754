import {Typography} from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardHeder = styled.div`
  display: flex;
  gap: 6px;
`;

export const Dot = styled.div`
  background-color: ${({theme}) => theme.color.backgroundDark};
  border-radius: 50%;
  color: ${({theme}) => theme.color.text};
  font-size: ${({theme}) => theme.size.md};
  font-weight: 600;
  height: 18px;
  line-height: 18px;
  text-align: center;
  width: 18px;
`;

export const Paragraph = styled(Typography.Paragraph)`
  && {
    margin-bottom: 0;
  }
`;
