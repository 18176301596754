import {Session} from '@ory/client';
import {TUserSchemas} from '@core/types/Common.types';
import Date from '@core/utils/Date';

export type TRawUser = TUserSchemas['User'] & {createdAt?: string};
type User = TRawUser & {createdAt?: string};

const defaultUser: TRawUser = {id: '', emails: [], name: '', createdAt: ''};

function User({id = '', name = '', emails = [], createdAt = ''} = defaultUser): User {
  return {
    id,
    name,
    emails,
    createdAt,
  };
}

User.FromSession = ({identity: {id, traits, created_at: createdAt}}: Session): User => {
  return User({
    id,
    name: `${traits.name?.first || ''} ${traits.name?.last || ''}`.trim(),
    emails: [traits.email],
    createdAt,
  });
};

User.getEmail = (user: User): string => {
  return user.emails[0];
};

User.wasRecentlyCreated = (user: User): boolean => {
  return Date.isDateWithinXDays(user.createdAt ?? '', 5);
};

export default User;
