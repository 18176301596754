import Loading from '@core/pages/Home/Loading';
import * as S from './LoadingList.styled';

const LoadingList = () => {
  return (
    <>
      <S.GlobalStyle />
      <S.Container>
        <Loading />
      </S.Container>
    </>
  );
};

export default LoadingList;
