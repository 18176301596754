import {TriggerTypes} from '@core/constants/Test.constants';
import {get} from 'lodash';
import {THeader} from '@core/types/Test.types';
import {TTriggerSchemas} from '@core/types/Common.types';
import {httpCodes} from '../constants/TriggerStatus.constants';

export const MAX_SIZE_BODY = 1024 * 1024 * 2; // 2MB

export type TRawTriggerResult = TTriggerSchemas['TriggerResult'];
type TriggerResult = {
  size: number;
  type: TriggerTypes;
  headers?: THeader[];
  body?: string;
  statusCode: number;
  bodyMimeType?: string;
  error?: TTriggerSchemas['TriggerError'];
  hasError: boolean;
  statusText?: string;
};

const getHttpStatusText = (statusCode: number) => {
  const baseDigit = Math.floor(statusCode / 100);
  const {message, code} = httpCodes[`${statusCode}`] || httpCodes[`${baseDigit}xx`] || {};

  return message ? `${code} ${message}` : statusCode.toString();
};

const ResponseData = {
  [TriggerTypes.http](response: object) {
    return {
      body: get(response, 'body', ''),
      headers: get(response, 'headers', undefined) as THeader[],
      statusCode: get(response, 'statusCode', 200),
      statusText: getHttpStatusText(get(response, 'statusCode', 200)),
    };
  },
  [TriggerTypes.grpc](response: object) {
    return {
      body: get(response, 'body', ''),
      headers: get(response, 'metadata', undefined) as THeader[],
      statusCode: get(response, 'statusCode', 0),
      statusText: get(response, 'statusCode', 0),
    };
  },
  [TriggerTypes.traceid](response: object) {
    return {
      body: get(response, 'id', ''),
      headers: [],
      statusCode: 200,
      statusText: '',
    };
  },
  [TriggerTypes.cypress](response: object) {
    return {
      body: get(response, 'id', ''),
      headers: [],
      statusCode: 200,
      statusText: '',
    };
  },
  [TriggerTypes.playwright](response: object) {
    return {
      body: get(response, 'id', ''),
      headers: [],
      statusCode: 200,
      statusText: '',
    };
  },
  [TriggerTypes.artillery](response: object) {
    return {
      body: get(response, 'id', ''),
      headers: [],
      statusCode: 200,
      statusText: '',
    };
  },
  [TriggerTypes.k6](response: object) {
    return {
      body: get(response, 'id', ''),
      headers: [],
      statusCode: 200,
      statusText: '',
    };
  },
  [TriggerTypes.kafka](response: object) {
    const kafkaResult = {
      offset: get(response, 'offset', ''),
      partition: get(response, 'partition', ''),
    };

    return {
      body: JSON.stringify(kafkaResult, null, 4),
      headers: [],
      statusCode: 0,
      statusText: '0',
    };
  },
  [TriggerTypes.playwrightEngine](response: object) {
    return {
      body: get(response, 'out', ''),
      headers: [],
      statusCode: get(response, 'success', false) ? 0 : 1,
      statusText: get(response, 'success', false) ? 'Successful' : 'Failed',
    };
  },
  [TriggerTypes.graphql](response: object) {
    return {
      body: get(response, 'body', ''),
      headers: get(response, 'headers', undefined) as THeader[],
      statusCode: get(response, 'statusCode', 200),
      statusText: getHttpStatusText(get(response, 'statusCode', 200)),
    };
  },
};

const TriggerResult = ({
  type: rawType = 'http',
  triggerResult: {
    error = {},
    http = {},
    grpc = {statusCode: 0},
    traceid = {},
    kafka = {},
    playwrightEngine = {},
    graphql = {},
  } = {},
}: TRawTriggerResult): TriggerResult => {
  const type = rawType as TriggerTypes;

  let request = {};
  if (type === TriggerTypes.http) {
    request = http;
  } else if (type === TriggerTypes.grpc) {
    request = grpc;
  } else if ([TriggerTypes.traceid, TriggerTypes.cypress, TriggerTypes.playwright].includes(type)) {
    request = traceid;
  } else if (type === TriggerTypes.kafka) {
    request = kafka;
  } else if (type === TriggerTypes.playwrightEngine) {
    request = playwrightEngine;
  } else if (type === TriggerTypes.graphql) {
    request = graphql;
  }

  const {body, headers = [], statusCode, statusText = ''} = ResponseData[type](request);

  const bodyMimeType = headers.find(({key}) => key.toLowerCase() === 'content-type')?.value;

  return {
    type,
    body,
    headers,
    statusCode,
    bodyMimeType,
    size: new Blob([body]).size,
    error,
    hasError: !!error.message,
    statusText,
  };
};

export default TriggerResult;
