import {CloseCircleFilled, InfoCircleFilled} from '@ant-design/icons';
import styled from 'styled-components';

export const Content = styled.div`
  width: 430px;
`;

export const Footer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 8px;
`;

export const WarningIcon = styled(CloseCircleFilled)`
  color: ${({theme}) => theme.color.error};
`;

export const InfoIcon = styled(InfoCircleFilled)`
  color: ${({theme}) => theme.color.warningYellow};
`;
