import Agent from '@models/Agent.model';
import {CloudFilled} from '@ant-design/icons';
import {Badge, Typography} from 'antd';
import styled from 'styled-components';

export const CloudIcon = styled(CloudFilled)<{$status: Agent['status']}>`
  && {
    color: ${({theme, $status}) => ($status === 'Green' ? theme.color.success : theme.color.error)};
    font-size: ${({theme}) => theme.size.xs};
  }
`;

export const DetailsText = styled(Typography.Text)`
  && {
    margin: 0;
    margin-left: 5px;
    color: ${({theme}) => theme.color.textSecondary};
    font-size: ${({theme}) => theme.size.sm};
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusBadge = styled(Badge)`
  && {
    .ant-badge-status-text {
      margin: 0;
    }
  }
`;
