import {Tabs} from 'antd';
import {PUBLIC_OTLP_TRACETEST_ENDPOINT_URL} from '@core/constants/Common.constants';
import {IMethodProps, PublicOTLPMethods} from '@core/types/PublicOTLPMethod.types';
import Go from '@core/components/PublicOTLPMethods/Go';
import Java from '@core/components/PublicOTLPMethods/Java';
import NodeJS from '@core/components/PublicOTLPMethods/NodeJS';
import OTELCollector from '@core/components/PublicOTLPMethods/OTELCollector';
import Python from '@core/components/PublicOTLPMethods/Python';
import Ruby from '@core/components/PublicOTLPMethods/Ruby';
import Browser from '@core/components/PublicOTLPMethods/Browser';
import * as S from './EnvironmentTraceSetup.styled';
import useIngestionToken from './hooks/useIngestionToken';

const methods: IMethodProps[] = [
  {
    id: PublicOTLPMethods.NodeJS,
    label: 'Node.js',
    component: NodeJS,
  },
  {
    id: PublicOTLPMethods.Python,
    label: 'Python',
    component: Python,
  },
  {
    id: PublicOTLPMethods.Go,
    label: 'Go',
    component: Go,
  },
  {
    id: PublicOTLPMethods.Ruby,
    label: 'Ruby',
    component: Ruby,
  },
  {
    id: PublicOTLPMethods.Java,
    label: 'Java',
    component: Java,
  },
  {
    id: PublicOTLPMethods.Browser,
    label: 'Browser',
    component: Browser,
  },
  {
    id: PublicOTLPMethods.OTELCollector,
    label: 'OTEL Collector',
    component: OTELCollector,
  },
];

const OtlpMethods = () => {
  const token = useIngestionToken();

  return (
    <>
      <S.SetupText>Choose your platform and follow the steps below</S.SetupText>
      <S.TabsContainer>
        <Tabs size="small" defaultActiveKey={PublicOTLPMethods.NodeJS}>
          {methods.map(({id, label, component: Component}) => (
            <Tabs.TabPane key={id} tab={label}>
              <Component endpoint={PUBLIC_OTLP_TRACETEST_ENDPOINT_URL} token={token?.id} />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </S.TabsContainer>
    </>
  );
};

export default OtlpMethods;
