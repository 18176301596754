import {ArrowRightOutlined} from '@ant-design/icons';
import pagesIcon from '@core/assets/pages.svg';
import groupIcon from '@core/assets/group.svg';
import mediaIcon from '@core/assets/media.svg';
import * as SR from '@core/components/Resources/Content/Content.styled';
import {COMMUNITY_SLACK_URL, DOCUMENTATION_URL} from '@core/constants/Common.constants';
import {useDashboard} from '@core/providers/Dashboard/Dashboard.provider';
import {Typography} from 'antd';
import * as S from './ContentResources.styled';

interface IProps {
  openOrganizationModal: () => void;
}

const ContentResources = ({openOrganizationModal}: IProps) => {
  const {navigate} = useDashboard();

  return (
    <SR.Container>
      <S.Container>
        <S.Card>
          <S.CardHeder>
            <S.Dot>1</S.Dot>
            <SR.Title level={3}>Explore Pre-Configured Tests</SR.Title>
          </S.CardHeder>
          <S.Paragraph>
            Dive into our Tracetest tests to see real scenarios.{' '}
            <Typography.Link
              onClick={e => {
                e.preventDefault();
                navigate(`/tests`);
              }}
            >
              Go to the Tests page
            </Typography.Link>{' '}
            and click on any test to see detailed execution steps and results.
          </S.Paragraph>
        </S.Card>

        <S.Card>
          <S.CardHeder>
            <S.Dot>2</S.Dot>
            <SR.Title level={3}>Your Next Steps</SR.Title>
          </S.CardHeder>
          <S.Paragraph>
            Ready to test your own app? You can easily{' '}
            <Typography.Link
              onClick={e => {
                e.preventDefault();
                openOrganizationModal();
              }}
            >
              create your own organization
            </Typography.Link>{' '}
            and environment by navigating to the header. We&apos;ll guide you through each step!
          </S.Paragraph>
        </S.Card>
      </S.Container>

      <SR.Body>
        <SR.Card>
          <SR.Icon src={groupIcon} />
          <div>
            <SR.Title level={3}>Tests</SR.Title>
            <SR.Text>
              Haven&apos;t created a test yet? Go to the &apos;Tests&apos; page to kickstart your testing adventure.
            </SR.Text>
            <div>
              <SR.Link
                onClick={e => {
                  e.preventDefault();
                  navigate(`/tests`);
                }}
              >
                {' '}
                Go to tests <ArrowRightOutlined />
              </SR.Link>
            </div>
          </div>
        </SR.Card>

        <SR.Card>
          <SR.Icon src={pagesIcon} />
          <div>
            <SR.Title level={3}>Documentation</SR.Title>
            <SR.Text>
              The ultimate technical resources and step-by-step guides that allows you to quickly start.
            </SR.Text>
            <div>
              <SR.Link target="_blank" href={DOCUMENTATION_URL}>
                {' '}
                View documentation <ArrowRightOutlined />
              </SR.Link>
            </div>
          </div>
        </SR.Card>

        <SR.Card>
          <SR.Icon src={mediaIcon} />
          <div>
            <SR.Title level={3}>Community</SR.Title>
            <SR.Text>Check the latest updates and support from the community.</SR.Text>
            <div>
              <SR.Link target="_blank" href={COMMUNITY_SLACK_URL}>
                {' '}
                Join our community <ArrowRightOutlined />
              </SR.Link>
            </div>
          </div>
        </SR.Card>
      </SR.Body>
    </SR.Container>
  );
};

export default ContentResources;
