import {DownloadOutlined} from '@ant-design/icons';
import {FramedCodeBlock} from '@core/components/CodeBlock';
import {Overlay} from '@core/components/Inputs';
import {Button} from 'antd';
import * as S from './ResourceDefinitionModal.styled';

interface IProps {
  definition: string;
  fileName: string;
  onDownload(): void;
  onFileNameChange(value: string): void;
  title: string;
}

const Definition = ({definition, fileName, onDownload, onFileNameChange, title}: IProps) => (
  <>
    <S.Title>{title}</S.Title>
    <S.FileNameContainer>
      <Overlay value={fileName} onChange={onFileNameChange} />
    </S.FileNameContainer>

    <FramedCodeBlock
      title="Preview your YAML file"
      value={definition}
      language="yaml"
      actions={
        <Button icon={<DownloadOutlined />} onClick={onDownload} size="small" type="primary">
          Download File
        </Button>
      }
      isFullHeight
    />
  </>
);

export default Definition;
