import {Model, TPlaywrightEngineSchemas} from '@core/types/Common.types';

export type TRawPlaywrightEngineRequest = TPlaywrightEngineSchemas['PlaywrightEngineRequest'];

type PlaywrightEngineRequest = Model<TRawPlaywrightEngineRequest, {}>;

const PlaywrightEngineRequest = ({
  target = '',
  script = '',
  method = '',
}: TRawPlaywrightEngineRequest): PlaywrightEngineRequest => {
  return {
    target,
    script,
    method,
  };
};

export default PlaywrightEngineRequest;
