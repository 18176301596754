import {Typography} from 'antd';
import styled from 'styled-components';

export const DemoBanner = styled.div`
  border-left: 1px solid #f0f0f0;
  margin-left: 360px;
  padding: 80px 16px 16px;
`;

export const Text = styled(Typography.Text)`
  && {
    margin: 0;
  }
`;

export const Link = styled(Typography.Link)`
  && {
    font-weight: 600;
    margin: 0;
  }
`;
