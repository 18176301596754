import {Role, TMemberSchemas} from '@core/types/Common.types';
import Invite from './Invite.model';
import User from './User.model';

export type TRawMember = TMemberSchemas['Member'];
type Member = TRawMember & {};

const defaultMember: TRawMember = {id: '', role: Role.Members, user: {id: '', emails: [], name: ''}};

function Member({id = '', role = Role.Members, user, invite, isOrgAdmin} = defaultMember): Member {
  return {
    id,
    role,
    user: User(user),
    invite: invite && Invite(invite),
    isOrgAdmin,
  };
}

Member.getHasAdminAccess = (member: Member) => [Role.Admins, Role.Owners].includes(member.role as Role);

export default Member;
