import {Button} from 'antd';
import * as S from './ResourceDefinitionModal.styled';
import Definition from './Definition';
import Command from './Command';

interface IProps {
  command: string;
  configureCommand?: string;
  definition: string;
  fileName: string;
  isOpen: boolean;
  onClose(): void;
  onDownload(): void;
  onFileNameChange(value: string): void;
  title: string;
}

export const ResourceDefinitionModal = ({
  command,
  configureCommand,
  definition,
  fileName,
  isOpen,
  onClose,
  onDownload,
  onFileNameChange,
  title,
}: IProps) => (
  <S.Modal
    footer={[
      <Button key="close" onClick={onClose}>
        Close
      </Button>,
    ]}
    onCancel={onClose}
    title={<S.Title $noMargin>Resource Definition</S.Title>}
    visible={isOpen}
    width="70%"
  >
    <S.Container>
      <S.LeftPanel>
        <Definition
          definition={definition}
          fileName={fileName}
          onDownload={onDownload}
          onFileNameChange={onFileNameChange}
          title={title}
        />
      </S.LeftPanel>

      <S.RightPanel>
        <Command command={command} configureCommand={configureCommand} />
      </S.RightPanel>
    </S.Container>
  </S.Modal>
);

export default ResourceDefinitionModal;
