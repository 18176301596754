import {Col, Row} from 'antd';
import {CloudPrivate, CloudPublic} from '@components/atoms/Icons';
import TooltipQuestion from '@core/components/TooltipQuestion';
import * as S from './ModeSelector.styled';
import Card from './Card';

interface IProps {
  isWizard: boolean;
  mode: 'public' | 'private' | undefined;
  onClick: (mode: 'public' | 'private') => void;
}

const ModeSelector = ({isWizard, mode, onClick}: IProps) => (
  <S.Container>
    <S.Header>
      <S.Row>
        <S.Title level={isWizard ? 1 : 2}>Tell us where your application is running </S.Title>
        <S.InfoContainer>
          <TooltipQuestion title="The Tracetest Agent needs network access to your application in order to run tests." />
        </S.InfoContainer>
      </S.Row>
      <S.Text type="secondary">Is your application internal (i.e. behind a firewall) or publicly accessible?</S.Text>
    </S.Header>

    <Row gutter={[12, 12]}>
      <Col span={12}>
        <Card
          title="Application is internal"
          icon={<CloudPrivate />}
          onClick={() => onClick('private')}
          isSelected={mode === 'private'}
        />
      </Col>
      <Col span={12}>
        <Card
          title="Application is publicly accessible"
          icon={<CloudPublic />}
          onClick={() => onClick('public')}
          isSelected={mode === 'public'}
        />
      </Col>
    </Row>
  </S.Container>
);

export default ModeSelector;
