import {Typography} from 'antd';
import {FramedCodeBlock} from '@core/components/CodeBlock';
import * as S from '@core/components/RunDetailAutomateMethods/methods/GithubActions/GithubActions.styled';
import {AGENT_DOCS_URL, CLI_DOCS_URL} from '@core/constants/Common.constants';

const actionConfig = `- name: Configure Tracetest CLI and Agent
  uses: kubeshop/tracetest-github-action@v1
  with:
    token: "{your tracetest token}" # See https://docs.tracetest.io/concepts/environment-tokens for more details
    apiKey: "{your tracetest agent apiKey}" # See https://docs.tracetest.io/concepts/agent for more details
`;

const GITHUB_ACTION_URL =
  'https://github.com/kubeshop/tracetest-github-action/tree/main/examples/tracetest-cli-and-agent-with-tracetest-io';
const TOKEN_DOCS_URL = 'https://docs.tracetest.io/concepts/environment-tokens';

const GithubActions = () => {
  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title>GitHub Action Configuration</S.Title>
      </S.TitleContainer>
      <Typography.Paragraph>
        Integrate Tracetest into your GitHub pipeline by adding this snippet to your workflow steps to utilize{' '}
        <a href={CLI_DOCS_URL} target="__blank">
          Tracetest CLI
        </a>{' '}
        and{' '}
        <a href={AGENT_DOCS_URL} target="__blank">
          Agent
        </a>{' '}
        for test runs:
      </Typography.Paragraph>
      <FramedCodeBlock title="Snippet" language="yaml" value={actionConfig} minHeight="180px" maxHeight="180px" />
      <S.Subtitle type="secondary">
        You can get your Tracetest Token from the Tracetest settings page by following the directions described{' '}
        <a href={TOKEN_DOCS_URL} target="__blank">
          {' '}
          here
        </a>
        . The Tracetest Agent APIKey can be seen on the Agent tab on Settings page. <br /> See{' '}
        <a href={GITHUB_ACTION_URL} target="__blank">
          tracetest-cli-and-agent-with-tracetest-io.yml
        </a>{' '}
        for a complete usage example.
      </S.Subtitle>
    </S.Container>
  );
};

export default GithubActions;
