import {Suspense, lazy} from 'react';
import {Route, Routes} from 'react-router-dom';
import Layout from '@components/organisms/Layout';
import ProtectedLayout, {
  EnvironmentProtectedLayout,
  OrganizationProtectedLayout,
} from '@components/organisms/ProtectedLayout';

const JoinRequestResponse = lazy(() => import('@pages/JoinRequestResponse'));
const AcceptInvite = lazy(() => import('@pages/AcceptInvite'));
const Environments = lazy(() => import('@pages/Environments'));
const Error = lazy(() => import('@pages/Error'));
const Home = lazy(() => import('@pages/Home'));
const Invites = lazy(() => import('@pages/Invites'));
const Members = lazy(() => import('@pages/Members'));
const OAuth = lazy(() => import('@pages/OAuth'));
const Settings = lazy(() => import('@pages/Settings'));
const OSSBaseApp = lazy(() => import('@core/BaseApp'));
const Tokens = lazy(() => import('@pages/Tokens'));
const Runs = lazy(() => import('@pages/Runs'));
const TokenFlow = lazy(() => import('@pages/TokenFlow'));

const Router = () => (
  <Routes>
    <Route path="/oauth" element={<ProtectedLayout />}>
      <Route index element={<OAuth />} />
    </Route>

    <Route element={<Layout />}>
      <Route path="/" element={<Home />} />
    </Route>

    <Route path="/organizations/:orgId" element={<OrganizationProtectedLayout />}>
      <Route index element={<Environments />} />
      <Route path="environments" element={<Environments />} />
      <Route path="settings" element={<Settings />} />
      <Route path="members" element={<Members />} />
      <Route path="invites" element={<Invites />} />
      <Route path="tokens" element={<Tokens />} />
      <Route path="runs" element={<Runs />} />
    </Route>

    <Route path="/organizations/:orgId" element={<EnvironmentProtectedLayout />}>
      <Route path="environments/:envId/join-requests/:joinRequestId/response" element={<JoinRequestResponse />} />
    </Route>

    <Route path="/organizations/:orgId" element={<EnvironmentProtectedLayout />}>
      <Route path="environments/:envId/*" element={<OSSBaseApp />} />
    </Route>

    <Route path="/organizations/:orgId" element={<ProtectedLayout />}>
      <Route path="invites/:invId/accept" element={<AcceptInvite />} />
    </Route>

    <Route path="/retrieve-token" element={<ProtectedLayout />}>
      <Route index element={<TokenFlow />} />
    </Route>

    <Route
      path="/error"
      element={
        <Suspense fallback={<div />}>
          <Error />
        </Suspense>
      }
    />

    <Route path="*" element={<Layout />}>
      <Route index element={<Home />} />
    </Route>
  </Routes>
);

export default Router;
