import {HTTP_METHOD} from '@core/constants/Common.constants';
import Token, {TRawToken} from '@models/Token.model';

import TracetestCloudAPI, {TAG_TYPES} from '../TracetestCloud.api';

const UserEndpoint = TracetestCloudAPI.injectEndpoints({
  endpoints: builder => ({
    getUserToken: builder.query<Token, {userId: string}>({
      query: ({userId}) => ({
        url: `/users/${userId}/token`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.USER],
      transformResponse: (raw: TRawToken) => Token(raw),
    }),

    logout: builder.mutation<undefined, {}>({
      query: () => ({
        url: '/webhook/logout',
        method: HTTP_METHOD.POST,
      }),
    }),
  }),
});

export const {useGetUserTokenQuery, useLogoutMutation} = UserEndpoint;
