import {CloseOutlined} from '@ant-design/icons';
import {Input as AntdInput, Typography} from 'antd';
import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .selector-tooltip{
    z-index: 999;

    .ant-tooltip-arrow-content:before,
    .ant-tooltip-inner {
      background: ${({theme}) => theme.color.primary};
      border-color: ${({theme}) => theme.color.primary};
      color: ${({theme}) => theme.color.white};
      min-width: 315px;
    }
  }
`;

export const Overlay = styled.div`
  padding: 9px 12px;
  display: flex;
  gap: 12px;
`;

export const Close = styled(CloseOutlined).attrs(({theme}) => ({
  color: theme.color.white,
}))`
  cursor: pointer;
`;

export const Title = styled(Typography.Title).attrs({
  level: 2,
})`
  && {
    color: ${({theme}) => theme.color.white};
  }
`;

export const Text = styled(Typography.Paragraph)`
  margin: 0;
  color: ${({theme}) => theme.color.white};
`;

export const Input = styled(AntdInput)`
  border: none;

  &.ant-input-affix-wrapper:hover,
  &.ant-input-affix-wrapper:focus-visible {
    outline: none;
    border: none;
    box-shadow: none;
  }
`;

export const DropdownContainer = styled.div`
  background: ${({theme}) => theme.color.white};
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const SearchContainer = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.color.borderLight};
  padding: 8px 12px;
`;

export const ListContainer = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.color.borderLight};
  height: 200px;
  overflow-y: scroll;
  padding: 8px 0;

  ::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  background: ${({theme}) => theme.color.white};
  cursor: pointer;
  padding: 5px 12px;
  transition: all 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const EmptyState = styled.div`
  margin-top: 24px;
  text-align: center;
`;

export const FooterContainer = styled.div<{$disabled?: boolean; $hasBorder?: boolean}>`
  background: ${({theme}) => theme.color.white};
  border-bottom: ${({$hasBorder, theme}) => ($hasBorder ? `1px solid ${theme.color.borderLight}` : 'none')};
  color: ${({$disabled, theme}) => ($disabled ? theme.color.textLight : theme.color.text)};
  cursor: ${({$disabled}) => ($disabled ? 'not-allowed' : 'pointer')};
  padding: 13px 12px;
  transition: all 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }
`;
