import {Model, TConfigSchemas} from '@core/types/Common.types';

export type TRawPollingResourceList = TConfigSchemas['PollingProfileList'];
export type TRawPolling = Required<TConfigSchemas['PollingProfile']>['spec'];
export type TRawPollingResource = TConfigSchemas['PollingProfile'];
type Polling = Model<
  TRawPolling,
  {
    meta: Record<string, string | number>;
  }
>;

function Polling({spec}: TRawPollingResource = {}): Polling {
  return Polling.fromRaw(spec);
}

const defaultPolling: TRawPolling = {
  default: false,
  id: 'default',
  name: 'Default',
  strategy: 'periodic',
  periodic: {},
};

Polling.fromRaw = ({
  default: isDefault = false,
  id = '',
  name = '',
  periodic,
  strategy = 'periodic',
} = defaultPolling): Polling => {
  const {retryDelay = '', timeout = '', selectorMatchRetries = 0} = periodic ?? {};

  return {
    default: isDefault,
    id,
    name,
    periodic: {
      timeout,
      retryDelay,
      selectorMatchRetries,
    },
    strategy,
    meta: {
      strategy,
      timeout,
      retryDelay,
      selectorMatchRetries,
    },
  };
};

export default Polling;
