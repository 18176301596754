import {Button as AntdButton, Typography, Menu as AntdMenu} from 'antd';
import {CheckCircleFilled, ReadOutlined, SettingOutlined} from '@ant-design/icons';
import styled from 'styled-components';

export const DocsLink = styled.a`
  line-height: initial;
`;

export const Settings = styled(SettingOutlined)`
  && {
    font-size: 16px;
    color: ${({theme}) => theme.color.textSecondary};
  }
`;

export const Documentation = styled(ReadOutlined)`
  && {
    font-size: 16px;
    color: ${({theme}) => theme.color.textSecondary};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Button = styled(AntdButton)`
  box-shadow: none;
  padding: 0;
`;

export const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
`;

export const SelectedItemText = styled(Typography.Text)`
  && {
    font-weight: 600;
    color: ${({theme}) => theme.color.primary};
  }
`;

export const DetailsText = styled(Typography.Paragraph)`
  && {
    margin: 0;
    color: ${({theme}) => theme.color.textSecondary};
  }
`;

export const NameText = styled(Typography.Text)`
  && {
    font-weight: 600;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`;

export const Menu = styled(AntdMenu)`
  padding: 17px 0 10px;
`;

export const SelectedIcon = styled(CheckCircleFilled)`
  && {
    color: ${({theme}) => theme.color.text};
    font-size: 14px;
    margin-left: 4px;
  }
`;

export const Row = styled.div`
  display: flex;
`;
