import Panel from '@components/molecules/Panel';
import LimitedAccess from '@components/organisms/LimitedAccess';
import DocsBanner from '@core/components/DocsBanner';
import {SupportedDataStoresToName} from '@core/constants/DataStore.constants';
import {useSettingsValues} from '@core/providers/SettingsValues/SettingsValues.provider';
import DataStoreService from '@core/services/DataStore.service';
import {useEnvironment} from '@providers/Environment.provider';
import {Descriptions, Typography} from 'antd';
import * as S from '../Settings.styled';

const CONFIGURATION_OVERVIEW_DOCUMENTATION = 'https://docs.tracetest.io/configuration/overview';

const LimitedDataStore = () => {
  const {environment, member} = useEnvironment();
  const {dataStoreConfig} = useSettingsValues();
  const publicInfo = DataStoreService.getPublicInfo(dataStoreConfig.defaultDataStore);

  return (
    <Panel title="Tracing Backend">
      <Typography.Paragraph type="secondary">
        <p>
          Tracetest needs to be able to retrieve your trace from your distributed tracing solution. Select your Tracing
          Backend and enter the configuration details.
        </p>
        <DocsBanner>
          Need more information about Tracing Backends?{' '}
          <a href={CONFIGURATION_OVERVIEW_DOCUMENTATION} target="__blank">
            Learn more in our docs
          </a>
        </DocsBanner>
      </Typography.Paragraph>

      <LimitedAccess
        description="To configure the Tracing Backend settings, please contact the environment administrator for assistance."
        organizationID={environment?.organizationID}
        role={member.role}
      />

      <S.Divider />

      <div>
        <Typography.Title level={3}>Current Tracing Backend settings:</Typography.Title>

        <S.Descriptions size="small" column={1}>
          <Descriptions.Item label="Tracing Backend">
            {SupportedDataStoresToName[dataStoreConfig.defaultDataStore.type]}
          </Descriptions.Item>

          {Object.entries(publicInfo).map(([key, value]) => (
            <Descriptions.Item key={key} label={key}>
              {value}
            </Descriptions.Item>
          ))}
        </S.Descriptions>
      </div>
    </Panel>
  );
};

export default LimitedDataStore;
