import {IHttpValues, ITriggerService} from '@core/types/Test.types';
import Validator from '@core/utils/Validator';
import {HTTP_METHOD} from '@core/constants/Common.constants';
import HttpRequest from '@core/models/HttpRequest.model';
import {Attributes} from '@core/constants/SpanAttribute.constants';

const HttpTriggerService = (): ITriggerService => ({
  async getRequest(values) {
    const {url, method, auth, headers, body, sslVerification} = values as IHttpValues;

    return HttpRequest({url, method, auth, headers, body, sslVerification});
  },

  async validateDraft(draft): Promise<boolean> {
    const {url, method} = draft as IHttpValues;
    return Validator.required(url) && Validator.required(method);
  },

  getInitialValues(request) {
    const {url, method, headers, body, auth, sslVerification} = request as HttpRequest;

    return {
      url,
      auth,
      method: method as HTTP_METHOD,
      headers,
      body,
      sslVerification,
    };
  },

  getParsedDefinition() {
    return {
      assets: [],
    };
  },

  getRequestFromSpan(span) {
    const headers = Object.entries(span.attributes).flatMap(([key, value]) => {
      if (key.startsWith('http.request.header.')) {
        const val = (JSON.parse(value.value) as string[]).join(', ');
        return [
          {
            key: key.replace('http.request.header.', '').replace('_', '-'),
            value: val,
          },
        ];
      }

      return [];
    });

    const hostName = span.attributes[Attributes.HTTP_HOST]?.value || `\${var:HOST}`;
    const schema = span.attributes[Attributes.HTTP_SCHEME]?.value || 'http';
    const fullUrl = span.attributes[Attributes.HTTP_URL]?.value;
    const path = span.attributes[Attributes.HTTP_ROUTE]?.value ?? '';

    const url = fullUrl || `${schema}://${hostName}${path}`;
    const method = span.attributes[Attributes.HTTP_METHOD]?.value as HTTP_METHOD;

    const body = method !== HTTP_METHOD.GET ? `\${var:BODY}` : '';

    return HttpRequest({
      url,
      method,
      headers,
      body,
    });
  },
});

export default HttpTriggerService();
