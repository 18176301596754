import {HTTP_METHOD} from '@core/constants/Common.constants';
import License, {TRawLicense} from '@models/License.model';
import TracetestCloudAPI, {TAG_TYPES} from '../TracetestCloud.api';

const ConfigEndpoint = TracetestCloudAPI.injectEndpoints({
  endpoints: builder => ({
    /**
     * License
     */
    getLicense: builder.query<License, {}>({
      query: () => ({
        url: `/license`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.LICENSE],
      transformResponse: (raw: TRawLicense) => License(raw),
    }),
  }),
});

export const {useGetLicenseQuery} = ConfigEndpoint;
