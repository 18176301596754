import CharIcon from '@components/atoms/CharIcon';
import * as S from '@components/molecules/MenuSelector';
import {PlanNames} from '@models/Plan.model';
import Organization from '@models/Organization.model';
import {Tooltip} from 'antd';
import {Link} from 'react-router-dom';

interface IProps {
  organization: Organization;
  isSelected: boolean;
}

const Item = ({organization: {name, plan, id}, isSelected}: IProps) => (
  <S.ItemContainer data-testid="selector-item">
    <CharIcon char={name?.[0] ?? ''} />
    <S.DetailsContainer>
      <S.NameText>
        {name}
        {isSelected && <S.SelectedIcon size={16} />}
      </S.NameText>
      <S.DetailsText>{PlanNames[plan.name || 'plan_free']}</S.DetailsText>
    </S.DetailsContainer>
    <Tooltip title="Manage organization">
      <Link to={`/organizations/${id}/settings`} onClick={e => e.stopPropagation()}>
        <S.Settings size={16} />
      </Link>
    </Tooltip>
  </S.ItemContainer>
);

export default Item;
