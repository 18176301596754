import {ITraceIDValues, ITriggerService} from '@core/types/Test.types';
import Validator from '@core/utils/Validator';
import TraceIDRequest from '@core/models/TraceIDRequest.model';

const TraceIDTriggerService = (): ITriggerService => ({
  async getRequest(values) {
    const {id} = values as ITraceIDValues;

    return TraceIDRequest({id: id.includes('env:') || id.includes('var:') ? id : `\${env:${id}}`});
  },

  async validateDraft(draft) {
    const {id} = draft as ITraceIDValues;
    return Validator.required(id);
  },

  getInitialValues(request) {
    const {id} = request as ITraceIDValues;

    return {id};
  },

  getParsedDefinition() {
    return {
      assets: [],
    };
  },

  getRequestFromSpan() {
    return undefined;
  },
});

export default TraceIDTriggerService();
