import styled from 'styled-components';
import {Typography} from 'antd';
import {CardList} from '@core/components/TriggerTypeModal/TriggerTypeModal.styled';

export const CardContainer = styled.div<{$isActive: boolean}>`
  align-items: center;
  background: ${({theme}) => theme.color.white};
  border: 1px solid ${({theme}) => theme.color.borderLight};
  border-radius: 4px;
  cursor: ${({$isActive}) => ($isActive ? 'pointer' : 'default')};
  display: flex;
  gap: 12px;
  padding: 4px 16px;
  transition: 0.3s;

  &:hover {
    background: ${({theme}) => theme.color.background};
    border: 1px solid ${({theme}) => theme.color.primary};

    .check {
      opacity: 1;
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Divider = styled.div`
  height: 1px;
  border-top: 1px dashed ${({theme}) => theme.color.borderLight};
  margin-bottom: 24px;
`;

export const CardTitle = styled(Typography.Text).attrs({
  strong: true,
})<{$isActive: boolean}>`
  display: inline-block;
  font-size: ${({theme}) => theme.size.sm};
  opacity: ${({$isActive}) => ($isActive ? 1 : 0.5)};
`;

export const CustomCardList = styled(CardList)`
  margin-bottom: 24px;
`;
