import Link from '@core/components/Link';
import PulseButton from '@core/components/PulseButton';
import {useOrganization} from '@providers/Organization.provider';
import UserPreferencesService from '@services/UserPreferences.service';
import * as S from './MenuTests.styled';

const {REACT_APP_DEMO_ORG_ID = ''} = process.env;

const MenuTests = () => {
  const {organization} = useOrganization();
  const isFirstTimeUser = !UserPreferencesService.get('wasSelectorTooltipShown');

  return (
    <>
      <Link to="/tests">Tests</Link>
      {REACT_APP_DEMO_ORG_ID === organization?.id && isFirstTimeUser && (
        <S.PulseContainer>
          <PulseButton $isDarkMode />
        </S.PulseContainer>
      )}
    </>
  );
};

export default MenuTests;
