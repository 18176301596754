import {SupportedDataStoresToName} from '@core/constants/DataStore.constants';
import {useSettingsValues} from '@core/providers/SettingsValues/SettingsValues.provider';
import {ConfigMode} from '@core/types/DataStore.types';
import * as S from './TracingBackend.styled';

const Tab = () => {
  const {dataStoreConfig} = useSettingsValues();

  return (
    <div>
      <S.TabText>
        {dataStoreConfig.mode === ConfigMode.READY
          ? `${SupportedDataStoresToName[dataStoreConfig.defaultDataStore.type]} connected`
          : 'No Trace Ingestion'}
      </S.TabText>
    </div>
  );
};

export default Tab;
