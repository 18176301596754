import {Typography} from 'antd';
import styled from 'styled-components';

export const TabsContainer = styled.div`
  .ant-tabs-nav {
    padding: 0 12px;
    margin-bottom: 0;
  }

  .ant-tabs-nav {
    padding: 0;
  }
`;

export const Title = styled(Typography.Title)`
  && {
    margin-bottom: 0;
  }
`;

export const Text = styled(Typography.Text)`
  && {
    font-size: ${({theme}) => theme.size.md};
    font-weight: 400;
  }
`;

export const Message = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-left: 4px;
`;
