const CloudPrivate = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="cloud-terminal">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57077 8.29101C5.72748 5.42912 8.09856 3.15625 11 3.15625C13.4783 3.15625 15.5711 4.81481 16.2254 7.08403L16.3778 7.61264L16.9278 7.62603C18.7527 7.67043 20.2188 9.16411 20.2188 11C20.2188 12.2482 19.5416 13.339 18.531 13.9238C18.1725 14.1312 18.05 14.59 18.2575 14.9485C18.4649 15.3071 18.9237 15.4295 19.2823 15.2221C20.7372 14.3802 21.7188 12.8052 21.7188 11C21.7188 8.53077 19.8831 6.49047 17.5018 6.16907C16.5185 3.53385 13.9794 1.65625 11 1.65625C7.53638 1.65625 4.66597 4.19416 4.14638 7.51222C1.98432 7.67683 0.28125 9.48328 0.28125 11.6875C0.28125 13.4027 1.3124 14.8755 2.78551 15.5227C3.16473 15.6894 3.60723 15.517 3.77386 15.1378C3.94049 14.7586 3.76815 14.3161 3.38893 14.1495C2.4409 13.7329 1.78125 12.7864 1.78125 11.6875C1.78125 10.2032 2.98448 9 4.46875 9H4.82189H5.53194L5.57077 8.29101ZM8.75092 10.1259C9.04381 9.83303 9.51869 9.83303 9.81158 10.1259L12.2178 12.5322L12.7482 13.0625L12.2178 13.5928L9.81158 15.9991C9.51869 16.292 9.04381 16.292 8.75092 15.9991C8.45803 15.7062 8.45803 15.2313 8.75092 14.9384L10.6268 13.0625L8.75092 11.1866C8.45803 10.8937 8.45803 10.4188 8.75092 10.1259ZM11.2812 18.2188C11.2812 17.8045 11.617 17.4688 12.0312 17.4688H15.4688C15.883 17.4688 16.2188 17.8045 16.2188 18.2188C16.2188 18.633 15.883 18.9688 15.4688 18.9688H12.0312C11.617 18.9688 11.2812 18.633 11.2812 18.2188Z"
        fill="#031849"
      />
    </g>
  </svg>
);

export default CloudPrivate;
