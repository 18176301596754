import {COMMUNITY_SLACK_URL} from '@core/constants/Common.constants';
import Footer from './Footer';
import * as S from './PlanLimitModal.styled';

interface IProps {
  isOpen: boolean;
  onClose(): void;
}

const PlanLimitModal = ({isOpen, onClose}: IProps) => (
  <S.Modal
    footer={<Footer onClose={onClose} />}
    onCancel={onClose}
    title={<S.Title data-testid="single-org-modal">Plan Limit Operation</S.Title>}
    visible={isOpen}
  >
    This operation is not supported in the current plan. Please contact the{' '}
    <a href={COMMUNITY_SLACK_URL}>Tracetest team</a> for more information.
  </S.Modal>
);

export default PlanLimitModal;
