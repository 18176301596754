import {Button} from 'antd';

import * as S from './TokenModal.styled';

interface IProps {
  isEditing: boolean;
  isLoading: boolean;
  onClose(): void;
  onSubmit(): void;
}

const Footer = ({isEditing, isLoading, onClose, onSubmit}: IProps) => (
  <S.Footer>
    <span>
      <Button type="primary" ghost onClick={onClose}>
        Cancel
      </Button>
    </span>
    <span>
      <Button
        htmlType="submit"
        loading={isLoading}
        type="primary"
        onClick={onSubmit}
        data-testid="token-modal-button-create"
      >
        {isEditing ? 'Update' : 'Create'}
      </Button>
    </span>
  </S.Footer>
);

export default Footer;
