import {CollectorConfigFunctionMap} from '@core/constants/CollectorConfig.constants';
import {TCollectorDataStores} from '@core/types/DataStore.types';

const tracetest = (url: string) => `# OTLP for Tracetest
  otlp/tracetest:
    endpoint: ${url} # Send traces to Tracetest. Read more in docs here:  https://docs.tracetest.io/configuration/connecting-to-data-stores/opentelemetry-collector
    tls:
      insecure: true`;
const defaultFn = () => '';

const CollectorConfigService = () => ({
  getCollectorConfig(dataStoreType: TCollectorDataStores, url: string) {
    const tracetestBlock = tracetest(url);

    const fn = CollectorConfigFunctionMap[dataStoreType] || defaultFn;
    return fn(tracetestBlock);
  },
});

export default CollectorConfigService();
