import {Button, Space, Typography} from 'antd';
import * as S from './CreateButton.styled';

interface IProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  onCreateOrganization?: () => void;
}

const DemoAlert = ({onClick, onCreateOrganization}: IProps) => (
  <Space align="start">
    <S.InfoIcon />
    <S.Content>
      <Typography.Text>
        You&apos;re currently exploring the <Typography.Text strong>tracetest-demo</Typography.Text> organization, where
        you can create tests against our demo apps to see how Tracetest works. If you&apos;d like to create tests on
        your own system, you&apos;ll need to set up your own organization.
      </Typography.Text>
      <S.Footer>
        <Button ghost onClick={onCreateOrganization} size="small" type="primary">
          Create My Organization
        </Button>
        <Button ghost onClick={onClick} size="small" type="primary">
          Continue
        </Button>
      </S.Footer>
    </S.Content>
  </Space>
);

export default DemoAlert;
