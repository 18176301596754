import {Form} from 'antd';
import {useEffect, useState, useMemo} from 'react';

import VariableSetForm from '@core/components/VariableSet';
import VariableSet, {TDraftVariableSet} from '@core/models/VariableSet.model';
import VariableSetService from '@core/services/VariableSet.service';
import * as S from './VariableSetModal.styled';
import VariableSetModalFooter from './VariableSetModalFooter';
import Tip from './Tip';

interface IProps {
  variableSet?: VariableSet;
  isOpen: boolean;
  isLoading: boolean;
  onClose(): void;
  onSubmit(variableSet: VariableSet): void;
}

export const DEFAULT_VALUES = [{key: '', value: ''}];

const VariableSetModal = ({variableSet, isOpen, onClose, onSubmit, isLoading}: IProps) => {
  const [form] = Form.useForm<TDraftVariableSet>();
  const [isFormValid, setIsFormValid] = useState(false);
  const isEditing = Boolean(variableSet);

  const initialValues = useMemo<TDraftVariableSet | undefined>(
    () =>
      variableSet
        ? {
            ...variableSet,
            values: variableSet.values.filter(({type}) => type === 'raw'),
            secrets: variableSet.values.filter(({type}) => type === 'secret'),
          }
        : undefined,
    [variableSet]
  );

  useEffect(() => {
    if (variableSet && isOpen)
      form.setFieldsValue({
        ...variableSet,
        values: variableSet.values.filter(({type}) => type === 'raw'),
        secrets: variableSet.values.filter(({type}) => type === 'secret'),
      });
    if (!isOpen || !variableSet) {
      form.resetFields();
      form.setFieldsValue({values: [{key: '', value: '', type: 'raw'}]});
    }
  }, [variableSet, form, isOpen]);

  const handleOnValidate = (changedValues: any, draft: VariableSet) => {
    setIsFormValid(VariableSetService.validateDraft(draft));
  };

  const handleOnSubmit = async (values: VariableSet) => {
    onSubmit(values);
    onClose();
  };

  return (
    <S.Modal
      cancelText="Cancel"
      footer={
        <VariableSetModalFooter
          isEditing={isEditing}
          isLoading={isLoading}
          isValid={isFormValid}
          onCancel={onClose}
          onSave={() => form.submit()}
        />
      }
      onCancel={onClose}
      title={<S.Title level={2}>{isEditing ? 'Edit variable set' : 'Create a new variable set'}</S.Title>}
      visible={isOpen}
      width={625}
    >
      <VariableSetForm
        form={form}
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        onValidate={handleOnValidate}
      />

      <Tip />
    </S.Modal>
  );
};

export default VariableSetModal;
