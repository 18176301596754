import {Alert, Button, Typography} from 'antd';
import styled from 'styled-components';

export const Footer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 14px 0;
`;

export const Section = styled.div<{$marginBottom?: number}>`
  margin-bottom: ${({$marginBottom}) => $marginBottom ?? 14}px;
`;

export const Title = styled(Typography.Title)<{$marginBottom?: number}>`
  && {
    margin-bottom: ${({$marginBottom}) => $marginBottom ?? 8}px;
  }
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
`;

export const Text = styled(Typography.Text)`
  && {
    margin: 0;
  }
`;

export const InfoAlert = styled(Alert)`
  background-color: ${({theme}) => theme.color.white};
  border: 1px solid ${({theme}) => theme.color.interactive};

  .ant-alert-icon {
    color: ${({theme}) => theme.color.interactive};
  }
`;

export const NoPaddingButton = styled(Button)`
  padding: 4px 0;
`;
