import {FramedCodeBlock} from '@core/components/CodeBlock';
import {IMethodChildrenProps} from '@core/types/PublicOTLPMethod.types';
import {Collapse} from 'antd';
import * as S from '../common.styled';

function getCodeBlocks(endpoint?: string, token?: string) {
  return {
    code1: `exporters:
  otlp/tracetest:
    endpoint: "${endpoint}"
    headers:
      "x-tracetest-token": "${token}"
    tls:
      insecure: true
`,
    code2: `service:
  pipelines:
    traces/1:
      receivers: [otlp]
      processors: [batch]
      exporters: [otlp/tracetest]

`,
    code3: `# otel-collector-config.yaml

receivers:
  otlp:
    protocols:
      grpc:
      http:

processors:
  batch:
    timeout: 100ms

exporters:
  otlp/tracetest:
    endpoint: "${endpoint}"
    headers:
      "x-tracetest-token": "${token}"
    tls:
      insecure: true

service:
  pipelines:
    traces/1:
      receivers: [otlp]
      processors: [batch]
      exporters: [otlp/tracetest]
`,
  };
}

const OTELCollector = ({endpoint, token}: IMethodChildrenProps) => {
  const {code1, code2, code3} = getCodeBlocks(endpoint, token);

  return (
    <S.Collapse accordion ghost defaultActiveKey="1" expandIconPosition="right">
      <Collapse.Panel header={<S.StepTitle level={3}>1. Configure exporter</S.StepTitle>} key="1">
        <S.StepText>Configure an OTLP exporter with the Tracetest Token as a header.</S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code1} language="yaml" title="Exporter" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
      <Collapse.Panel header={<S.StepTitle level={3}>2. Service Pipelines</S.StepTitle>} key="2">
        <S.StepText>Configure a service pipeline for traces.</S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code2} language="yaml" title="Pipelines" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
      <Collapse.Panel header={<S.StepTitle level={3}>3. Full configuration file</S.StepTitle>} key="3">
        <S.StepText>Here&apos;s what your final file will look like.</S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code3} language="bash" title="Collector configuration" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
    </S.Collapse>
  );
};

export default OTELCollector;
