import {Tooltip as AntdTooltip, TooltipProps} from 'antd';
import {useCallback, useState} from 'react';
import UserPreferencesService from '@services/UserPreferences.service';
import * as S from './EnvironmentSelector.styled';

interface Props extends Omit<TooltipProps, 'title' | 'overlay'> {
  onClose(): void;
}

export const useTooltip = () => {
  const [isToolTipVisible, setIsToolTipVisible] = useState(!UserPreferencesService.get('wasSelectorTooltipShown'));
  const onTooltipClose = useCallback(() => {
    UserPreferencesService.set('wasSelectorTooltipShown', true);
    setIsToolTipVisible(false);
  }, []);

  return {isToolTipVisible, onTooltipClose};
};

const Tooltip = ({children, onClose, ...props}: Props) => {
  return (
    <>
      <S.GlobalStyle />
      <AntdTooltip
        {...props}
        overlayClassName="selector-tooltip"
        overlay={
          <S.Overlay>
            <div>
              <S.Title>Switch between envs here!</S.Title>
              <S.Text>
                You can switch between envs or create a new one here. When you switch organizations you are also
                switching to a different environment.
              </S.Text>
            </div>
            <S.Close onClick={onClose} />
          </S.Overlay>
        }
        placement="bottom"
      >
        {children}
      </AntdTooltip>
    </>
  );
};

export default Tooltip;
