import {IAuthzService} from './types';

export enum Group {
  Admins = 'admins',
  Engineers = 'engineers',
  Runners = 'runners',
  Agent = 'agent',
}

export enum Operation {
  Configure = 'configure',
  Edit = 'edit',
  View = 'view',
}

export type TRelationshipEnvironment = {group: Group; operation: Operation};

const Permissions: Record<Operation, Group[]> = {
  [Operation.Configure]: [Group.Admins],
  [Operation.Edit]: [Group.Admins, Group.Engineers],
  [Operation.View]: [Group.Admins, Group.Engineers, Group.Runners],
};

const EnvironmentService: IAuthzService<TRelationshipEnvironment> = {
  check(relationship: TRelationshipEnvironment) {
    return Permissions[relationship.operation]?.includes(relationship.group) ?? false;
  },
};

export default EnvironmentService;
