import {ReadOutlined} from '@ant-design/icons';
import {Typography} from 'antd';
import {load} from 'js-yaml';
import {FramedCodeBlock} from '@core/components/CodeBlock';
import {IMethodChildrenProps} from '@core/components/RunDetailAutomateMethods/RunDetailAutomateMethods';
import * as S from './Typescript.styled';

const getSnippet = (definition: string, variableSetId?: string) => {
  const test = load(definition);
  return `import Tracetest from '@tracetest/client';
import { TestResource } from '@tracetest/client/dist/modules/openapi-client';

const definition: TestResource = ${JSON.stringify(test, null, 2)};

const main = async () => {
  const tracetest = await Tracetest('<your_tracetest_token>');

  const test = await tracetest.newTest(definition);
  await tracetest.runTest(test${variableSetId ? `, { variableSetId: '${variableSetId}' }` : ''});
  console.log(await tracetest.getSummary());
};

main();
`;
};

const TYPESCRIPT_DOCS = 'https://docs.tracetest.io/tools-and-integrations/typescript';

const Typescript = ({definition = '', variableSetId}: IMethodChildrenProps) => (
  <S.Container>
    <S.TitleContainer>
      <S.Title>Typescript Integration</S.Title>
      <a href={TYPESCRIPT_DOCS} target="_blank">
        <ReadOutlined />
      </a>
    </S.TitleContainer>
    <Typography.Paragraph>The code snippet below enables you to run this test via a Typescript.</Typography.Paragraph>
    <FramedCodeBlock
      title="Install:"
      language="bash"
      value="npm i @tracetest/client"
      minHeight="50px"
      maxHeight="50px"
    />
    <br />
    <FramedCodeBlock
      title="Typescript code snippet:"
      language="typescript"
      value={getSnippet(definition, variableSetId)}
    />
  </S.Container>
);

export default Typescript;
