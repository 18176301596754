import {TCommonSchemas} from '@core/types/Common.types';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';

export type TRawProblem = TCommonSchemas['Problem'];
export enum ProblemTypes {
  Forbidden = 'Forbidden',
  InternalServerError = 'InternalServerError',
  NotFound = 'NotFound',
  Unauthorized = 'Unauthorized',
  Unknown = 'Unknown',
}

type Problem = TRawProblem & {args: Record<string, string>};

const defaultProblem: Partial<Problem> = {
  type: 'Unknown',
  title: 'Unknown Error',
  status: 0,
  detail: '',
  instance: '',
  args: {},
};

function Problem({
  type = 'Unknown',
  title = '',
  status = 0,
  detail = '',
  instance = '',
  args = {},
} = defaultProblem): Problem {
  return {
    type,
    title,
    status,
    detail,
    instance,
    args,
  };
}

Problem.Decode = (raw = ''): Problem => {
  try {
    return Problem(JSON.parse(raw));
  } catch (err) {
    return Problem();
  }
};

Problem.Encode = (problem: Problem): string => encodeURIComponent(JSON.stringify(problem));

Problem.FromFetchError = ({status, data}: FetchBaseQueryError): Problem => {
  const decodedStatus = typeof status === 'number' ? status : 0;
  const decoded =
    typeof data === 'string'
      ? Problem({
          status: decodedStatus,
          type: typeFromStatus(decodedStatus),
          title: data,
        })
      : Problem(data as TRawProblem);

  return Problem({
    ...decoded,
    status: decodedStatus,
  });
};

const typeFromStatus = (status: number): ProblemTypes => {
  switch (status) {
    case 401:
      return ProblemTypes.Unauthorized;
    case 403:
      return ProblemTypes.Forbidden;
    case 404:
      return ProblemTypes.NotFound;
    case 500:
      return ProblemTypes.InternalServerError;
    default:
      return ProblemTypes.Unknown;
  }
};

export default Problem;
