import Member from '@models/Member.model';
import Organization from '@models/Organization.model';
import {useConfirmationModal} from '@core/providers/ConfirmationModal/ConfirmationModal.provider';
import {
  useGetMembersMeQuery,
  useDeleteOrganizationMutation,
  useGetOrganizationQuery,
  useDeleteMembersMeMutation,
} from '@redux/apis/TracetestCloud';
import {skipToken} from '@reduxjs/toolkit/query';
import {noop} from 'lodash';
import {createContext, useCallback, useContext, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useOrganizations} from './Organizations.provider';

interface IContext {
  organization?: Organization;
  member: Member;
  deleteOrganization(): void;
  deleteMemberMe(): void;
}

const Context = createContext<IContext>({
  organization: undefined,
  member: Member(),
  deleteOrganization: noop,
  deleteMemberMe: noop,
});

interface IProps {
  children: React.ReactNode;
}

export const OrganizationProvider = ({children}: IProps) => {
  const {organizationId} = useOrganizations();
  const {onOpen} = useConfirmationModal();
  const navigate = useNavigate();

  const {data: member = Member()} = useGetMembersMeQuery(organizationId ? {orgId: organizationId} : skipToken);
  const {data: organization} = useGetOrganizationQuery(
    organizationId ? {orgId: organizationId} : skipToken
  );

  // delete organization
  const [deleteOrg] = useDeleteOrganizationMutation();
  const handleDeleteOrganization = useCallback(() => {
    const onConfirm = async () => {
      await deleteOrg({orgId: organization?.id ?? ''});
    };

    onOpen({
      heading: 'Delete organization',
      title: `Do you really want to delete ${organization?.name}?
              All your historical and analytical data will also be removed.
              This action is not reversible.`,
      okText: 'Delete',
      onConfirm,
    });
  }, [deleteOrg, onOpen, organization?.id, organization?.name]);

  const [deleteMemberMe] = useDeleteMembersMeMutation();
  const handleDeleteMemberMe = useCallback(() => {
    const onConfirm = async () => {
      await deleteMemberMe({orgId: organization?.id ?? ''});
      navigate('/');
    };

    onOpen({
      heading: 'Delete myself from the organization',
      title: `Do you really want to delete yourself from ${organization?.name}?`,
      okText: 'Delete',
      onConfirm,
    });
  }, [deleteMemberMe, navigate, onOpen, organization?.id, organization?.name]);

  const value = useMemo(
    () => ({
      organization,
      member,
      deleteOrganization: handleDeleteOrganization,
      deleteMemberMe: handleDeleteMemberMe,
    }),
    [handleDeleteMemberMe, handleDeleteOrganization, member, organization]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useOrganization = () => useContext(Context);
