import {createContext, useCallback, useContext, useMemo} from 'react';
import Environment from '@models/Environment.model';
import {useConfirmationModal} from '@core/providers/ConfirmationModal/ConfirmationModal.provider';
import {
  useDeleteEnvironmentMembersMeMutation,
  useDeleteEnvironmentMutation,
  useGetEnvironmentMembersMeQuery,
  useGetEnvironmentQuery,
  useRestartEnvironmentAgentMutation,
  useUpdateEnvironmentMutation,
} from '@redux/apis/TracetestCloud';
import {skipToken} from '@reduxjs/toolkit/query';
import noop from 'lodash/noop';
import {useNotification} from '@core/providers/Notification/Notification.provider';
import Member from '@models/Member.model';
import {useNavigate} from 'react-router-dom';
import {useOrganization} from './Organization.provider';
import {useEnvironments} from './Environments.provider';

interface IContext {
  environment?: Environment;
  member: Member;
  deleteEnvironment(): void;
  deleteMemberMe(): void;
  updateEnvironment(update: Environment): void;
  restartAgent(): void;
  isLoading: boolean;
}

const Context = createContext<IContext>({
  environment: undefined,
  member: Member(),
  deleteEnvironment: noop,
  deleteMemberMe: noop,
  updateEnvironment: noop,
  restartAgent: noop,
  isLoading: false,
});

interface IProps {
  children: React.ReactNode;
}

export const EnvironmentProvider = ({children}: IProps) => {
  const {organization} = useOrganization();
  const {environmentId} = useEnvironments();
  const {onOpen} = useConfirmationModal();
  const navigate = useNavigate();
  const {showNotification} = useNotification();

  const {data: member = Member()} = useGetEnvironmentMembersMeQuery(
    organization?.id && environmentId ? {orgId: organization.id, envId: environmentId} : skipToken
  );
  const {data: environment} = useGetEnvironmentQuery(
    organization?.id && environmentId ? {envId: environmentId} : skipToken,
    {
      pollingInterval: 10000,
    }
  );

  // delete environment
  const [deleteEnv, {isLoading: isDeleteLoading}] = useDeleteEnvironmentMutation();
  const handleDeleteEnvironment = useCallback(() => {
    const onConfirm = async () => {
      await deleteEnv({envId: environment?.id ?? ''});
      showNotification({
        type: 'success',
        title: (
          <>
            Environment <b>{environment?.name}</b> deleted successfully
          </>
        ),
      });

      navigate(`/organizations/${organization?.id}`);
    };

    onOpen({
      heading: 'Delete environment',
      title: `Do you really want to delete ${environment?.name}?
              All your historical and analytical data will also be removed.
              This action is not reversible.`,
      okText: 'Delete',
      onConfirm,
    });
  }, [deleteEnv, environment?.id, environment?.name, navigate, onOpen, organization?.id, showNotification]);

  const [deleteMemberMe, {isLoading: isDeleteMeLoading}] = useDeleteEnvironmentMembersMeMutation();
  const handleDeleteMemberMe = useCallback(() => {
    const onConfirm = async () => {
      await deleteMemberMe({envId: environment?.id ?? '', orgId: environment?.organizationID ?? ''});
      showNotification({
        type: 'success',
        title: (
          <>
            You have been deleted from Environment <b>{environment?.name}</b> successfully
          </>
        ),
      });
      navigate(`/organizations/${organization?.id}`);
    };

    onOpen({
      heading: 'Delete myself from the environment',
      title: `Do you really want to delete yourself from ${environment?.name}?`,
      okText: 'Delete',
      onConfirm,
    });
  }, [
    deleteMemberMe,
    environment?.id,
    environment?.name,
    environment?.organizationID,
    navigate,
    onOpen,
    organization?.id,
    showNotification,
  ]);

  const [updateEnv, {isLoading: isUpdateLoading}] = useUpdateEnvironmentMutation();
  const handleUpdateEnvironment = useCallback(
    (update: Partial<Environment>) =>
      updateEnv({
        envId: environment?.id ?? '',
        environment: {...environment, ...update} as Environment,
      }),
    [environment, updateEnv]
  );

  const [restartAgent, {isLoading: isRestartLoading}] = useRestartEnvironmentAgentMutation();
  const handleRestartAgent = useCallback(() => {
    restartAgent({envId: environment?.id ?? '', orgId: environment?.organizationID ?? ''});
  }, [environment?.id, environment?.organizationID, restartAgent]);

  const value = useMemo(
    () => ({
      environment,
      member,
      deleteEnvironment: handleDeleteEnvironment,
      deleteMemberMe: handleDeleteMemberMe,
      updateEnvironment: handleUpdateEnvironment,
      restartAgent: handleRestartAgent,
      isLoading: isDeleteLoading || isDeleteMeLoading || isUpdateLoading || isRestartLoading,
    }),
    [
      environment,
      handleDeleteEnvironment,
      handleDeleteMemberMe,
      handleRestartAgent,
      handleUpdateEnvironment,
      isDeleteLoading,
      isDeleteMeLoading,
      isRestartLoading,
      isUpdateLoading,
      member,
    ]
  );

  return <Context.Provider value={value}>{!!environment && children}</Context.Provider>;
};

export const useEnvironment = () => useContext(Context);
