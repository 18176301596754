import {SearchOutlined} from '@ant-design/icons';
import {useMemo} from 'react';
import {debounce} from 'lodash';
import {Input} from 'antd';
import * as S from './EnvironmentTokenList.styled';

interface IProps {
  onNameFilter(name: string): void;
}

const Filters = ({onNameFilter}: IProps) => {
  const handleName = useMemo(
    () =>
      debounce(event => {
        onNameFilter(event.target.value);
      }, 500),
    [onNameFilter]
  );

  return (
    <S.FiltersContainer>
      <Input prefix={<SearchOutlined />} placeholder="Search tokens" onChange={handleName} />
    </S.FiltersContainer>
  );
};

export default Filters;
