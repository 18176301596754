import {Badge} from 'antd';
import styled from 'styled-components';

interface IProps {
  offset?: [number, number];
}

const NewBadge = styled(Badge).attrs<IProps>(({offset = [30, 7]}) => ({
  count: 'New',
  size: 'small',
  offset,
}))`
  && {
    color: inherit;
    sup {
      box-shadow: none;
      font-weight: 700;
      background: ${({theme}) => theme.color.white};
      color: ${({theme}) => theme.color.primary};
    }
  }
`;

export default NewBadge;
