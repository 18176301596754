import {useState} from 'react';
import CreateTestAnalytics from '@core/services/Analytics/CreateTest.service';
import {CreateTriggerTypeToPlugin} from '@core/constants/Plugins.constants';
import {TriggerTypes} from '@core/constants/Test.constants';
import {Typography} from 'antd';
import TriggerTypeCard from '@core/components/TriggerTypeModal/TriggerTypeCard';
import {Integrations} from '@constants/integrations.constants';
import * as O from '@core/components/TriggerTypeModal/TriggerTypeModal.styled';
import * as S from './TriggerTypeList.styled';
import IntegrationTypeCard from './IntegrationTypeCard';

const pluginList = Object.values(CreateTriggerTypeToPlugin);
const integrationList = Object.values(Integrations);

interface IProps {
  onClick(plugin: TriggerTypes): void;
}

const TriggerTypeList = ({onClick}: IProps) => {
  const [selectedType, setSelectedType] = useState<TriggerTypes | undefined>(undefined);

  return (
    <>
      <O.CardList>
        {pluginList.map(plugin => (
          <TriggerTypeCard
            key={plugin.name}
            isSelected={selectedType === plugin.type}
            onClick={selectedPlugin => {
              CreateTestAnalytics.onTriggerSelect(selectedPlugin.type);
              onClick(selectedPlugin.type);
              setSelectedType(selectedPlugin.type);
            }}
            plugin={plugin}
          />
        ))}
      </O.CardList>

      <S.Divider />

      <Typography.Title level={3}>Or trigger a Tracetest via an external integration</Typography.Title>

      <O.CardList>
        {integrationList.map(integration => (
          <IntegrationTypeCard key={integration.name} integration={integration} />
        ))}
      </O.CardList>
    </>
  );
};

export default TriggerTypeList;
