import {SupportedPlugins} from '@core/constants/Common.constants';
import {TriggerTypeToPlugin} from '@core/constants/Plugins.constants';
import {TriggerTypes} from '@core/constants/Test.constants';
import {toRawTestOutputs} from '@core/models/TestOutput.model';
import {IPlugin} from '@core/types/Plugins.types';
import {stringify, parse} from 'yaml';
import {IAsset, TDraftTest} from '@core/types/Test.types';
import Span from '@core/models/Span.model';
import {SemanticGroupNames} from '@core/constants/SemanticGroupNames.constants';
import Validator from '@core/utils/Validator';
import Test, {TRawTest, TRawTestResource} from '@core/models/Test.model';
import TestDefinitionService from './TestDefinition.service';
import GrpcService from './Triggers/Grpc.service';
import HttpService from './Triggers/Http.service';
import TraceIDService from './Triggers/TraceID.service';
import CypressService from './Triggers/Cypress.service';
import KafkaService from './Triggers/Kafka.service';
import PlaywrightEngineService from './Triggers/PlaywrightEngine.service';
import GraphqlService from './Triggers/Graphql.service';
import SpanService from './Span.service';

const authValidation = ({auth}: TDraftTest): boolean => {
  switch (auth?.type) {
    case 'apiKey':
      return Validator.required(auth?.apiKey?.key) && Validator.required(auth?.apiKey?.value);
    case 'basic':
      return Validator.required(auth?.basic?.username) && Validator.required(auth?.basic?.password);
    case 'bearer':
      return Validator.required(auth?.bearer?.token);
    default:
      return true;
  }
};

const basicDetailsValidation = ({name}: TDraftTest): boolean => {
  return Validator.required(name);
};

const TriggerServiceMap = {
  [SupportedPlugins.GRPC]: GrpcService,
  [SupportedPlugins.REST]: HttpService,
  [SupportedPlugins.Kafka]: KafkaService,
  [SupportedPlugins.TraceID]: TraceIDService,
  [SupportedPlugins.Cypress]: CypressService,
  [SupportedPlugins.Playwright]: CypressService,
  [SupportedPlugins.Artillery]: CypressService,
  [SupportedPlugins.K6]: CypressService,
  [SupportedPlugins.PlaywrightEngine]: PlaywrightEngineService,
  [SupportedPlugins.Graphql]: GraphqlService,
} as const;

const TriggerServiceByTypeMap = {
  [TriggerTypes.grpc]: GrpcService,
  [TriggerTypes.http]: HttpService,
  [TriggerTypes.traceid]: TraceIDService,
  [TriggerTypes.cypress]: CypressService,
  [TriggerTypes.playwright]: CypressService,
  [TriggerTypes.artillery]: CypressService,
  [TriggerTypes.k6]: CypressService,
  [TriggerTypes.kafka]: KafkaService,
  [TriggerTypes.playwrightEngine]: PlaywrightEngineService,
  [TriggerTypes.graphql]: GraphqlService,
} as const;

const SpanTypeToField: Record<string, string> = {
  [SemanticGroupNames.Http]: 'httpRequest',
} as const;

const TestService = () => ({
  async getRequest(
    {type, requestType, name: pluginName}: IPlugin,
    draft: TDraftTest,
    original?: Test
  ): Promise<TRawTestResource> {
    const {name, description, skipTraceCollection = false, pollingProfile} = draft;
    const triggerService = TriggerServiceMap[pluginName];
    const request = await triggerService.getRequest(draft);

    const trigger = {
      type,
      triggerType: type,
      ...(request && {[requestType ?? type]: request}),
    };

    return {
      type: 'Test',
      spec: {
        name,
        description,
        trigger,
        pollingProfile,
        skipTraceCollection,
        ...(original
          ? {
              outputs: toRawTestOutputs(original.outputs ?? []),
              specs: original.definition.specs.map(def => TestDefinitionService.toRaw(def)),
            }
          : {}),
      },
    };
  },

  async validateDraft(pluginName: SupportedPlugins, draft: TDraftTest, isBasicDetails = false): Promise<boolean> {
    const triggerService = TriggerServiceMap[pluginName];
    const isTriggerValid = await triggerService.validateDraft(draft);

    return (isBasicDetails && basicDetailsValidation(draft)) || (isTriggerValid && authValidation(draft));
  },

  getInitialValues({trigger: {request, type}, name, description, skipTraceCollection, pollingProfile}: Test) {
    const triggerService = TriggerServiceByTypeMap[type];

    return {
      name,
      description,
      type,
      skipTraceCollection,
      pollingProfile,
      ...triggerService.getInitialValues!(request),
    };
  },

  getUpdatedRawTest(test: Test, partialTest: Partial<Test>): Promise<TRawTestResource> {
    const plugin = TriggerTypeToPlugin[test?.trigger?.type || TriggerTypes.http];
    const testTriggerData = this.getInitialValues(test);
    const updatedTest = {...test, ...partialTest};
    return this.getRequest(plugin, testTriggerData, updatedTest);
  },

  async getDuplicatedRawTest(test: Test, name: string): Promise<TRawTestResource> {
    const raw = await this.getUpdatedRawTest(test, {});
    return {...raw, spec: {...raw.spec, name}};
  },

  getParsedDefinition({trigger: {request, type}}: Test, definition: string): {definition: string; assets: IAsset[]} {
    const triggerService = TriggerServiceByTypeMap[type];

    const parsed = parse(definition) as TRawTestResource;
    const {trigger: rawTrigger, assets} = triggerService.getParsedDefinition(request);

    return {
      definition: rawTrigger ? stringify({...parsed, spec: {...parsed.spec, trigger: rawTrigger}}) : definition,
      assets,
    };
  },

  getRequestFromSpan(span: Span): TRawTest | undefined {
    const type = SpanService.getSpanTriggerType(span);
    if (!type) return undefined;

    const triggerService = TriggerServiceByTypeMap[type];
    const request = triggerService.getRequestFromSpan(span);

    if (!request) return undefined;

    const trigger = {
      type,
      triggerType: type,
      ...(request && {[SpanTypeToField[type] ?? type]: request}),
    };

    return {
      name: span.name,
      description: '',
      trigger,
    };
  },
  getInitialValuesFromQuery(query: string): TDraftTest {
    if (!query) return {};

    try {
      const parsed = parse(query) as TRawTest;
      const {
        trigger: {request, type},
        name,
        description,
        skipTraceCollection,
        pollingProfile,
      } = Test.FromRawTest(parsed);
      const triggerService = TriggerServiceByTypeMap[type];

      return {
        name,
        description,
        skipTraceCollection,
        pollingProfile,
        ...triggerService.getInitialValues!(request),
      };
    } catch (e) {
      return {};
    }
  },
});

export default TestService();
