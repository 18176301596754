import {createContext, useContext, useMemo} from 'react';
import User from '@models/User.model';
import {Session} from '@ory/client';

interface IContext {
  user: User;
}

const Context = createContext<IContext>({
  user: User(),
});

interface IProps {
  children: React.ReactNode;
  session: Session;
}

export const UserProvider = ({children, session}: IProps) => {
  const value = useMemo(
    () => ({
      user: User.FromSession(session),
    }),
    [session]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useUser = () => useContext(Context);
