import {Typography} from 'antd';
import * as S from './Status.styled';

export type TConnectionStatus = 'loading' | 'success' | 'conflict' | 'conflict-serverless' | 'idle';

const Icon = ({status}: {status: TConnectionStatus}) => {
  switch (status) {
    case 'loading':
      return <S.LoadingIcon />;
    case 'success':
      return <S.IconSuccess />;
    case 'conflict':
      return <S.IconFail />;
    case 'conflict-serverless':
      return <S.IconFail />;
    case 'idle':
    default:
      return <S.IconInfo />;
  }
};

const getText = (status: TConnectionStatus) => {
  switch (status) {
    case 'loading':
      return 'Waiting for Agent status';
    case 'success':
      return 'Successful Connection';
    case 'conflict':
      return 'You have a public setup running. Go to the "Application is publicly accessible" section to stop it';
    case 'conflict-serverless':
      return 'You have an existing Agent running. Stop it to continue with the public setup';
    case 'idle':
    default:
      return 'Waiting for Agent status';
  }
};

interface IProps {
  status: TConnectionStatus;
}

const Status = ({status}: IProps) => (
  <S.Container>
    <Icon status={status} />
    <Typography.Text type="secondary">
      <b>{getText(status)}</b>
    </Typography.Text>
  </S.Container>
);

export default Status;
