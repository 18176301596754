import ContentResources from '@core/components/Resources/Content';
import HeaderResources from '@core/components/Resources/Header';
import * as S from '@core/pages/Wizard/Wizard.styled';
import {Collapse} from 'antd';

const Resources = () => (
  <S.Container>
    <S.Header>
      <S.Title>Welcome to Tracetest!</S.Title>
      <S.Text>Here&apos;s a guide to get started and help you test your modern applications with OpenTelemetry.</S.Text>
    </S.Header>

    <Collapse defaultActiveKey="resources">
      <Collapse.Panel header={<HeaderResources />} key="resources">
        <ContentResources />
      </Collapse.Panel>
    </Collapse>
  </S.Container>
);

export default Resources;
