import {Model, TGraphqlSchemas} from '@core/types/Common.types';
import {THeader} from '@core/types/Test.types';

export type TRawGraphqlRequest = TGraphqlSchemas['GraphqlRequest'];

type GraphqlRequest = Model<
  TRawGraphqlRequest,
  {
    headers: THeader[];
  }
>;

const GraphqlRequest = ({
  url = '',
  headers = [],
  auth = {},
  body = {},
  sslVerification = false,
  schema = '',
}: TRawGraphqlRequest): GraphqlRequest => {
  return {
    url,
    headers: headers.map(({key = '', value = ''}) => ({
      key,
      value,
    })),
    auth,
    body: {
      query: body.query || '',
      variables: body.variables || {},
      operationName: body.operationName || '',
    },
    sslVerification,
    schema,
  };
};

export default GraphqlRequest;
