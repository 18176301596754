import logo from '@assets/logo.svg';
import * as S from './Loading.styled';

const Loading = () => (
  <S.Container>
    <S.LogoContainer>
      <img alt="Tracetest logo" src={logo} />
      <S.Loader />
    </S.LogoContainer>
  </S.Container>
);

export default Loading;
