import {getIsAgentOffline} from '@components/molecules/CreateButton';
import AllowButton, {Operation} from '@core/components/AllowButton';
import Empty from '@core/components/Empty';
import {ADD_TEST_URL} from '@core/constants/Common.constants';
import {useEnvironment} from '@providers/Environment.provider';
import {Button} from 'antd';
import {useNavigate} from 'react-router-dom';
import * as S from './EmptyTestList.styled';

export const DEMO_ORG_DOCS_URL = 'https://docs.tracetest.io/getting-started/try-live-demos';

interface IProps {
  onClick(): void;
}

const EmptyTestList = ({onClick}: IProps) => {
  const {environment} = useEnvironment();
  const navigate = useNavigate();
  const isAgentOffline = getIsAgentOffline(environment?.agent);

  const handleAlertOnClick = () => {
    navigate(`/organizations/${environment?.organizationID}/environments/${environment?.id}/settings?tab=agent`);
  };

  if (isAgentOffline) {
    return (
      <Empty
        title="Haven't Created a Test Yet"
        message={
          <>
            Your agent is currently disconnected. To create tests, the agent needs to be active and ready to collect
            response and trace information. Please start the agent.
          </>
        }
        action={
          <Button onClick={handleAlertOnClick} type="primary">
            Setup Agent
          </Button>
        }
      />
    );
  }

  return (
    <Empty
      title="Haven't Created a Test Yet"
      message={
        <>
          Hit the &apos;Create&apos; button below to kickstart your testing adventure. Want to learn more about tests?
          Just click{' '}
          <S.Link href={ADD_TEST_URL} target="_blank">
            here
          </S.Link>
          . If you don’t have an app that’s generating OpenTelemetry traces we have a demo for you. Follow these{' '}
          <S.Link href={DEMO_ORG_DOCS_URL} target="_blank">
            instructions
          </S.Link>
          !
        </>
      }
      action={
        <AllowButton operation={Operation.Edit} onClick={onClick} type="primary">
          Create Your First Test
        </AllowButton>
      }
    />
  );
};

export default EmptyTestList;
