import Panel from '@components/molecules/Panel';
import LimitedAccess from '@components/organisms/LimitedAccess';
import DocsBanner from '@core/components/DocsBanner';
import {TRACE_POLLING_DOCUMENTATION_URL} from '@core/constants/Common.constants';
import {useSettingsValues} from '@core/providers/SettingsValues/SettingsValues.provider';
import {useEnvironment} from '@providers/Environment.provider';
import {Descriptions, Typography} from 'antd';
import * as S from '../Settings.styled';

const LimitedTracePolling = () => {
  const {environment, member} = useEnvironment();
  const {pollingProfile} = useSettingsValues();

  return (
    <Panel title="Trace Polling">
      <Typography.Paragraph type="secondary">
        <p>Tracetest uses polling to gather the distributed trace associated with each test run.</p>
        <DocsBanner>
          Need more information about Trace Polling?{' '}
          <a href={TRACE_POLLING_DOCUMENTATION_URL} target="__blank">
            Learn more in our docs
          </a>
        </DocsBanner>
      </Typography.Paragraph>

      <LimitedAccess
        description="To configure the Trace Polling settings, please contact the environment administrator for assistance."
        organizationID={environment?.organizationID}
        role={member.role}
      />

      <S.Divider />

      <div>
        <Typography.Title level={3}>Current Trace Polling settings:</Typography.Title>

        <S.Descriptions size="small" column={1}>
          <Descriptions.Item label="Max wait time for trace">{pollingProfile.periodic.timeout}</Descriptions.Item>
          <Descriptions.Item label="Retry delay">{pollingProfile.periodic.retryDelay}</Descriptions.Item>
        </S.Descriptions>
      </div>
    </Panel>
  );
};

export default LimitedTracePolling;
