import Panel from '@components/molecules/Panel';
import EnvironmentTokensList from '@components/organisms/EnvironmentTokenList';
import TokenModal from '@components/organisms/TokenModal';
import {ENVIRONMENT_TOKENS_DOCUMENTATION} from '@constants/url.constants';
import {EnvironmentOperation, useAuthz} from '@hooks/authz';
import Token from '@models/Token.model';
import DocsBanner from '@core/components/DocsBanner';
import {useConfirmationModal} from '@core/providers/ConfirmationModal/ConfirmationModal.provider';
import {useNotification} from '@core/providers/Notification/Notification.provider';
import {useEnvironment} from '@providers/Environment.provider';
import {useDeleteTokenMutation, useGetTokensQuery, useUpsertTokenMutation} from '@redux/apis/TracetestCloud';
import {skipToken} from '@reduxjs/toolkit/query';
import {PAGE_SIZE} from '@constants/list.constants';
import {Button, Typography} from 'antd';
import {useState} from 'react';

const EnvironmentTokens = () => {
  const {environment} = useEnvironment();
  const {showNotification} = useNotification();
  const {onOpen} = useConfirmationModal();
  const {checkEnvironment} = useAuthz();
  const canEdit = checkEnvironment(EnvironmentOperation.Edit);

  // create token modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  // delete token
  const [deleteToken] = useDeleteTokenMutation();
  const handleDeleteToken = (value: Token) => {
    const onConfirm = async () => {
      await deleteToken({tokenId: value.id});
      showNotification({
        type: 'success',
        title: 'Token revoked successfully',
      });
    };

    onOpen({
      heading: 'Revoke token',
      title: `Are you sure you want to revoke ${value.name}?`,
      okText: 'Revoke',
      onConfirm,
    });
  };

  const [selectedToken, setSelectedToken] = useState<Token | undefined>(undefined);
  const [upserToken, {isLoading: isCreateTokenLoading}] = useUpsertTokenMutation();
  const handleUpsertToken = async (values: Token, isEditing: boolean) => {
    await upserToken({token: values});
    showNotification({
      type: 'success',
      title: `Token ${values.name} ${isEditing ? 'updated' : 'created'} successfully`,
    });
  };

  // fetch tokens
  const [query, setQuery] = useState('');
  const [skip, setSkip] = useState(0);
  const {data: {items: tokens = [], total = 0} = {}, isLoading} = useGetTokensQuery(
    environment?.id ? {take: PAGE_SIZE, query, skip} : skipToken
  );

  return (
    <>
      <TokenModal
        isLoading={isCreateTokenLoading}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedToken(undefined);
        }}
        onSubmit={handleUpsertToken}
        initialValues={selectedToken}
      />

      <Panel
        title="Environment tokens"
        actions={
          <Button
            disabled={!checkEnvironment(EnvironmentOperation.Edit)}
            onClick={() => setIsModalOpen(true)}
            type="primary"
          >
            Create a new token
          </Button>
        }
      >
        <Typography.Paragraph type="secondary">
          <p>
            Environment Tokens are strings employed to authenticate the Tracetest CLI within both an organization and a
            specific environment.
          </p>
          <DocsBanner>
            Need more information about Tokens?{' '}
            <a href={ENVIRONMENT_TOKENS_DOCUMENTATION} target="__blank">
              Learn more in our docs
            </a>
          </DocsBanner>
        </Typography.Paragraph>
        <EnvironmentTokensList
          total={total}
          canEdit={canEdit}
          tokens={tokens}
          isLoading={isLoading}
          onDelete={handleDeleteToken}
          onEdit={selected => {
            setIsModalOpen(true);
            setSelectedToken(selected);
          }}
          onSearch={setQuery}
          onPageChange={(page, pageSize) => setSkip((page - 1) * pageSize)}
          pageSize={PAGE_SIZE}
        />
      </Panel>
    </>
  );
};

export default EnvironmentTokens;
