import Action from '@components/atoms/Action';
import {RoleToName} from '@core/types/Common.types';
import {EnvironmentOperation, useAuthz} from '@hooks/authz';
import Member from '@models/Member.model';
import User from '@models/User.model';
import {Avatar, List} from 'antd';

interface IProps {
  members?: Member[];
  isLoading: boolean;
  onDelete?(member: Member): void;
  onUpdate?(member: Member): void;
  onPageChange?(page: number, pageSize: number): void;
  total: number;
  pageSize: number;
}

const EnvironmentMembers = ({members, onDelete, onUpdate, isLoading, pageSize, total, onPageChange}: IProps) => {
  const {checkEnvironment} = useAuthz();
  const canEdit = checkEnvironment(EnvironmentOperation.Edit);

  return (
    <List<Member>
      bordered
      loading={isLoading}
      pagination={{
        hideOnSinglePage: true,
        onChange: onPageChange,
        pageSize,
        position: 'bottom',
        showSizeChanger: false,
        total,
      }}
      dataSource={members}
      renderItem={item => (
        <List.Item
          actions={[
            <Action
              disabled={!canEdit || item.isOrgAdmin}
              item={item}
              key="member-update"
              onClick={onUpdate}
              title="update role"
              tooltip={
                item.isOrgAdmin
                  ? 'You cannot update an environment member role if they are an organization owner or admin.'
                  : undefined
              }
            />,
            <Action
              disabled={!canEdit || item.isOrgAdmin}
              item={item}
              key="member-delete"
              onClick={onDelete}
              title="remove member"
              tooltip={
                item.isOrgAdmin
                  ? 'You cannot remove an environment member if they are an organization owner or admin.'
                  : undefined
              }
            />,
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar />}
            title={User.getEmail(item.user)}
            description={item.invite?.createdAt && `Created at ${item.invite?.createdAt}`}
          />
          <div>{RoleToName[item.role]}</div>
        </List.Item>
      )}
    />
  );
};

export default EnvironmentMembers;
