import {COMMUNITY_SLACK_URL} from '@core/constants/Common.constants';
import {ConfigMode, SupportedDataStores} from '@core/types/DataStore.types';
import {Button, Typography} from 'antd';
import noop from 'lodash/noop';
import BackendCard from './BackendCard';
import * as S from './DataStore.styled';

const backends = Object.values(SupportedDataStores);

interface IProps {
  configMode: ConfigMode;
  isWizard?: boolean;
  onSelect(backend: SupportedDataStores): void;
  onSkip?(): void;
  selectedBackend?: SupportedDataStores;
}

const Selector = ({configMode, isWizard = false, onSelect, onSkip = noop, selectedBackend}: IProps) => (
  <S.Container>
    {isWizard && (
      <Typography.Title level={1}>Tell us how Tracetest should ingest traces from your application</Typography.Title>
    )}

    <Typography.Paragraph type="secondary">Select your Tracing Backend</Typography.Paragraph>

    <S.BackendSelector>
      {backends.map(backend => (
        <BackendCard
          backend={backend}
          selectedBackend={selectedBackend}
          key={backend}
          onSelect={() => onSelect(backend)}
        />
      ))}
    </S.BackendSelector>

    <S.Row>
      <Typography.Paragraph type="secondary">
        Don&apos;t see the Tracing Backend you need?{' '}
        <a href={COMMUNITY_SLACK_URL} target="__blank">
          Submit a request.
        </a>
      </Typography.Paragraph>

      {isWizard && configMode === ConfigMode.NO_TRACING_MODE && (
        <Button type="primary" ghost onClick={() => onSkip()}>
          Setup trace ingestion later
        </Button>
      )}
    </S.Row>
  </S.Container>
);

export default Selector;
