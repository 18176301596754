import * as S from '@components/molecules/MenuSelector';
import Environment from '@models/Environment.model';
import {Tooltip} from 'antd';
import AgentBadge from '../AgentBadge';

const {REACT_APP_DEMO_ORG_ID = ''} = process.env;
const LIVE_DEMOS_DOCS_URL = 'https://docs.tracetest.io/getting-started/try-live-demos';

interface IProps {
  environment: Environment;
  isSelected: boolean;
  onSettingsClick: (environment: Environment, path: string) => void;
}

const Item = ({
  environment: {
    organizationID = '',
    name,
    agent: {status},
    agentConfiguration: {serverless},
  },
  environment,
  isSelected,
  onSettingsClick,
}: IProps) => (
  <S.ItemContainer data-testid="selector-item">
    <S.DetailsContainer>
      <div />
      <S.NameText>
        {name}
        {isSelected && <S.SelectedIcon size={16} />}
      </S.NameText>
      <S.Row>
        <AgentBadge status={status} isServerless={serverless} showText />
      </S.Row>
    </S.DetailsContainer>
    <div />
    <S.IconContainer>
      {REACT_APP_DEMO_ORG_ID === organizationID && (
        <Tooltip title="Demo env documentation">
          <S.DocsLink href={LIVE_DEMOS_DOCS_URL} target="__blank">
            <S.Documentation size={16} />
          </S.DocsLink>
        </Tooltip>
      )}
      <Tooltip title="Settings">
        <S.Settings
          size={16}
          onClick={e => {
            e.stopPropagation();
            onSettingsClick(environment, '/settings');
          }}
        />
      </Tooltip>
    </S.IconContainer>
  </S.ItemContainer>
);

export default Item;
