import ExpirationSelector from '@components/molecules/ExpirationSelector';
import Token from '@models/Token.model';
import {EnvironmentGroup} from '@services/Authz';
import {Form, FormInstance, Input, Select, Switch} from 'antd';
import capitalize from 'lodash/capitalize';

const roles = Object.values(EnvironmentGroup);

interface IProps {
  enabledRoles?: string[];
  form: FormInstance<Token>;
  initialValues?: Record<string, any>;
  isEditing?: boolean;
  onSubmit(values: Token): void;
}

const FormBody = ({enabledRoles = roles, form, initialValues, isEditing, onSubmit}: IProps) => {
  const role = Form.useWatch('role', form);
  const issuedAt = Form.useWatch('issuedAt', form);

  return (
    <Form<Token> form={form} initialValues={initialValues} layout="vertical" name="token" onFinish={onSubmit}>
      <Form.Item name="id" hidden>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item name="issuedAt" hidden>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item label="Name" name="name" rules={[{required: true, message: 'Please add a name'}]}>
        <Input placeholder="e.g. my-token" />
      </Form.Item>

      <Form.Item label="Role" name="role" rules={[{required: true, message: 'Please select a role'}]}>
        <Select disabled={isEditing} placeholder="Select a role">
          {enabledRoles.map(item => (
            <Select.Option key={item} value={item}>
              {capitalize(item)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {role === EnvironmentGroup.Agent && (
        <Form.Item label="Is Default Agent" name="isAgentToken" valuePropName="checked">
          <Switch />
        </Form.Item>
      )}

      <Form.Item
        label="Expiration"
        name="duration"
        normalize={value => (value ? parseInt(String(value), 10) : undefined)}
        rules={[{required: true, message: 'Please select an expiration date'}]}
      >
        <ExpirationSelector createdAt={issuedAt} />
      </Form.Item>
    </Form>
  );
};

export default FormBody;
