import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import * as S from './TestsSelection.styled';

interface IProps {
  resource: {id: string; name: string};
  sortableId: string;
  onDelete(sortableId: string): void;
  path: string;
}

const TestItem = ({resource, onDelete, sortableId, path}: IProps) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: sortableId});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <S.TestItemContainer ref={setNodeRef} style={style} {...attributes}>
      <S.DragHandle {...listeners} />
      <S.TestNameContainer>
        <S.TestLink to={`/${path}/${resource.id}`} target="_blank">
          <span>{resource.name}</span>
        </S.TestLink>
      </S.TestNameContainer>
      <S.DeleteIcon onClick={() => onDelete(sortableId)} />
    </S.TestItemContainer>
  );
};

export default TestItem;
