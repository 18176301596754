import {Button} from 'antd';

import * as S from './PollingProfileModal.styled';

interface IProps {
  isLoading: boolean;
  onClose(): void;
  onSubmit(): void;
  isEditing: boolean;
}

const Footer = ({isEditing, isLoading, onClose, onSubmit}: IProps) => (
  <S.Footer>
    <span>
      <Button type="primary" ghost onClick={onClose}>
        Cancel
      </Button>
    </span>
    <span>
      <Button htmlType="submit" loading={isLoading} type="primary" onClick={onSubmit}>
        {isEditing ? 'Update' : 'Create'}
      </Button>
    </span>
  </S.Footer>
);

export default Footer;
