import Container from '@components/atoms/Container';
import Skeleton from '@components/atoms/Skeleton';
import Panel from '@components/molecules/Panel';
import {Layout} from 'antd';
import * as S from './LoadingLayout.styled';

const LoadingLayout = () => (
  <Layout>
    <Container>
      <S.Header>
        <Skeleton height={22} width={100} />
        <Skeleton height={22} width={300} />
      </S.Header>
    </Container>

    <Container>
      <S.Navigation>
        <Skeleton height={22} width={100} />
      </S.Navigation>
      <Panel>
        <Skeleton height={22} width={150} />
        <Skeleton height={22} />
        <Skeleton height={22} />
        <Skeleton height={22} />
      </Panel>
    </Container>
  </Layout>
);

export default LoadingLayout;
