import * as S from './Panel.styled';

interface IProps {
  actions?: React.ReactNode;
  children?: React.ReactNode;
  danger?: boolean;
  title?: React.ReactNode;
  className?: string;
}

const Panel = ({actions, children, danger, title, className}: IProps) => (
  <S.Container $danger={danger} className={className}>
    {(!!actions || !!title) && (
      <S.Header>
        {typeof title === 'string' ? <S.Title level={2}>{title}</S.Title> : title}
        <div>{actions}</div>
      </S.Header>
    )}
    {children}
  </S.Container>
);

export default Panel;
