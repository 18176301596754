import {CompositePropagator, W3CBaggagePropagator, W3CTraceContextPropagator} from '@opentelemetry/core';
import {WebTracerProvider} from '@opentelemetry/sdk-trace-web';
import {SimpleSpanProcessor} from '@opentelemetry/sdk-trace-base';
import {registerInstrumentations} from '@opentelemetry/instrumentation';
import {Resource} from '@opentelemetry/resources';
import {SemanticResourceAttributes} from '@opentelemetry/semantic-conventions';
import {getWebAutoInstrumentations} from '@opentelemetry/auto-instrumentations-web';
import {ZoneContextManager} from '@opentelemetry/context-zone';
import {UserInteractionInstrumentation} from '@tracetest/instrumentation-user-interaction';
import {OTLPTraceExporter} from '@opentelemetry/exporter-trace-otlp-http';

const createTracer = async () => {
  const provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: 'tracetest-cloud-frontend',
    }),
  });

  if (process.env.REACT_APP_OTEL_COLLECTR_ENDPOINT) {
    provider.addSpanProcessor(
      new SimpleSpanProcessor(new OTLPTraceExporter({url: process.env.REACT_APP_OTEL_COLLECTR_ENDPOINT}))
    );
  }

  provider.register({
    contextManager: new ZoneContextManager(),
    propagator: new CompositePropagator({
      propagators: [new W3CBaggagePropagator(), new W3CTraceContextPropagator()],
    }),
  });

  registerInstrumentations({
    tracerProvider: provider,
    instrumentations: [
      new UserInteractionInstrumentation(),
      getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-fetch': {
          propagateTraceHeaderCorsUrls: /.*/,
          clearTimingResources: true,
        },
        '@opentelemetry/instrumentation-user-interaction': {
          enabled: false,
        },
      }),
    ],
  });
};

createTracer();
