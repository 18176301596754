import {FramedCodeBlock} from '@core/components/CodeBlock';
import {IMethodChildrenProps} from '@core/types/PublicOTLPMethod.types';
import {Collapse} from 'antd';
import * as S from '../common.styled';

function getCodeBlocks(endpoint?: string, token?: string) {
  return {
    code1: `npm install @tracetest/opentelemetry-web
`,
    code2: `/*instrumentation.js*/
import TracetestWebSDK from "@tracetest/opentelemetry-web";

const sdk = new TracetestWebSDK({
  serviceName: "browser-service",
  apiKey: "${token}",
  endpoint: "${endpoint}/v1/traces",
});

sdk.start();
`,
    code3: `// index.js
import './instrumentation.js'

// ...rest of the app's entrypoint code`,
  };
}

const Browser = ({endpoint, token}: IMethodChildrenProps) => {
  const {code1, code2, code3} = getCodeBlocks(endpoint, token);

  return (
    <S.Collapse accordion ghost defaultActiveKey="1" expandIconPosition="right">
      <Collapse.Panel header={<S.StepTitle level={3}>1. Install dependencies</S.StepTitle>} key="1">
        <S.StepText>First, install the Tracetest Web SDK.</S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code1} language="bash" title="Install dependencies" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
      <Collapse.Panel header={<S.StepTitle level={3}>2. Setup</S.StepTitle>} key="2">
        <S.StepText>
          Create a file named instrumentation.js, which will contain your instrumentation setup code.
        </S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code2} language="javascript" title="Setup" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
      <Collapse.Panel header={<S.StepTitle level={3}>3. Import and Run the App</S.StepTitle>} key="3">
        <S.StepText>
          Load the initialization file at the top of your web page&apos;s header or entrypoint file.
        </S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code3} language="javascript" title="Run the app" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
    </S.Collapse>
  );
};

export default Browser;
