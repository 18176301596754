import * as S from './Header.styled';

interface IProps {
  title?: string;
}

const Header = ({title}: IProps) => (
  <S.Container>
    <div>
      <S.Title>📚 {title || 'More resources'}</S.Title>
      <S.Text>Get acquainted with useful information.</S.Text>
    </div>
  </S.Container>
);

export default Header;
