import {useEffect} from 'react';
import {addListener, isRejected} from '@reduxjs/toolkit';
import {useAppDispatch} from '@redux/hooks';
import {useNavigate} from 'react-router-dom';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import Problem, {TRawProblem} from '@models/Problem.model';

const skipErrorInstances = ['GetLicense'];

const useErrorHandler = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return dispatch(
      addListener({
        matcher: isRejected,
        effect: action => {
          if (action.type.includes('executeQuery/rejected')) {
            const error = action.payload as FetchBaseQueryError;
            const instance = (error?.data as any)?.instance as string;
            if (error?.status === 403 && !skipErrorInstances.includes(instance)) {
              const args = (action.meta.arg as any).originalArgs;
              const errorData = error.data as TRawProblem;
              const encoded = Problem.Encode(
                Problem({
                  ...errorData,
                  args,
                })
              );

              navigate(`/error?error=${encoded}`);
            }
          }
        },
      })
    );
  }, [dispatch, navigate]);
};

export default useErrorHandler;
