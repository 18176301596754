import {useMemo} from 'react';
import Link from '@core/components/Link';
import TooltipQuestion from '@core/components/TooltipQuestion';
import {useToken} from '@providers/Tokens.provider';
import {Role} from '@core/types/Common.types';
import {Form, Select} from 'antd';
import * as S from '../SSL/SSL.styled';

const validTokenRoles = [Role.Admins, Role.Engineers] as string[];

const filterOption = (input: string, option: {children: string; label: string} | undefined) =>
  (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

const SelectToken = () => {
  const {tokens: allTokens} = useToken();
  const tokens = useMemo(() => allTokens.filter(({role}) => validTokenRoles.includes(role)), [allTokens]);

  return (
    <S.SSLVerificationContainer>
      <Form.Item
        name="tokenId"
        label={
          <>
            Token{' '}
            <TooltipQuestion
              title={
                <span>
                  A token with Admin or Engineer role is required. Create or edit Tokens{' '}
                  <Link to="/settings?tab=tokens">here</Link>.
                </span>
              }
            />
          </>
        }
        valuePropName="value"
        rules={[{required: true, message: 'Please select a token'}]}
        style={{width: '100%'}}
      >
        <Select allowClear filterOption={filterOption} placeholder="Select a Token" showSearch>
          {tokens.map(({id, name}) => (
            <Select.Option key={id} value={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </S.SSLVerificationContainer>
  );
};

export default SelectToken;
