import {TBaseFilters, Model, TRunSchemas} from '@core/types/Common.types';
import {TTestRunState} from '@core/types/TestRun.types';
import AssertionResults from './AssertionResults.model';
import LinterResult from './LinterResult.model';
import RequiredGatesResult from './RequiredGatesResult.model';
import RunMetadata from './RunMetadata.model';
import {getTestResultCount} from './TestRun.model';
import Trigger from './Trigger.model';
import MonitorRun from './MonitorRun.model';

export type TRunsFilters = TBaseFilters &
  Partial<{
    runGroupId: string;
    testId: string;
    environmentId: string;
    startDate: string;
    endDate: string;
    name: string;

    options: Record<string, string>;
  }>;

export type TRawRun = TRunSchemas['Run'];
type Run = Model<
  TRawRun,
  {
    result: AssertionResults;
    trigger: Trigger;
    state: TTestRunState;
    totalAssertionCount: number;
    failedAssertionCount: number;
    passedAssertionCount: number;
    linter: LinterResult;
    requiredGatesResult: RequiredGatesResult;
    metadata: RunMetadata;
    monitorRun?: MonitorRun;
  }
>;

const defaultRun: TRawRun = {
  runId: 0,
  name: '',
  testId: '',
  trigger: {},
  state: 'CREATED',
  result: {},
};

const Run = ({
  name = '',
  runId = 0,
  testId = '',
  trigger = {},
  state = 'CREATED',
  result = {},
  linter,
  requiredGatesResult = {required: [], failed: [], passed: true},
  metadata: rawMetadata = {},
  executionTime = 0,
  createdAt = '',
  completedAt = '',
  testVersion = 0,
} = defaultRun): Run => {
  const metadata = RunMetadata(rawMetadata, createdAt, executionTime);
  const monitorRun = MonitorRun.fromMetadata(metadata);

  return {
    name,
    runId,
    testId,
    state,
    testVersion,
    createdAt,
    completedAt,
    executionTime,
    trigger: Trigger(trigger),
    result: AssertionResults(result),
    totalAssertionCount: getTestResultCount(result),
    failedAssertionCount: getTestResultCount(result, 'failed'),
    passedAssertionCount: getTestResultCount(result, 'passed'),
    linter: LinterResult(linter),
    requiredGatesResult: RequiredGatesResult(requiredGatesResult),
    metadata,
    monitorRun,
  };
};

export default Run;
