import Agent from '@models/Agent.model';
import DocsBanner from '@core/components/DocsBanner';
import {TooltipQuestion} from '@core/components/TooltipQuestion/TooltipQuestion';
import {AGENT_DOCS_URL, CLOUD_AGENT_DOCS_URL} from '@core/constants/Common.constants';
import {Typography} from 'antd';
import * as S from './EnvironmentAgent.styled';
import AgentBadge from '../../molecules/AgentBadge';

interface IProps {
  agent?: Agent;
  isLocal: boolean;
  isServerless: boolean;
}

const Header = ({agent, isLocal, isServerless}: IProps) => (
  <div>
    <S.TitleContainer>
      <S.Title level={2} $marginBottom={0}>
        Agent
      </S.Title>
      {isLocal && (
        <TooltipQuestion
          margin={0}
          title="Each person in an organization has a localMode where they can develop locally to instrument their applications and run tests. These are private environments which use an agent to listen to incoming OpenTelemetry Protocol (OTLP) data on the common 4317 and 4318 ports."
        />
      )}
      {!!agent && <AgentBadge status={agent.status} isServerless={isServerless} showText />}
    </S.TitleContainer>

    <Typography.Paragraph type="secondary">
      <p>
        Tracetest needs to access your application under test and to ingest trace data from your OpenTelemetry Collector
        or tracing backend.
      </p>
      <DocsBanner>
        Need more information about the agent? Learn more about{' '}
        <a href={AGENT_DOCS_URL} target="__blank">
          private
        </a>{' '}
        or{' '}
        <a href={CLOUD_AGENT_DOCS_URL} target="__blank">
          public
        </a>{' '}
        access in our docs
      </DocsBanner>
    </Typography.Paragraph>
  </div>
);

export default Header;
