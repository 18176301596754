import Panel from '@components/molecules/Panel';
import {EnvironmentOperation, OrganizationOperation, useAuthz} from '@hooks/authz';
import {useEnvironment} from '@providers/Environment.provider';
import {Button, Col, Row} from 'antd';
import {RoleToName} from '@core/types/Common.types';
import * as S from './EnvironmentGeneral.styled';
import EnvironmentSettings from '../EnvironmentSettings';

const EnvironmentGeneral = () => {
  const {deleteEnvironment, deleteMemberMe, environment, updateEnvironment, isLoading, member} = useEnvironment();
  const {checkEnvironment, checkOrganization} = useAuthz();
  const canConfigure = checkEnvironment(EnvironmentOperation.Configure);

  return (
    <>
      {!!environment && (
        <EnvironmentSettings
          environment={environment}
          disabled={!canConfigure}
          isLoading={isLoading}
          onSubmit={updateEnvironment}
        />
      )}

      <Panel title="Your Role">
        <div>
          <S.Text>Your current role in the environment is </S.Text>
          <S.Text strong>{RoleToName[member?.role]}</S.Text>
        </div>
      </Panel>

      {!checkOrganization(OrganizationOperation.Edit) && (
        <Panel danger title="Delete Yourself From This Environment">
          <Row align="middle" gutter={32} justify="space-between">
            <Col span={18}>
              <S.Text>Permanently remove yourself from this environment.</S.Text>
            </Col>
            <Col span={6}>
              <Button block danger onClick={deleteMemberMe}>
                Remove myself
              </Button>
            </Col>
          </Row>
        </Panel>
      )}
      {canConfigure && (
        <Panel danger title="Delete This Environment">
          <Row align="middle" gutter={32} justify="space-between">
            <Col span={18}>
              <S.Text>
                Permanently remove environment <b>{environment?.name}</b> and all its contents from the Tracetest
                platform. This action is irreversible, so please proceed with caution.
              </S.Text>
            </Col>
            <Col span={6}>
              <Button block danger onClick={deleteEnvironment}>
                Delete environment
              </Button>
            </Col>
          </Row>
        </Panel>
      )}
    </>
  );
};

export default EnvironmentGeneral;
