import Panel from '@components/molecules/Panel';
import Header from './Header';
import OtlpMethods from './OtlpMethods';

const EnvironmentTraceSetup = () => {
  return (
    <Panel title={<Header />}>
      <OtlpMethods />
    </Panel>
  );
};

export default EnvironmentTraceSetup;
