import EnvironmentService, {TRelationshipEnvironment} from './Environment.service';
import OrganizationService, {TRelationshipOrganization} from './Organization.service';
import {IAuthzService} from './types';

export enum Namespace {
  Environment = 'Environment',
  Organization = 'Organization',
}

const AuthzServiceRecord: Record<Namespace, IAuthzService<TRelationshipEnvironment | TRelationshipOrganization>> = {
  [Namespace.Environment]: EnvironmentService,
  [Namespace.Organization]: OrganizationService,
};

type TRelationship = {
  [Namespace.Environment]: TRelationshipEnvironment;
  [Namespace.Organization]: TRelationshipOrganization;
};

const AuthzService = {
  /**
   * @description Check if the user has permission to perform the operation
   * @param namespace - Namespace to check
   * @param relationship - Relationship to check
   */
  check<T extends Namespace>(namespace: T, relationship: TRelationship[T]): boolean {
    const authzService = AuthzServiceRecord[namespace];
    return authzService.check(relationship);
  },
};

export default AuthzService;
