import Panel from '@components/molecules/Panel';
import {useAgent} from '@hooks/agent';
import Environment from '@models/Environment.model';
import {useEnvironment} from '@providers/Environment.provider';
import Content from './Content';
import Header from './Header';

const EnvironmentAgent = () => {
  const {environment: {id: environmentId = '', organizationID: organizationId = '', isLocal = false} = Environment()} =
    useEnvironment();
  const {agent, isServerless, key, token, mode, startPublicAgent, stopPublicAgent, toggleAgentMode} = useAgent();

  return (
    <Panel title={<Header agent={agent} isLocal={isLocal} isServerless={isServerless} />}>
      <Content
        agent={agent}
        apiKey={key}
        environmentId={environmentId}
        isLocal={isLocal}
        isServerless={isServerless}
        mode={mode}
        onStartPublicAgent={startPublicAgent}
        onStopPublicAgent={stopPublicAgent}
        onToggleAgentMode={toggleAgentMode}
        organizationId={organizationId}
        token={token}
      />
    </Panel>
  );
};

export default EnvironmentAgent;
