import User from '@models/User.model';
import JoinRequest from '@models/JoinRequest.model';
import Action from '@components/atoms/Action';
import {List, Space, Tag} from 'antd';

interface IProps {
  canEdit: boolean;
  isLoading: boolean;
  joinRequests: JoinRequest[];
  onPageChange(page: number, pageSize: number): void;
  onUpdate(request: JoinRequest): void;
  pageSize: number;
  total: number;
}

const EnvironmentJoinRequestList = ({
  canEdit,
  isLoading,
  joinRequests,
  onPageChange,
  onUpdate,
  pageSize,
  total,
}: IProps) => {
  return (
    <List<JoinRequest>
      bordered
      pagination={{
        hideOnSinglePage: true,
        onChange: onPageChange,
        pageSize,
        position: 'bottom',
        showSizeChanger: false,
        total,
      }}
      loading={isLoading}
      dataSource={joinRequests}
      renderItem={item => (
        <List.Item
          actions={[
            <Action
              disabled={!canEdit || item.status !== 'pending'}
              item={item}
              key="join-request-accept"
              onClick={() =>
                onUpdate({
                  ...item,
                  status: 'accepted',
                })
              }
              title="accept"
            />,
            <Action
              disabled={!canEdit || item.status !== 'pending'}
              item={item}
              key="join-request-reject"
              onClick={() =>
                onUpdate({
                  ...item,
                  status: 'rejected',
                })
              }
              title="reject"
            />,
          ]}
        >
          <List.Item.Meta title={User.getEmail(item.user)} description={item.createdAt} />
          <Space>
            <div>
              {item.status === 'rejected' && <Tag color="error">rejected</Tag>}
              {item.status === 'accepted' && <Tag color="success">accepted</Tag>}
            </div>
          </Space>
        </List.Item>
      )}
    />
  );
};

export default EnvironmentJoinRequestList;
