import styled from 'styled-components';

export const Header = styled.header`
  align-items: center;
  display: flex;
  gap: 12px;
  margin: 24px 0;
`;

export const Navigation = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.color.borderLight}`};
  display: flex;
  gap: 32px;
  padding: 8px 0;
  margin-bottom: 24px;
`;
