import {IIconProps} from '../IntegrationIcon';

const Artillery = ({color, width = '16', height = '16'}: IIconProps) => (
  <svg width={width} height={height} viewBox="0 0 256 256" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M159.627 130.314L172.355 117.586L183.669 128.9L110.13 202.439L98.8162 191.125L132.757 157.184L98.8162 123.243L64.8751 157.184L53.5614 145.87L127.1 72.331L138.414 83.6447L125.686 96.3726L159.627 130.314ZM144.071 145.87L110.13 111.929L115.787 106.272L149.728 140.213L144.071 145.87Z" />
    <path d="M192.154 63.8457L74.0675 104.151L90.3309 120.414L166.698 89.3016L135.586 165.669L151.849 181.933L192.154 63.8457Z" />
  </svg>
);

export default Artillery;
