enum TracetestSources {
  Client = '@tracetest/client',
  Playwright = '@tracetest/playwright',
  Cypress = '@tracetest/cypress',
  Artillery = 'tracetest-plugin-artillery',
  ArtilleryEngine = 'tracetest-engine-artillery',
  Web = 'web',
}

const TracetestSourcesDocs: Record<string, string> = {
  [TracetestSources.Client]: 'https://docs.tracetest.io/tools-and-integrations/typescript',
  [TracetestSources.Playwright]: 'https://docs.tracetest.io/tools-and-integrations/playwright',
  [TracetestSources.Cypress]: 'https://docs.tracetest.io/tools-and-integrations/cypress',
  [TracetestSources.Artillery]: 'https://docs.tracetest.io/tools-and-integrations/artillery-plugin',
  [TracetestSources.ArtilleryEngine]: 'https://docs.tracetest.io/tools-and-integrations/artillery-engine',
} as const;

const TracetestKeys = {
  TracetestSource: 'tracetest.source',

  // agent
  TracetestAgentVersion: 'tracetest.agent.version',
  TracetestAgentName: 'tracetest.agent.name',
  TracetestAgentIP: 'tracetest.agent.ip',
  TracetestAgentHostname: 'tracetest.agent.hostname',
  TracetestAgentOS: 'tracetest.agent.os',
  TracetestAgentArch: 'tracetest.agent.arch',

  // token
  TracetestTokenName: 'tracetest.token.name',
  TracetestTokenRole: 'tracetest.token.role',

  // user
  TracetestUserName: 'tracetest.user.name',
  TracetestUserEmail: 'tracetest.user.email',
  TracetestIsCI: 'tracetest.is.ci',

  TracetestCreatedAt: 'tracetest.created.at',
  TracetestRanAt: 'tracetest.ran.at',
  TracetestExecutionTime: 'tracetest.execution.time',
  TracetestEnvironmentAgentStatus: 'tracetest.environment.agent.status',
} as const;

const GitKeys = {
  GitRepo: 'git.repo',
  GitBranch: 'git.branch',
  GitCommitMessage: 'git.commit.message',
  GitCommit: 'git.commit',
  GitTag: 'git.tag',
  GitSha: 'git.sha',
  GitIsPr: 'git.is.pr',
  GitPr: 'git.pr',
  GitPrBranch: 'git.pr.branch',
} as const;

const CIKeys = {
  CIProvider: 'ci.provider',
  CIJob: 'ci.job.id',
  CIJobUrl: 'ci.job.url',
  CIProviderName: 'ci.provider.name',
  CIBuildUrl: 'ci.build.url',
  CIBuildNumber: 'ci.build.number',
} as const;

const CypressKeys = {
  CypressVersion: 'cypress.version',
  CypressBrowser: 'cypress.browser',
  CypressProjectId: 'cypress.project.id',
  CypressBaseUrl: 'cypress.base.url',
  CypressSpecName: 'cypress.spec.name',
} as const;

const Playwright = {
  PlaywrightVersion: 'playwright.version',
  PlaywrightBrowser: 'playwright.browser',
  PlaywrightBaseUrl: 'playwright.base.url',
} as const;

const Artillery = {
  ArtilleryVersion: 'artillery.version',
  ArtilleryUuid: 'artillery.test.uuid',
  ArtilleryTestId: 'artillery.test.id',
  ArtilleryTarget: 'artillery.target',
  ArtilleryScenarioName: 'artillery.scenario.name',
} as const;

const Environment = {
  NodeVersion: 'node.version',
} as const;

const Keys = {
  ...TracetestKeys,
  ...GitKeys,
  ...CIKeys,
  ...CypressKeys,
  ...Playwright,
  ...Artillery,
  ...Environment,
} as const;

const MainKeys: string[] = [
  TracetestKeys.TracetestRanAt,
  TracetestKeys.TracetestExecutionTime,
  TracetestKeys.TracetestSource,
  TracetestKeys.TracetestTokenName,
  TracetestKeys.TracetestUserEmail,
  GitKeys.GitBranch,
  CIKeys.CIBuildUrl,
];

const EnvironmentKeys: string[] = [TracetestKeys.TracetestEnvironmentAgentStatus, TracetestKeys.TracetestCreatedAt];

export {Keys, MainKeys, EnvironmentKeys, TracetestSources, TracetestSourcesDocs};
