export enum PublicOTLPMethods {
  NodeJS = 'nodejs',
  Python = 'python',
  Go = 'go',
  Ruby = 'ruby',
  Java = 'java',
  Browser = 'browser',
  OTELCollector = 'otelcollector',
}

export interface IMethodChildrenProps {
  endpoint?: string;
  token?: string;
}

export interface IMethodProps {
  id: PublicOTLPMethods;
  label: string;
  component: (props: IMethodChildrenProps) => JSX.Element;
}
