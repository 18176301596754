import {Typography, Collapse as AntdCollapse} from 'antd';
import styled from 'styled-components';

export const StepTitle = styled(Typography.Title)`
  && {
    margin: 0;
  }
`;

export const StepText = styled(Typography.Text)`
  && {
    color: ${({theme}) => theme.color.textSecondary};
    font-size: ${({theme}) => theme.size.md};
    margin-bottom: 8px;
  }
`;

export const CodeContainer = styled.div`
  margin: 8px 0;
`;

export const Collapse = styled(AntdCollapse)`
  && {
    .ant-collapse-header,
    .ant-collapse-content-box {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;
