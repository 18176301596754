import {VariableSetValue} from '@core/models/VariableSet.model';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Form} from 'antd';
import Value from './Value';

interface IProps {
  initialValue?: VariableSetValue[];
  values?: VariableSetValue[];
}

const defaultValues: VariableSetValue[] = [{key: '', value: '', type: 'raw'}];

const VariableSetValues = ({initialValue = defaultValues, values}: IProps) => (
  <Form.Item label="Values List" shouldUpdate>
    <Form.List name="values" initialValue={initialValue}>
      {(fields, {add, remove}) => (
        <>
          {fields.map((field, index) => (
            <Value field={field} index={index} onDelete={remove} key={field.name} value={values?.[index]} />
          ))}

          <Button
            data-cy="add-value"
            icon={<PlusOutlined />}
            onClick={() => add()}
            style={{fontWeight: 600, height: 'auto', padding: 0}}
            type="link"
          >
            Add Value
          </Button>
        </>
      )}
    </Form.List>
  </Form.Item>
);
export default VariableSetValues;
