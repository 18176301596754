import RunMetadata from '@core/models/RunMetadata.model';
import {Keys} from '@core/constants/Metadata.constants';
import {TAgentSchemas, TEnvironmentSchemas} from '@core/types/Common.types';
import Agent from './Agent.model';

export type TRawEnvironmentResource = TEnvironmentSchemas['EnvironmentResource'];
export type TRawEnvironment = TEnvironmentSchemas['Environment'];
export type TRawAgentConfiguration = TAgentSchemas['AgentConfiguration'];

type Environment = TRawEnvironment & {
  agent: Agent;
  agentConfiguration: TRawAgentConfiguration;
  metadata: RunMetadata;
};

const defaultEnvironment: Environment = {
  id: '',
  name: '',
  connected: false,
  description: '',
  agent: Agent(),
  agentConfiguration: {
    serverless: false,
  },
  metadata: RunMetadata(),
};

function Environment({
  id = '',
  name = '',
  description = '',
  labels = {},
  createdAt = '',
  updatedAt = '',
  organizationID = '',
  isLocal = false,
  userID = '',
  agent: rawAgent,
  connected = false,
  agentConfiguration = {serverless: false},
}: TRawEnvironment = defaultEnvironment): Environment {
  const agent = Agent(rawAgent);

  const baseMetadata = {
    ...(labels ?? {}),
    [Keys.TracetestEnvironmentAgentStatus]: agent.status,
  };

  return {
    id,
    connected,
    name,
    description,
    labels,
    createdAt,
    updatedAt,
    organizationID,
    userID,
    agent,
    isLocal,
    agentConfiguration,
    metadata: RunMetadata(baseMetadata, createdAt),
  };
}

Environment.toResource = ({
  id,
  name,
  connected,
  description,
  labels,
  agentConfiguration,
}: TRawEnvironment): TRawEnvironmentResource => {
  return {
    type: 'Environment',
    spec: {
      id,
      name,
      connected,
      description,
      labels,
      agentConfiguration,
    },
  };
};

Environment.fromResource = function FromResource({spec = defaultEnvironment}: TRawEnvironmentResource): Environment {
  return Environment(spec);
};

export default Environment;
