import {Model, TGraphqlSchemas} from '@core/types/Common.types';
import {THeader} from '@core/types/Test.types';

export type TRawGraphqlResponse = TGraphqlSchemas['GraphqlResponse'];

type GraphqlResponse = Model<
  TRawGraphqlResponse,
  {
    headers: THeader[];
  }
>;

const GraphqlResponse = ({
  status = '',
  statusCode = 0,
  headers = [],
  body = '',
}: TRawGraphqlResponse): GraphqlResponse => {
  return {
    status,
    statusCode,
    headers: headers.map(({key = '', value = ''}) => ({
      key,
      value,
    })),
    body,
  };
};

export default GraphqlResponse;
