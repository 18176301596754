import {CopyOutlined} from '@ant-design/icons';
import {Typography} from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 8px;
`;

export const Title = styled(Typography.Text)`
  && {
    font-size: ${({theme}) => theme.size.md};
  }
`;

export const IDText = styled(Typography.Text)`
  && {
    font-size: ${({theme}) => theme.size.md};
  }
`;

export const IDContainer = styled.div`
  position: relative;
  cursor: pointer;
  padding: 3px 45px 3px 6px;
  background: rgba(97, 23, 94, 0.05);
  border-radius: 2px;
  border: 1px solid ${({theme}) => theme.color.primaryLight};
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CopyIcon = styled(CopyOutlined)`
  color: ${({theme}) => theme.color.primary};
  position: absolute;
  right: 12px;
  top: 7px;
`;
