import {Button} from 'antd';
import * as S from './PlanLimitModal.styled';

interface IProps {
  onClose(): void;
}

const Footer = ({onClose}: IProps) => (
  <S.Footer>
    <span>
      <Button htmlType="submit" type="primary" onClick={onClose}>
        Continue
      </Button>
    </span>
  </S.Footer>
);

export default Footer;
