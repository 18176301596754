import * as S from './HeaderBanner.styled';

const BOOK_DEMO_URL = 'https://dub.sh/tracetest-demo';

const HeaderBanner = () => (
  <S.Container>
    <S.Text>Want More Info?</S.Text>
    <a href={BOOK_DEMO_URL} target="_blank">
      <S.Button type="primary" size="small">
        Book a Free Call
      </S.Button>
    </a>
  </S.Container>
);

export default HeaderBanner;
