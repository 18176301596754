import styled from 'styled-components';

export const TabsContainer = styled.div`
  .ant-tabs-nav {
    padding: 0 12px;
    margin-bottom: 0;
  }

  .ant-tabs-nav {
    padding: 0;
  }
`;
