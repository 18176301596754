import {Button, Tooltip} from 'antd';

interface IProps<T> {
  disabled?: boolean;
  item: T;
  onClick?(item: T): void;
  title: string;
  tooltip?: string;
}

const Action = <T extends unknown>({disabled, item, onClick, title, tooltip}: IProps<T>) => {
  const button = (
    <Button
      disabled={disabled}
      onClick={e => {
        e.preventDefault();
        !disabled && onClick?.(item);
      }}
      type="link"
    >
      {title}
    </Button>
  );

  return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;
};

export default Action;
