import {Tooltip} from 'antd';
import Agent from '@models/Agent.model';
import * as S from './AgentBadge.styled';

interface IProps {
  isServerless: boolean;
  status: Agent['status'];
  showText?: boolean;
}

export function getAgentBadge(status?: 'Green' | 'Yellow' | 'Red') {
  if (status === 'Green') return 'success';
  return 'error';
}

export function getAgentStatus(status?: 'Green' | 'Yellow' | 'Red') {
  if (status === 'Green') return 'Agent connected';
  return 'Agent offline';
}

const AgentBadge = ({status, isServerless, showText = false}: IProps) => {
  return (
    <S.Container>
      <Tooltip title={getAgentStatus(status)}>
        {isServerless ? <S.CloudIcon $status={status} /> : <S.StatusBadge status={getAgentBadge(status)} />}
      </Tooltip>
      {showText && <S.DetailsText>{getAgentStatus(status)}</S.DetailsText>}
    </S.Container>
  );
};

export default AgentBadge;
