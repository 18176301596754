import {ReadOutlined} from '@ant-design/icons';
import * as S from './DocsBanner.styled';

interface IProps {
  children: React.ReactNode;
  isLightTheme?: boolean;
}

const DocsBanner = ({children, isLightTheme}: IProps) => (
  <S.DocsBannerContainer $isLight={isLightTheme}>
    <ReadOutlined />
    <S.Text $isLight={isLightTheme}>{children}</S.Text>
  </S.DocsBannerContainer>
);

export default DocsBanner;
