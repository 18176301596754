import {Session} from '@ory/client';
import AuthnService from '@services/Authn.service';
import {useEffect, useState} from 'react';
import {useHref, useLocation, useNavigate} from 'react-router-dom';
import Problem from '@models/Problem.model';
import Env from '@core/utils/Env';
import {useSdkError} from './useSdkError';

/**
 * @param defaultNav - Default navigate target for errors, if undefined no navigation will be done
 */
export const useCheckSession = (defaultNav?: string) => {
  const [session, setSession] = useState<Session | null>(null);
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const currentUrl = useHref(useLocation());

  // initialize the sdkError for generic handling of errors
  // if no session is found, we redirect to the defaultNav parameter
  const sdkErrorHandler = useSdkError(
    undefined,
    undefined,
    defaultNav && `${defaultNav}?returnTo=${encodeURIComponent(currentUrl)}`
  );

  useEffect(() => {
    setIsLoading(true);

    AuthnService.checkSession()
      .then(data => {
        // set the session data which contains the user Identifier and other traits.
        setSession(data.session);
        // set the logout URL which we can use to log the user out
        setLogoutUrl(data.logoutUrl);
        // set the user identifier for the analytics service
        Env.set('serverID', data.session.identity.id);
      })
      .catch(sdkErrorHandler)
      .catch(error => {
        // handle all other errors like error.message "network error" if Kratos can not be connected etc.
        let errorMessage = JSON.stringify(error);
        if (error.message) {
          errorMessage = error.message;
        }
        const encoded = Problem.Encode(Problem({title: errorMessage}));

        return navigate(`/error?error=${encoded}`, {
          replace: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {isLoading, logoutUrl, session};
};
