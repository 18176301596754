import {useMemo} from 'react';
import {NavigateFunction, NavigateOptions, To, useLocation, useNavigate} from 'react-router-dom';
import DashboardProvider from '@core/providers/Dashboard';
import useErrorHandler from '@hooks/error/useErrorHandler';
import {getServerBaseUrl} from '@core/utils/Common';

const dashboardUrlRegex = /^\/organizations\/([^\/]+)\/environments\/([^\/]+)(?=\/|$)/;
const prefixSlashRegex = /^\/+/;

interface IProps {
  children: React.ReactNode;
}

const DashboardWrapper = ({children}: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  useErrorHandler();

  const [dashboardBaseUrl] = useMemo(
    () => location.pathname.match(dashboardUrlRegex) || ['/organizations/default/environments/default'],
    [location.pathname]
  );

  const dashboardProviderValue = useMemo(() => {
    const dashboardNavigate: NavigateFunction = (toOrDelta: To | number, options?: NavigateOptions) => {
      if (typeof toOrDelta === 'number' || toOrDelta == null) {
        return navigate(toOrDelta);
      }
      if (typeof toOrDelta === 'string') {
        if (toOrDelta.startsWith('/organizations/')) {
          return navigate(toOrDelta, options);
        }

        return navigate(`${dashboardBaseUrl}/${toOrDelta.replace(prefixSlashRegex, '')}`, options);
      }

      return navigate(
        {
          ...toOrDelta,
          pathname: toOrDelta.pathname
            ? `${dashboardBaseUrl}/${toOrDelta.pathname.replace(prefixSlashRegex, '')}`
            : undefined,
        },
        options
      );
    };

    return {
      baseUrl: dashboardBaseUrl,
      navigate: dashboardNavigate,
      dashboardUrl: `${getServerBaseUrl().slice(0, -1)}${dashboardBaseUrl}/`,
    };
  }, [dashboardBaseUrl, navigate]);

  return <DashboardProvider value={dashboardProviderValue}>{children}</DashboardProvider>;
};

export default DashboardWrapper;
