import Panel from '@components/molecules/Panel';
import EnvironmentPollingProfileList from '@components/organisms/EnvironmentPollingProfileList';
import {EnvironmentOperation, useAuthz} from '@hooks/authz';
import DocsBanner from '@core/components/DocsBanner';
import {useConfirmationModal} from '@core/providers/ConfirmationModal/ConfirmationModal.provider';
import {useNotification} from '@core/providers/Notification/Notification.provider';
import {useState} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import Polling, {TRawPollingResource} from '@core/models/Polling.model';
import {useDeletePollingMutation, useListPollingQuery, useUpsertPollingMutation} from '@core/redux/apis/Tracetest';
import {TRACE_POLLING_DOCUMENTATION_URL} from '@core/constants/Common.constants';
import {ResourceType} from '@core/types/Resource.type';
import {useResourceDefinition} from '@providers/ResourceDefinition.provider';
import {PAGE_SIZE} from '@constants/list.constants';
import {Button, Typography} from 'antd';
import PollingProfileModal from '../PollingProfileModal';

const EnvironmentPollingProfiles = () => {
  const {showNotification} = useNotification();
  const {onOpen} = useConfirmationModal();
  const {checkEnvironment} = useAuthz();
  const [profile, setProfile] = useState<Polling>();
  const {onOpenDefinition} = useResourceDefinition();

  // upsert modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [upsertPollingProfile, {isLoading: isUpsertLoading}] = useUpsertPollingMutation();
  const handleUpsertProfile = async (values: TRawPollingResource) => {
    await upsertPollingProfile({polling: values});
    showNotification({
      type: 'success',
      title: profile ? 'Polling Profile updated successfully' : 'Polling Profile created successfully',
    });
  };

  // delete
  const [deleteProfile] = useDeletePollingMutation();
  const handleDeleteProfile = ({id, name}: Polling) => {
    const onConfirm = async () => {
      await deleteProfile({id});
      showNotification({
        type: 'success',
        title: `Polling Profile ${name} deleted successfully`,
      });
    };

    onOpen({
      heading: 'Delete Polling Profile',
      title: `Are you sure you want to delete ${name}?`,
      okText: 'Delete',
      onConfirm,
    });
  };

  const [skip, setSkip] = useState(0);
  // fetch list
  const {data: profiles, isLoading} = useListPollingQuery({
    take: PAGE_SIZE,
    skip,
  });

  return (
    <>
      <PollingProfileModal
        isLoading={isUpsertLoading}
        isOpen={isModalOpen}
        onClose={() => {
          setProfile(undefined);
          setIsModalOpen(false);
        }}
        onSubmit={handleUpsertProfile}
        profile={profile}
      />

      <Panel
        title="Trace Polling"
        actions={
          <Button
            disabled={!checkEnvironment(EnvironmentOperation.Edit)}
            onClick={() => {
              setProfile(undefined);
              setIsModalOpen(true);
            }}
            type="primary"
            icon={<PlusOutlined />}
          >
            Add Polling Profile
          </Button>
        }
      >
        <Typography.Paragraph type="secondary">
          <p>Tracetest uses polling to gather the distributed trace associated with each test run.</p>
          <DocsBanner>
            Need more information about Trace Polling?{' '}
            <a href={TRACE_POLLING_DOCUMENTATION_URL} target="__blank">
              Learn more in our docs
            </a>
          </DocsBanner>
        </Typography.Paragraph>
        <EnvironmentPollingProfileList
          isLoading={isLoading}
          onDelete={handleDeleteProfile}
          onEdit={editProfile => {
            setIsModalOpen(true);
            setProfile(editProfile);
          }}
          onOpenDefinition={definitionProfile => onOpenDefinition(ResourceType.PollingProfile, definitionProfile.id)}
          onPageChange={(page, pageSize) => setSkip((page - 1) * pageSize)}
          pageSize={PAGE_SIZE}
          profiles={profiles}
        />
      </Panel>
    </>
  );
};

export default EnvironmentPollingProfiles;
