import {Typography} from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const Header = styled.div`
  margin-bottom: 12px;
`;

export const Title = styled(Typography.Title)`
  && {
    margin-bottom: 12px;
  }
`;

export const Text = styled(Typography.Text)`
  && {
    margin: 0;
  }
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
`;

export const InfoContainer = styled.div`
  margin-bottom: 12px;
`;
