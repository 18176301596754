import {Form, FormInstance, Input} from 'antd';

import {VariableSetValues} from '@core/components/Fields';
import {TooltipQuestion} from '@core/components/TooltipQuestion/TooltipQuestion';
import {DEFAULT_VALUES} from '@core/components/VariableSetModal/VariableSetModal';
import Value from '@core/components/Fields/VariableSetValues/Value';
import VariableSet, {TDraftVariableSet} from '@core/models/VariableSet.model';

interface IProps {
  form: FormInstance<TDraftVariableSet>;
  initialValues?: TDraftVariableSet;
  onSubmit(values: VariableSet): void;
  onValidate(changedValues: any, values: TDraftVariableSet): void;
}

const VariableSetForm = ({form, initialValues, onSubmit, onValidate}: IProps) => {
  const values = Form.useWatch('values', form);

  return (
    <Form<TDraftVariableSet>
      initialValues={{...initialValues}}
      form={form}
      layout="vertical"
      name="variableSet"
      onFinish={varSet =>
        onSubmit({
          ...varSet,
          values: varSet.values?.concat(varSet.secrets ?? []) ?? [],
        })
      }
      onValuesChange={onValidate}
    >
      <Form.Item name="id" hidden>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item label="Name" name="name" rules={[{required: true, message: 'Please input a name'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="Description" name="description">
        <Input />
      </Form.Item>

      {!!initialValues && !!initialValues.secrets?.length && (
        <Form.Item
          label={
            <>
              Secrets List{' '}
              <TooltipQuestion title="After creation, secrets can only be deleted. To make updates, please delete the existing secret and create a new one with a different name." />
            </>
          }
          shouldUpdate
        >
          <Form.List name="secrets">
            {(fields, {remove}) => (
              <>
                {fields.map((field, index) => (
                  <Value isDisabled field={field} index={index} onDelete={remove} key={field.name} />
                ))}
              </>
            )}
          </Form.List>
        </Form.Item>
      )}

      <VariableSetValues initialValue={form.getFieldValue('values') || DEFAULT_VALUES} values={values} />
    </Form>
  );
};

export default VariableSetForm;
