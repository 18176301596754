import User from '@models/User.model';
import CaptureProvider from '@core/providers/Capture';
import {useUser} from '@providers/User.provider';
import noop from 'lodash/noop';
import {usePostHog} from 'posthog-js/react';
import {useCallback, useEffect, useMemo} from 'react';

interface IProps {
  children: React.ReactNode;
}

const Content = ({children}: IProps) => {
  const posthog = usePostHog();
  const {user} = useUser();

  useEffect(() => {
    if (user) {
      posthog?.identify(user.id, {
        email: User.getEmail(user),
        name: user.name,
      });
    }
  }, [posthog, user]);

  const pageView = useCallback(() => {
    posthog?.capture('$pageview');
  }, [posthog]);

  const captureProviderValue = useMemo(() => ({load: noop, pageView}), [pageView]);

  return <CaptureProvider value={captureProviderValue}>{children}</CaptureProvider>;
};

export default Content;
