import Polling, {TRawPollingResource} from '@core/models/Polling.model';
import {useEffect} from 'react';
import {Form} from 'antd';
import * as S from './PollingProfileModal.styled';
import Footer from './Footer';
import FormBody from './FormBody';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose(): void;
  profile?: Polling;
  onSubmit(values: TRawPollingResource): void;
}

const PollingProfileModal = ({profile, isLoading, isOpen, onClose, onSubmit}: IProps) => {
  const [form] = Form.useForm<Polling>();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(profile ?? {});
  }, [form, profile]);

  const handleOnSubmit = (values: Polling) => {
    onSubmit({
      type: 'PollingProfile',
      spec: values,
    });
    onClose();
  };

  return (
    <S.Modal
      cancelText="Cancel"
      footer={<Footer isEditing={!!profile} isLoading={isLoading} onClose={onClose} onSubmit={() => form.submit()} />}
      onCancel={onClose}
      title={<S.Title>{profile ? 'Edit Polling Profile' : 'Create a new Polling Profile'} </S.Title>}
      visible={isOpen}
    >
      <FormBody form={form} onSubmit={handleOnSubmit} />
    </S.Modal>
  );
};

export default PollingProfileModal;
