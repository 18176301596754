import {HTTP_METHOD} from '@core/constants/Common.constants';
import {SortBy, SortDirection, TracetestApiTags} from '@core/constants/Test.constants';
import {PaginationResponse} from '@core/hooks/usePagination';
import Polling, {TRawPollingResource, TRawPollingResourceList} from '@core/models/Polling.model';
import {TTestApiEndpointBuilder} from '../Tracetest.api';

export const pollingEndpoints = (builder: TTestApiEndpointBuilder) => ({
  getPolling: builder.query<Polling, {id: string}>({
    query: ({id}) => ({
      url: `/pollingprofiles/${id}`,
      method: HTTP_METHOD.GET,
      headers: {
        'content-type': 'application/json',
      },
    }),
    providesTags: polling => [{type: TracetestApiTags.POLLING, id: polling?.default ? 'current' : polling?.id}],
    transformResponse: (rawPolling: TRawPollingResource) => Polling(rawPolling),
  }),
  upsertPolling: builder.mutation<Polling, {polling: TRawPollingResource}>({
    query: ({polling}) => ({
      url: '/pollingprofiles',
      method: HTTP_METHOD.PUT,
      body: polling,
      headers: {
        'content-type': 'application/json',
      },
    }),
    transformResponse: (rawPolling: TRawPollingResource) => Polling(rawPolling),
    invalidatesTags: () => [
      {type: TracetestApiTags.POLLING, id: 'LIST'},
      {type: TracetestApiTags.POLLING, id: 'current'},
    ],
  }),
  deletePolling: builder.mutation<unknown, {id: string}>({
    query: ({id}) => ({
      url: `/pollingprofiles/${id}`,
      method: HTTP_METHOD.DELETE,
    }),
    invalidatesTags: (result, err, {id}) => [
      {type: TracetestApiTags.POLLING, id},
      {type: TracetestApiTags.POLLING, id: 'LIST'},
      {type: TracetestApiTags.POLLING, id: 'current'},
    ],
  }),
  listPolling: builder.query<
    PaginationResponse<Polling>,
    {take?: number; skip?: number; query?: string; sortBy?: SortBy; sortDirection?: SortDirection}
  >({
    query: ({take = 25, skip = 0, query = '', sortBy = '', sortDirection = ''}) => ({
      url: `/pollingprofiles?take=${take}&skip=${skip}&query=${query}&sortBy=${sortBy}&sortDirection=${sortDirection}`,
      method: HTTP_METHOD.GET,
      headers: {
        'content-type': 'application/json',
      },
    }),
    providesTags: () => [{type: TracetestApiTags.POLLING, id: 'LIST'}],
    transformResponse: ({items = [], count: total = 0}: TRawPollingResourceList) => ({
      items: items.map(raw => Polling(raw)),
      total,
    }),
  }),
});
