import {Role, TTokenSchemas} from '@core/types/Common.types';
import {differenceInDays, isBefore, parseISO} from 'date-fns';

export type TRawTokenResource = TTokenSchemas['TokenResource'];
export type TRawToken = TTokenSchemas['Token'];
type Token = TRawToken & {isExpired: boolean};

const defaultToken: TRawToken = {
  id: '',
  name: '',
  role: Role.Admins,
  userID: '',
  isRevoked: false,
  issuedAt: '',
  expiresAt: '',
  duration: 0,
  isMonitorToken: false,
};

function Token({
  id = '',
  name = '',
  role = Role.Admins,
  userID = '',
  isRevoked = false,
  issuedAt = '',
  expiresAt = '',
  isAgentToken = false,
  isMonitorToken = false,
}: TRawToken = defaultToken): Token {
  const duration = differenceInDays(parseISO(expiresAt), parseISO(issuedAt));
  const diff = expiresAt ? differenceInDays(parseISO(expiresAt), new Date()) : 0;

  return {
    id,
    name,
    role,
    userID,
    isRevoked,
    issuedAt,
    expiresAt,
    duration,
    isAgentToken,
    isMonitorToken,
    isExpired: diff < 0,
  };
}

Token.fromResource = ({spec}: TRawTokenResource): Token => {
  return Token(spec);
};

Token.toResource = (token: TRawToken): TRawTokenResource => {
  return {
    type: 'Token',
    spec: token,
  };
};

Token.getIsExpired = ({expiresAt}: Token): boolean => {
  return isBefore(parseISO(expiresAt ?? ''), new Date());
};

export default Token;
