import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {addListener, isRejected} from '@reduxjs/toolkit';
import LicenseExpirationModal from '@components/organisms/LicenseExpirationModal';
import {useGetLicenseQuery} from '@redux/apis/TracetestCloud';
import {useAppDispatch} from '@redux/hooks';
import License from '@models/License.model';
import Env from '@core/utils/Env';
import {FetchBaseQueryError, FetchBaseQueryMeta} from '@reduxjs/toolkit/dist/query';
import PlanLimitModal from '../components/organisms/PlanLimitModal';

interface IContext {
  license: License;
  isLoading: boolean;
  isEnterprise: boolean;
  isCertificateValid: boolean;
  onPlanLimitOpen(): void;
}

const Context = createContext<IContext>({
  license: License(),
  isLoading: false,
  isEnterprise: false,
  isCertificateValid: true,
  onPlanLimitOpen: () => {},
});

interface IProps {
  children: React.ReactNode;
}

export const LicenseProvider = ({children}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPlanLimitOpen, setIsPlanLimitOpen] = useState(false);
  const {
    data: license = License({
      isValid: false,
      expiresAt: new Date().toISOString(),
    }),
    isLoading,
    isUninitialized,
    error = {},
  } = useGetLicenseQuery({});
  const dispatch = useAppDispatch();
  const isEnterprise = !isUninitialized && !isLoading && (error as FetchBaseQueryError)?.status !== 403;

  const value = useMemo(
    () => ({
      license,
      isLoading,
      isEnterprise,
      onPlanLimitOpen: () => setIsPlanLimitOpen(true),
      isCertificateValid: !isEnterprise || process.env.REACT_APP_EXPECT_VALID_CERTIFICATES === 'true',
    }),
    [isEnterprise, isLoading, license]
  );

  useEffect(() => {
    return dispatch(
      addListener({
        matcher: isRejected,
        effect: action => {
          if (action.type.includes('executeMutation/rejected')) {
            const meta = ((action.meta as any)?.baseQueryMeta as FetchBaseQueryMeta) ?? {};
            const status = meta.response?.status as number;
            if (status === 402) setIsOpen(true);
          }
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    Env.set('isEnterprise', isEnterprise);
    Env.set('licenseName', license.name);
  }, [isEnterprise, license.name]);

  return (
    <Context.Provider value={value}>
      {children}
      <LicenseExpirationModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <PlanLimitModal isOpen={isPlanLimitOpen} onClose={() => setIsPlanLimitOpen(false)} />
    </Context.Provider>
  );
};

export const useLicense = () => useContext(Context);
