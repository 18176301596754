import {createApi, BaseQueryFn, fetchBaseQuery, FetchArgs, FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import {EndpointBuilder} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import UserPreferencesService from '@services/UserPreferences.service';
import {TracetestApiTags} from '@core/constants/Test.constants';

export type TTestApiEndpointBuilder = EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
  TracetestApiTags,
  'tracetest'
>;

export type TBaseQueryFn = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

export const TAG_TYPES = {
  ENVIRONMENT_MEMBER_ME: 'environmentmemberme',
  ENVIRONMENT_MEMBER: 'environmentmember',
  ENVIRONMENT_AGENT: 'environmentagent',
  ENVIRONMENT: 'environment',
  INVITE_LIST: 'invitelist',
  ENVIRONMENT_LIST: 'environmentlist',
  ENVIRONMENT_LIST_ME: 'environmentlistme',
  INVITE: 'invite',
  MEMBER_ME: 'memberme',
  MEMBER: 'member',
  ORGANIZATION: 'organization',
  LICENSE: 'license',
  ORGANIZATION_LIST: 'organizationlist',
  TOKEN_LIST: 'tokenlist',
  TOKEN: 'token',
  ORG_TOKEN_LIST: 'orgtokenlist',
  USER: 'user',
  JOIN_REQUEST_LIST: 'joinrequestlist',
  JOIN_REQUEST: 'joinrequest',
} as const;

const customBaseQuery: TBaseQueryFn = async (args, api, extraOptions) => {
  const defaultOrganizationId = UserPreferencesService.get('orgId');
  const defaultEnvironmentId = UserPreferencesService.get('envId');

  const orgDashboardUrlRegex = /^\/organizations\/([^\/]+)/;
  const environmentDashboardUrlRegex = /^\/organizations\/([^\/]+)\/environments\/([^\/]+)(?=\/|$)/;
  const [, organizationId = defaultOrganizationId] = window.location.pathname.match(orgDashboardUrlRegex) ?? [];
  const [, , environmentId = defaultEnvironmentId] = window.location.pathname.match(environmentDashboardUrlRegex) ?? [];

  return fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('x-source', 'web');

      // allow overriding organization and environment ids
      if (!headers.get('x-organization-id')) headers.set('x-organization-id', organizationId);
      if (!headers.get('x-environment-id')) headers.set('x-environment-id', environmentId);
    },
  })(args, api, extraOptions);
};

const TracetestCloudAPI = createApi({
  reducerPath: 'tracetest-cloud',
  baseQuery: customBaseQuery,
  tagTypes: Object.values(TAG_TYPES),
  endpoints: () => ({}),
});

export default TracetestCloudAPI;
