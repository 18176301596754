const HubSpotService = {
  /**
   * @description Identify HubSpot contact.
   * @param email - User email.
   */
  identify(email: string) {
    if (!(window as any)._hsq) {
      return;
    }

    const _hsq: any[] = (window as any)._hsq || [];
    _hsq.push(['identify', {email}]);
    _hsq.push(['trackPageView']);
  },
};

export default HubSpotService;
