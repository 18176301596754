import {acceptInviteUrlRegex} from '@constants/url.constants';
import {Configuration, FrontendApi, UpdateLoginFlowBody} from '@ory/client';

const sdk = new FrontendApi(
  new Configuration({
    // this is the URL of the ORY Kratos Public API
    basePath: process.env.REACT_APP_ORY_KRATOS_PUBLIC_API_URL,
    // we always want to include the cookies in each request
    // cookies are used for sessions and CSRF protection
    baseOptions: {
      withCredentials: true,
    },
  })
);

interface ICreateLoginFlowRequest {
  aal?: string;
  returnTo?: string;
}

const AuthnService = {
  /**
   * @description Check if the user is logged in by checking if there is a session,
   * then it calls the createLogoutFlow function to create a new logout URL
   */
  checkSession() {
    return sdk.toSession().then(({data: session}) => this.createLogoutFlow().then(logoutUrl => ({logoutUrl, session})));
  },

  /**
   * @description Create a new logout URL which we can use to log the user out
   */
  createLogoutFlow() {
    return sdk.createBrowserLogoutFlow({returnTo: window.location.origin}).then(({data}) => data.logout_url);
  },

  /**
   * @description Create a new login flow, we always pass refresh (true) on login so
   * that the session can be refreshed when there is already an active session
   * @param request - Login flow request body
   */
  createLoginFlow(request: ICreateLoginFlowRequest) {
    return this.augmentInviteLink(request?.returnTo ?? '').then(returnTo =>
      sdk.createBrowserLoginFlow({refresh: true, ...request, returnTo}).then(({data}) => data)
    );
  },

  /**
   * @description Get the flow based on the flowId
   * @param id - flowId
   */
  getLoginFlow(id: string) {
    return sdk.getLoginFlow({id}).then(({data}) => data);
  },

  /**
   * @description Submit the login form data to Ory
   * @param id - flowId
   * @param body - Form data
   */
  submitLoginFlow(id: string, body: UpdateLoginFlowBody) {
    return sdk.updateLoginFlow({flow: id, updateLoginFlowBody: body});
  },

  /**
   * @description Augment the invite link with autoAccept query param if the invite link is valid
   * @param returnTo - Return to URL
   */
  augmentInviteLink: (returnTo: string) => {
    return new Promise<string | undefined>(resolve => {
      const acceptInviteMatch = returnTo.match(acceptInviteUrlRegex);
      if (!acceptInviteMatch) {
        resolve(returnTo);
        return;
      }

      fetch(
        `${process.env.REACT_APP_BASE_API_URL}/organizations/${acceptInviteMatch?.[1]}/invites/${acceptInviteMatch?.[2]}`
      ).then(
        response => resolve(response.status === 200 ? `${returnTo}?autoAccept=true` : returnTo),
        () => resolve(returnTo)
      );
    });
  },
};

export default AuthnService;
