import {Editor} from '@core/components/Inputs';
import {SupportedEditors} from '@core/constants/Editor.constants';
import {ReactCodeMirrorRef} from '@uiw/react-codemirror';
import {graphql, updateSchema} from 'cm6-graphql';
import {buildSchema, GraphQLSchema} from 'graphql';
import {noop} from 'lodash';
import {useEffect, useMemo, useRef} from 'react';
import * as S from './GraphqlBody.styled';

function getGraphQLSchema(schema?: string) {
  let builtSchema: GraphQLSchema;
  try {
    builtSchema = buildSchema(schema || '');
  } catch (error) {
    builtSchema = new GraphQLSchema({});
  }

  return builtSchema;
}

interface IProps {
  onChange?(value: string): void;
  value?: string;
  schema?: File;
}

const GraphqlBody = ({value = '', onChange = noop, schema}: IProps) => {
  const ref = useRef<ReactCodeMirrorRef>(null);

  const extensions = useMemo(() => {
    return [
      graphql(getGraphQLSchema(''), {
        /* onShowInDocs(field, type, parentType) {
           alert(`Showing in docs.: Field: ${field}, Type: ${type}, ParentType: ${parentType}`);
        },
        onFillAllFields(view, s, _query, cursor, token) {
           alert(`Filling all fields. Token: ${token}`);
        }, */
      }),
    ];
  }, []);

  useEffect(() => {
    const getFileText = async () => {
      if (!ref.current?.view) return;

      const schemaText = (await schema?.text()) ?? '';
      updateSchema(ref.current.view, getGraphQLSchema(schemaText));
    };

    getFileText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema?.lastModified, ref.current]);

  return (
    <S.Container>
      <Editor
        ref={ref}
        value={value}
        onChange={onChange}
        type={SupportedEditors.Graphql}
        basicSetup={{lineNumbers: true, indentOnInput: true}}
        extensions={extensions}
        placeholder="Enter your GraphQL query here"
      />
    </S.Container>
  );
};

export default GraphqlBody;
