import {VariableSetValue} from '@core/models/VariableSet.model';
import {Button, Form, Input, Select} from 'antd';
import {FormListFieldData} from 'antd/lib/form/FormList';
import * as S from './VariableSetValues.styled';

interface IProps {
  field: FormListFieldData;
  onDelete(name: number): void;
  index: number;
  isDisabled?: boolean;
  value?: VariableSetValue;
}

const Value = ({field, onDelete, index, isDisabled = false, value}: IProps) => (
  <S.Container key={field.name}>
    <Form.Item name={[field.name, 'type']} noStyle>
      <Select defaultValue="raw" disabled={isDisabled}>
        <Select.Option value="raw">Raw</Select.Option>
        <Select.Option value="secret">Secret</Select.Option>
      </Select>
    </Form.Item>

    <Form.Item name={[field.name, 'key']} noStyle>
      <Input placeholder={`Key ${index + 1}`} disabled={isDisabled} />
    </Form.Item>

    <Form.Item name={[field.name, 'value']} noStyle>
      <Input
        placeholder={`Value ${index + 1}`}
        disabled={isDisabled}
        type={value?.type === 'secret' ? 'password' : 'text'}
      />
    </Form.Item>

    <Form.Item noStyle>
      <Button icon={<S.DeleteIcon />} onClick={() => onDelete(field.name)} type="link" />
    </Form.Item>
  </S.Container>
);

export default Value;
