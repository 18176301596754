import Environment from '@models/Environment.model';
import {Form} from 'antd';
import * as S from './EnvironmentModal.styled';
import Footer from './Footer';
import FormBody from './FormBody';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose(): void;
  onSubmit(values: Environment): void;
}

const EnvironmentModal = ({isLoading, isOpen, onClose, onSubmit}: IProps) => {
  const [form] = Form.useForm<Environment>();

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  const handleOnSubmit = (values: Environment) => {
    onSubmit(values);
    handleOnClose();
  };

  return (
    <S.Modal
      cancelText="Cancel"
      footer={<Footer isLoading={isLoading} onClose={handleOnClose} onSubmit={() => form.submit()} />}
      onCancel={handleOnClose}
      title={<S.Title>Create a New Environment</S.Title>}
      visible={isOpen}
    >
      <FormBody form={form} onSubmit={handleOnSubmit} />
    </S.Modal>
  );
};

export default EnvironmentModal;
