import Panel from '@components/molecules/Panel';
import EnvironmentMembersList from '@components/organisms/EnvironmentMemberList';
import RoleModal, {IRoleForm} from '@components/organisms/RoleModal';
import Member from '@models/Member.model';
import User from '@models/User.model';
import {useConfirmationModal} from '@core/providers/ConfirmationModal/ConfirmationModal.provider';
import {useNotification} from '@core/providers/Notification/Notification.provider';
import {useEnvironment} from '@providers/Environment.provider';
import {
  useDeleteEnvironmentMemberMutation,
  useGetEnvironmentMembersQuery,
  useUpdateEnvironmentMemberMutation,
} from '@redux/apis/TracetestCloud';
import { PAGE_SIZE } from '@constants/list.constants';
import {skipToken} from '@reduxjs/toolkit/query';
import {EnvironmentGroup, OrganizationOperation} from '@services/Authz';
import {Tabs} from 'antd';
import {useState} from 'react';
import {useAuthz} from '@hooks/authz';
import AssignMemberPanel from './AssignMemberPanel';

const roles = Object.values(EnvironmentGroup);

const EnvironmentMembers = () => {
  const {environment} = useEnvironment();
  const {showNotification} = useNotification();
  const {onOpen} = useConfirmationModal();
  const {checkOrganization} = useAuthz();

  // delete member
  const [deleteMember] = useDeleteEnvironmentMemberMutation();
  const handleDeleteMember = (value: Member) => {
    const onConfirm = async () => {
      await deleteMember({envId: environment?.id ?? '', memId: value.id, orgId: environment?.organizationID ?? ''});
      showNotification({
        type: 'success',
        title: 'Member removed successfully',
      });
    };

    onOpen({
      heading: 'Remove member',
      title: `Are you sure you want to remove ${User.getEmail(value.user)}?`,
      okText: 'Remove',
      onConfirm,
    });
  };

  // update member role modal
  const [isUpdateRoleModalOpen, setIsUpdateRoleModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<Member>();
  const [updateMember, {isLoading: isUpdateMemberLoading}] = useUpdateEnvironmentMemberMutation();
  const handleUpdateMemberRole = async (value: IRoleForm) => {
    await updateMember({
      envId: environment?.id ?? '',
      memId: value.id,
      orgId: environment?.organizationID ?? '',
      role: value.role,
    });
    setSelectedMember(undefined);
    showNotification({
      type: 'success',
      title: 'Member role updated successfully',
    });
  };

  // fetch members
  const [skip, setSkip] = useState(0);
  const {data: {items: members, total} = {items: [], total: 0}, isLoading} = useGetEnvironmentMembersQuery(
    environment?.id
      ? {envId: environment.id, orgId: environment?.organizationID ?? '', skip, take: PAGE_SIZE}
      : skipToken
  );

  return (
    <>
      {checkOrganization(OrganizationOperation.Edit) && <AssignMemberPanel members={members} />}

      <Panel>
        <Tabs defaultActiveKey="members">
          <Tabs.TabPane tab="Members" key="members">
            <EnvironmentMembersList
              isLoading={isLoading}
              members={members}
              onDelete={handleDeleteMember}
              total={total}
              pageSize={PAGE_SIZE}
              onPageChange={(page, pageSize) => setSkip((page - 1) * pageSize)}
              onUpdate={value => {
                setSelectedMember(value);
                setIsUpdateRoleModalOpen(true);
              }}
            />
          </Tabs.TabPane>
        </Tabs>
      </Panel>

      <RoleModal
        isLoading={isUpdateMemberLoading}
        isOpen={isUpdateRoleModalOpen}
        member={selectedMember}
        onClose={() => setIsUpdateRoleModalOpen(false)}
        onSubmit={handleUpdateMemberRole}
        roles={roles}
      />
    </>
  );
};

export default EnvironmentMembers;
