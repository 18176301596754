import {Flag as OSSFlag} from '@core/providers/Customization';
import {components, flags} from './Customization.constants';

const CustomizationService = {
  getComponent(id: string) {
    return components[id];
  },

  getFlag(flag: OSSFlag) {
    return flags[flag] || false;
  },
};

export default CustomizationService;
