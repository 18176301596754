const MAX_CHARACTERS = 1000;

export const navigate = (path: string) => {
  if (path === window.location.pathname) return;

  window.location.assign(path);
};

export const cutToMaxCharacters = (text: string, maxCharacters = MAX_CHARACTERS) => {
  return text.length > maxCharacters ? `${text.slice(0, maxCharacters)}...<Value is Too Large>` : text;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['b', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
