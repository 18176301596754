import {PostHogProvider} from 'posthog-js/react';
import Content from './Content';

const posthogKey = process.env.REACT_APP_POSTHOG_KEY;
const isDisabled = process.env.REACT_APP_DISABLE_TELEMETRY === 'true';

const options = {
  api_host: 'https://app.posthog.com',
  session_recording: {
    maskAllInputs: false,
  },
};

interface IProps {
  children: React.ReactNode;
}

const CaptureWrapper = ({children}: IProps) =>
  isDisabled ? (
    <div>{children}</div>
  ) : (
    <PostHogProvider apiKey={posthogKey} options={options}>
      <Content>{children}</Content>
    </PostHogProvider>
  );

export default CaptureWrapper;
