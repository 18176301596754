import {FolderOpenOutlined, GithubOutlined, LogoutOutlined, ReadOutlined, TeamOutlined} from '@ant-design/icons';
import slackIcon from '@core/assets/slack.svg';
import {COMMUNITY_SLACK_URL, DOCUMENTATION_URL, GITHUB_URL} from '@core/constants/Common.constants';
import Env from '@core/utils/Env';
import Organization from '@models/Organization.model';
import User from '@models/User.model';
import {useCheckSession} from '@hooks/authn';
import {OrganizationOperation, useAuthz} from '@hooks/authz';
import {useLogoutMutation} from '@redux/apis/TracetestCloud';
import {Dropdown, Menu, Space} from 'antd';
import {Link} from 'react-router-dom';
import * as S from '../MenuSelector';
import * as UM from './UserMenu.styled';

const appVersion = Env.get('appVersion');

interface IProps {
  organization?: Organization;
  user: User;
}

const UserMenu = ({organization, user}: IProps) => {
  const [logout] = useLogoutMutation();
  const {logoutUrl = ''} = useCheckSession();
  const {checkOrganization} = useAuthz();
  const name = user.name || User.getEmail(user);
  const char = name.charAt(0) || '';

  const onLogOut = () => {
    logout({});
    window.location.assign(logoutUrl);
  };

  const userMenu = (
    <S.Menu>
      <Menu.Item key="name" disabled>
        <UM.Text>{User.getEmail(user)}</UM.Text>
      </Menu.Item>

      {!!organization && (
        <>
          <Menu.Divider />
          <Menu.Item key="organization-settings">
            <Link to={`/organizations/${organization.id}/settings`} onClick={e => e.stopPropagation()}>
              <UM.Column>
                <div>{organization.name}</div>
                <UM.Text $isActive>Organization settings</UM.Text>
              </UM.Column>
            </Link>
          </Menu.Item>
          <Menu.Item key="organization-environments" icon={<FolderOpenOutlined />}>
            <Link to={`/organizations/${organization.id}/environments`} onClick={e => e.stopPropagation()}>
              Environments
            </Link>
          </Menu.Item>
          {checkOrganization(OrganizationOperation.Edit) && (
            <Menu.Item key="organization-members" icon={<TeamOutlined />}>
              <Link to={`/organizations/${organization.id}/members`} onClick={e => e.stopPropagation()}>
                Members
              </Link>
            </Menu.Item>
          )}
        </>
      )}

      <Menu.Divider />
      <Menu.Item key="slack" icon={<img src={slackIcon} width={12} />}>
        <a target="_blank" href={COMMUNITY_SLACK_URL}>
          Join our Slack community
        </a>
      </Menu.Item>
      <Menu.Item key="documentation" icon={<ReadOutlined />}>
        <a target="_blank" href={DOCUMENTATION_URL}>
          Documentation
        </a>
      </Menu.Item>
      <Menu.Item key="github" icon={<GithubOutlined />}>
        <a target="_blank" href={GITHUB_URL}>
          GitHub
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="version" disabled>
        <UM.Text>App version: {appVersion}</UM.Text>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" danger data-testid="user-menu-logout">
        <a onClick={onLogOut}>
          <Space>
            <LogoutOutlined />
            Logout
          </Space>
        </a>
      </Menu.Item>
    </S.Menu>
  );

  return (
    <Dropdown overlay={userMenu} trigger={['click']}>
      <S.Button type="link" data-testid="user-menu-button">
        <UM.Avatar char={char} />
      </S.Button>
    </Dropdown>
  );
};

export default UserMenu;
