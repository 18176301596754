import {Model, TRunSchemas} from '@core/types/Common.types';

export type TRawRunFilter = TRunSchemas['RunFilter'];
type RunFilter = Model<TRawRunFilter, {}>;
export type TFilters = TRunSchemas['RunFilterName'];

export const RunFilterName: Record<string, TFilters> = {
  Status: 'status',
  TriggerType: 'triggerType',
  MonitorName: 'monitorName',
  SuiteName: 'suiteName',
  UserEmail: 'userEmail',
  Source: 'source',
  Token: 'token',
  Branch: 'branch',
  IsCi: 'isCi',
  Provider: 'provider',
  PlaywrightBrowser: 'playwrightBrowser',
  CypressBrowser: 'cypressBrowser',
};

const defaultRun: TRawRunFilter = {
  name: 'group',
  values: [],
};

const RunFilter = ({name = 'group', values = []} = defaultRun): RunFilter => ({
  name,
  values,
});

export default RunFilter;
