import Action from '@components/atoms/Action';
import {List, Space, Tag, Tooltip} from 'antd';
import Polling from '@core/models/Polling.model';
import {FieldTimeOutlined} from '@ant-design/icons';
import {useMemo} from 'react';
import {startCase} from 'lodash';

const metaIconMap: Record<string, React.ReactNode> = {
  strategy: '⤴',
  retryDelay: <FieldTimeOutlined />,
  timeout: <FieldTimeOutlined />,
  selectorMatchRetries: '#',
};

interface IProps {
  profile: Polling;
  onDelete(profile: Polling): void;
  onEdit(profile: Polling): void;
  onOpenDefinition(profile: Polling): void;
  canEdit: boolean;
}

const ProfileItem = ({
  profile: {meta, default: isDefault, name},
  profile,
  onEdit,
  onDelete,
  onOpenDefinition,
  canEdit,
}: IProps) => {
  const metadata = useMemo(
    () => (
      <div style={{display: 'flex', gap: '8px'}}>
        {Object.entries(meta)
          .filter(([, value]) => !!value)
          .map(([key, value]) => (
            <div style={{cursor: 'pointer'}}>
              <Tooltip title={startCase(key)} key={key}>
                {metaIconMap[key] ?? '•'} {value}
              </Tooltip>
            </div>
          ))}
      </div>
    ),
    [meta]
  );

  return (
    <List.Item
      actions={[
        <Action item={profile} disabled={!canEdit} key="profile-edit" onClick={onEdit} title="edit" />,
        <Action
          item={profile}
          disabled={!canEdit || isDefault}
          key="profile-delete"
          onClick={onDelete}
          title="delete"
        />,
        <Action
          item={profile}
          disabled={!canEdit}
          key="profile-definition"
          onClick={onOpenDefinition}
          title="definition"
        />,
      ]}
    >
      <List.Item.Meta title={name} description={metadata} />
      <Space>
        <div>{!!isDefault && <Tag color="success">Default</Tag>}</div>
      </Space>
    </List.Item>
  );
};

export default ProfileItem;
