import {GITHUB_ISSUES_URL} from '@core/constants/Common.constants';

import {IIntegration} from '@constants/integrations.constants';
import IntegrationIcon from '@components/atoms/IntegrationIcon/IntegrationIcon';
import * as S from './TriggerTypeList.styled';

interface IProps {
  integration: IIntegration;
}

const IntegrationTypeCard = ({integration: {name, title, isActive, url}}: IProps) => (
  <a href={url} target="__blank" aria-disabled={!isActive}>
    <S.CardContainer data-cy={`${name.toLowerCase()}-plugin`} $isActive={isActive}>
      <IntegrationIcon integrationName={name} />

      <S.CardContent>
        <div>
          <S.CardTitle $isActive={isActive}>{title} </S.CardTitle>
          {!isActive && (
            <S.CardTitle $isActive>
              &nbsp;-{' '}
              <a href={GITHUB_ISSUES_URL} target="_blank">
                Coming soon!
              </a>
            </S.CardTitle>
          )}
        </div>
      </S.CardContent>
    </S.CardContainer>
  </a>
);

export default IntegrationTypeCard;
