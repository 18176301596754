import {useOrganizations} from '@providers/Organizations.provider';
import {Typography} from 'antd';
import {capitalize} from 'lodash';
import * as S from './LimitedAccess.styled';

const {REACT_APP_DEMO_ORG_ID = ''} = process.env;

interface IProps {
  description?: string;
  organizationID?: string;
  role: string;
}

const LimitedAccess = ({description, organizationID, role}: IProps) => {
  const {openOrganizationModal} = useOrganizations();
  const isDemoOrg = REACT_APP_DEMO_ORG_ID === organizationID;

  return (
    <div>
      <S.Title level={3} type="secondary">
        <S.Warning /> Limited Access
      </S.Title>
      <S.Paragraph type="secondary">
        Your current role group (<b>{capitalize(role)}</b>) has limited access to this{' '}
        {isDemoOrg ? 'Demo environment.' : 'environment.'} <br />
        {!isDemoOrg &&
          (description || 'To adjust settings, please contact the environment administrator for assistance.')}
        {isDemoOrg && (
          <div>
            <b>Are you ready to test your own app?</b> You can{' '}
            <Typography.Link
              onClick={e => {
                e.preventDefault();
                openOrganizationModal();
              }}
            >
              create an organization
            </Typography.Link>{' '}
            and start testing with your own data.
          </div>
        )}
      </S.Paragraph>
    </div>
  );
};

export default LimitedAccess;
