import Panel from '@components/molecules/Panel';
import Header from '@components/organisms/EnvironmentAgent/Header';
import LimitedAccess from '@components/organisms/LimitedAccess';
import Environment from '@models/Environment.model';
import {useEnvironment} from '@providers/Environment.provider';

const LimitedAccessAgent = () => {
  const {
    environment: {agent, isLocal = false, agentConfiguration: {serverless}, organizationID} = Environment(),
    member,
  } = useEnvironment();

  return (
    <Panel title={<Header agent={agent} isLocal={isLocal} isServerless={serverless} />}>
      <LimitedAccess
        description="To configure Agent settings, please contact the environment administrator for assistance."
        organizationID={organizationID}
        role={member.role}
      />
    </Panel>
  );
};

export default LimitedAccessAgent;
