import {EyeOutlined, FolderOpenOutlined, PlusOutlined} from '@ant-design/icons';
import * as S from '@components/molecules/MenuSelector';
import {OrganizationOperation, useAuthz} from '@hooks/authz';
import Environment from '@models/Environment.model';
import {TracetestApiTagsList} from '@core/constants/Test.constants';
import TracetestAPI from '@core/redux/apis/Tracetest';
import {useEnvironments} from '@providers/Environments.provider';
import {useAppDispatch} from '@redux/hooks';
import TracetestCloudAPI, {TAG_TYPES} from '@redux/apis/TracetestCloud/TracetestCloud.api';
import {Dropdown, Tooltip as AntdTooltip, Typography} from 'antd';
import {useOrganization} from '@providers/Organization.provider';
import {useInView} from 'react-cool-inview';
import {useNavigate} from 'react-router-dom';
import * as SS from './EnvironmentSelector.styled';
import Item from './Item';
import Search from './Search';
import Tooltip, {useTooltip} from './Tooltip';
import AgentBadge from '../AgentBadge';

interface IProps {
  environment?: Environment;
  environments?: Environment[];
  onLoadData(): void;
  onSearch(name: string): void;
  onClick?(env: Environment): void;
  onCreateEnvironment?(env: Environment): void;
  withControls?: boolean;
}

const EnvironmentSelector = ({
  environment,
  environments,
  onLoadData,
  onSearch,
  onClick,
  onCreateEnvironment,
  withControls = true,
}: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {openEnvironmentModal} = useEnvironments();
  const {organization} = useOrganization();
  const {checkOrganization} = useAuthz();
  const {isToolTipVisible, onTooltipClose} = useTooltip();
  const canEditOrg = checkOrganization(OrganizationOperation.Edit);

  const onItemClick = ({organizationID, id}: Environment, path = '') => {
    navigate(`/organizations/${organizationID}/environments/${id}${path}`);

    dispatch(TracetestAPI.util.invalidateTags(TracetestApiTagsList));
    dispatch(TracetestCloudAPI.util.invalidateTags([TAG_TYPES.TOKEN_LIST]));
  };

  const {observe} = useInView({
    rootMargin: '10px 0px',
    onEnter: ({unobserve}) => {
      // Pause observe when loading data
      unobserve();
      // Load more data
      onLoadData();
    },
  });

  const environmentsMenu = (
    <SS.DropdownContainer>
      <Search onSearch={onSearch} />

      <SS.ListContainer>
        <SS.List>
          {environments?.map((env, idx) => (
            <SS.ListItem
              key={env.id}
              onClick={() => (onClick ? onClick(env) : onItemClick(env))}
              ref={idx === environments.length - 1 ? observe : null}
            >
              <Item environment={env} isSelected={env.id === environment?.id} onSettingsClick={onItemClick} />
            </SS.ListItem>
          ))}

          {environments?.length === 0 && (
            <SS.EmptyState>
              <Typography.Text type="secondary">No Environments Found</Typography.Text>
            </SS.EmptyState>
          )}
        </SS.List>
      </SS.ListContainer>

      {withControls && (
        <>
          <SS.FooterContainer
            onClick={() => {
              navigate(`/organizations/${organization?.id}`);
            }}
            $hasBorder
          >
            <S.ItemContainer>
              <EyeOutlined />
              See All Environments
              <div />
            </S.ItemContainer>
          </SS.FooterContainer>

          <SS.FooterContainer
            onClick={e => {
              if (canEditOrg) {
                openEnvironmentModal(onCreateEnvironment);
              } else {
                e.stopPropagation();
              }
            }}
            $disabled={!canEditOrg}
          >
            <AntdTooltip
              placement="right"
              title={
                !canEditOrg
                  ? `You can't create a new environment in ${organization?.name}. Switch to or create your own organization to work a new environment. Click on the organization dropdown to create a new organization.`
                  : ''
              }
            >
              <S.ItemContainer data-testid="organization-selector-new-env">
                <PlusOutlined />
                Create a New Environment
                <div />
              </S.ItemContainer>
            </AntdTooltip>
          </SS.FooterContainer>
        </>
      )}
    </SS.DropdownContainer>
  );

  return (
    <Dropdown overlay={environmentsMenu} onVisibleChange={onTooltipClose} trigger={['click']}>
      <Tooltip visible={isToolTipVisible && !!environments?.length} onClose={onTooltipClose}>
        <S.Button type="link" color="primary" data-testid="environment-selector-button">
          <S.MenuSelector
            name={
              environment ? (
                <>
                  <AgentBadge
                    isServerless={environment.agentConfiguration.serverless}
                    status={environment.agent.status}
                  />
                  <S.SelectedItemText data-testid="menu-selector-name">
                    {environment.name || 'Untitled Environment'}
                  </S.SelectedItemText>
                </>
              ) : (
                <>Select Environment</>
              )
            }
            icon={<FolderOpenOutlined />}
          />
        </S.Button>
      </Tooltip>
    </Dropdown>
  );
};

export default EnvironmentSelector;
