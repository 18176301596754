import {TPlanSchemas} from '@core/types/Common.types';

export type TRawLicense = TPlanSchemas['License'];
type License = TRawLicense & {};

const defaultLicense: License = {
  id: '',
  name: '',
  createdAt: '',
  expiresAt: '',
  isValid: false,
};

function License({
  id = '',
  name = '',
  createdAt = '',
  expiresAt = '',
  isValid = false,
}: TRawLicense = defaultLicense): License {
  return {
    id,
    name,
    isValid,
    createdAt,
    expiresAt,
  };
}

export default License;
