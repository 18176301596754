import {TRawToken} from '@models/Token.model';
import {Role} from '@core/types/Common.types';
import {useCreateTokenMutation, useGetTokensQuery} from '@redux/apis/TracetestCloud';
import {useEffect, useMemo} from 'react';

const useIngestionToken = () => {
  const {data: {items: tokens = []} = {}, isLoading} = useGetTokensQuery({take: 100000, skip: 0});
  const [createToken] = useCreateTokenMutation();

  const token = useMemo(() => {
    return tokens.find(
      ({role, isExpired, isRevoked, isMonitorToken}) =>
        role === Role.Admins && !isExpired && !isRevoked && !isMonitorToken
    );
  }, [tokens]);

  useEffect(() => {
    if (!token && !isLoading) {
      createToken({
        token: {
          name: 'ingestion-token',
          role: 'admins',
          isMonitorToken: false,
          isAgentToken: false,
          duration: 365 * 5, // 5 years
        } as TRawToken,
      });
    }
  }, [createToken, isLoading, token]);

  return token;
};

export default useIngestionToken;
