import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .ant-spin-nested-loading {
    height: 150px
  }
`;

export const Container = styled.div`
  padding: 24px;
`;