import Panel from '@components/molecules/Panel';
import LimitedAccess from '@components/organisms/LimitedAccess';
import DocsBanner from '@core/components/DocsBanner';
import {TEST_RUNNER_DOCUMENTATION_URL} from '@core/constants/Common.constants';
import {SupportedRequiredGates} from '@core/models/TestRunner.model';
import {useSettingsValues} from '@core/providers/SettingsValues/SettingsValues.provider';
import {ToTitle} from '@core/utils/Common';
import {useEnvironment} from '@providers/Environment.provider';
import {Descriptions, Typography} from 'antd';
import * as S from '../Settings.styled';

const supportedGates = Object.values(SupportedRequiredGates);

const LimitedTestRunner = () => {
  const {environment, member} = useEnvironment();
  const {testRunner} = useSettingsValues();

  return (
    <Panel title="Test Runner">
      <Typography.Paragraph type="secondary">
        <p>The Test Runner allows you to configure the behavior used to execute your tests and generate the results.</p>
        <DocsBanner>
          Need more information about the Test Runner?{' '}
          <a href={TEST_RUNNER_DOCUMENTATION_URL} target="_blank">
            Learn more in our docs
          </a>
        </DocsBanner>
      </Typography.Paragraph>

      <LimitedAccess
        description="To configure the Test Runner settings, please contact the environment administrator for assistance."
        organizationID={environment?.organizationID}
        role={member.role}
      />

      <S.Divider />

      <div>
        <Typography.Title level={3}>Current Test Runner settings:</Typography.Title>

        <S.Descriptions size="small" column={1}>
          {supportedGates.map(gate => (
            <Descriptions.Item key={gate} label={ToTitle(gate)}>
              {testRunner.requiredGates.includes(gate) ? 'Enabled' : 'Disabled'}
            </Descriptions.Item>
          ))}
        </S.Descriptions>
      </div>
    </Panel>
  );
};

export default LimitedTestRunner;
