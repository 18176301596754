import {TInvitesSchemas, InviteStatus, InviteType, Role} from '@core/types/Common.types';
import InviteEnvironment from './InviteEnvironment';
import Organization from './Organization.model';

export type TRawInviteResource = TInvitesSchemas['InviteResource'];
export type TRawInvite = TInvitesSchemas['Invite'];
type Invite = TRawInvite & {
  organization: Organization;
  environments: InviteEnvironment[];
};

const defaultInvite: TRawInvite = {
  id: '',
  role: Role.Members,
  to: '',
};

function Invite({
  id = '',
  role = Role.Members,
  to = '',
  createdAt = '',
  sentAt = '',
  type = InviteType.Email,
  returnTo = '',
  status = InviteStatus.Pending,
  environments = [],
  organization,
}: TRawInvite = defaultInvite): Invite {
  return {
    id,
    role,
    to,
    createdAt,
    sentAt,
    type,
    returnTo,
    status,
    environments: environments.map(environment => InviteEnvironment(environment)),
    organization: Organization(organization),
  };
}

Invite.fromResource = function FromResource({spec = defaultInvite}: TRawInviteResource): Invite {
  return Invite(spec);
};

Invite.toResource = function ToResource(invite: TRawInvite): TRawInviteResource {
  return {
    type: 'Invite',
    spec: invite,
  };
};

export default Invite;
