import {media} from '@utils/media';
import styled from 'styled-components';

export const ResponsiveContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 540px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;

  ${media.md`
    max-width: 720px;
  `}

  ${media.lg`
    max-width: 960px;
  `}

  ${media.xl`
    max-width: 1140px;
  `}

  ${media.xxl`
    max-width: 1320px;
  `}
`;
