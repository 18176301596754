import CharIcon from '@components/atoms/CharIcon';
import {Layout as LayoutAntd, Typography, Progress as AntdProgress} from 'antd';
import Link from '@core/components/Link';
import styled from 'styled-components';

export const Layout = styled(LayoutAntd)<{$marginLeft?: number}>`
  background: ${({theme}) => theme.color.background};
  margin-left: ${({$marginLeft}) => $marginLeft}px;
  min-height: 100%;
`;

export const Sider = styled(LayoutAntd.Sider)`
  background: linear-gradient(180deg, #2f1e61 0%, #8b2c53 111.31%, rgba(49, 38, 132, 0) 180.18%, #df4f80 180.18%);
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 1000;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .ant-layout-sider-trigger {
    background: rgba(255, 255, 255, 0.05);
  }
`;

export const TopNav = styled.div`
  display: flex;
  overflow-x: clip;
`;

export const Logo = styled.div<{$collapsed: boolean}>`
  display: inline-flex;
  padding: 7px 21px;

  .logo {
    opacity: ${({$collapsed}) => ($collapsed ? 0 : 1)};
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    visibility: ${({$collapsed}) => ($collapsed ? 'hidden' : 'visible')};
  }
`;

export const SiderBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const Menu = styled.div`
  padding: 24px 0;

  .ant-menu.ant-menu-dark {
    background: transparent;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgba(255, 255, 255, 0.05);

    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 3px;
      background-color: #f03950;
    }
  }
`;

export const MenuBottom = styled.div<{$collapsed: boolean}>`
  opacity: ${({$collapsed}) => ($collapsed ? 0 : 1)};
  padding: 24px 14px;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  visibility: ${({$collapsed}) => ($collapsed ? 'hidden' : 'visible')};
`;

export const Body = styled(LayoutAntd.Content)<{$withPadding?: boolean}>`
  display: flex;
  flex-direction: column;
  padding: ${({$withPadding}) => ($withPadding ? '24px' : '0')};
`;

export const HeaderContainer = styled.div<{$collapsed: boolean}>`
  align-items: ${({$collapsed}) => ($collapsed ? 'center' : 'flex-start')};
  background-color: rgba(248, 248, 250, 0.1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px;
  padding: ${({$collapsed}) => ($collapsed ? '16px 11px' : '16px')};

  h3,
  a,
  > span {
    display: ${({$collapsed}) => ($collapsed ? 'none' : 'block')};
  }
`;

export const HeaderRow = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
`;

export const HeaderTitle = styled(Typography.Title)`
  && {
    color: ${({theme}) => theme.color.white};
    margin: 0;
    word-break: break-word;
  }
`;

export const HeaderLink = styled(Link)`
  color: rgba(255, 255, 255, 0.65);
  display: block;
  font-size: ${({theme}) => theme.size.sm};
  padding: 0;
  transition: color 0.2s ease;

  &:hover {
    color: ${({theme}) => theme.color.white};
  }
`;

export const HeaderText = styled(Typography.Text)`
  color: rgba(255, 255, 255, 0.65);
  display: block;
  font-size: ${({theme}) => theme.size.sm};
  padding: 0;
`;

export const HeaderCharIcon = styled(CharIcon)`
  height: 18px;
  width: 18px;

  span {
    font-size: ${({theme}) => theme.size.md};
  }
`;

export const ExtraSpace = styled.div`
  min-height: 60px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  gap: 5px;

  span {
    color: ${({theme}) => theme.color.primaryDeep};
  }
`;

export const Progress = styled(AntdProgress).attrs(({theme}) => ({
  strokeColor: theme.color.white,
  trailColor: theme.color.primaryDeep,
}))`
  width: 120px;
  .ant-progress-text {
    color: ${({theme}) => theme.color.white};
  }
`;

export const ProgressText = styled(Typography.Text)`
  && {
    color: ${({theme}) => theme.color.primaryDeep};
  }
`;

export const SetupLink = styled(Link)`
  line-height: 20px;
  display: flex;
  flex-direction: column;
`;
