import {useLicense} from '@providers/License.provider';
import {getParsedURL} from '../utils/Common';

const PROD_DOMAIN = 'https://app.tracetest.io';
const serverUrl = !window.location.href.includes(PROD_DOMAIN)
  ? ` --server-url ${process.env.REACT_APP_BASE_API_URL}/`
  : '';
const insecure = getParsedURL(window.location.href).protocol === 'http:' ? ' --insecure' : '';

export const getOptions = (isCertificateValid: boolean) => {
  const skipVerify = !isCertificateValid ? ' --skip-verify' : '';

  return {serverUrl, skipVerify, insecure};
};

const useCommand = (command: string) => {
  const {isCertificateValid} = useLicense();
  const {skipVerify} = getOptions(isCertificateValid);

  return `${command}${serverUrl}${skipVerify}`;
};

export default useCommand;
