import {LoginFlow, UpdateLoginFlowBody, UiNodeAttributes, UiNodeInputAttributes} from '@ory/client';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import AuthnService from '@services/Authn.service';
import {useSdkError} from '@hooks/authn';

export const ACCOUNT_EXISTS_KRATOS_ID = 1010016;

export const useLoginFlow = () => {
  const [flow, setFlow] = useState<LoginFlow | null>(null);
  const [returnTo, setReturnTo] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // get the flow based on the flowId in the URL
  const getFlow = useCallback(
    (flowId: string) =>
      AuthnService.getLoginFlow(flowId).then(data => {
        // set the flow data which contains the form fields, csrf token and error messages
        setFlow(data);
      }),
    []
  );

  // initialize the sdkError for generic handling of errors
  const sdkErrorHandler = useSdkError(getFlow, setFlow, '/', true);

  // submit the login form data to Ory, this submitFlow does not apply for social login
  const submitFlow = (body: UpdateLoginFlowBody) => {
    // something unexpected went wrong and the flow was not set
    if (!flow) return navigate('/', {replace: true});

    // we submit the flow to Ory with the form data
    AuthnService.submitLoginFlow(flow.id, body)
      .then(() => {
        // we successfully submitted the login flow, so lets redirect to the dashboard
        navigate('/organizations/default', {replace: true});
      })
      .catch(sdkErrorHandler);
  };

  useEffect(() => {
    // create a new login flow
    const createFlow = () => {
      const aal2 = searchParams.get('aal2');
      const returnToParam = searchParams.get('returnTo');
      // aal2 is a query parameter that can be used to request Two-Factor authentication
      // aal1 is the default authentication level (Single-Factor)
      AuthnService.createLoginFlow({
        aal: aal2 ? 'aal2' : 'aal1',
        returnTo: returnToParam ?? '/organizations/default',
      })
        // the flow data contains the form fields and csrf token
        .then(data => {
          // update URI query params to include flow id
          setSearchParams({flow: data.id});
          // set the flow data
          setFlow(data);
          // persist returnTo
          if (data.return_to) {
            setReturnTo(data.return_to);
          }
        })
        .catch(sdkErrorHandler);
    };

    // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
    const flowId = searchParams.get('flow');

    // the flow already exists
    if (flowId) {
      getFlow(flowId)
        // if for some reason the flow has expired, we need to get a new one
        .catch(createFlow);
      return;
    }

    // we assume there was no flow, so we create a new one
    createFlow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {flow: parseFlow(flow), submitFlow, returnTo};
};

const isUiNodeInputAttributes = (attrs: UiNodeAttributes): attrs is UiNodeInputAttributes => {
  return (attrs as UiNodeInputAttributes).value !== undefined;
};

const parseFlow = (flow: LoginFlow | null): LoginFlow | null => {
  if (!flow || !flow.ui.messages?.length) return flow;

  // we need to parse the error messages and set them in the form fields
  const {
    ui: {messages = [], nodes = []},
    ui,
  } = flow;

  const foundMessage = messages.find(({id}) => id === ACCOUNT_EXISTS_KRATOS_ID);
  if (!foundMessage) return flow;

  const providerToRemove = (foundMessage.context as {provider: string}).provider as string;

  return {
    ...flow,
    ui: {
      ...ui,
      nodes: nodes.filter(
        ({attributes}) =>
          isUiNodeInputAttributes(attributes) &&
          (attributes.value as string).toLowerCase() !== providerToRemove.toLowerCase()
      ),
    },
  };
};
