import {noop} from 'lodash';
import {createContext, useCallback, useContext, useMemo, useState} from 'react';

import {SupportedDataStoresToName} from '@core/constants/DataStore.constants';
import {
  useDeleteDataStoreMutation,
  useTestConnectionMutation,
  useUpdateDataStoreMutation,
} from '@core/redux/apis/Tracetest';
import DataStoreService from '@core/services/DataStore.service';
import {useContactUsModal} from '@core/components/ContactUs';
import {SupportedDataStores, TConnectionResult, TDraftDataStore} from '@core/types/DataStore.types';
import DataStore from '@core/models/DataStore.model';
import OTLPTestConnectionResponse from '@core/models/OTLPTestConnectionResponse.model';
import useDataStoreNotification from './hooks/useDataStoreNotification';
import {useConfirmationModal} from '../ConfirmationModal/ConfirmationModal.provider';
import {useSettingsValues} from '../SettingsValues/SettingsValues.provider';
import {useWizard} from '../Wizard';

interface IContext {
  isFormValid: boolean;
  isLoading: boolean;
  isTestConnectionLoading: boolean;
  isTestConnectionSuccessful: boolean;
  testConnectionResponse?: TConnectionResult;
  otlpTestConnectionResponse?: OTLPTestConnectionResponse;
  onSaveConfig(draft: TDraftDataStore, defaultDataStore: DataStore, onAfterSave?: () => void): void;
  onDeleteConfig(onAfterDelete?: () => void): void;
  onTestConnection(draft: TDraftDataStore, defaultDataStore: DataStore): void;
  onSetOtlpTestConnectionResponse(response?: OTLPTestConnectionResponse): void;
  onIsFormValid(isValid: boolean): void;
  resetTestConnection(): void;
}

export const Context = createContext<IContext>({
  isFormValid: false,
  isLoading: false,
  isTestConnectionLoading: false,
  isTestConnectionSuccessful: false,
  onSaveConfig: noop,
  onDeleteConfig: noop,
  onIsFormValid: noop,
  onTestConnection: noop,
  resetTestConnection: noop,
  onSetOtlpTestConnectionResponse: noop,
});

interface IProps {
  children: React.ReactNode;
}

export const useDataStore = () => useContext(Context);

const DataStoreProvider = ({children}: IProps) => {
  const {isFetching} = useSettingsValues();
  const {onCompleteStep} = useWizard();
  const [updateDataStore, {isLoading: isLoadingUpdate}] = useUpdateDataStoreMutation();
  const [deleteDataStore] = useDeleteDataStoreMutation();
  const [
    testConnection,
    {isLoading: isTestConnectionLoading, data: testConnectionResponse, reset: resetTestConnection},
  ] = useTestConnectionMutation();
  const [otlpTestConnectionResponse, setOtlpTestConnectionResponse] = useState<
    OTLPTestConnectionResponse | undefined
  >();

  const [isFormValid, setIsFormValid] = useState(false);
  const {showSuccessNotification, showTestConnectionNotification} = useDataStoreNotification();
  const {onOpen} = useConfirmationModal();
  const [connectionTries, setConnectionTries] = useState(0);
  const {onOpen: onContactUsOpen} = useContactUsModal();

  const onSaveConfig = useCallback(
    async (draft: TDraftDataStore, defaultDataStore: DataStore, onAfterSave: () => void = noop) => {
      const warningMessage =
        !!defaultDataStore.id && draft.dataStoreType !== defaultDataStore.type
          ? `Saving will delete your previous configuration of the ${
              SupportedDataStoresToName[defaultDataStore.type || SupportedDataStores.JAEGER]
            } Tracing Backend.`
          : '';

      onOpen({
        title: (
          <>
            <p>Are you sure you want to save this Tracing Backend configuration?</p>
            <p>{warningMessage}</p>
          </>
        ),
        heading: 'Save Confirmation',
        okText: 'Save',
        onConfirm: async () => {
          DataStoreService.getRequest(draft, defaultDataStore).then(async dataStore => {
            await updateDataStore({dataStore}).unwrap();
            await onCompleteStep('tracing_backend');
            showSuccessNotification();
            onAfterSave();
          });
        },
      });
    },
    [onCompleteStep, onOpen, showSuccessNotification, updateDataStore]
  );

  const onDeleteConfig = useCallback(
    async (onAfterDelete: () => void = noop) => {
      onOpen({
        title: 'Disabling this option will remove the stored Tracing Backend configuration from Tracetest.',
        heading: 'Delete Confirmation',
        okText: 'Continue',
        onConfirm: async () => {
          await deleteDataStore().unwrap();
          onAfterDelete();
        },
      });
    },
    [deleteDataStore, onOpen]
  );

  const onResetTestConnection = useCallback(() => {
    resetTestConnection();
  }, [resetTestConnection]);

  const onIsFormValid = useCallback((isValid: boolean) => {
    setIsFormValid(isValid);
  }, []);

  const onTestConnection = useCallback(
    async (draft: TDraftDataStore, defaultDataStore: DataStore) => {
      DataStoreService.getRequest(draft, defaultDataStore).then(async dataStore => {
        if (DataStoreService.getIsOtlpBased(draft)) {
          return;
        }

        try {
          const result = await testConnection(dataStore.spec!).unwrap();
          showTestConnectionNotification(result, draft.dataStoreType!);
          setConnectionTries(0);
        } catch (err) {
          setConnectionTries(prev => prev + 1);
          showTestConnectionNotification(err as TConnectionResult, draft.dataStoreType!);
          if (connectionTries + 1 === 3) {
            onContactUsOpen();
          }
        }
      });
    },
    [connectionTries, onContactUsOpen, showTestConnectionNotification, testConnection]
  );

  const value = useMemo<IContext>(
    () => ({
      isLoading: isLoadingUpdate,
      isFormValid,
      isTestConnectionLoading,
      isTestConnectionSuccessful: testConnectionResponse?.allPassed || !!otlpTestConnectionResponse?.spanCount,
      onSaveConfig,
      onDeleteConfig,
      onIsFormValid,
      onTestConnection,
      testConnectionResponse,
      otlpTestConnectionResponse,
      resetTestConnection: onResetTestConnection,
      onSetOtlpTestConnectionResponse: setOtlpTestConnectionResponse,
    }),
    [
      isFormValid,
      isLoadingUpdate,
      isTestConnectionLoading,
      onIsFormValid,
      onResetTestConnection,
      onSaveConfig,
      onDeleteConfig,
      onTestConnection,
      otlpTestConnectionResponse,
      testConnectionResponse,
    ]
  );

  return <Context.Provider value={value}>{isFetching ? null : children}</Context.Provider>;
};

export default DataStoreProvider;
