import {createAsyncThunk} from '@reduxjs/toolkit';
import {PatchCollection} from '@reduxjs/toolkit/dist/query/core/buildThunks';

import TestGateway from '@core/gateways/Test.gateway';
import TestRunGateway from '@core/gateways/TestRun.gateway';
import TestSpecsSelectors from '@core/selectors/TestSpecs.selectors';
import {selectTestOutputs} from '@core/redux/testOutputs/selectors';
import TestDefinitionService from '@core/services/TestDefinition.service';
import TestService from '@core/services/Test.service';
import TestRun from '@core/models/TestRun.model';
import Test from '@core/models/Test.model';
import AssertionResults from '@core/models/AssertionResults.model';
import {TTestSpecEntry} from '@core/models/TestSpecs.model';
import {RootState} from '../store';

export type TChange = {
  selector: string;
  action: 'add' | 'remove' | 'update';
  patch: PatchCollection;
};

const TestSpecsActions = () => ({
  publish: createAsyncThunk<TestRun, {test: Test; testId: string; runId: number}>(
    'testDefinition/publish',
    async ({test, testId, runId}, {dispatch, getState}) => {
      const specs = TestSpecsSelectors.selectSpecs(getState() as RootState).filter(def => !def.isDeleted);
      const outputs = selectTestOutputs(getState() as RootState);

      return TestService.getUpdatedRawTest(test, {definition: {specs}, outputs}).then(async rawTest => {
        await dispatch(TestGateway.edit(rawTest, testId));
        return dispatch(TestRunGateway.reRun(testId, runId)).unwrap();
      });
    }
  ),
  dryRun: createAsyncThunk<AssertionResults, {definitionList: TTestSpecEntry[]; testId: string; runId: number}>(
    'testDefinition/dryRun',
    ({definitionList, testId, runId}, {dispatch}) => {
      const specs = definitionList.map(def => TestDefinitionService.toRaw(def));

      return dispatch(TestRunGateway.dryRun(testId, runId, {specs})).unwrap();
    }
  ),
});

export default TestSpecsActions();
