import * as S from './CharIcon.styled';

interface IProps {
  char: string;
  className?: string;
}

const CharIcon = ({char, className}: IProps) => (
  <S.Container className={className}>
    <S.Text>{char.toUpperCase()}</S.Text>
  </S.Container>
);

export default CharIcon;
