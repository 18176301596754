const Tracetest = () => (
  <svg height="34" viewBox="0 0 51 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2125_1585)">
      <path
        d="M34.9124 17.8076C34.9124 17.1949 34.6703 16.6179 34.2336 16.187C33.3324 15.2937 31.8714 15.2977 30.9742 16.1949L23.6495 23.523L19.6676 19.5823C19.2309 19.1515 18.6553 18.9143 18.0439 18.9143C18.0399 18.9143 18.0399 18.9143 18.0359 18.9143C17.4206 18.9143 16.841 19.1554 16.4082 19.5902C15.511 20.4874 15.515 21.9459 16.4162 22.8353L23.6614 30.0131L34.2375 19.4321C34.6742 18.9973 34.9124 18.4202 34.9124 17.8076Z"
        fill="#61175E"
      />
      <path
        d="M12.2435 11.5816C11.7314 11.0717 10.9016 11.0717 10.3935 11.5816L0 21.9295L2.77503 24.6923C3.02117 24.9374 3.35068 25.0757 3.70004 25.0757C4.0494 25.0757 4.37891 24.9413 4.62506 24.6923C5.13322 24.1864 5.13322 23.3563 4.62506 22.8504L3.70004 21.9295L3.77547 21.8504L12.2395 13.4235C12.7556 12.9176 12.7556 12.0915 12.2435 11.5816Z"
        fill="url(#paint0_linear_2125_1585)"
      />
      <path
        d="M45.262 23.1581C45.6114 23.1581 45.9409 23.0237 46.187 22.7747L50.6811 18.3004L32.2999 0L23.6215 8.64032L18.4288 3.47036L14.0657 7.81423C13.8196 8.05929 13.6806 8.38735 13.6806 8.73518C13.6806 9.083 13.8156 9.41107 14.0657 9.65613C14.5739 10.166 15.4076 10.166 15.9157 9.65613L18.4288 7.15415L23.6215 12.3241L32.2999 3.68379L32.3793 3.75889L46.981 18.2964L44.337 20.9289C43.8249 21.4348 43.8249 22.2648 44.337 22.7707C44.5871 23.0198 44.9126 23.1581 45.262 23.1581Z"
        fill="url(#paint1_linear_2125_1585)"
      />
      <path
        d="M6.68581 28.1042L23.6457 44.9895L27.8023 40.8512C28.3144 40.3413 28.3144 39.5152 27.8023 39.0093C27.5561 38.7642 27.2266 38.6259 26.8772 38.6259C26.5279 38.6259 26.1984 38.7603 25.9522 39.0093L23.6457 41.3057L23.5663 41.2306L8.53583 26.2623C8.28175 26.0093 7.9443 25.8828 7.61082 25.8828C7.27734 25.8828 6.93989 26.0093 6.68581 26.2623C6.17765 26.7682 6.17765 27.5982 6.68581 28.1042Z"
        fill="url(#paint2_linear_2125_1585)"
      />
      <path
        d="M41.471 24.1055C41.1375 24.1055 40.8001 24.232 40.546 24.4849L29.6444 35.3387C29.1322 35.8446 29.1322 36.6746 29.6444 37.1806C29.8905 37.4256 30.22 37.564 30.5694 37.564C30.9187 37.564 31.2482 37.4296 31.4944 37.1806L42.396 26.3268C42.9081 25.8209 42.9081 24.9908 42.396 24.4849C42.1419 24.232 41.8045 24.1055 41.471 24.1055Z"
        fill="url(#paint3_linear_2125_1585)"
      />
      <path
        d="M20.5921 34.578L23.5895 37.5621L32.7919 28.4001C33.3041 27.8902 33.3041 27.0641 32.7919 26.5582C32.2798 26.0483 31.4501 26.0483 30.9419 26.5582L23.5895 33.8784L22.4421 32.7361C22.188 32.4831 21.8506 32.3566 21.5171 32.3566C21.1836 32.3566 20.8462 32.4831 20.5921 32.7361C20.08 33.242 20.08 34.0681 20.5921 34.578Z"
        fill="url(#paint4_linear_2125_1585)"
      />
      <path
        d="M32.2408 7.40625L29.7318 9.90427C29.2196 10.4142 29.2196 11.2402 29.7318 11.7462C29.9779 11.9912 30.3074 12.1296 30.6568 12.1296C31.0061 12.1296 31.3356 11.9952 31.5818 11.7462L32.2408 11.09L32.3202 11.1651L39.359 18.173L34.5434 22.9715C34.0313 23.4774 34.0313 24.3074 34.5434 24.8134C34.7895 25.0584 35.119 25.1968 35.4684 25.1968C35.8178 25.1968 36.1473 25.0624 36.3934 24.8134L43.0591 18.177L32.2408 7.40625Z"
        fill="url(#paint5_linear_2125_1585)"
      />
      <path
        d="M27.7743 15.52C28.2864 15.014 28.2864 14.184 27.7743 13.6781C27.2661 13.1682 26.4324 13.1682 25.9243 13.6781L23.6296 15.9627L18.4567 10.8164L7.56299 21.6623L16.8171 30.8757C17.0632 31.1208 17.3927 31.2591 17.7421 31.2591C18.0914 31.2591 18.4209 31.1247 18.6671 30.8757C19.1792 30.3698 19.1792 29.5397 18.6671 29.0338L11.263 21.6623L11.3385 21.5832L18.4527 14.5002L23.6296 19.6504L27.7743 15.52Z"
        fill="url(#paint6_linear_2125_1585)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2125_1585"
        x1="-6.11341"
        y1="31.7008"
        x2="34.5543"
        y2="-9.14622"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDE87E" />
        <stop offset="0.0641" stopColor="#FBD178" />
        <stop offset="0.1985" stopColor="#F79668" />
        <stop offset="0.3907" stopColor="#F03950" />
        <stop offset="0.4545" stopColor="#EE1847" />
        <stop offset="0.5636" stopColor="#CB184D" />
        <stop offset="0.8043" stopColor="#72175B" />
        <stop offset="0.8492" stopColor="#61175E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2125_1585"
        x1="3.76865"
        y1="41.5389"
        x2="44.4363"
        y2="0.691802"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDE87E" />
        <stop offset="0.0641" stopColor="#FBD178" />
        <stop offset="0.1985" stopColor="#F79668" />
        <stop offset="0.3907" stopColor="#F03950" />
        <stop offset="0.4545" stopColor="#EE1847" />
        <stop offset="0.5636" stopColor="#CB184D" />
        <stop offset="0.8043" stopColor="#72175B" />
        <stop offset="0.8492" stopColor="#61175E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2125_1585"
        x1="6.50251"
        y1="44.254"
        x2="47.1702"
        y2="3.40686"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDE87E" />
        <stop offset="0.0641" stopColor="#FBD178" />
        <stop offset="0.1985" stopColor="#F79668" />
        <stop offset="0.3907" stopColor="#F03950" />
        <stop offset="0.4545" stopColor="#EE1847" />
        <stop offset="0.5636" stopColor="#CB184D" />
        <stop offset="0.8043" stopColor="#72175B" />
        <stop offset="0.8492" stopColor="#61175E" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2125_1585"
        x1="14.5205"
        y1="52.2381"
        x2="55.1882"
        y2="11.391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDE87E" />
        <stop offset="0.0641" stopColor="#FBD178" />
        <stop offset="0.1985" stopColor="#F79668" />
        <stop offset="0.3907" stopColor="#F03950" />
        <stop offset="0.4545" stopColor="#EE1847" />
        <stop offset="0.5636" stopColor="#CB184D" />
        <stop offset="0.8043" stopColor="#72175B" />
        <stop offset="0.8492" stopColor="#61175E" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2125_1585"
        x1="9.71977"
        y1="47.4651"
        x2="50.3875"
        y2="6.61802"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDE87E" />
        <stop offset="0.0641" stopColor="#FBD178" />
        <stop offset="0.1985" stopColor="#F79668" />
        <stop offset="0.3907" stopColor="#F03950" />
        <stop offset="0.4545" stopColor="#EE1847" />
        <stop offset="0.5636" stopColor="#CB184D" />
        <stop offset="0.8043" stopColor="#72175B" />
        <stop offset="0.8492" stopColor="#61175E" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2125_1585"
        x1="6.27221"
        y1="44.0342"
        x2="46.9399"
        y2="3.18709"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDE87E" />
        <stop offset="0.0641" stopColor="#FBD178" />
        <stop offset="0.1985" stopColor="#F79668" />
        <stop offset="0.3907" stopColor="#F03950" />
        <stop offset="0.4545" stopColor="#EE1847" />
        <stop offset="0.5636" stopColor="#CB184D" />
        <stop offset="0.8043" stopColor="#72175B" />
        <stop offset="0.8492" stopColor="#61175E" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2125_1585"
        x1="0.76928"
        y1="38.5587"
        x2="41.437"
        y2="-2.28838"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDE87E" />
        <stop offset="0.0641" stopColor="#FBD178" />
        <stop offset="0.1985" stopColor="#F79668" />
        <stop offset="0.3907" stopColor="#F03950" />
        <stop offset="0.4545" stopColor="#EE1847" />
        <stop offset="0.5636" stopColor="#CB184D" />
        <stop offset="0.8043" stopColor="#72175B" />
        <stop offset="0.8492" stopColor="#61175E" />
      </linearGradient>
      <clipPath id="clip0_2125_1585">
        <rect width="51" height="45" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const TracetestLogo = () => (
  <svg className="logo" height="34" viewBox="0 0 139 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2125_1586)" transform="translate(-59)">
      <path
        d="M69.3724 30.6352H68.3521C67.8082 30.6352 67.3635 30.4099 66.9864 29.9475C66.5973 29.4732 66.3988 28.8684 66.3988 28.153V21.1056H68.622C69.1223 21.1056 69.5391 20.9554 69.8607 20.6629C70.1902 20.3625 70.353 19.9791 70.353 19.5285C70.353 19.0384 70.1862 18.6392 69.8607 18.3348C69.5391 18.0423 69.1223 17.8921 68.622 17.8921H66.3988V13.9158C66.3988 13.3901 66.2241 12.9554 65.8827 12.6115C65.5413 12.2716 65.1006 12.0977 64.5726 12.0977C64.0446 12.0977 63.6039 12.2716 63.2625 12.6115C62.9211 12.9514 62.7464 13.3901 62.7464 13.9158V17.8921H61.6904C61.1902 17.8921 60.7694 18.0423 60.4518 18.3348C60.1222 18.6352 59.9595 19.0384 59.9595 19.5285C59.9595 19.9791 60.1262 20.3585 60.4518 20.6629C60.7733 20.9593 61.1902 21.1056 61.6904 21.1056H62.7464V28.153C62.7464 29.2953 62.9926 30.3388 63.4729 31.2558C63.9573 32.1767 64.6282 32.9158 65.4738 33.4534C66.3234 33.9909 67.2881 34.2637 68.3481 34.2637H68.888C69.4835 34.2637 69.9837 34.0977 70.3728 33.7696C70.7777 33.4297 70.9802 32.983 70.9802 32.4494C70.9802 31.9317 70.8254 31.5008 70.5237 31.1609C70.218 30.8131 69.8289 30.6352 69.3724 30.6352Z"
        fill="white"
      />
      <path
        d="M85.5782 17.7457C84.9391 17.3228 83.9744 17.1094 82.7159 17.1094C81.4653 17.1094 80.3021 17.4058 79.262 17.9868C78.8014 18.2438 78.3767 18.5442 77.9995 18.8841C77.9638 18.4058 77.8089 18.0303 77.539 17.7576C77.2253 17.4493 76.7728 17.2912 76.1852 17.2912C75.6175 17.2912 75.1689 17.4532 74.8473 17.7734C74.5257 18.0936 74.363 18.5402 74.363 19.1054V32.4453C74.363 33.0303 74.5257 33.4888 74.8513 33.7971C75.1729 34.1054 75.6215 34.2596 76.1852 34.2596C76.7728 34.2596 77.2293 34.0975 77.543 33.7734C77.8526 33.4532 78.0074 33.0066 78.0074 32.4453V23.7299C78.0074 22.9078 78.2734 22.22 78.7975 21.6864C79.3374 21.137 80.0957 20.7576 81.0485 20.5521C82.0171 20.3426 83.1327 20.3623 84.3555 20.6114C84.6572 20.6825 84.943 20.6904 85.2051 20.643C85.483 20.5916 85.7331 20.473 85.9474 20.2833C86.1698 20.0896 86.3206 19.8169 86.3921 19.477C86.5628 18.8011 86.2889 18.2161 85.5782 17.7457Z"
        fill="white"
      />
      <path
        d="M103.304 25.5672C103.272 24.0336 102.887 22.6265 102.169 21.3893C101.414 20.0929 100.382 19.0533 99.0998 18.3024C97.8174 17.5514 96.3486 17.168 94.7367 17.168C93.1289 17.168 91.656 17.5474 90.3618 18.3024C89.0676 19.0533 88.0314 20.0929 87.2771 21.3893C86.5228 22.6858 86.1377 24.168 86.1377 25.7885C86.1377 27.4091 86.4871 28.8794 87.1818 30.164C87.8766 31.4526 88.8373 32.4881 90.0362 33.2391C91.2431 33.9941 92.6207 34.3775 94.1373 34.3775C95.6538 34.3775 97.0314 33.9941 98.2383 33.2391C98.7306 32.9308 99.1871 32.5711 99.6 32.168V32.4447C99.6 32.9703 99.7786 33.413 100.128 33.7609C100.477 34.1126 100.926 34.2866 101.45 34.2866C101.978 34.2866 102.423 34.1087 102.772 33.7609C103.125 33.4091 103.3 32.9664 103.3 32.4447V25.666L103.304 25.5672ZM99.7231 25.7885C99.7231 26.7846 99.5047 27.6897 99.072 28.4842C98.6432 29.2747 98.0437 29.9071 97.2974 30.3616C96.555 30.8162 95.6935 31.0454 94.7407 31.0454C93.8038 31.0454 92.9502 30.8162 92.1959 30.3616C91.4377 29.9071 90.8342 29.2747 90.3935 28.4802C89.9529 27.6858 89.7306 26.7806 89.7306 25.7846C89.7306 24.7688 89.9529 23.8557 90.3935 23.0613C90.8342 22.2707 91.4377 21.6383 92.1959 21.1838C92.9502 20.7292 93.8077 20.5 94.7407 20.5C95.6935 20.5 96.555 20.7292 97.2974 21.1838C98.0437 21.6383 98.6392 22.2707 99.072 23.0613C99.5047 23.8597 99.7231 24.7767 99.7231 25.7885Z"
        fill="white"
      />
      <path
        d="M117.517 17.8832C116.501 17.4168 115.365 17.1836 114.143 17.1836C112.515 17.1836 111.046 17.5551 109.783 18.2864C108.517 19.0176 107.509 20.0492 106.786 21.3496C106.064 22.646 105.694 24.1441 105.694 25.8041C105.694 27.4247 106.068 28.899 106.802 30.1875C107.536 31.4761 108.565 32.5077 109.859 33.2626C111.153 34.0136 112.642 34.397 114.293 34.397C116.525 34.397 118.414 33.6935 119.919 32.3022C120.272 31.9504 120.423 31.5472 120.371 31.0966C120.32 30.6658 120.09 30.2785 119.689 29.9464C119.363 29.6698 118.978 29.5512 118.553 29.5868C118.148 29.6223 117.759 29.7923 117.386 30.0927C116.981 30.4247 116.525 30.6698 116.04 30.8279C115.544 30.986 114.956 31.065 114.297 31.065C113.305 31.065 112.423 30.8358 111.677 30.3812C110.931 29.9267 110.339 29.3022 109.918 28.5196C109.498 27.737 109.283 26.8239 109.283 25.8081C109.283 24.733 109.494 23.7883 109.907 23.0018C110.315 22.2231 110.891 21.6105 111.614 21.1757C112.34 20.7409 113.19 20.5196 114.143 20.5196C115.5 20.5196 116.656 21.0097 117.569 21.9781C117.894 22.3022 118.259 22.5077 118.66 22.5868C119.089 22.6737 119.49 22.5907 119.843 22.3377C120.268 22.0334 120.53 21.6698 120.63 21.2547C120.733 20.8121 120.61 20.3852 120.26 19.986C119.458 19.0532 118.533 18.3496 117.517 17.8832Z"
        fill="white"
      />
      <path
        d="M135.445 18.247C134.278 17.5316 132.869 17.168 131.257 17.168C129.629 17.168 128.16 17.5395 126.898 18.2707C125.631 19.002 124.631 20.0296 123.916 21.3182C123.202 22.6028 122.84 24.1087 122.84 25.7885C122.84 27.4526 123.221 28.9466 123.98 30.2352C124.734 31.5237 125.794 32.5474 127.132 33.2786C128.462 34.0099 130.002 34.3775 131.709 34.3775C132.662 34.3775 133.67 34.1996 134.707 33.8518C135.747 33.5039 136.632 33.0454 137.335 32.4881C137.764 32.164 137.966 31.7569 137.942 31.2865C137.918 30.8399 137.696 30.4328 137.287 30.0731C136.966 29.8043 136.584 29.6699 136.156 29.6818C135.739 29.6937 135.35 29.832 134.996 30.0889C134.623 30.3656 134.123 30.6186 133.508 30.832C132.896 31.0454 132.293 31.1561 131.713 31.1561C130.637 31.1561 129.673 30.919 128.847 30.4526C128.021 29.9862 127.366 29.3419 126.898 28.5395C126.648 28.1126 126.461 27.6423 126.346 27.1443H137.351C137.835 27.1443 138.244 26.9901 138.566 26.6818C138.891 26.3695 139.054 25.9782 139.054 25.5079C139.054 23.8952 138.744 22.4486 138.137 21.2035C137.513 19.9624 136.612 18.9624 135.445 18.247ZM135.58 24.1245H126.378C126.477 23.7253 126.612 23.3458 126.783 22.9901C127.172 22.1838 127.751 21.5435 128.506 21.081C129.26 20.6186 130.185 20.3814 131.257 20.3814C132.186 20.3814 132.984 20.5948 133.627 21.0138C134.274 21.4367 134.778 22.0217 135.131 22.749C135.334 23.1719 135.485 23.6344 135.58 24.1245Z"
        fill="white"
      />
      <path
        d="M149.304 30.6352H148.284C147.74 30.6352 147.296 30.4099 146.918 29.9475C146.529 29.4732 146.331 28.8684 146.331 28.153V21.1056H148.554C149.054 21.1056 149.471 20.9554 149.793 20.6629C150.122 20.3625 150.285 19.9791 150.285 19.5285C150.285 19.0384 150.118 18.6392 149.793 18.3348C149.471 18.0423 149.054 17.8921 148.554 17.8921H146.331V13.9158C146.331 13.3901 146.156 12.9554 145.815 12.6115C145.473 12.2716 145.033 12.0977 144.505 12.0977C143.977 12.0977 143.536 12.2716 143.195 12.6115C142.853 12.9514 142.678 13.3901 142.678 13.9158V17.8921H141.622C141.122 17.8921 140.701 18.0423 140.384 18.3348C140.054 18.6352 139.892 19.0384 139.892 19.5285C139.892 19.9791 140.058 20.3585 140.384 20.6629C140.705 20.9593 141.122 21.1056 141.622 21.1056H142.678V28.153C142.678 29.2953 142.925 30.3388 143.405 31.2558C143.889 32.1767 144.56 32.9158 145.406 33.4534C146.255 33.9909 147.22 34.2637 148.28 34.2637H148.82C149.416 34.2637 149.916 34.0977 150.305 33.7696C150.71 33.4297 150.912 32.983 150.912 32.4494C150.912 31.9317 150.757 31.4969 150.456 31.1609C150.146 30.8131 149.757 30.6352 149.304 30.6352Z"
        fill="white"
      />
      <path
        d="M165.478 18.247C164.311 17.5316 162.902 17.168 161.29 17.168C159.662 17.168 158.193 17.5395 156.931 18.2707C155.665 19.002 154.664 20.0296 153.945 21.3182C153.231 22.6028 152.87 24.1087 152.87 25.7885C152.87 27.4526 153.251 28.9466 154.009 30.2352C154.763 31.5237 155.823 32.5474 157.161 33.2786C158.491 34.0099 160.032 34.3775 161.739 34.3775C162.691 34.3775 163.7 34.1996 164.736 33.8518C165.776 33.5039 166.661 33.0454 167.364 32.4881C167.793 32.164 167.995 31.7569 167.972 31.2865C167.948 30.8399 167.725 30.4328 167.316 30.0731C166.995 29.8043 166.618 29.6699 166.185 29.6818C165.768 29.6937 165.379 29.832 165.026 30.0889C164.653 30.3656 164.152 30.6186 163.537 30.832C162.926 31.0454 162.322 31.1561 161.743 31.1561C160.667 31.1561 159.702 30.919 158.876 30.4526C158.05 29.9862 157.395 29.3419 156.927 28.5435C156.677 28.1166 156.49 27.6462 156.375 27.1482H167.38C167.864 27.1482 168.273 26.9941 168.595 26.6858C168.92 26.3735 169.083 25.9822 169.083 25.5118C169.083 23.8992 168.773 22.4526 168.166 21.2075C167.551 19.9624 166.646 18.9624 165.478 18.247ZM156.415 24.1245C156.514 23.7253 156.649 23.3458 156.82 22.9901C157.209 22.1838 157.788 21.5435 158.543 21.081C159.297 20.6186 160.222 20.3814 161.294 20.3814C162.223 20.3814 163.021 20.5948 163.664 21.0138C164.311 21.4367 164.815 22.0217 165.169 22.749C165.371 23.1719 165.522 23.6304 165.617 24.1205H156.415V24.1245Z"
        fill="white"
      />
      <path
        d="M182.473 25.1759C181.58 24.7055 180.353 24.3142 178.833 24.0099C177.777 23.7964 176.971 23.5751 176.435 23.3577C175.939 23.1561 175.613 22.9348 175.458 22.7016C175.303 22.4684 175.228 22.2035 175.228 21.9071C175.228 21.4526 175.462 21.1284 175.962 20.8834C176.522 20.6067 177.229 20.4684 178.051 20.4684C178.773 20.4684 179.416 20.5553 179.964 20.7213C180.492 20.8834 180.992 21.1917 181.457 21.6344C181.802 21.9545 182.231 22.164 182.727 22.2549C183.251 22.3498 183.716 22.2628 184.121 21.9861C184.518 21.6897 184.732 21.3103 184.76 20.8676C184.784 20.4328 184.629 20.0217 184.296 19.6423C183.664 18.8676 182.803 18.2549 181.743 17.8201C180.691 17.3893 179.468 17.168 178.11 17.168C177.058 17.168 176.034 17.3379 175.065 17.6779C174.077 18.0217 173.255 18.5553 172.624 19.2589C171.973 19.9822 171.643 20.9031 171.643 21.998C171.643 23.2431 172.139 24.3103 173.116 25.164C174.065 25.9941 175.593 26.6225 177.666 27.0296C179.166 27.2984 180.206 27.6383 180.75 28.0454C181.254 28.4209 181.493 28.8399 181.473 29.3261C181.457 29.8122 181.199 30.2035 180.683 30.5237C180.127 30.8676 179.293 31.0415 178.202 31.0415C177.332 31.0415 176.534 30.8834 175.828 30.5751C175.125 30.2668 174.613 29.9348 174.299 29.5909C173.966 29.2391 173.565 29.0296 173.1 28.9703C172.624 28.911 172.143 29.0691 171.679 29.4367C171.27 29.749 171.028 30.1601 170.968 30.6541C170.905 31.1601 171.064 31.6146 171.437 32.0099C172.127 32.6976 173.1 33.2707 174.327 33.7134C175.546 34.1522 176.888 34.3735 178.321 34.3735C179.782 34.3735 181.02 34.1442 182.009 33.6857C183.009 33.2233 183.779 32.5948 184.299 31.8083C184.824 31.0217 185.09 30.1284 185.09 29.1522C185.09 28.2984 184.883 27.5356 184.47 26.8755C184.061 26.2352 183.39 25.6581 182.473 25.1759Z"
        fill="white"
      />
      <path
        d="M197.54 31.1609C197.226 30.8131 196.837 30.6352 196.385 30.6352H195.364C194.821 30.6352 194.376 30.4099 193.999 29.9475C193.61 29.4732 193.411 28.8684 193.411 28.153V21.1056H195.634C196.135 21.1056 196.551 20.9554 196.873 20.6629C197.203 20.3625 197.365 19.9791 197.365 19.5285C197.365 19.0384 197.199 18.6392 196.873 18.3348C196.551 18.0423 196.135 17.8921 195.634 17.8921H193.411V13.9158C193.411 13.3901 193.236 12.9554 192.895 12.6115C192.554 12.2716 192.113 12.0977 191.585 12.0977C191.057 12.0977 190.616 12.2716 190.275 12.6115C189.933 12.9514 189.759 13.3901 189.759 13.9158V17.8921H188.703C188.203 17.8921 187.782 18.0423 187.464 18.3348C187.135 18.6352 186.972 19.0384 186.972 19.5285C186.972 19.9791 187.139 20.3585 187.464 20.6629C187.786 20.9593 188.203 21.1056 188.703 21.1056H189.767V28.153C189.767 29.2953 190.013 30.3388 190.493 31.2558C190.978 32.1767 191.648 32.9158 192.494 33.4534C193.344 33.9909 194.308 34.2637 195.368 34.2637H195.908C196.504 34.2637 197.004 34.0977 197.393 33.7696C197.798 33.4297 198 32.983 198 32.4494C198 31.9317 197.846 31.5008 197.54 31.1609Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2125_1586">
        <rect width="198" height="45" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Tracetest;
