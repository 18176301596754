export enum ResourceType {
  Test = 'tests',
  TestSuite = 'testsuites',
  Monitor = 'monitors',
  VariableSet = 'variablesets',
  DataStore = 'datastores',
  Demo = 'demos',
  Analyzer = 'analyzers',
  TestRunner = 'testrunners',
  PollingProfile = 'pollingprofiles',
}

export const ResourceName = {
  [ResourceType.Test]: 'Test',
  [ResourceType.TestSuite]: 'Test Suite',
  [ResourceType.Monitor]: 'Monitor',
  [ResourceType.VariableSet]: 'Variable Set',
  [ResourceType.DataStore]: 'Tracing Backend',
  [ResourceType.Demo]: 'Demo',
  [ResourceType.Analyzer]: 'Analyzer',
  [ResourceType.TestRunner]: 'Test Runner',
  [ResourceType.PollingProfile]: 'Polling Profile',
} as const;
