import Loading from '@components/organisms/Loading';
import {useCheckSession} from '@hooks/authn';
import {UserProvider} from '@providers/User.provider';
import {Suspense} from 'react';
import {Outlet} from 'react-router-dom';

const ProtectedLayout = () => {
  const {isLoading, session} = useCheckSession('/');

  if (isLoading) {
    return <Loading />;
  }

  return (
    <UserProvider session={session!}>
      <Suspense fallback={<div />}>
        <Outlet />
      </Suspense>
    </UserProvider>
  );
};

export default ProtectedLayout;
