import {Role} from '@core/types/Common.types';
import {EnvironmentGroup, OrganizationGroup} from '@services/Authz';
import {Form, FormInstance, Input, Select} from 'antd';
import capitalize from 'lodash/capitalize';

export interface IRoleForm {
  id: string;
  role: Role;
}

interface IProps {
  form: FormInstance<IRoleForm>;
  id: string;
  onSubmit(values: IRoleForm): void;
  roles: OrganizationGroup[] | EnvironmentGroup[];
}

const FormBody = ({form, id, onSubmit, roles}: IProps) => (
  <Form<IRoleForm> form={form} initialValues={{id}} layout="vertical" name="role" onFinish={onSubmit}>
    <Form.Item name="id" hidden>
      <Input type="hidden" />
    </Form.Item>

    <Form.Item label="Role" name="role" rules={[{required: true, message: 'Please select a role'}]}>
      <Select placeholder="Select a role">
        {roles.map(item => (
          <Select.Option key={item} value={item}>
            {capitalize(item)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  </Form>
);

export default FormBody;
