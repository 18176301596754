import {Typography} from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: ${({theme}) => theme.color.backgroundDark};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  height: 27px;
  justify-content: center;
  width: 27px;
`;

export const Text = styled(Typography.Text)`
  font-weight: 600;
  font-size: ${({theme}) => theme.size.lg};
`;
