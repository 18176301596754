import Action from '@components/atoms/Action';
import useCopy from '@core/hooks/useCopy';
import {RoleToName} from '@core/types/Common.types';
import Date from '@core/utils/Date';
import Token from '@models/Token.model';
import {List, Space, Tag} from 'antd';
import {noop} from 'lodash';
import Filters from './Filters';

interface IProps {
  tokens: Token[];
  total: number;
  isLoading: boolean;
  canEdit: boolean;
  enableEdit?: boolean;
  onDelete?(token: Token): void;
  onEdit?(token: Token): void;
  onSearch(query: string): void;
  onPageChange(page: number, pageSize: number): void;
  pageSize: number;
  withActions?: boolean;
  onClick?(token: Token): void;
}

const EnvironmentTokens = ({
  canEdit,
  isLoading,
  onDelete,
  onEdit,
  onClick,
  onPageChange,
  onSearch = noop,
  pageSize,
  tokens,
  total,
  enableEdit = true,
  withActions = true,
}: IProps) => {
  const copy = useCopy();

  const handleOnCopy = (value: Token) => {
    copy(value.id);
  };

  return (
    <>
      <Filters onNameFilter={onSearch} />
      <List<Token>
        bordered
        pagination={{
          hideOnSinglePage: true,
          onChange: onPageChange,
          pageSize,
          position: 'bottom',
          showSizeChanger: false,
          total,
        }}
        loading={isLoading}
        dataSource={tokens}
        renderItem={item => {
          const isExpired = Token.getIsExpired(item);

          return (
            <List.Item
              onClick={() => onClick?.(item)}
              style={{cursor: onClick ? 'pointer' : 'default'}}
              actions={
                withActions
                  ? (enableEdit
                      ? [
                          <Action
                            item={item}
                            disabled={!!item.isMonitorToken}
                            key="token-edit"
                            onClick={onEdit}
                            title="edit"
                          />,
                        ]
                      : []
                    ).concat([
                      <Action item={item} key="token-copy" onClick={handleOnCopy} title="copy" />,
                      <Action
                        disabled={!canEdit || !!item.isRevoked || !!item.isMonitorToken}
                        item={item}
                        key="token-revoke"
                        onClick={onDelete}
                        title="revoke"
                      />,
                    ])
                  : []
              }
            >
              <List.Item.Meta
                title={item.name}
                description={
                  (item.isMonitorToken && <div />) ||
                  (item.isRevoked && <Tag color="error">Revoked {Date.getTimeAgo(item.expiresAt ?? '')}</Tag>) ||
                  (isExpired && <Tag color="error">Expired {Date.getTimeAgo(item.expiresAt ?? '')}</Tag>) || (
                    <Tag color="success">Expires {Date.getTimeAgo(item.expiresAt ?? '')}</Tag>
                  )
                }
              />
              <Space>
                <div>{!!item.isAgentToken && <Tag color="success">default</Tag>}</div>
                <div>{RoleToName[item.role]}</div>
              </Space>
            </List.Item>
          );
        }}
      />
    </>
  );
};

export default EnvironmentTokens;
