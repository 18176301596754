import Organization from '@models/Organization.model';
import UserPreferencesService from './UserPreferences.service';

const {REACT_APP_DEMO_ORG_ID = ''} = process.env;

const OrganizationService = {
  /**
   * @description Get the target organization based on the orgId in the URL.
   * If the orgId is not found in the list of organizations, we check if there
   * is a stored orgId in localStorage, otherwise we return the first organization
   * in the list.
   * @param organizations - List of organizations
   * @param orgId - Organization ID from URL
   */
  getTargetOrganization(organizations?: Organization[], orgId?: string): string | undefined {
    if (orgId !== 'default') return orgId;
    if (!organizations?.length) return;

    const userPrefOrgId = UserPreferencesService.get('orgId');
    const target =
      (orgId && organizations?.find(org => org.id === orgId)) ||
      (userPrefOrgId && organizations?.find(org => org.id === userPrefOrgId)) ||
      (organizations?.length
        ? (organizations.length > 1 && organizations?.find(org => org.id !== REACT_APP_DEMO_ORG_ID)) || organizations[0]
        : undefined);

    return target?.id;
  },

  /**
   * @description Set the organization as preferred in localStorage
   * @param orgId - Organization ID
   */
  setOrganizationAsPreferred(organizationId?: string): string | undefined {
    if (!organizationId) return;
    UserPreferencesService.set('orgId', organizationId);
    return organizationId;
  },
};

export default OrganizationService;
