import {SearchOutlined} from '@ant-design/icons';
import {debounce} from 'lodash';
import {useMemo} from 'react';
import * as S from './EnvironmentSelector.styled';

interface IProps {
  onSearch(name: string): void;
}

const Search = ({onSearch}: IProps) => {
  const handleName = useMemo(
    () =>
      debounce(event => {
        onSearch(event.target.value);
      }, 500),
    [onSearch]
  );

  return (
    <S.SearchContainer>
      <S.Input
        onChange={handleName}
        onClick={e => e.stopPropagation()}
        placeholder="Find Environment"
        prefix={<SearchOutlined />}
      />
    </S.SearchContainer>
  );
};

export default Search;
