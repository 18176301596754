import {Model, TMonitorSchemas} from '@core/types/Common.types';
import {THeader} from '@core/types/Test.types';
import Date from '@core/utils/Date';
import Test from './Test.model';
import TestSuite from './TestSuite.model';

// Alert

export type TRawAlert = TMonitorSchemas['Alert'];
export type Alert = Model<TRawAlert, {webhook: Webhook}>;

const defaultAlert: TRawAlert = {
  id: '',
  type: 'webhook',
  webhook: {},
  events: ['FAILED'],
};

export const Alert = ({id = '', type = 'webhook', webhook = {}, events = []} = defaultAlert): Alert => ({
  id,
  type,
  webhook: Webhook(webhook),
  events,
});

// Webhook

export type TRawWebhook = TMonitorSchemas['Webhook'];
type Webhook = Model<TRawWebhook, {headers: THeader[]}>;

const defaultWebhook: TRawWebhook = {
  url: '',
  body: '',
  method: 'GET',
  headers: [],
  sslVerification: false,
};

export const Webhook = ({
  url = '',
  body = '',
  method = 'GET',
  headers = [],
  sslVerification = false,
} = defaultWebhook): Webhook => ({
  url,
  body,
  method,
  headers: headers.map(({key = '', value = ''}) => ({
    key,
    value,
  })),
  sslVerification,
});

// Monitor

export type TRawMonitorResource = TMonitorSchemas['MonitorResource'];
export type TRawMonitor = TMonitorSchemas['Monitor'];
type Monitor = Model<
  TRawMonitor,
  {
    deletedAt?: string;
    fullTests: Test[];
    fullTestSuites: TestSuite[];
    variableSetId?: string;
  }
>;

const defaultMonitor: TRawMonitor = {
  id: '',
  name: '',
  createdAt: '',
  updatedAt: '',
  enabled: false,
  tests: [],
  testSuites: [],
  fullTests: [],
  fullTestSuites: [],
  schedule: {cron: '', timeZone: ''},
  tokenId: '',
  alerts: [],
};

const Monitor = ({
  id = '',
  version = 1,
  name = '',
  createdAt = '',
  updatedAt = '',
  enabled = false,
  tests = [],
  testSuites = [],
  fullTests = [],
  fullTestSuites = [],
  variableSetId,
  schedule = {},
  alerts = [],
  tokenId = '',
  summary: {runs = 0, lastState = '', lastRunTime = ''} = {},
} = defaultMonitor): Monitor => ({
  id,
  version,
  name,
  createdAt,
  updatedAt,
  enabled,
  tests,
  testSuites,
  fullTests: fullTests?.map(test => Test.FromRawTest(test)) ?? [],
  fullTestSuites: fullTestSuites?.map(testSuite => TestSuite.FromRawTestSuite(testSuite)) ?? [],
  variableSetId,
  tokenId,
  schedule: {cron: '', timeZone: '', ...schedule},
  alerts: alerts.map(alert => Alert(alert)),
  summary: {runs, lastState, lastRunTime: Date.isDefaultDate(lastRunTime) ? '' : lastRunTime},
});

Monitor.fromResource = ({spec: rawMonitor = {}}: TRawMonitorResource): Monitor => Monitor(rawMonitor);

Monitor.toResource = (rawMonitor: TRawMonitor): TRawMonitorResource => ({
  type: 'Monitor',
  spec: {...rawMonitor},
});

Monitor.toRaw = (monitor: Monitor): TRawMonitor => ({
  ...monitor,
  fullTests: [],
  fullTestSuites: [],
});

export default Monitor;
