import Agent from '@models/Agent.model';
import {useEnvironment} from '@providers/Environment.provider';
import {useOrganizations} from '@providers/Organizations.provider';
import {Button, ButtonProps, Popover} from 'antd';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import AgentAlert from './AgentAlert';
import DemoAlert from './DemoAlert';

const {REACT_APP_DEMO_ORG_ID = ''} = process.env;

interface IProps extends ButtonProps {
  withDemoCheck?: boolean;
}

export function getIsAgentOffline(agent?: Agent) {
  return agent?.status === 'Red';
}

const CreateButton = ({withDemoCheck, ...props}: IProps) => {
  const {openOrganizationModal} = useOrganizations();
  const {environment} = useEnvironment();
  const navigate = useNavigate();
  const isAgentOffline = getIsAgentOffline(environment?.agent);
  const [isOpenDemoPopover, setIsOpenDemoPopover] = useState(false);

  const handleAlertOnClick = () => {
    navigate(`/organizations/${environment?.organizationID}/environments/${environment?.id}/settings?tab=agent`);
  };

  if (isAgentOffline) {
    return (
      <Popover content={<AgentAlert onClick={handleAlertOnClick} />} placement="bottomRight" trigger="click">
        <Button {...props} onClick={event => event.stopPropagation()} />
      </Popover>
    );
  }

  if (withDemoCheck && REACT_APP_DEMO_ORG_ID === environment?.organizationID) {
    return (
      <Popover
        content={
          <DemoAlert
            onClick={e => {
              props?.onClick?.(e);
              setIsOpenDemoPopover(false);
            }}
            onCreateOrganization={() => {
              openOrganizationModal();
              setIsOpenDemoPopover(false);
            }}
          />
        }
        placement="bottomRight"
        trigger="click"
        visible={isOpenDemoPopover}
        onVisibleChange={setIsOpenDemoPopover}
      >
        <Button {...props} onClick={event => event.stopPropagation()} />
      </Popover>
    );
  }

  return <Button {...props} />;
};

export default CreateButton;
