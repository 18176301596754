import CaptureWrapper from '@components/organisms/CaptureWrapper';
import CustomizationWrapper from '@components/organisms/CustomizationWrapper';
import Loading from '@components/organisms/Loading';
import LoadingLayout from '@components/organisms/LoadingLayout';
import AgentListener from '@components/organisms/Wizard/AgentListener';
import Wrapper from '@components/organisms/Wizard/Wrapper';
import {useCheckSession} from '@hooks/authn';
import {EnvironmentProvider} from '@providers/Environment.provider';
import {OrganizationProvider} from '@providers/Organization.provider';
import {UserProvider} from '@providers/User.provider';
import {EnvironmentsProvider} from '@providers/Environments.provider';
import {OrganizationsProvider} from '@providers/Organizations.provider';
import HubSpotService from '@services/HubSpot.service';
import {Suspense, useEffect} from 'react';
import {Outlet} from 'react-router-dom';

const EnvironmentProtectedLayout = () => {
  const {isLoading, session} = useCheckSession('/');

  useEffect(() => {
    if (session) {
      HubSpotService.identify(session?.identity?.traits?.email);
    }
  }, [session]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <UserProvider session={session!}>
      <OrganizationsProvider>
        <OrganizationProvider>
          <EnvironmentsProvider>
            <EnvironmentProvider>
              <Wrapper>
                <AgentListener />
                <CaptureWrapper>
                  <CustomizationWrapper>
                    <Suspense fallback={<LoadingLayout />}>
                      <Outlet />
                    </Suspense>
                  </CustomizationWrapper>
                </CaptureWrapper>
              </Wrapper>
            </EnvironmentProvider>
          </EnvironmentsProvider>
        </OrganizationProvider>
      </OrganizationsProvider>
    </UserProvider>
  );
};

export default EnvironmentProtectedLayout;
