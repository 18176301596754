import styled, {keyframes} from 'styled-components';

const skeletonLoading = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const Container = styled.span<{$height?: number; $width?: number}>`
  animation: ${skeletonLoading} 8s ease-in-out infinite;
  background-image: linear-gradient(270deg, #fafafa, #eaeaea, #eaeaea, #fafafa);
  background-size: 400% 100%;
  min-height: ${({$height}) => ($height ? `${$height}px` : '100%')};
  width: ${({$width}) => ($width ? `${$width}px` : '100%')};
`;
