import {TPlanSchemas} from '@core/types/Common.types';

export type TRawPlan = TPlanSchemas['Plan'];
type Plan = TRawPlan & {};

export const PlanNames = {
  plan_free: 'Free Plan',
  plan_pro: 'Premium Plan',
  plan_enterprise: 'Enterprise Plan',
} as const;

const defaultPlan: TRawPlan = {id: '', name: 'plan_free'};

function Plan({
  id = '',
  name = 'plan_free',
  description = '',
  customerId = '',
  checkoutSessionId,
  subscriptionId,
  currentPeriodStart,
  currentPeriodEnd,
} = defaultPlan): Plan {
  return {
    id,
    name,
    customerId,
    description,
    checkoutSessionId,
    subscriptionId,
    currentPeriodStart,
    currentPeriodEnd,
  };
}

export default Plan;
