import {Modal as AntdModal, Typography} from 'antd';
import styled from 'styled-components';

export const Modal = styled(AntdModal)`
  & .ant-modal-body {
    background: ${({theme}) => theme.color.white};
    max-height: calc(100vh - 250px);
    overflow-y: scroll;
  }
`;

export const Title = styled(Typography.Title)<{$noMargin?: boolean}>`
  && {
    font-size: ${({theme}) => theme.size.lg};
    margin-bottom: ${({$noMargin}) => ($noMargin ? 0 : '16px')};
  }
`;

export const Subtitle = styled(Typography.Paragraph)`
  && {
    font-size: ${({theme}) => theme.size.md};
    margin-bottom: 16px;
  }
`;
export const FileNameContainer = styled.div`
  margin-bottom: 14px;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const LeftPanel = styled.div`
  background-color: ${({theme}) => theme.color.white};
  flex: 1;
  height: 100%;
  padding: 0 24px 0 0;
`;

export const RightPanel = styled.div`
  background-color: ${({theme}) => theme.color.white};
  flex: 1;
  height: 100%;
  padding: 0 0 0 24px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;
