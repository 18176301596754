import {Typography} from 'antd';
import styled from 'styled-components';

export const DocsBannerContainer = styled.div<{$isLight?: boolean}>`
  align-items: center;
  background: ${({theme, $isLight}) => ($isLight ? 'transparent' : theme.color.backgroundInteractive)};
  border-radius: 2px;
  color: ${({theme, $isLight}) => ($isLight ? theme.color.textSecondary : theme.color.text)};
  display: flex;
  gap: 8px;
  padding: ${({$isLight}) => ($isLight ? '12px 0' : '12px 18px')};
  width: max-content;
`;

export const Text = styled(Typography.Text)<{$isLight?: boolean}>`
  color: ${({theme, $isLight}) => ($isLight ? theme.color.textSecondary : theme.color.text)};

  a {
    font-weight: 600;
  }
`;
