import AssignMember from '@models/AssignMember.type';
import Member from '@models/Member.model';
import User from '@models/User.model';
import {EnvironmentOperation, useAuthz} from '@hooks/authz';
import {EnvironmentGroup} from '@services/Authz';
import {Button, Form, Select} from 'antd';
import capitalize from 'lodash/capitalize';
import * as S from './EnvironmentMember.styled';

const roles = Object.values(EnvironmentGroup);

interface IProps {
  assignableMembers: Member[];
  isLoading: boolean;
  onSubmit(values: AssignMember): void;
}

const filterOption = (input: string, option: {children: string; label: string} | undefined) =>
  (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

const FormBody = ({assignableMembers, isLoading, onSubmit}: IProps) => {
  const {checkEnvironment} = useAuthz();
  const [form] = Form.useForm<AssignMember>();

  const handleOnSubmit = (values: AssignMember) => {
    onSubmit(values);
    form.resetFields();
  };

  return (
    <Form<AssignMember> form={form} layout="vertical" name="member" onFinish={handleOnSubmit}>
      <S.FormListContainer>
        <S.Row>
          <Form.Item
            label="Member"
            name="id"
            rules={[{required: true, message: 'Please select a valid member'}]}
            style={{width: '70%', marginBottom: 0}}
          >
            <Select filterOption={filterOption} placeholder="Select one of your organization members" showSearch>
              {assignableMembers.map(item => (
                <Select.Option key={item.id} value={item.user.id}>
                  {User.getEmail(item.user)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Role"
            name="role"
            rules={[{required: true, message: 'Please select a role'}]}
            style={{width: '30%', marginBottom: 0}}
          >
            <Select placeholder="Select a role">
              {roles.map(item => (
                <Select.Option key={item} value={item}>
                  {capitalize(item)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </S.Row>
      </S.FormListContainer>

      <S.ButtonContainer>
        <Button
          disabled={!checkEnvironment(EnvironmentOperation.Edit)}
          htmlType="submit"
          loading={isLoading}
          type="primary"
          data-testid="environment-members-button-add"
        >
          Add member
        </Button>
      </S.ButtonContainer>
    </Form>
  );
};

export default FormBody;
