import {Tabs} from 'antd';
import {useSearchParams} from 'react-router-dom';
import DataStore from '@core/components/Settings/DataStore';
import Demo from '@core/components/Settings/Demo';
import Linter from '@core/components/Settings/Linter';
import TestRunner from '@core/components/Settings/TestRunner';
import BetaBadge from '@core/components/BetaBadge/BetaBadge';
import EnvironmentAgent from '@components/organisms/EnvironmentAgent';
import EnvironmentGeneral from '@components/organisms/EnvironmentGeneral';
import EnvironmentMembers from '@components/organisms/EnvironmentMembers';
import EnvironmentTokens from '@components/organisms/EnvironmentTokens';
import EnvironmentJoinRequests from '@components/organisms/EnvironmentJoinRequests';
import {useEnvironment} from '@providers/Environment.provider';
import {useOrganization} from '@providers/Organization.provider';
import {useListJoinRequestsQuery} from '@redux/apis/TracetestCloud';
import {Operation, useCustomization} from '@core/providers/Customization';
import LimitedAccessAgent from './LimitedAccessAgent';
import LimitedAnalyzer from './LimitedAnalyzer';
import LimitedDataStore from './LimitedDataStore';
import LimitedDemo from './LimitedDemo';
import LimitedTestRunner from './LimitedTestRunner';
import LimitedTracePolling from './LimitedTracePolling';
import * as S from './Settings.styled';
import EnvironmentPollingProfiles from '../EnvironmentPollingProfiles';
import IdPopover from '../IdPopover';
import EnvironmentTraceSetup from '../EnvironmentTraceSetup';

const TabsKeys = {
  Agent: 'agent',
  TraceSetup: 'traceSetup',
  General: 'general',
  Members: 'members',
  Tokens: 'tokens',
  Analytics: 'analytics',
  DataStore: 'dataStore',
  Demo: 'demo',
  Polling: 'polling',
  Analyzer: 'analyzer',
  TestRunner: 'testRunner',
  JoinRequests: 'joinRequests',
};

const Settings = () => {
  const [query, setQuery] = useSearchParams();
  const {environment} = useEnvironment();
  const {organization} = useOrganization();
  const {getIsAllowed} = useCustomization();
  const canConfigure = getIsAllowed(Operation.Configure);

  const {data: {total = 0} = {}} = useListJoinRequestsQuery({
    envId: environment?.id ?? '',
    orgId: organization?.id ?? '',
    status: 'pending',
    take: 0,
  });

  return (
    <S.Container>
      <S.Header>
        <S.Title>Settings</S.Title>
        <IdPopover envId={environment?.id ?? ''} orgId={organization?.id ?? ''} />
      </S.Header>

      <S.TabsContainer>
        <Tabs
          size="small"
          defaultActiveKey={query.get('tab') || TabsKeys.TraceSetup}
          onChange={newTab => {
            setQuery([['tab', newTab]]);
          }}
        >
          <Tabs.TabPane key={TabsKeys.TraceSetup} tab="Trace Setup">
            <EnvironmentTraceSetup />
          </Tabs.TabPane>
          <Tabs.TabPane key={TabsKeys.Agent} tab="Agent">
            {canConfigure ? <EnvironmentAgent /> : <LimitedAccessAgent />}
          </Tabs.TabPane>
          <Tabs.TabPane key={TabsKeys.DataStore} tab="Trace Ingestion">
            {canConfigure ? <DataStore /> : <LimitedDataStore />}
          </Tabs.TabPane>
          {!environment?.isLocal && canConfigure && (
            <>
              <Tabs.TabPane key={TabsKeys.Members} tab="Members">
                <EnvironmentMembers />
              </Tabs.TabPane>
              <Tabs.TabPane key={TabsKeys.Tokens} tab="Tokens">
                <EnvironmentTokens />
              </Tabs.TabPane>
              <Tabs.TabPane
                key={TabsKeys.JoinRequests}
                tab={
                  <S.BadgeContainer>
                    <span>Join Requests</span>
                    <S.NotificationBadge count={total} />
                  </S.BadgeContainer>
                }
              >
                <EnvironmentJoinRequests />
              </Tabs.TabPane>
            </>
          )}
          <Tabs.TabPane key={TabsKeys.Polling} tab="Trace Polling">
            {canConfigure ? <EnvironmentPollingProfiles /> : <LimitedTracePolling />}
          </Tabs.TabPane>
          <Tabs.TabPane key={TabsKeys.Demo} tab="Demo">
            {canConfigure ? <Demo /> : <LimitedDemo />}
          </Tabs.TabPane>
          <Tabs.TabPane
            key={TabsKeys.Analyzer}
            tab={
              <S.TabTextContainer>
                Analyzer
                <BetaBadge />
              </S.TabTextContainer>
            }
          >
            {canConfigure ? <Linter /> : <LimitedAnalyzer />}
          </Tabs.TabPane>
          <Tabs.TabPane key={TabsKeys.TestRunner} tab="Test Runner">
            {canConfigure ? <TestRunner /> : <LimitedTestRunner />}
          </Tabs.TabPane>
          {!environment?.isLocal && (
            <Tabs.TabPane key={TabsKeys.General} tab="General">
              <EnvironmentGeneral />
            </Tabs.TabPane>
          )}
        </Tabs>
      </S.TabsContainer>
    </S.Container>
  );
};

export default Settings;
