import {useEnvironment} from '@providers/Environment.provider';
import {useOrganization} from '@providers/Organization.provider';
import AuthzService, {
  EnvironmentGroup,
  EnvironmentOperation,
  Namespace,
  OrganizationGroup,
  OrganizationOperation,
} from '@services/Authz';

export const useAuthz = () => {
  const {member: memberOrganization} = useOrganization();
  const {member: memberEnvironment} = useEnvironment();

  const checkEnvironment = (operation: EnvironmentOperation) => {
    return AuthzService.check(Namespace.Environment, {
      operation,
      group: memberEnvironment?.role as EnvironmentGroup,
    });
  };

  const checkOrganization = (operation: OrganizationOperation) => {
    return AuthzService.check(Namespace.Organization, {
      operation,
      group: memberOrganization?.role as OrganizationGroup,
    });
  };

  return {checkEnvironment, checkOrganization};
};
