import Logo from '@core/assets/Logo.svg';
import {useUser} from '@providers/User.provider';
import OrganizationSelector from '@components/molecules/OrganizationSelector/OrganizationSelector';
import EnvironmentSelector from '@components/molecules/EnvironmentSelector/EnvironmentSelector';
import UserMenu from '@components/molecules/UserMenu/UserMenu';
import {useEnvironments} from '@providers/Environments.provider';
import {useOrganizations} from '@providers/Organizations.provider';
import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import LicenseBadge from '@components/molecules/LicenseBadge';
import {useLicense} from '@providers/License.provider';
import HeaderBanner from '@components/molecules/HeaderBanner';
import useGetEnvironments from './useGetEnvironments';
import * as S from './HeaderMenu.styled';

interface IProps {
  hasLogo?: boolean;
}

const HeaderMenu = ({hasLogo = false}: IProps) => {
  const navigate = useNavigate();
  const {organizations, organizationId} = useOrganizations();
  const {environments, environmentId} = useEnvironments();
  const environment = useMemo(() => environments?.find(env => env.id === environmentId), [environmentId, environments]);
  const organization = useMemo(
    () => organizations?.find(org => org.id === organizationId),
    [organizationId, organizations]
  );
  const {user} = useUser();

  const {data, loadData, search} = useGetEnvironments({organizationId});
  const {isEnterprise, license} = useLicense();

  return (
    <S.Header>
      <S.MenuContainer>
        {hasLogo && <S.Logo alt="Tracetest logo" data-cy="logo" src={Logo} onClick={() => navigate('')} />}
        <S.MenuContainer>
          <OrganizationSelector organizations={organizations} organization={organization} />
          <EnvironmentSelector environment={environment} environments={data} onLoadData={loadData} onSearch={search} />
        </S.MenuContainer>
      </S.MenuContainer>
      <S.MenuContainer>
        {isEnterprise && <LicenseBadge license={license} />}
        <HeaderBanner />
        <UserMenu organization={organization} user={user} />
      </S.MenuContainer>
    </S.Header>
  );
};

export default HeaderMenu;
