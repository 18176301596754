import CreateButton from '@components/molecules/CreateButton';
import EmptyTestList from '@components/molecules/EmptyTestList';
import GithubActions from '@components/molecules/GithubActions';
import MenuTests from '@components/molecules/MenuTests';
import TriggerTypeList from '@components/molecules/TriggerTypeList';
import Typescript from '@components/molecules/Typescript';
import CLiCommand from '@components/organisms/CLICommand';
import HomeContent from '@components/organisms/HomeContent';
import OpenTelemetryCollector from '@components/organisms/OpenTelemetryCollector';
import Settings from '@components/organisms/Settings';
import {Flag as OSSFlag} from '@core/providers/Customization';
import React from 'react';

export const components: Record<string, React.ComponentType<any>> = {
  settings: Settings,
  menuTests: MenuTests,
  createButton: CreateButton,
  emptyTestList: EmptyTestList,
  TriggerTypeList,
  openTelemetryCollector: OpenTelemetryCollector,
  githubActions: GithubActions,
  homeContent: HomeContent,
  cliCommand: CLiCommand,
  typescript: Typescript,
  contactLauncher: () => null,
};

export const flags: Record<OSSFlag, boolean> = {
  isAgentDataStoreEnabled: true,
  isLocalModeEnabled: false,
};
