import {useCallback, useMemo} from 'react';
import {useAuthz} from '@hooks/authz';
import CustomizationProvider, {Flag as OSSFlag, Operation as OSSOperation} from '@core/providers/Customization';
import {useEnvironment} from '@providers/Environment.provider';
import {EnvironmentOperation} from '@services/Authz';
import CustomizationService from '@services/Customization/Customization.service';

interface IProps {
  children: React.ReactNode;
}

const getComponent = <T,>(id: string, fallback: React.ComponentType<T>) =>
  CustomizationService.getComponent(id) || fallback;

const CustomizationWrapper = ({children}: IProps) => {
  const {checkEnvironment} = useAuthz();
  const {environment, member} = useEnvironment();

  const getIsAllowed = useCallback(
    (operation: OSSOperation) => checkEnvironment(operation as EnvironmentOperation),
    [checkEnvironment]
  );

  const getRole = useCallback(() => member.role, [member.role]);

  const getFlag = useCallback(
    (flag: OSSFlag) => {
      if (flag === OSSFlag.IsLocalModeEnabled) {
        return environment?.isLocal || false;
      }

      return CustomizationService.getFlag(flag);
    },
    [environment?.isLocal]
  );

  const value = useMemo(() => ({getComponent, getFlag, getIsAllowed, getRole}), [getFlag, getIsAllowed, getRole]);

  return <CustomizationProvider value={value}>{children}</CustomizationProvider>;
};

export default CustomizationWrapper;
