import { TAgentSchemas } from '@core/types/Common.types';

export type TRawAgent = TAgentSchemas['Agent'];
type Agent = TRawAgent & {};

const defaultAgent: Agent = {
  id: '',
  name: '',
  status: 'Red',
  meta: {},
  createdAt: '',
  updatedAt: '',
  serverless: false,
  serverlessUrl: '',
};

function Agent({
  id = '',
  name = '',
  meta = {},
  status = 'Red',
  createdAt = '',
  updatedAt = '',
  organizationID = '',
  environmentID = '',
  serverless = false,
  serverlessUrl = '',
}: TRawAgent = defaultAgent): Agent {
  return {
    id,
    name,
    meta,
    status,
    createdAt,
    updatedAt,
    organizationID,
    environmentID,
    serverlessUrl,
    serverless,
  };
}

export default Agent;
