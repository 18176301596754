import {ITraceIDValues, ITriggerService} from '@core/types/Test.types';
import Validator from '@core/utils/Validator';

const CypressTriggerService = (): ITriggerService => ({
  async getRequest() {
    return null;
  },

  async validateDraft(draft) {
    const {id} = draft as ITraceIDValues;
    return Validator.required(id);
  },

  getInitialValues(request) {
    const {id} = request as ITraceIDValues;

    return {id};
  },
  getParsedDefinition() {
    return {
      assets: [],
    };
  },

  getRequestFromSpan() {
    return undefined;
  },
});

export default CypressTriggerService();
