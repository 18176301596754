import {InfoCircleFilled} from '@ant-design/icons';
import {Typography} from 'antd';
import styled from 'styled-components';

export const Title = styled(Typography.Title)``;

export const Paragraph = styled(Typography.Paragraph)`
  && {
    margin: 0;
  }
`;

export const Warning = styled(InfoCircleFilled)`
  && {
    color: ${({theme}) => theme.color.warningYellow};
  }
`;
