import TracingBackend, {TracingBackendTab} from '@core/components/Wizard/Steps/TracingBackend';
import WizardProvider from '@core/providers/Wizard/Wizard.provider';
import {TWizardMap} from '@core/types/Wizard.types';
import Agent, {AgentTab} from '../Steps/Agent';

interface IProps {
  children: React.ReactNode;
}

const stepsMap: TWizardMap = {
  create_test: undefined,
  agent: {
    name: 'Configure Tracetest Agent',
    description: '',
    component: Agent,
    tabComponent: AgentTab,
    isEnabled: true,
  },
  tracing_backend: {
    name: 'Configure trace ingestion',
    description: '',
    component: TracingBackend,
    tabComponent: TracingBackendTab,
    isEnabled: false,
  },
};

const Wrapper = ({children}: IProps) => <WizardProvider stepsMap={stepsMap}>{children}</WizardProvider>;

export default Wrapper;
