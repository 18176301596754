import {IPlaywrightEngineValues, ITriggerService} from '@core/types/Test.types';
import Validator from '@core/utils/Validator';
import PlaywrightEngineRequest from '@core/models/PlaywrightEngineRequest.model';

interface IPlaywrightEngineTriggerService extends ITriggerService {}

const PlaywrightEngine = (): IPlaywrightEngineTriggerService => ({
  async validateDraft(draft) {
    const {method, url, script} = draft as IPlaywrightEngineValues;

    const isValid = Validator.required(url) && Validator.required(method) && Validator.fileRequired(script);

    return isValid;
  },
  async getRequest(values) {
    const {script: scriptFile, method, url: target} = values as IPlaywrightEngineValues;
    const script = await scriptFile.text();

    return PlaywrightEngineRequest({
      target,
      script,
      method,
    });
  },

  getInitialValues(request) {
    const {target: url, script, method} = request as PlaywrightEngineRequest;
    const scriptFile = new File([script], 'script.js');

    return {
      url,
      method,
      script: scriptFile,
    };
  },

  getParsedDefinition(request) {
    const values = request as PlaywrightEngineRequest;

    return {
      trigger: {
        type: 'playwrightengine',
        playwrightEngine: {
          ...values,
          script: './script.js',
        },
      },
      assets: [
        {
          name: 'script.js',
          content: values.script.trim(),
          language: 'javascript',
        },
      ],
    };
  },

  getRequestFromSpan() {
    return undefined;
  },
});

export default PlaywrightEngine();
