import Environment from '@models/Environment.model';
import {Form, FormInstance, Input} from 'antd';
import Explanation from './Explanation';

interface IProps {
  form: FormInstance<Environment>;
  onSubmit(values: Environment): void;
}

const FormBody = ({form, onSubmit}: IProps) => (
  <Form<Environment>
    form={form}
    layout="vertical"
    name="environment"
    onFinish={onSubmit}
  >
    <Form.Item name="id" hidden>
      <Input type="hidden" />
    </Form.Item>

    <Form.Item label="Name" name="name" rules={[{required: true, message: 'Please add a name'}]}>
      <Input placeholder="QA, Stage, Production..." />
    </Form.Item>
    <Explanation />
  </Form>
);

export default FormBody;
