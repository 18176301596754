import {Button, Form, Input, Typography} from 'antd';
import Panel from '@components/molecules/Panel';
import Environment from '@models/Environment.model';
import * as S from './EnvironmentSettings.styled';

interface IProps {
  disabled: boolean;
  isLoading: boolean;
  onSubmit(values: Environment): void;
  environment: Environment;
}

const EnvironmentSettings = ({disabled, isLoading, onSubmit, environment}: IProps) => {
  const [form] = Form.useForm<Environment>();

  return (
    <Panel title={environment.name}>
      <Typography.Paragraph type="secondary">
        <p>Depending on your role, you can manage various settings for your environment.</p>
      </Typography.Paragraph>

      <Form<Environment>
        form={form}
        initialValues={environment}
        layout="vertical"
        name="environment"
        onFinish={onSubmit}
      >
        <Form.Item name="id" hidden>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item
          label="Environment name"
          name="name"
          rules={[{required: true, message: 'Please add a valid environment name'}]}
        >
          <Input disabled={disabled} placeholder="my-env" />
        </Form.Item>

        <S.ButtonContainer>
          <Button disabled={disabled} htmlType="submit" loading={isLoading} type="primary">
            Save
          </Button>
        </S.ButtonContainer>
      </Form>
    </Panel>
  );
};

export default EnvironmentSettings;
