import {Space} from 'antd';
import React from 'react';
import {DownOutlined} from '@ant-design/icons';
import * as S from '.';

interface IProps {
  name: React.ReactNode;
  icon: React.ReactNode;
}

const MenuSelector = ({name, icon}: IProps) => (
  <Space>
    {icon}
    {name}
    <S.SelectedItemText>
      <DownOutlined />
    </S.SelectedItemText>
  </Space>
);

export default MenuSelector;
