import Member from '@models/Member.model';
import User from '@models/User.model';
import {EnvironmentGroup, OrganizationGroup} from '@services/Authz';
import {Form, Typography} from 'antd';
import Footer from './Footer';
import FormBody, {IRoleForm} from './FormBody';
import * as S from './RoleModal.styled';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  member?: Member;
  onClose(): void;
  onSubmit(values: IRoleForm): void;
  roles: OrganizationGroup[] | EnvironmentGroup[];
}

const RoleModal = ({isLoading, isOpen, member, onClose, onSubmit, roles}: IProps) => {
  const [form] = Form.useForm<IRoleForm>();

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  const handleOnSubmit = (values: IRoleForm) => {
    onSubmit(values);
    handleOnClose();
  };

  return (
    <S.Modal
      cancelText="Cancel"
      footer={<Footer isLoading={isLoading} onClose={handleOnClose} onSubmit={() => form.submit()} />}
      onCancel={handleOnClose}
      title={<S.Title>Update role</S.Title>}
      visible={isOpen}
    >
      <>
        {member && (
          <Typography.Paragraph>
            <Typography.Text type="secondary">Update the role for </Typography.Text>
            <Typography.Text strong>{User.getEmail(member.user)}.</Typography.Text>
          </Typography.Paragraph>
        )}
        <FormBody form={form} id={member?.id ?? ''} onSubmit={handleOnSubmit} roles={roles} />
      </>
    </S.Modal>
  );
};

export default RoleModal;
