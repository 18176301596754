import {FramedCodeBlock} from '@core/components/CodeBlock';
import * as S from './ResourceDefinitionModal.styled';

interface IProps {
  command: string;
  configureCommand?: string;
}

const Command = ({command, configureCommand}: IProps) => (
  <>
    <S.Title>Automate Techniques</S.Title>
    <S.Subtitle>The commands below enable you to apply this resource using the CLI.</S.Subtitle>

    {!!configureCommand && (
      <>
        <FramedCodeBlock
          title="Configure Tracetest CLI:"
          language="bash"
          value={configureCommand}
          minHeight="80px"
          maxHeight="80px"
        />
        <br />
      </>
    )}

    <FramedCodeBlock title="Tracetest CLI command:" language="bash" value={command} minHeight="80px" maxHeight="80px" />
  </>
);

export default Command;
