import {FramedCodeBlock} from '@core/components/CodeBlock';
import {IMethodChildrenProps} from '@core/types/PublicOTLPMethod.types';
import {Collapse} from 'antd';
import * as S from '../common.styled';

function getCodeBlocks(endpoint?: string, token?: string) {
  return {
    code1: `curl -L -O https://github.com/open-telemetry/opentelemetry-java-instrumentation/releases/latest/download/opentelemetry-javaagent.jar`,
    code2: `export OTEL_SERVICE_NAME=my-service-name
export OTEL_EXPORTER_OTLP_PROTOCOL=http/protobuf
export OTEL_EXPORTER_OTLP_ENDPOINT="${endpoint}"
export OTEL_EXPORTER_OTLP_HEADERS="x-tracetest-token=${token}"

java -javaagent:opentelemetry-javaagent.jar -jar /path/to/myapp.jar
    `,
  };
}

const Java = ({endpoint, token}: IMethodChildrenProps) => {
  const {code1, code2} = getCodeBlocks(endpoint, token);

  return (
    <S.Collapse accordion ghost defaultActiveKey="1" expandIconPosition="right">
      <Collapse.Panel header={<S.StepTitle level={3}>1. Download opentelemetry-javaagent.jar</S.StepTitle>} key="1">
        <S.StepText>
          Download opentelemetry-javaagent.jar from the opentelemetry-java-instrumentation repository. The JAR file
          contains the agent and all automatic instrumentation packages.
        </S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code1} language="bash" title="opentelemetry-javaagent.jar" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
      <Collapse.Panel header={<S.StepTitle level={3}>2. Run the instrumented app</S.StepTitle>} key="2">
        <S.StepText>You can now run your OpenTelemetry instrumented java app using the agent JAR file.</S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code2} language="bash" title="Run the app" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
    </S.Collapse>
  );
};

export default Java;
