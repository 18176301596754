import Panel from '@components/molecules/Panel';
import LimitedAccess from '@components/organisms/LimitedAccess';
import DocsBanner from '@core/components/DocsBanner';
import {ANALYZER_DOCUMENTATION_URL, COMMUNITY_SLACK_URL, OCTOLIINT_ISSUE_URL} from '@core/constants/Common.constants';
import {useSettingsValues} from '@core/providers/SettingsValues/SettingsValues.provider';
import {useEnvironment} from '@providers/Environment.provider';
import {Descriptions, Typography} from 'antd';
import * as S from '../Settings.styled';

const LimitedAnalyzer = () => {
  const {environment, member} = useEnvironment();
  const {linter} = useSettingsValues();

  return (
    <Panel title="Analyzer (Beta)">
      <Typography.Paragraph type="secondary">
        <p>
          The Tracetest Analyzer is a plugin based framework used to audit OpenTelemetry traces to help teams improve
          their instrumentation data, find potential problems and provide tips to fix the problems. We have released
          this initial version to get feedback from the community. Have thoughts about how to improve the Tracetest
          Analyzer? Add to this <a href={OCTOLIINT_ISSUE_URL}>Issue</a> or call us on{' '}
          <a href={COMMUNITY_SLACK_URL}>Slack</a>!
        </p>
        <DocsBanner>
          Need more information about the Analyzer?{' '}
          <a href={ANALYZER_DOCUMENTATION_URL} target="_blank">
            Learn more in our docs
          </a>
        </DocsBanner>
      </Typography.Paragraph>

      <LimitedAccess
        description="To configure the Analyzer settings, please contact the environment administrator for assistance."
        organizationID={environment?.organizationID}
        role={member.role}
      />

      <S.Divider />

      <div>
        <Typography.Title level={3}>Current Analyzer settings:</Typography.Title>

        <S.Descriptions size="small" column={1}>
          <Descriptions.Item label="Minimum score">{linter.minimumScore}</Descriptions.Item>
          <Descriptions.Item label="Enabled for All Tests">{linter.enabled ? 'Yes' : 'No'}</Descriptions.Item>

          {linter.plugins.map(plugin => (
            <>
              <Descriptions.Item key={plugin.id} label={<b>{plugin.name}</b>}>
                {plugin.enabled ? 'Enabled' : 'Disabled'}
              </Descriptions.Item>
              {plugin.rules.map(rule => (
                <Descriptions.Item key={rule.id} label={rule.name}>
                  {`${rule.errorLevel} - weight ${rule.weight}`}
                </Descriptions.Item>
              ))}
            </>
          ))}
        </S.Descriptions>
      </div>
    </Panel>
  );
};

export default LimitedAnalyzer;
