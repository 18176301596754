import {ClockCircleFilled, WarningFilled} from '@ant-design/icons';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const InvalidIcon = styled(WarningFilled)`
  && {
    color: ${({theme}) => theme.color.error};
    font-size: ${({theme}) => theme.size.xl};
    cursor: pointer;
  }
`;

export const ClockIcon = styled(ClockCircleFilled)<{$isExpired: boolean}>`
  && {
    color: ${({theme, $isExpired}) => ($isExpired ? theme.color.error : theme.color.warningYellow)};
    font-size: ${({theme}) => theme.size.xl};
    cursor: pointer;
  }
`;
