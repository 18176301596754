import styled from 'styled-components';
import {Descriptions as AntdDescriptions, Badge, Typography} from 'antd';

export const Container = styled.div`
  padding: 0 24px;
`;

export const Header = styled.div`
  margin: 23px 0 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TabsContainer = styled.div`
  .ant-tabs-nav {
    padding: 0 12px;
    margin-bottom: 0;
  }

  .ant-tabs-nav {
    padding: 0;
  }

  > .ant-tabs {
    gap: 24px;
  }
`;

export const Title = styled(Typography.Title)`
  && {
    margin: 0;
  }
`;

export const TabTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Divider = styled.div`
  border-bottom: 1px dashed ${({theme}) => theme.color.borderLight};
  margin: 12px 0;
  width: 100%;
`;

export const Descriptions = styled(AntdDescriptions)`
  .ant-descriptions-item-label {
    color: ${({theme}) => theme.color.textSecondary};
  }
`;

export const NotificationBadge = styled(Badge)`
  .ant-badge-count {
    background-color: ${({theme}) => theme.color.primary};
    color: ${({theme}) => theme.color.white};
  }
`;

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
