import styled from 'styled-components';

export const CollapseContainer = styled.div`
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 12px 0;
  }
`;
