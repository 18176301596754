import {Typography} from 'antd';
import styled from 'styled-components';
import CharIcon from '@components/atoms/CharIcon/CharIcon';

export const Avatar = styled(CharIcon)`
  height: 18px;
  width: 18px;

  span {
    font-size: ${({theme}) => theme.size.md};
  }
`;

export const Text = styled(Typography.Text)<{$isActive?: boolean}>`
  && {
    color: ${({theme, $isActive}) => ($isActive ? theme.color.primary : theme.color.textLight)};
    font-size: ${({theme}) => theme.size.sm};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
