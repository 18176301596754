import styled, {DefaultTheme, css, keyframes} from 'styled-components';

export const getPulseAnimation = (theme: DefaultTheme, $isDarkMode = false, size = 9) =>
  keyframes`
  from {
    box-shadow: 0 0 0 0 ${$isDarkMode ? theme.color.white : theme.color.primary};
  }
  70% {
    box-shadow: 0 0 0 ${size}px ${theme.color.primaryLight};
  }
  to {
    box-shadow: 0 0 0 0 ${theme.color.primaryLight};
  }
`;

export const withPulseAnimation = (theme: DefaultTheme, $isDarkMode?: boolean, size?: number) => css`
  animation-name: ${getPulseAnimation(theme, $isDarkMode, size)};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

export const PulseButton = styled.button<{$isDarkMode?: boolean}>`
  background: ${({theme, $isDarkMode}) => ($isDarkMode ? theme.color.white : theme.color.primary)};
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 0 ${({theme, $isDarkMode}) => ($isDarkMode ? theme.color.white : theme.color.primary)};
  cursor: pointer;
  height: 9px;
  padding: 0px;
  transition: all 0.2s;
  width: 9px;

  ${({theme, $isDarkMode}) => withPulseAnimation(theme, $isDarkMode)}
`;
