import {Typography} from 'antd';
import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled(Typography.Title)`
  && {
    font-size: ${({theme}) => theme.size.md};
  }
`;

export const Description = styled(Typography.Paragraph)`
  && {
    color: ${({theme}) => theme.color.textSecondary};
    font-size: ${({theme}) => theme.size.md};
  }
`;

export const UrlEntry = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 16px;
`;
