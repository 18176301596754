import {Tooltip} from 'antd';
import {useMemo} from 'react';
import {differenceInDays, formatDistance, parseISO} from 'date-fns';
import License from '@models/License.model';
import * as S from './LicenseBadge.styled';

interface IProps {
  license: License;
}

const LicenseBadge = ({license: {isValid, expiresAt = ''}}: IProps) => {
  const expiresIn = expiresAt ? formatDistance(parseISO(expiresAt), new Date()) : '';
  const diff = expiresAt ? differenceInDays(parseISO(expiresAt), new Date()) : 0;
  const isExpired = diff < 0;

  const icon = useMemo(() => {
    return (
      (!expiresAt && isValid && <div />) ||
      (!isValid && <S.InvalidIcon />) ||
      (diff < 2 && <S.ClockIcon $isExpired={isExpired} />) || <div />
    );
  }, [diff, expiresAt, isExpired, isValid]);

  return (
    <S.Container>
      <Tooltip
        title={(!isValid && 'Invalid License') || (isExpired && 'License Expired') || `License expires in ${expiresIn}`}
      >
        {icon}
      </Tooltip>
    </S.Container>
  );
};

export default LicenseBadge;
