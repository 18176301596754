import {Model, TVariableSetSchemas} from '@core/types/Common.types';

export type TRawVariableSet = TVariableSetSchemas['VariableSetResource'];
export type TVariableSetValue = TVariableSetSchemas['VariableSetValue'];

export type TDraftVariableSet = VariableSet & {
  secrets: VariableSetValue[];
};

export type VariableSetValue = Model<TVariableSetSchemas['VariableSetValue'], {}>;
type VariableSet = Model<TVariableSetSchemas['VariableSet'], {values: VariableSetValue[]}>;

function VariableSet({spec: {id = '', name = '', description = '', values = []} = {}}: TRawVariableSet): VariableSet {
  return VariableSet.fromRun({id, name, description, values});
}

VariableSet.fromRun = ({
  id = '',
  name = '',
  description = '',
  values = [],
}: TVariableSetSchemas['VariableSet']): VariableSet => {
  return {
    id,
    name,
    description,
    values: values?.map(({key = '', value = '', type = 'raw'}) => ({key, value, type: type || 'raw'})),
  };
};

export default VariableSet;
