import {Model, TTraceModeSchemas} from '@core/types/Common.types';

export type TRawListTracesResponse = TTraceModeSchemas['ListTracesResponse'];

export type TRawTraceMetadata = TTraceModeSchemas['TraceMetadata'];
type TraceMetadata = Model<TRawTraceMetadata, {}>;

const defaultTraceMetadata: TRawTraceMetadata = {
  traceId: '',
  rootServiceName: '',
  rootTraceName: '',
  startTime: 0,
  durationMs: 0,
  spanCount: 0,
};

const TraceMetadata = ({
  traceId = '',
  rootServiceName = '',
  rootTraceName = '',
  startTime = 0,
  durationMs = 0,
  spanCount = 0,
}: TRawTraceMetadata = defaultTraceMetadata): TraceMetadata => ({
  traceId,
  rootServiceName,
  rootTraceName,
  startTime,
  durationMs,
  spanCount,
});

export default TraceMetadata;
