import {CheckCircleOutlined} from '@ant-design/icons';
import {Button, Typography} from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Overlay = styled.div`
  background: ${({theme}) => theme.color.white};
  height: 100%;
  opacity: 0.6;
  position: absolute;
  width: 100%;
  z-index: 9;
`;

export const SwitchContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const SwitchLabel = styled.label`
  color: ${({theme}) => theme.color.text};
  cursor: pointer;
  margin-bottom: 0;
`;

export const BackendSelector = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 16px;
`;

export const Card = styled.div<{$isSelected?: boolean}>`
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 6px;
  border: 1px solid ${({theme}) => theme.color.borderLight};
  border-color: ${({theme, $isSelected}) => ($isSelected ? theme.color.primary : theme.color.borderLight)};
  border-radius: 2px;
  cursor: pointer;
  height: 32px;
  width: 142px;
  font-size: ${({theme}) => theme.size.sm};
  font-weight: 700;
  transition: 0.3s;

  &:hover {
    border-color: ${({theme}) => theme.color.primary};
    background-color: ${({theme}) => theme.color.background};
  }
`;

export const Name = styled(Typography.Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SelectedIcon = styled(CheckCircleOutlined)`
  color: ${({theme}) => theme.color.primary};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const NoPaddingButton = styled(Button)`
  padding: 4px 0;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
