import AssignMember from '@models/AssignMember.type';
import Member from '@models/Member.model';
import {Typography} from 'antd';
import {Link} from 'react-router-dom';
import FormBody from './FormBody';
import * as S from './EnvironmentMember.styled';

interface IProps {
  assignableMembers: Member[];
  isLoading: boolean;
  onSubmit(values: AssignMember): void;
  orgId: string;
}

const EnvironmentMember = ({assignableMembers, isLoading, onSubmit, orgId}: IProps) => (
  <S.Container>
    {assignableMembers.length === 0 ? (
      <Typography.Text>
        There are no members in your organization which can be added to this environment. Manage your organization
        members <Link to={`/organizations/${orgId}/members`}>here</Link>.
      </Typography.Text>
    ) : (
      <FormBody assignableMembers={assignableMembers} isLoading={isLoading} onSubmit={onSubmit} />
    )}
  </S.Container>
);

export default EnvironmentMember;
