import ContentResources from '@components/molecules/ContentResources';
import HeaderResources from '@core/components/Resources/Header';
import * as S from '@core/pages/Wizard/Wizard.styled';
import {Collapse} from 'antd';

interface IProps {
  environmentName: string;
  openOrganizationModal: () => void;
}

const ResourcesDemo = ({environmentName, openOrganizationModal}: IProps) => (
  <S.Container>
    <S.Header>
      <S.Title>Welcome to the Tracetest Demo Environment!</S.Title>
      <S.Text>
        You&apos;re currently exploring the {environmentName} environment, designed to showcase the power of Tracetest.
        Here&apos;s how to make the most of your demo experience:
      </S.Text>
    </S.Header>

    <Collapse defaultActiveKey="resources">
      <Collapse.Panel header={<HeaderResources title="Resources" />} key="resources">
        <ContentResources openOrganizationModal={openOrganizationModal} />
      </Collapse.Panel>
    </Collapse>
  </S.Container>
);

export default ResourcesDemo;
