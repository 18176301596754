import {useGetEnvironmentsMeQuery} from '@redux/apis/TracetestCloud';
import {useState} from 'react';

const PAGE_SIZE = 20;

interface IParams {
  organizationId: string;
}

const useGetEnvironments = ({organizationId}: IParams) => {
  const [queryParams, setQueryParams] = useState({
    pageNumber: 1,
    query: '',
  });

  const {data} = useGetEnvironmentsMeQuery(
    {
      orgId: organizationId ?? '',
      query: queryParams.query,
      skip: 0,
      take: queryParams.pageNumber * PAGE_SIZE,
    },
    {
      skip: !organizationId,
    }
  );

  const loadData = () => {
    setQueryParams(prev => ({...prev, pageNumber: prev.pageNumber + 1}));
  };

  const search = (name: string) => {
    setQueryParams({pageNumber: 1, query: name});
  };

  return {
    data,
    loadData,
    search,
  };
};

export default useGetEnvironments;
