import {FramedCodeBlock} from '@core/components/CodeBlock';
import {IMethodChildrenProps} from '@core/types/PublicOTLPMethod.types';
import {Collapse} from 'antd';
import * as S from '../common.styled';

function getCodeBlocks(endpoint?: string, token?: string) {
  return {
    code1: `npm install @opentelemetry/sdk-node \\
  @opentelemetry/exporter-trace-otlp-http \\
  @opentelemetry/auto-instrumentations-node
`,
    code2: `/*instrumentation.js*/

const { NodeSDK } = require('@opentelemetry/sdk-node');
const {
  getNodeAutoInstrumentations,
} = require('@opentelemetry/auto-instrumentations-node');
const { OTLPTraceExporter } = require('@opentelemetry/exporter-trace-otlp-http');

const sdk = new NodeSDK({
  traceExporter: new OTLPTraceExporter(),
  instrumentations: [getNodeAutoInstrumentations()],
});

sdk.start();
`,
    code3: `export OTEL_SERVICE_NAME=my-service-name
export OTEL_EXPORTER_OTLP_PROTOCOL=http/protobuf
export OTEL_EXPORTER_OTLP_ENDPOINT="${endpoint}"
export OTEL_EXPORTER_OTLP_HEADERS="x-tracetest-token=${token}"

node -r ./instrumentation.js app.js`,
  };
}

const NodeJS = ({endpoint, token}: IMethodChildrenProps) => {
  const {code1, code2, code3} = getCodeBlocks(endpoint, token);

  return (
    <S.Collapse accordion ghost defaultActiveKey="1" expandIconPosition="right">
      <Collapse.Panel header={<S.StepTitle level={3}>1. Install dependencies</S.StepTitle>} key="1">
        <S.StepText>First, install the Node SDK and autoinstrumentations package.</S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code1} language="bash" title="Install dependencies" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
      <Collapse.Panel header={<S.StepTitle level={3}>2. Setup</S.StepTitle>} key="2">
        <S.StepText>
          Create a file named instrumentation.js, which will contain your instrumentation setup code.
        </S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code2} language="javascript" title="Setup" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
      <Collapse.Panel header={<S.StepTitle level={3}>3. Run the instrumented app</S.StepTitle>} key="3">
        <S.StepText>
          Run your application, but you can use the --require flag to load the instrumentation before the application
          code.
        </S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code3} language="bash" title="Run the app" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
    </S.Collapse>
  );
};

export default NodeJS;
