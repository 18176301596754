import {TOrganizationSchemas} from '@core/types/Common.types';
import Plan from './Plan.model';

export type TRawOrganization = TOrganizationSchemas['Organization'];
type Organization = TRawOrganization & {
  plan: Plan;
};

const defaultOrganization: TRawOrganization = {
  id: '',
  name: '',
};

function Organization({
  id = '',
  name = '',
  description = '',
  labels = {},
  createdAt = '',
  updatedAt = '',
  optOutLocalMode = false,
  plan = {id: '', name: 'plan_free'},
} = defaultOrganization): Organization {
  return {
    id,
    name,
    description,
    labels,
    createdAt,
    updatedAt,
    optOutLocalMode,
    plan: Plan(plan),
  };
}

export default Organization;
