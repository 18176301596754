import {noop} from 'lodash';
import moment from 'moment';
import {parseISO, addDays} from 'date-fns';
import {DatePicker, Select} from 'antd';
import {useEffect, useMemo, useState} from 'react';
import * as S from './ExpirationSelector.styled';

const CUSTOM_VALUE = 'custom';

function getDiffDays(from: string, dateString: string) {
  const today = from ? parseISO(from) : new Date();
  today.setHours(0, 0, 0, 0);
  const date = new Date(dateString);
  const diffTime = Math.abs(date.getTime() - today.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

interface IProps {
  id?: string;
  value?: string;
  onChange?(value: string): void;
  createdAt?: string;
}

const defaultValuesMap: Record<string, string> = {
  '7': '7 Days',
  '30': '30 Days',
  '60': '60 Days',
  '90': '90 Days',
  '365': '1 Year',
};

const defaultValues = Object.entries(defaultValuesMap);
// the last value in the default values map
const [initialSelectorValue = ''] = defaultValues.at(-1) ?? [];

const ExpirationSelector = ({id, value = '', onChange = noop, createdAt = ''}: IProps) => {
  const [isCustomOpen, setIsCustomOpen] = useState(false);
  const [selectorValue, setSelectorValue] = useState(
    value ? defaultValuesMap[value] ?? CUSTOM_VALUE : initialSelectorValue
  );
  const isCustom = useMemo(() => !!value && !defaultValuesMap[value], [value]);
  const customValue = useMemo(() => {
    if (!isCustomOpen) return undefined;

    const initialDate = createdAt ? parseISO(createdAt) : new Date();
    const stringDate = addDays(initialDate, parseInt(value, 10)).toISOString();

    return moment(stringDate);
  }, [createdAt, isCustomOpen, value]);

  const handleOnDatePickerChange = (val: any, dateString: string) => {
    if (!dateString) {
      onChange('');
      return;
    }

    const days = getDiffDays(createdAt, dateString);
    onChange(days.toString());
  };

  useEffect(() => {
    if (!value) onChange(initialSelectorValue);
  }, [onChange, value]);

  useEffect(() => {
    if (isCustom) {
      setIsCustomOpen(true);
    }
  }, [isCustom]);

  return (
    <S.Row>
      <S.Item>
        <Select
          id={id}
          value={selectorValue}
          onChange={selectedValue => {
            if (selectedValue !== CUSTOM_VALUE) {
              onChange(selectedValue);
            }

            setSelectorValue(selectedValue);
          }}
          onSelect={selectedValue => setIsCustomOpen(selectedValue === CUSTOM_VALUE)}
          placeholder="Select an expiration date"
        >
          {defaultValues.map(([val, label]) => (
            <Select.Option key={val} value={val}>
              {label}
            </Select.Option>
          ))}
          <Select.Option value={CUSTOM_VALUE}>Custom</Select.Option>
        </Select>
      </S.Item>

      {isCustomOpen && (
        <S.Item>
          <DatePicker
            value={customValue}
            disabledDate={current => current && current.valueOf() < Date.now()}
            format="MM/DD/YYYY"
            onChange={handleOnDatePickerChange}
            placeholder="MM/DD/YYYY"
            style={{width: '100%'}}
          />
        </S.Item>
      )}
    </S.Row>
  );
};

export default ExpirationSelector;
