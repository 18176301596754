import {Typography, Button as AntButton} from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background: rgba(97, 23, 94, 0.2);
  border-radius: 16px;
  display: flex;
  gap: 12px;
  height: 34px;
  margin: auto;
  padding: 4px 4px 4px 14px;
`;

export const Text = styled(Typography.Text)`
  margin: 0;
`;

export const Button = styled(AntButton)`
  && {
    background: ${({theme}) => theme.color.primary};
    border-radius: 16px;
    font-weight: 600;
  }
`;
