import TracetestAPI from '@core/redux/apis/Tracetest';
import {TRawTestSpecs} from '@core/models/TestSpecs.model';

const TestRunGateway = () => ({
  getById(testId: string, runId: number) {
    return TracetestAPI.endpoints.getRunById.initiate({testId, runId});
  },
  reRun(testId: string, runId: number) {
    return TracetestAPI.endpoints.reRun.initiate({testId, runId});
  },
  dryRun(testId: string, runId: number, testDefinition: Partial<TRawTestSpecs>) {
    return TracetestAPI.endpoints.dryRun.initiate({testId, runId, testDefinition});
  },
  runTest(testId: string, variableSetId = '') {
    return TracetestAPI.endpoints.runTest.initiate({testId, variableSetId});
  },
});

export default TestRunGateway();
