import {Form, Select} from 'antd';
import Link from '@core/components/Link';
import {useListPollingQuery} from '@core/redux/apis/Tracetest';
import {TooltipQuestion} from '@core/components/TooltipQuestion/TooltipQuestion';
import * as S from '../SSL/SSL.styled';

const defaultItem = {id: '', name: ''};

const filterOption = (input: string, option: {children: string; label: string} | undefined) =>
  (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

const SelectPollingProfile = () => {
  const {data: {items} = {items: [], total: 0}, isLoading} = useListPollingQuery({take: 200});

  return (
    <S.SSLVerificationContainer>
      <Form.Item
        name="pollingProfile"
        label={
          <>
            Select A Polling Profile{' '}
            <TooltipQuestion
              title={
                <span>
                  Create or edit Trace Polling Profiles <Link to="/settings?tab=polling">here</Link> to customize the
                  timing and strategy for polling test results.
                </span>
              }
            />
          </>
        }
        valuePropName="value"
      >
        <Select placeholder="my polling profile" filterOption={filterOption} loading={isLoading} showSearch>
          {[defaultItem, ...items].map(({id, name}) => (
            <Select.Option key={id} value={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </S.SSLVerificationContainer>
  );
};

export default SelectPollingProfile;
