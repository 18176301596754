import {FramedCodeBlock} from '@core/components/CodeBlock';
import {IMethodChildrenProps} from '@core/types/PublicOTLPMethod.types';
import {Collapse} from 'antd';
import * as S from '../common.styled';

function getCodeBlocks(endpoint?: string, token?: string) {
  return {
    code1: `gem install opentelemetry-sdk \\
            opentelemetry-exporter-otlp \\
            opentelemetry-instrumentation-all
`,
    code2: `require 'opentelemetry/sdk'
require 'opentelemetry/exporter/otlp'
require 'opentelemetry/instrumentation/all'

OpenTelemetry::SDK.configure do |c|
    c.use_all() # enables all instrumentation!
end
`,
    code3: `export OTEL_SERVICE_NAME=my-service-name
export OTEL_EXPORTER_OTLP_PROTOCOL=http/protobuf
export OTEL_EXPORTER_OTLP_ENDPOINT="${endpoint}"
export OTEL_EXPORTER_OTLP_HEADERS="x-tracetest-token=${token}"

rails server -p 8080 # rails example`,
  };
}

const Ruby = ({endpoint, token}: IMethodChildrenProps) => {
  const {code1, code2, code3} = getCodeBlocks(endpoint, token);

  return (
    <S.Collapse accordion ghost defaultActiveKey="1" expandIconPosition="right">
      <Collapse.Panel header={<S.StepTitle level={3}>1. Add dependencies</S.StepTitle>} key="1">
        <S.StepText>Install the opentelemetry-sdk and opentelemetry-instrumentation-all packages.</S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code1} language="bash" title="Add dependencies" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
      <Collapse.Panel header={<S.StepTitle level={3}>2. Setup</S.StepTitle>} key="2">
        <S.StepText>
          For Rails applications, the usual way to initialize OpenTelemetry is in a Rails initializer. For other Ruby
          services, perform this initialization as early as possible in the start-up process. Create a file named
          config/initializers/opentelemetry.rb with the following code:
        </S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code2} language="ruby" title="Setup" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
      <Collapse.Panel header={<S.StepTitle level={3}>3. Run the instrumented app</S.StepTitle>} key="3">
        <S.StepText>Run your Ruby app to automatically generate traces.</S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code3} language="bash" title="Run the app" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
    </S.Collapse>
  );
};

export default Ruby;
