import {TracetestApiTags} from '@core/constants/Test.constants';
import {PaginationResponse} from '@core/hooks/usePagination';
import RunFilter, {TFilters, RunFilterName, TRawRunFilter} from '@core/models/RunFilter.model';
import Run, {TRawRun, TRunsFilters} from '@core/models/Run.model';
import {getTotalCountFromHeaders} from '@core/utils/Common';
import {TTestApiEndpointBuilder} from '../Tracetest.api';

const defaultFilters = Object.values(RunFilterName);

export const runEndpoints = (builder: TTestApiEndpointBuilder) => ({
  getRuns: builder.query<PaginationResponse<Run>, Partial<TRunsFilters>>({
    query: ({
      runGroupId = '',
      take = 25,
      skip = 0,
      testId = '',
      startDate = '',
      endDate = '',
      name = '',
      options = {},
    }) => ({
      url: `/runs?take=${take}&skip=${skip}&runGroupIds=${runGroupId}&name=${name}&startDate=${startDate}&endDate=${endDate}&testIds=${testId}${Object.entries(
        options
      )
        .map(([key, value]) => `&${key}=${value}`)
        .join('')}`,
    }),
    providesTags: () => [{type: TracetestApiTags.RUN, id: 'LIST'}],
    transformResponse: (rawRunGroups: TRawRun[], meta) => ({
      items: rawRunGroups.map(raw => Run(raw)),
      total: getTotalCountFromHeaders(meta),
    }),
  }),

  getFilters: builder.query<RunFilter[], {filters?: TFilters[]; runGroupId?: string; testId?: string}>({
    query: ({filters = defaultFilters, runGroupId = '', testId = ''}) => ({
      url: `/runs/filters?filters=${filters.join(',')}&runGroupIds=${runGroupId}&testIds=${testId}`,
    }),
    providesTags: () => [{type: TracetestApiTags.RUN_FILTERS, id: 'LIST'}],
    transformResponse: (rawFilters: TRawRunFilter[]) => rawFilters.map(raw => RunFilter(raw)),
  }),

  // Organization Runs
  getOrganizationRuns: builder.query<PaginationResponse<Run>, Partial<TRunsFilters>>({
    query: ({
      runGroupId = '',
      take = 25,
      skip = 0,
      testId = '',
      startDate = '',
      endDate = '',
      name = '',
      options = {},
    }) => ({
      url: `/organizationruns?take=${take}&skip=${skip}&runGroupIds=${runGroupId}&name=${name}&startDate=${startDate}&endDate=${endDate}&testIds=${testId}${Object.entries(
        options
      )
        .map(([key, value]) => `&${key}=${value}`)
        .join('')}`,
    }),
    providesTags: () => [{type: TracetestApiTags.ORGANIZATION_RUN, id: 'LIST'}],
    transformResponse: (rawRuns: TRawRun[], meta) => ({
      items: rawRuns.map(rawRun => Run(rawRun)),
      total: getTotalCountFromHeaders(meta),
    }),
  }),

  getOrganizationRunsFilters: builder.query<RunFilter[], {filters?: TFilters[]; runGroupId?: string; testId?: string}>({
    query: ({filters = defaultFilters, runGroupId = '', testId = ''}) => ({
      url: `/organizationruns/filters?filters=${filters.join(',')}&runGroupIds=${runGroupId}&testIds=${testId}`,
    }),
    providesTags: () => [{type: TracetestApiTags.ORGANIZATION_RUN_FILTERS, id: 'LIST'}],
    transformResponse: (rawRunFilters: TRawRunFilter[]) => rawRunFilters.map(rawRunFilter => RunFilter(rawRunFilter)),
  }),
});
