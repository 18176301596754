import {TriggerTypes} from '@core/constants/Test.constants';
import {get} from 'lodash';
import {TTriggerSchemas} from '@core/types/Common.types';
import {TTriggerRequest} from '@core/types/Test.types';
import GrpcRequest from './GrpcRequest.model';
import HttpRequest from './HttpRequest.model';
import TraceIDRequest from './TraceIDRequest.model';
import KafkaRequest from './KafkaRequest.model';
import PlaywrightEngineRequest from './PlaywrightEngineRequest.model';
import GraphqlRequest from './GraphqlRequest.model';

export type TRawTrigger = TTriggerSchemas['Trigger'];
type Trigger = {
  type: TriggerTypes;
  entryPoint: string;
  method: string;
  request: TTriggerRequest;
};

type TRequest = object | null;

const EntryData = {
  [TriggerTypes.http](request: TRequest) {
    return {
      entryPoint: get(request, 'url', ''),
      method: get(request, 'method', ''),
    };
  },
  [TriggerTypes.grpc](request: TRequest) {
    return {
      entryPoint: get(request, 'address', ''),
      method: get(request, 'method', ''),
    };
  },
  [TriggerTypes.traceid](request: TRequest) {
    return {
      entryPoint: get(request, 'id', ''),
      method: '',
    };
  },
  [TriggerTypes.cypress](request: TRequest) {
    return {
      entryPoint: get(request, 'id', ''),
      method: '',
    };
  },
  [TriggerTypes.playwright](request: TRequest) {
    return {
      entryPoint: get(request, 'id', ''),
      method: '',
    };
  },
  [TriggerTypes.artillery](request: TRequest) {
    return {
      entryPoint: get(request, 'id', ''),
      method: '',
    };
  },
  [TriggerTypes.k6](request: TRequest) {
    return {
      entryPoint: get(request, 'id', ''),
      method: '',
    };
  },
  [TriggerTypes.kafka](request: TRequest) {
    let entryPoint = '';

    const kafkaRequest = request as KafkaRequest;
    if (kafkaRequest) {
      entryPoint = kafkaRequest.brokerUrls.join(', ');
    }

    return {
      entryPoint,
      method: get(request, 'topic', ''),
    };
  },
  [TriggerTypes.playwrightEngine](request: TRequest) {
    return {
      entryPoint: get(request, 'target', ''),
      method: get(request, 'method', ''),
    };
  },
  [TriggerTypes.graphql](request: TRequest) {
    return {
      entryPoint: get(request, 'url', ''),
      method: '',
    };
  },
};

const Trigger = ({
  type: rawType = 'http',
  httpRequest = {},
  grpc = {},
  traceid = {},
  kafka = {},
  playwrightEngine = {},
  graphql = {},
}: TRawTrigger): Trigger => {
  const type = rawType as TriggerTypes;

  let request = {} as TTriggerRequest;
  if (type === TriggerTypes.http) {
    request = HttpRequest(httpRequest);
  } else if (type === TriggerTypes.grpc) {
    request = GrpcRequest(grpc);
  } else if (
    [
      TriggerTypes.traceid,
      TriggerTypes.cypress,
      TriggerTypes.playwright,
      TriggerTypes.artillery,
      TriggerTypes.k6,
    ].includes(type)
  ) {
    request = TraceIDRequest(traceid);
  } else if (type === TriggerTypes.kafka) {
    request = KafkaRequest(kafka);
  } else if (type === TriggerTypes.playwrightEngine) {
    request = PlaywrightEngineRequest(playwrightEngine);
  } else if (type === TriggerTypes.graphql) {
    request = GraphqlRequest(graphql);
  }

  const {entryPoint, method} = EntryData[type || TriggerTypes.http](request);

  return {
    type,
    entryPoint,
    method,
    request,
  };
};

export default Trigger;
