import {ProfileOutlined} from '@ant-design/icons';
import {ResourceType} from '@core/types/Resource.type';
import {useResourceDefinition} from '@providers/ResourceDefinition.provider';
import {Typography} from 'antd';

interface IProps {
  resourceId: string;
  resourceType: ResourceType;
}

const ResourceDefinitionButton = ({resourceId, resourceType}: IProps) => {
  const {onOpenDefinition} = useResourceDefinition();

  return (
    <Typography.Link onClick={() => onOpenDefinition(resourceType, resourceId)}>
      <ProfileOutlined /> Resource Definition
    </Typography.Link>
  );
};

export default ResourceDefinitionButton;
