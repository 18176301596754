import {PaginationResponse} from '@core/hooks/usePagination';
import {EnvironmentOperation, useAuthz} from '@hooks/authz';
import Polling from '@core/models/Polling.model';
import {List} from 'antd';
import ProfileItem from './ProfileItem';

interface IProps {
  isLoading: boolean;
  onDelete(profile: Polling): void;
  onEdit(profile: Polling): void;
  onOpenDefinition(profile: Polling): void;
  onPageChange(page: number, pageSize: number): void;
  pageSize: number;
  profiles?: PaginationResponse<Polling>;
}

const EnvironmentPollingProfileList = ({
  isLoading,
  onDelete,
  onEdit,
  onOpenDefinition,
  onPageChange,
  pageSize,
  profiles: {items = [], total} = {items: [], total: 0},
}: IProps) => {
  const {checkEnvironment} = useAuthz();
  const canEdit = checkEnvironment(EnvironmentOperation.Edit);

  return (
    <List<Polling>
      bordered
      pagination={{
        hideOnSinglePage: true,
        onChange: onPageChange,
        pageSize,
        position: 'bottom',
        showSizeChanger: false,
        total,
      }}
      loading={isLoading}
      dataSource={items}
      renderItem={item => (
        <ProfileItem
          profile={item}
          canEdit={canEdit}
          onEdit={onEdit}
          onDelete={onDelete}
          onOpenDefinition={onOpenDefinition}
        />
      )}
    />
  );
};

export default EnvironmentPollingProfileList;
