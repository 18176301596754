import {FramedCodeBlock} from '@core/components/CodeBlock';
import {IMethodChildrenProps} from '@core/types/PublicOTLPMethod.types';
import {Collapse} from 'antd';
import * as S from '../common.styled';

function getCodeBlocks(endpoint?: string, token?: string) {
  return {
    code1: `go get "go.opentelemetry.io/otel" \\
  "go.opentelemetry.io/otel/exporters/stdout/stdoutmetric" \\
  "go.opentelemetry.io/otel/exporters/stdout/stdouttrace" \\
  "go.opentelemetry.io/otel/exporters/stdout/stdoutlog" \\
  "go.opentelemetry.io/otel/sdk/log" \\
  "go.opentelemetry.io/otel/log/global" \\
  "go.opentelemetry.io/otel/propagation" \\
  "go.opentelemetry.io/otel/sdk/metric" \\
  "go.opentelemetry.io/otel/sdk/resource" \\
  "go.opentelemetry.io/otel/sdk/trace" \\
  "go.opentelemetry.io/contrib/instrumentation/net/http/otelhttp" \\
  "go.opentelemetry.io/contrib/bridges/otelslog"
`,
    code2: `package main

import (
	"context"
	"errors"
	"time"

	"go.opentelemetry.io/otel"
	"go.opentelemetry.io/otel/exporters/stdout/stdouttrace"
	"go.opentelemetry.io/otel/log/global"
	"go.opentelemetry.io/otel/propagation"
	"go.opentelemetry.io/otel/sdk/trace"
)

// setupOTelSDK bootstraps the OpenTelemetry pipeline.
// If it does not return an error, make sure to call shutdown for proper cleanup.
func setupOTelSDK(ctx context.Context) (shutdown func(context.Context) error, err error) {
	var shutdownFuncs []func(context.Context) error

	// shutdown calls cleanup functions registered via shutdownFuncs.
	// The errors from the calls are joined.
	// Each registered cleanup will be invoked once.
	shutdown = func(ctx context.Context) error {
		var err error
		for _, fn := range shutdownFuncs {
			err = errors.Join(err, fn(ctx))
		}
		shutdownFuncs = nil
		return err
	}

	// handleErr calls shutdown for cleanup and makes sure that all errors are returned.
	handleErr := func(inErr error) {
		err = errors.Join(inErr, shutdown(ctx))
	}

	// Set up propagator.
	prop := newPropagator()
	otel.SetTextMapPropagator(prop)

	// Set up trace provider.
	tracerProvider, err := newTraceProvider()
	if err != nil {
		handleErr(err)
		return
	}
	shutdownFuncs = append(shutdownFuncs, tracerProvider.Shutdown)
	otel.SetTracerProvider(tracerProvider)

	return
}

func newPropagator() propagation.TextMapPropagator {
	return propagation.NewCompositeTextMapPropagator(
		propagation.TraceContext{},
		propagation.Baggage{},
	)
}

func newTraceProvider() (*trace.TracerProvider, error) {
	traceExporter, err := stdouttrace.New(
		stdouttrace.WithPrettyPrint())
	if err != nil {
		return nil, err
	}

	traceProvider := trace.NewTracerProvider(
		trace.WithBatcher(traceExporter,
			// Default is 5s. Set to 1s for demonstrative purposes.
			trace.WithBatchTimeout(time.Second)),
	)
	return traceProvider, nil
}

`,
    code3: `package main

import (
	"context"
	"errors"
	"log"
	"net"
	"net/http"
	"os"
	"os/signal"
	"time"

	"go.opentelemetry.io/contrib/instrumentation/net/http/otelhttp"
)

func main() {
	if err := run(); err != nil {
		log.Fatalln(err)
	}
}

func run() (err error) {
	// Handle SIGINT (CTRL+C) gracefully.
	ctx, stop := signal.NotifyContext(context.Background(), os.Interrupt)
	defer stop()

	// Set up OpenTelemetry.
	otelShutdown, err := setupOTelSDK(ctx)
	if err != nil {
		return
	}
	// Handle shutdown properly so nothing leaks.
	defer func() {
		err = errors.Join(err, otelShutdown(context.Background()))
	}()

	// Start HTTP server.
	srv := &http.Server{
		Addr:         ":8080",
		BaseContext:  func(_ net.Listener) context.Context { return ctx },
		ReadTimeout:  time.Second,
		WriteTimeout: 10 * time.Second,
		Handler:      newHTTPHandler(),
	}
	srvErr := make(chan error, 1)
	go func() {
		srvErr <- srv.ListenAndServe()
	}()

	// Wait for interruption.
	select {
	case err = <-srvErr:
		// Error when starting HTTP server.
		return
	case <-ctx.Done():
		// Wait for first CTRL+C.
		// Stop receiving signal notifications as soon as possible.
		stop()
	}

	// When Shutdown is called, ListenAndServe immediately returns ErrServerClosed.
	err = srv.Shutdown(context.Background())
	return
}

func newHTTPHandler() http.Handler {
	mux := http.NewServeMux()

	// handleFunc is a replacement for mux.HandleFunc
	// which enriches the handler's HTTP instrumentation with the pattern as the http.route.
	handleFunc := func(pattern string, handlerFunc func(http.ResponseWriter, *http.Request)) {
		// Configure the "http.route" for the HTTP instrumentation.
		handler := otelhttp.WithRouteTag(pattern, http.HandlerFunc(handlerFunc))
		mux.Handle(pattern, handler)
	}

	// Register handlers.
	handleFunc("/rolldice/", rolldice)
	handleFunc("/rolldice/{player}", rolldice)

	// Add HTTP instrumentation for the whole server.
	handler := otelhttp.NewHandler(mux, "/")
	return handler
}
`,
    code4: `export OTEL_SERVICE_NAME="your-service-name"
export OTEL_EXPORTER_OTLP_PROTOCOL=http/protobuf
export OTEL_EXPORTER_OTLP_ENDPOINT="${endpoint}"
export OTEL_EXPORTER_OTLP_HEADERS="x-tracetest-token=${token}"

go run APP.go`,
  };
}

const Go = ({endpoint, token}: IMethodChildrenProps) => {
  const {code1, code2, code3, code4} = getCodeBlocks(endpoint, token);

  return (
    <S.Collapse accordion ghost defaultActiveKey="1" expandIconPosition="right">
      <Collapse.Panel header={<S.StepTitle level={3}>1. Add dependencies</S.StepTitle>} key="1">
        <S.StepText>Install the OpenTelemetry SDK components and net/http instrumentation.</S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code1} language="bash" title="Add dependencies" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
      <Collapse.Panel header={<S.StepTitle level={3}>2. Initialize the OpenTelemetry SDK</S.StepTitle>} key="2">
        <S.StepText>Create a file named otel.go, which will contain your instrumentation setup code.</S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code2} language="go" title="Initialize" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
      <Collapse.Panel header={<S.StepTitle level={3}>3. Instrument the HTTP server</S.StepTitle>} key="3">
        <S.StepText>
          Modify main.go to include code that sets up OpenTelemetry SDK and instruments the HTTP server using the
          otelhttp instrumentation library.
        </S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code3} language="go" title="Run the app" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
      <Collapse.Panel header={<S.StepTitle level={3}>4. Run the instrumented app</S.StepTitle>} key="4">
        <S.StepText>Build and run the application with the following command.</S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code4} language="bash" title="Run the app" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
    </S.Collapse>
  );
};

export default Go;
