import {
  DashboardOutlined,
  FolderOpenOutlined,
  KeyOutlined,
  SendOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import {ORGANIZATION_MENU_KEYS} from '@constants/organizationmenu.constants';
import {OrganizationOperation, useAuthz} from '@hooks/authz';
import {Menu} from 'antd';
import {useMemo} from 'react';
import {Link, useLocation} from 'react-router-dom';

const menuItems = [
  {
    key: ORGANIZATION_MENU_KEYS.environments,
    icon: <FolderOpenOutlined />,
    label: <Link to={ORGANIZATION_MENU_KEYS.environments}>Environments</Link>,
    title: 'Environments',
  },
  {
    key: ORGANIZATION_MENU_KEYS.runs,
    icon: <DashboardOutlined />,
    label: <Link to={ORGANIZATION_MENU_KEYS.runs}>Runs</Link>,
    title: 'Runs',
  },
  {
    key: ORGANIZATION_MENU_KEYS.settings,
    icon: <SettingOutlined />,
    label: <Link to={ORGANIZATION_MENU_KEYS.settings}>Settings</Link>,
    title: 'Settings',
  },
];

const editMenuItems = [
  {
    key: ORGANIZATION_MENU_KEYS.members,
    icon: <TeamOutlined />,
    label: <Link to={ORGANIZATION_MENU_KEYS.members}>Members</Link>,
    title: 'Members',
  },
  {
    key: ORGANIZATION_MENU_KEYS.invites,
    icon: <SendOutlined />,
    label: <Link to={ORGANIZATION_MENU_KEYS.invites}>Invites</Link>,
    title: 'Invites',
  },
  {
    key: ORGANIZATION_MENU_KEYS.tokens,
    icon: <KeyOutlined />,
    label: <Link to={ORGANIZATION_MENU_KEYS.tokens}>Tokens</Link>,
    title: 'Tokens',
  },
];

function getMenuKey(pathname: string): string {
  const activeKey = Object.values(ORGANIZATION_MENU_KEYS).find(path => pathname.includes(path));
  return activeKey || ORGANIZATION_MENU_KEYS.environments;
}

const OrganizationProtectedLayoutMenu = () => {
  const pathname = useLocation().pathname;
  const {checkOrganization} = useAuthz();
  const items = useMemo(
    () => (checkOrganization(OrganizationOperation.Edit) ? menuItems.toSpliced(2, 0, ...editMenuItems) : menuItems),
    [checkOrganization]
  );
  const activeMenuKey = useMemo(() => getMenuKey(pathname), [pathname]);

  return (
    <Menu
      defaultSelectedKeys={[activeMenuKey]}
      items={items}
      mode="inline"
      selectedKeys={[activeMenuKey]}
      theme="dark"
    />
  );
};

export default OrganizationProtectedLayoutMenu;
