import {Navigate, Outlet} from 'react-router-dom';
import Loading from '@components/organisms/Loading';
import {useCheckSession} from '@hooks/authn';
import {Suspense} from 'react';

const Layout = () => {
  const {isLoading, session} = useCheckSession();

  if (isLoading) {
    return <Loading />;
  }

  if (session) {
    return <Navigate to="/organizations/default" />;
  }

  return (
    <Suspense fallback={<div />}>
      <Outlet />
    </Suspense>
  );
};

export default Layout;
