import AllowButton, {Operation} from '@core/components/AllowButton';
import AgentModel from '@models/Agent.model';
import {Button, Tooltip} from 'antd';
import * as S from '../EnvironmentAgent.styled';
import Status, {TConnectionStatus} from '../Status';

function getAgentStatus(status: AgentModel['status'], isServerless: boolean): TConnectionStatus {
  if (status === 'Green' && isServerless) {
    return 'success';
  }

  if (status === 'Green' && !isServerless) {
    return 'conflict-serverless';
  }

  return 'loading';
}

interface IProps {
  agent: AgentModel;
  isLoading: boolean;
  isServerless: boolean;
  onNext?(skipSave?: boolean): void;
  onStart(): void;
  onStop(): void;
}

const Public = ({agent, isLoading, isServerless, onNext, onStart, onStop}: IProps) => {
  const status = getAgentStatus(agent?.status, isServerless);

  return (
    <div>
      <S.Section $marginBottom={16}>
        <S.Title level={3}>Run the Tracetest Agent in the Cloud</S.Title>
        <S.Text type="secondary">
          You need to make sure there are publicly facing web or api interfaces for your application that Tracetest can
          access to run tests against. Also confirm that you can either send traces to Tracetest or there is public
          access to your tracing backend.
        </S.Text>
      </S.Section>

      <S.Section>
        <S.Title level={3}>Click the launch button to start the public setup</S.Title>
        <AllowButton
          disabled={status === 'conflict-serverless'}
          ghost
          onClick={onStart}
          operation={Operation.Configure}
          type="primary"
        >
          {status !== 'success' ? 'Launch Public Agent' : 'Restart Public Agent'}
        </AllowButton>
      </S.Section>

      {status === 'success' && (
        <S.Section>
          <S.InfoAlert
            message={
              <>
                Your public setup is up and running. If you want to stop it{' '}
                <S.NoPaddingButton onClick={() => onStop()} type="link">
                  click here
                </S.NoPaddingButton>
                .
              </>
            }
            type="info"
            showIcon
          />
        </S.Section>
      )}

      {onNext && (
        <S.Footer>
          <Status status={status} />
          <Tooltip title={status !== 'success' ? 'You need to launch the public setup to continue' : ''}>
            <Button disabled={status !== 'success'} loading={isLoading} onClick={() => onNext()} type="primary">
              Continue
            </Button>
          </Tooltip>
        </S.Footer>
      )}
    </div>
  );
};

export default Public;
