import Panel from '@components/molecules/Panel';
import {EnvironmentOperation, useAuthz} from '@hooks/authz';
import {useConfirmationModal} from '@core/providers/ConfirmationModal/ConfirmationModal.provider';
import {useNotification} from '@core/providers/Notification/Notification.provider';
import {useEnvironment} from '@providers/Environment.provider';
import {skipToken} from '@reduxjs/toolkit/query';
import {Typography} from 'antd';
import {useState} from 'react';
import {capitalize} from 'lodash';
import {useOrganization} from '@providers/Organization.provider';
import {useListJoinRequestsQuery, useUpdateJoinRequestMutation} from '@redux/apis/TracetestCloud';
import JoinRequest from '@models/JoinRequest.model';
import User from '@models/User.model';
import {PAGE_SIZE} from '@constants/list.constants';
import EnvironmentJoinRequestList from '../EnvironmentJoinRequestList';

const EnvironmentJoinRequests = () => {
  const {environment} = useEnvironment();
  const {organization} = useOrganization();
  const {showNotification} = useNotification();
  const {onOpen} = useConfirmationModal();
  const {checkEnvironment} = useAuthz();
  const canEdit = checkEnvironment(EnvironmentOperation.Edit);

  // update
  const [update] = useUpdateJoinRequestMutation();
  const handleUpdate = (joinRequest: JoinRequest) => {
    const onConfirm = async () => {
      await update({orgId: organization?.id ?? '', envId: environment?.id ?? '', joinRequest});
      showNotification({
        type: 'success',
        title: 'Join Request updated successfully',
      });
    };

    const statusText = joinRequest.status === 'accepted' ? 'accept' : 'reject';
    onOpen({
      heading: `${capitalize(statusText)} Join Request`,
      title: `Are you sure you want to ${statusText} "${User.getEmail(joinRequest.user)}" join request?`,
      okText: capitalize(statusText),
      onConfirm,
    });
  };

  // fetch
  const [skip, setSkip] = useState(0);
  const {data: {items: joinRequests = [], total = 0} = {}, isLoading} = useListJoinRequestsQuery(
    environment?.id ? {take: PAGE_SIZE, skip, envId: environment.id, orgId: organization?.id ?? ''} : skipToken
  );

  return (
    <Panel title="Join Requests">
      <Typography.Paragraph type="secondary">
        <p>Environment Join Requests are petitions of users in your organization to be part of the environment.</p>
      </Typography.Paragraph>
      <EnvironmentJoinRequestList
        canEdit={canEdit}
        isLoading={isLoading}
        joinRequests={joinRequests}
        onPageChange={(page, pageSize) => setSkip((page - 1) * pageSize)}
        onUpdate={handleUpdate}
        pageSize={PAGE_SIZE}
        total={total}
      />
    </Panel>
  );
};

export default EnvironmentJoinRequests;
