import {TBaseFilters, TCollection, TPaginatedCollection} from '@core/types/Common.types';
import Invite, {TRawInvite, TRawInviteResource} from '@models/Invite.model';
import Member, {TRawMember} from '@models/Member.model';
import Token, {TRawToken} from '@models/Token.model';
import {PaginationResponse} from '@core/hooks/usePagination';
import {PAGE_SIZE} from '@constants/list.constants';
import Organization, {TRawOrganization} from '@models/Organization.model';
import {HTTP_METHOD} from '@core/constants/Common.constants';
import TracetestCloudAPI, {TAG_TYPES} from '../TracetestCloud.api';

const OrganizationEndpoint = TracetestCloudAPI.injectEndpoints({
  endpoints: builder => ({
    /**
     * Organizations
     */
    getOrganization: builder.query<Organization, {orgId: string}>({
      query: ({orgId}) => ({
        url: `/organizations/${orgId}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.ORGANIZATION],
      transformResponse: (raw: TRawOrganization) => {
        return Organization(raw);
      },
    }),
    getOrganizations: builder.query<Organization[], {}>({
      query: () => ({
        url: '/organizations',
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.ORGANIZATION_LIST],
      transformResponse: (response: TCollection<TRawOrganization>) => {
        return response?.elements?.map(rawOrganization => Organization(rawOrganization)) ?? [];
      },
    }),
    createOrganization: builder.mutation<Organization, {organization: TRawOrganization}>({
      query: ({organization}) => ({
        url: `/organizations`,
        method: HTTP_METHOD.POST,
        body: organization,
      }),
      invalidatesTags: [{type: TAG_TYPES.ORGANIZATION_LIST}],
      transformResponse: (raw: TRawOrganization) => Organization(raw),
    }),
    updateOrganization: builder.mutation<undefined, {organization: TRawOrganization}>({
      query: ({organization}) => ({
        url: `/organizations/${organization.id}`,
        method: HTTP_METHOD.PATCH,
        body: organization,
      }),
      invalidatesTags: [{type: TAG_TYPES.ORGANIZATION}, {type: TAG_TYPES.ORGANIZATION_LIST}],
    }),
    deleteOrganization: builder.mutation<undefined, {orgId: string}>({
      query: ({orgId}) => ({
        url: `/organizations/${orgId}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: [{type: TAG_TYPES.ORGANIZATION_LIST}],
    }),

    /**
     * Members
     */
    getMembers: builder.query<PaginationResponse<Member>, {orgId: string; skip?: number; take?: number}>({
      query: ({orgId, take = PAGE_SIZE, skip = 0}) => ({
        url: `/organizations/${orgId}/members?${new URLSearchParams({skip: skip.toString(), take: take.toString()})}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.MEMBER],
      transformResponse: ({items = [], count}: TPaginatedCollection<TRawMember>) => ({
        items: items.map(rawMember => Member(rawMember)) ?? [],
        total: count,
      }),
    }),
    getMembersMe: builder.query<Member, {orgId: string}>({
      query: ({orgId}) => ({
        url: `/organizations/${orgId}/members/me`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.MEMBER_ME],
      transformResponse: (response: TRawMember) => {
        return Member(response);
      },
    }),
    deleteMembersMe: builder.mutation<undefined, {orgId: string}>({
      query: ({orgId}) => ({
        url: `/organizations/${orgId}/members/me`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: [{type: TAG_TYPES.ORGANIZATION_LIST}],
    }),

    deleteMember: builder.mutation<undefined, {memId: string; orgId: string}>({
      query: ({memId, orgId}) => ({
        url: `/organizations/${orgId}/members/${memId}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: [{type: TAG_TYPES.MEMBER}],
    }),
    updateMember: builder.mutation<undefined, {memId: string; orgId: string; role: string}>({
      query: ({memId, orgId, role}) => ({
        url: `/organizations/${orgId}/members/${memId}`,
        method: HTTP_METHOD.PATCH,
        body: {role},
      }),
      invalidatesTags: [{type: TAG_TYPES.MEMBER}],
    }),

    /**
     * Invites
     */
    getInvites: builder.query<Invite[], {orgId: string}>({
      query: () => ({
        url: `/invites`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.INVITE_LIST],
      transformResponse: (response: TPaginatedCollection<TRawInviteResource>) => {
        return response?.items?.map(rawInvite => Invite.fromResource(rawInvite)) ?? [];
      },
    }),
    getInvite: builder.query<Invite, {orgId: string; invId: string}>({
      query: ({invId, orgId}) => ({
        url: `organizations/${orgId}/invites/${invId}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.INVITE],
      transformResponse: (raw: TRawInvite) => Invite(raw),
    }),
    createInvite: builder.mutation<Invite, {invite: TRawInvite; orgId: string}>({
      query: ({invite}) => ({
        url: `/invites`,
        method: HTTP_METHOD.POST,
        body: Invite.toResource(invite),
      }),
      invalidatesTags: [{type: TAG_TYPES.INVITE_LIST}],
      transformResponse: (response: TRawInviteResource) => Invite.fromResource(response),
    }),

    resendInvite: builder.mutation<undefined, {invId: string; orgId: string}>({
      query: ({invId, orgId}) => ({
        url: `/organizations/${orgId}/invites/${invId}/resend`,
        method: HTTP_METHOD.POST,
      }),
      // invalidatesTags: [{type: TAG_TYPES.INVITE}],
    }),
    acceptInvite: builder.query<Invite, {invId: string; orgId: string}>({
      query: ({invId, orgId}) => ({
        url: `/organizations/${orgId}/invites/${invId}/accept`,
        method: HTTP_METHOD.GET,
      }),
      transformResponse: (response: TRawInvite) => Invite(response),
    }),
    revokeInvite: builder.mutation<undefined, {invId: string; orgId: string}>({
      query: ({invId}) => ({
        url: `/invites/${invId}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: [{type: TAG_TYPES.INVITE_LIST}],
    }),

    /**
     * Tokens
     */
    listOrgTokens: builder.query<PaginationResponse<Token>, TBaseFilters & {orgId: string}>({
      query: ({orgId, query = '', skip = 0, take = 20}) => ({
        url: `/organizations/${orgId}/tokens?${new URLSearchParams({
          skip: skip.toString(),
          take: take.toString(),
          query,
          sortBy: 'issuedat',
          sortDirection: 'desc',
        })}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.ORG_TOKEN_LIST],
      transformResponse: ({items = [], count = 0}: TPaginatedCollection<TRawToken>) => ({
        items: items?.map(rawToken => Token(rawToken)) ?? [],
        total: count,
      }),
    }),
    createOrgToken: builder.mutation<Token, {token: TRawToken; orgId: string}>({
      query: ({token, orgId}) => ({
        url: `/organizations/${orgId}/tokens`,
        method: HTTP_METHOD.POST,
        body: token,
      }),
      invalidatesTags: [{type: TAG_TYPES.ORG_TOKEN_LIST}],
    }),
    deleteOrgToken: builder.mutation<undefined, {tokenId: string; orgId: string}>({
      query: ({tokenId, orgId}) => ({
        url: `/organizations/${orgId}/tokens/${tokenId}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: [{type: TAG_TYPES.ORG_TOKEN_LIST}],
    }),
  }),
});

export const {
  useAcceptInviteQuery,
  useCreateInviteMutation,
  useCreateOrganizationMutation,
  useDeleteMemberMutation,
  useDeleteMembersMeMutation,
  useDeleteOrganizationMutation,
  useGetInviteQuery,
  useGetInvitesQuery,
  useGetMembersMeQuery,
  useGetMembersQuery,
  useGetOrganizationQuery,
  useGetOrganizationsQuery,
  useLazyAcceptInviteQuery,
  useLazyGetOrganizationsQuery,
  useResendInviteMutation,
  useRevokeInviteMutation,
  useUpdateMemberMutation,
  useUpdateOrganizationMutation,
  useCreateOrgTokenMutation,
  useDeleteOrgTokenMutation,
  useListOrgTokensQuery,
  useLazyGetMembersMeQuery,
} = OrganizationEndpoint;
