import {Model, TJoinRequestSchemas} from '@core/types/Common.types';
import User from './User.model';

export type TRawJoinRequest = TJoinRequestSchemas['JoinRequest'];
export type TJoinRequeststatus = TRawJoinRequest['status'];

type JoinRequest = Model<TRawJoinRequest, {}>;

const defaultJoinRequest: TRawJoinRequest = {
  id: '',
  memberId: '',
  createdAt: '',
  updatedAt: '',
  status: 'pending',
};

const JoinRequest = ({
  id = '',
  memberId = '',
  createdAt = '',
  updatedAt = '',
  status = 'pending',
  user,
} = defaultJoinRequest): JoinRequest => ({
  id,
  memberId,
  createdAt,
  updatedAt,
  status,
  user: User(user),
});

export default JoinRequest;
