import {BulbFilled} from '@ant-design/icons';
import {Typography} from 'antd';

const Explanation = () => (
  <div>
    <Typography.Title type="secondary" level={3}>
      <BulbFilled /> What are Organizations?
    </Typography.Title>
    <Typography.Paragraph type="secondary">
      An organization is an entity that contains users, environments, and the tests and test runs used. It is also the
      level billing for paid accounts is managed at.
    </Typography.Paragraph>
    <Typography.Paragraph type="secondary">
      Typically, a company would only have one organization. Read more <a href="">here</a>
    </Typography.Paragraph>
  </div>
);

export default Explanation;
