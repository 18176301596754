import ContentResources from '@core/components/Resources/Content';
import HeaderResources from '@core/components/Resources/Header';
import Header from '@core/components/Wizard/Header';
import Content from '@core/components/Wizard/Content';
import {useWizard} from '@core/providers/Wizard';
import * as S from '@core/pages/Wizard/Wizard.styled';
import {Collapse} from 'antd';
import * as SH from './HomeContent.styled';

const {REACT_APP_DEMO_ORG_INVITE_URL = ''} = process.env;

const Wizard = () => {
  const {activeStepId, isLoading, onGoTo, onNext, steps} = useWizard();
  const completedSteps = steps.filter(({state}) => state === 'completed').length;
  const isWizardComplete = !!steps.length && completedSteps === steps.length;

  return (
    <S.Container>
      <S.Header>
        <S.Title>Welcome to Tracetest!</S.Title>
        <S.Text>
          Here&apos;s a guide to get started and help you test your modern applications with OpenTelemetry.
        </S.Text>
      </S.Header>

      {!!steps.length && (
        <>
          <Collapse defaultActiveKey={!isWizardComplete ? 'wizard' : ''}>
            <Collapse.Panel
              header={<Header activeStep={completedSteps} totalCompleteSteps={steps.length} />}
              key="wizard"
            >
              <Content
                activeStepId={activeStepId}
                isLoading={isLoading}
                onChange={onGoTo}
                onNext={onNext}
                steps={steps}
              />
              <SH.DemoBanner>
                <SH.Text>Not sure how to get started? </SH.Text>
                <SH.Link href={REACT_APP_DEMO_ORG_INVITE_URL}>Join our Demo organization</SH.Link>
              </SH.DemoBanner>
            </Collapse.Panel>
          </Collapse>

          <Collapse defaultActiveKey={isWizardComplete ? 'resources' : ''}>
            <Collapse.Panel header={<HeaderResources />} key="resources">
              <ContentResources />
            </Collapse.Panel>
          </Collapse>
        </>
      )}
    </S.Container>
  );
};

export default Wizard;
