import {Col, Form, Row, Select} from 'antd';
import {HTTP_METHOD} from '@core/constants/Common.constants';
import {DockerTip} from '@core/components/Inputs';
import noop from 'lodash/noop';
import SingleLine from '../../Inputs/SingleLine';

interface IProps {
  label?: string;
  marginBottom?: number;
  name?: string | (string | number)[];
  methodName?: string | (string | number)[];
  showMethodSelector?: boolean;
  placeholder?: string;
  onFocus?(): void;
  onBlur?(event: React.FocusEvent<HTMLDivElement>): void;
}

const URL = ({
  label,
  placeholder = 'Enter URL',
  marginBottom = 0,
  name = 'url',
  methodName = 'method',
  showMethodSelector = true,
  onFocus = noop,
  onBlur = noop,
}: IProps) => (
  <>
    <Row>
      {showMethodSelector && (
        <Col span={3}>
          <Form.Item name={methodName} initialValue={HTTP_METHOD.GET} valuePropName="value" style={{marginBottom: 0}}>
            <Select
              data-cy="method-select"
              filterOption={(input, option) => option?.key?.toLowerCase().includes(input.toLowerCase())}
              showSearch
            >
              {Object.keys(HTTP_METHOD).map(method => {
                return (
                  <Select.Option data-cy={`method-select-option-${method}`} key={method} value={method}>
                    {method}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      )}

      <Col span={showMethodSelector ? 21 : 24}>
        <Form.Item
          data-cy="url"
          name={name}
          rules={[{required: true, message: 'Please enter a valid URL'}]}
          style={{marginBottom}}
          label={label}
        >
          <SingleLine onFocus={onFocus} onBlur={onBlur} placeholder={placeholder} />
        </Form.Item>
      </Col>
    </Row>

    <Form.Item name={name} style={{marginBottom: 0}} noStyle>
      <DockerTip />
    </Form.Item>
  </>
);

export default URL;
