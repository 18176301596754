import {EnvironmentOperation, useAuthz} from '@hooks/authz';
import {useEnvironment} from '@providers/Environment.provider';
import {useOrganization} from '@providers/Organization.provider';
import {useOrganizations} from '@providers/Organizations.provider';
import Resources from './Resources';
import ResourcesDemo from './ResourcesDemo';
import Wizard from './Wizard';

const {REACT_APP_DEMO_ORG_ID = ''} = process.env;

const HomeContent = () => {
  const {checkEnvironment} = useAuthz();
  const canConfigure = checkEnvironment(EnvironmentOperation.Configure);
  const {organization} = useOrganization();
  const {openOrganizationModal} = useOrganizations();
  const {environment} = useEnvironment();

  if (canConfigure) {
    return <Wizard />;
  }

  return REACT_APP_DEMO_ORG_ID === organization?.id ? (
    <ResourcesDemo environmentName={environment?.name ?? ''} openOrganizationModal={openOrganizationModal} />
  ) : (
    <Resources />
  );
};

export default HomeContent;
