import DataStore, {fromType} from '@core/models/DataStore.model';
import {useDataStore} from '@core/providers/DataStore/DataStore.provider';
import {ConfigMode, SupportedDataStores} from '@core/types/DataStore.types';
import {Switch} from 'antd';
import {useState} from 'react';
import * as S from './DataStore.styled';
import Configuration from './Configuration';
import Selector from './Selector';

interface IProps {
  defaultDataStore: DataStore;
  mode: ConfigMode;
}

const Content = ({defaultDataStore, mode}: IProps) => {
  const [isTraceIngestionEnabled, setIsTraceIngestionEnabled] = useState(mode === ConfigMode.READY);
  const [selectedTracingBackend, setSelectedTracingBackend] = useState<DataStore | undefined>();
  const {onDeleteConfig} = useDataStore();

  const onSwitchChange = (checked: boolean) => {
    if (checked) {
      setIsTraceIngestionEnabled(true);
      return;
    }

    onDeleteConfig(() => {
      setSelectedTracingBackend(undefined);
      setIsTraceIngestionEnabled(false);
    });
  };

  const onTracingBackendSelect = (type: SupportedDataStores) => {
    setSelectedTracingBackend(type === defaultDataStore.type ? defaultDataStore : fromType(type));
  };

  return (
    <>
      <S.SwitchContainer>
        <Switch onChange={onSwitchChange} checked={isTraceIngestionEnabled} />
        <S.SwitchLabel onClick={() => onSwitchChange(!isTraceIngestionEnabled)}>Enable Trace Ingestion</S.SwitchLabel>
      </S.SwitchContainer>

      <S.Container>
        {!isTraceIngestionEnabled && <S.Overlay />}

        {selectedTracingBackend ? (
          <Configuration
            dataStore={selectedTracingBackend}
            onBack={() => setSelectedTracingBackend(undefined)}
            onNext={() => {}}
          />
        ) : (
          <Selector
            configMode={mode}
            onSelect={onTracingBackendSelect}
            selectedBackend={mode === ConfigMode.READY ? (defaultDataStore.type as SupportedDataStores) : undefined}
          />
        )}
      </S.Container>
    </>
  );
};

export default Content;
