import {configureStore} from '@reduxjs/toolkit';
import {createReduxHistoryContext} from 'redux-first-history';
import {createBrowserHistory} from 'history';
import TracetestCloudAPI from '@redux/apis/TracetestCloud';
import {middlewares, reducers} from '@core/redux/setup';
import TracetestAPI from '@core/redux/apis/Tracetest/Tracetest.api';
import RouterMiddleware from '@core/redux/Router.middleware';

const {createReduxHistory, routerMiddleware, routerReducer} = createReduxHistoryContext({
  history: createBrowserHistory(),
});

export const store = configureStore({
  reducer: {
    [TracetestAPI.reducerPath]: TracetestAPI.reducer,
    ...reducers,
    router: routerReducer,
    [TracetestCloudAPI.reducerPath]: TracetestCloudAPI.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .prepend(RouterMiddleware.middleware)
      .concat([TracetestAPI.middleware, ...middlewares, TracetestCloudAPI.middleware, routerMiddleware]),
});

export const history = createReduxHistory(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
