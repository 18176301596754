import {FramedCodeBlock} from '@core/components/CodeBlock';
import {IMethodChildrenProps} from '@core/types/PublicOTLPMethod.types';
import {Collapse} from 'antd';
import * as S from '../common.styled';

function getCodeBlocks(endpoint?: string, token?: string) {
  return {
    code1: `pip install opentelemetry-distro`,
    code2: `opentelemetry-bootstrap -a install`,
    code3: `export OTEL_SERVICE_NAME=my-service-name
export OTEL_EXPORTER_OTLP_PROTOCOL=http/protobuf
export OTEL_EXPORTER_OTLP_ENDPOINT="${endpoint}"
export OTEL_EXPORTER_OTLP_HEADERS="x-tracetest-token=${token}"

opentelemetry-instrument python myapp.py
    `,
  };
}

const Python = ({endpoint, token}: IMethodChildrenProps) => {
  const {code1, code2, code3} = getCodeBlocks(endpoint, token);

  return (
    <S.Collapse accordion ghost defaultActiveKey="1" expandIconPosition="right">
      <Collapse.Panel header={<S.StepTitle level={3}>1. Install packages</S.StepTitle>} key="1">
        <S.StepText>
          Install the opentelemetry-distro package, which contains the OpenTelemetry API, SDK and also the tools
          opentelemetry-bootstrap and opentelemetry-instrument you will use below.
        </S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code1} language="bash" title="Install packages" minHeight="auto" />
        </S.CodeContainer>
        <S.StepText>Run the opentelemetry-bootstrap command:</S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code2} language="bash" title="opentelemetry-bootstrap" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
      <Collapse.Panel header={<S.StepTitle level={3}>2. Run the instrumented app</S.StepTitle>} key="2">
        <S.StepText>You can now run your instrumented app with opentelemetry-instrument.</S.StepText>
        <S.CodeContainer>
          <FramedCodeBlock value={code3} language="bash" title="Run the app" minHeight="auto" />
        </S.CodeContainer>
      </Collapse.Panel>
    </S.Collapse>
  );
};

export default Python;
