import {FramedCodeBlock} from '@core/components/CodeBlock';
import {Tabs} from 'antd';
import * as S from './CliInstallCommands.styled';

const AgentCommands = () => {
  const methods = [
    {
      id: 'mac',
      label: 'Mac',
      children: (
        <FramedCodeBlock
          title="CLI Install command:"
          language="bash"
          value="brew install kubeshop/tracetest/tracetest"
          minHeight="0px"
          maxHeight="70px"
        />
      ),
    },
    {
      id: 'linux',
      label: 'Linux',
      children: (
        <FramedCodeBlock
          title="CLI Install command:"
          language="bash"
          value="curl -L https://raw.githubusercontent.com/kubeshop/tracetest/main/install-cli.sh | bash"
          minHeight="0px"
          maxHeight="70px"
        />
      ),
    },
    {
      id: 'windows',
      label: 'Windows',
      children: (
        <FramedCodeBlock
          title="CLI Install command:"
          language="bash"
          value="choco source add --name=kubeshop_repo --source=https://chocolatey.kubeshop.io/chocolatey ; choco install tracetest"
          minHeight="0px"
          maxHeight="70px"
        />
      ),
    },
  ];

  return (
    <S.TabsContainer>
      <Tabs size="small">
        {methods.map(({id, label, children}) => (
          <Tabs.TabPane key={id} tab={label}>
            {children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </S.TabsContainer>
  );
};

export default AgentCommands;
