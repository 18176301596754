import {Typography} from 'antd';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Description = styled(Typography.Text)`
  && {
    color: ${({theme}) => theme.color.textSecondary};
    font-size: ${({theme}) => theme.size.sm};
    margin: 0;
  }
`;

export const Help = styled.div`
  margin-left: 52px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const SwitchItem = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const Text = styled(Typography.Text)`
  && {
    margin: 0;
  }
`;
