import {Typography} from 'antd';
import styled from 'styled-components';

export const Container = styled.div<{$danger?: boolean}>`
  background: ${({theme}) => theme.color.white};
  border: ${({theme, $danger}) =>
    $danger ? `1px solid ${theme.color.error}` : `1px solid ${theme.color.borderLight}`};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  padding: 24px;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Typography.Title)`
  && {
    margin: 0;
  }
`;
