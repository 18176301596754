import {HTTP_METHOD} from '@core/constants/Common.constants';
import {TracetestSources} from '@core/constants/Metadata.constants';
import {TracetestApiTags} from '@core/constants/Test.constants';
import {PaginationResponse} from '@core/hooks/usePagination';
import RunError from '@core/models/RunError.model';
import SearchSpansResult, {TRawSearchSpansResult} from '@core/models/SearchSpansResult.model';
import TestRun, {TRawTestRun} from '@core/models/TestRun.model';
import Trace, {TRawTrace} from '@core/models/Trace.model';
import TraceMetadata, {TRawListTracesResponse} from '@core/models/TraceMetadata.model';
import {TVariableSetValue} from '@core/models/VariableSet.model';
import {TBaseFilters} from '@core/types/Common.types';
import {TTestApiEndpointBuilder} from '../Tracetest.api';

const defaultHeaders = {'content-type': 'application/json'};

export const traceEndpoints = (builder: TTestApiEndpointBuilder) => ({
  getTraces: builder.query<PaginationResponse<TraceMetadata>, TBaseFilters>({
    query: ({take = 25, skip = 0, query = ''}) => ({
      url: `/traces?${new URLSearchParams({
        take: take.toString(),
        skip: skip.toString(),
        query,
      })}`,
      headers: defaultHeaders,
    }),
    providesTags: () => [{type: TracetestApiTags.TRACE, id: 'LIST'}],
    transformResponse: ({items = [], count = 0}: TRawListTracesResponse) => ({
      items: items.map(rawTraceMetadata => TraceMetadata(rawTraceMetadata)) ?? [],
      total: count,
    }),
  }),

  getTrace: builder.query<Trace, {traceId: string}>({
    query: ({traceId}) => ({
      url: `/traces/${traceId}`,
      headers: defaultHeaders,
    }),
    providesTags: result => [{type: TracetestApiTags.TRACE, id: result?.traceId}],
    transformResponse: (rawTrace: TRawTrace) => Trace(rawTrace),
  }),

  searchTraceSpans: builder.mutation<SearchSpansResult, {query: string; traceId: string}>({
    query: ({query, traceId}) => ({
      url: `/traces/${traceId}/search-spans`,
      method: HTTP_METHOD.POST,
      body: JSON.stringify({query}),
    }),
    transformResponse: (rawSearchSpansResult: TRawSearchSpansResult) => SearchSpansResult(rawSearchSpansResult),
  }),

  runTrace: builder.mutation<TestRun, {traceId: string; variables?: TVariableSetValue[]}>({
    query: ({traceId, variables = []}) => ({
      url: `/traces/${traceId}/run`,
      method: HTTP_METHOD.POST,
      body: {
        variables,
        metadata: {
          'tracetest.source': TracetestSources.Web,
        },
      },
    }),
    invalidatesTags: () => [
      {type: TracetestApiTags.TEST, id: 'LIST'},
      {type: TracetestApiTags.RESOURCE, id: 'LIST'},
    ],
    transformResponse: (rawTestRun: TRawTestRun) => TestRun(rawTestRun),
    transformErrorResponse: ({data: result}) => RunError(result),
  }),
});
