import Panel from '@components/molecules/Panel';
import LimitedAccess from '@components/organisms/LimitedAccess';
import DocsBanner from '@core/components/DocsBanner';
import {DEMO_DOCUMENTATION_URL, OTEL_DEMO_GITHUB, POKESHOP_GITHUB} from '@core/constants/Common.constants';
import {useSettingsValues} from '@core/providers/SettingsValues/SettingsValues.provider';
import SettingService from '@core/services/Setting.service';
import {useEnvironment} from '@providers/Environment.provider';
import {Descriptions, Typography} from 'antd';
import * as S from '../Settings.styled';

const LimitedDemo = () => {
  const {environment, member} = useEnvironment();
  const {demos} = useSettingsValues();
  const publicInfo = SettingService.getDemoFormInitialValues(demos);

  return (
    <Panel title="Demo">
      <Typography.Paragraph type="secondary">
        <p>
          Tracetest has the option to enable Test examples for our{' '}
          <a href={POKESHOP_GITHUB} target="_blank">
            Pokeshop Demo App
          </a>{' '}
          or the{' '}
          <a href={OTEL_DEMO_GITHUB} target="_blank">
            OpenTelemetry Astronomy Shop Demo
          </a>
          . You will require an instance of those applications running alongside your Tracetest server to be able to use
          them.
        </p>
        <DocsBanner>
          Need more information about the Demos?{' '}
          <a href={DEMO_DOCUMENTATION_URL} target="__blank">
            Learn more in our docs
          </a>
        </DocsBanner>
      </Typography.Paragraph>

      <LimitedAccess
        description="To configure the Demo settings, please contact the environment administrator for assistance."
        organizationID={environment?.organizationID}
        role={member.role}
      />

      <S.Divider />

      <div>
        <Typography.Title level={3}>Current Demo settings:</Typography.Title>

        <S.Descriptions size="small" column={1}>
          <Descriptions.Item label={<b>Pokeshop</b>}>
            {publicInfo?.pokeshop?.enabled ? 'Enabled' : 'Disabled'}
          </Descriptions.Item>

          {publicInfo?.pokeshop?.enabled && (
            <>
              <Descriptions.Item label="HTTP Endpoint">
                {publicInfo?.pokeshop?.pokeshop?.httpEndpoint}
              </Descriptions.Item>
              <Descriptions.Item label="GRPC Endpoint">
                {publicInfo?.pokeshop?.pokeshop?.grpcEndpoint}
              </Descriptions.Item>
              <Descriptions.Item label="Kafka Broker">{publicInfo?.pokeshop?.pokeshop?.kafkaBroker}</Descriptions.Item>
            </>
          )}

          <Descriptions.Item label={<b>OpenTelemetry Astronomy Shop Demo</b>}>
            {publicInfo?.opentelemetryStore?.enabled ? 'Enabled' : 'Disabled'}
          </Descriptions.Item>

          {publicInfo?.opentelemetryStore?.enabled && (
            <>
              <Descriptions.Item label="Frontend Endpoint">
                {publicInfo?.opentelemetryStore?.opentelemetryStore?.frontendEndpoint}
              </Descriptions.Item>
              <Descriptions.Item label="Cart Endpoint">
                {publicInfo?.opentelemetryStore?.opentelemetryStore?.cartEndpoint}
              </Descriptions.Item>
              <Descriptions.Item label="Product Catalog Endpoint">
                {publicInfo?.opentelemetryStore?.opentelemetryStore?.productCatalogEndpoint}
              </Descriptions.Item>
              <Descriptions.Item label="Checkout Endpoint">
                {publicInfo?.opentelemetryStore?.opentelemetryStore?.checkoutEndpoint}
              </Descriptions.Item>
            </>
          )}
        </S.Descriptions>
      </div>
    </Panel>
  );
};

export default LimitedDemo;
