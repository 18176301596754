import {Button, Space, Typography} from 'antd';
import * as S from './CreateButton.styled';

interface IProps {
  onClick(): void;
}

const AgentAlert = ({onClick}: IProps) => (
  <Space align="start">
    <S.WarningIcon />
    <S.Content>
      <Typography.Text>
        Your agent is currently disconnected. To create tests, the agent needs to be active and ready to collect
        response and trace information. Please start the agent.
      </Typography.Text>
      <S.Footer>
        <Button ghost onClick={onClick} size="small" type="primary">
          Setup Agent
        </Button>
      </S.Footer>
    </S.Content>
  </Space>
);

export default AgentAlert;
