import Configuration from '@core/components/Settings/DataStore/Configuration';
import Selector from '@core/components/Settings/DataStore/Selector';
import DataStore, {fromType} from '@core/models/DataStore.model';
import {useSettingsValues} from '@core/providers/SettingsValues/SettingsValues.provider';
import WizardAnalytics from '@core/services/Analytics/Wizard.service';
import {ConfigMode, SupportedDataStores} from '@core/types/DataStore.types';
import {IWizardStepComponentProps} from '@core/types/Wizard.types';
import {useCallback, useState} from 'react';

const TracingBackend = ({onNext}: IWizardStepComponentProps) => {
  const {
    dataStoreConfig: {defaultDataStore, mode},
  } = useSettingsValues();
  const [selectedTracingBackend, setSelectedTracingBackend] = useState<DataStore | undefined>();

  const handleOnSelect = useCallback(
    type => {
      setSelectedTracingBackend(type === defaultDataStore.type ? defaultDataStore : fromType(type));
      WizardAnalytics.onTracingBackendTypeSelect(type);
    },
    [defaultDataStore]
  );

  return selectedTracingBackend ? (
    <Configuration
      dataStore={selectedTracingBackend}
      isWizard
      onBack={() => setSelectedTracingBackend(undefined)}
      onNext={onNext}
    />
  ) : (
    <Selector
      configMode={mode}
      isWizard
      onSelect={handleOnSelect}
      onSkip={onNext}
      selectedBackend={mode === ConfigMode.READY ? (defaultDataStore.type as SupportedDataStores) : undefined}
    />
  );
};

export default TracingBackend;
