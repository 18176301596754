import {COMMUNITY_SLACK_URL} from '@core/constants/Common.constants';
import Footer from './Footer';
import * as S from './LicenseExpirationModal.styled';

interface IProps {
  isOpen: boolean;
  onClose(): void;
}

const LicenseExpirationModal = ({isOpen, onClose}: IProps) => (
  <S.Modal
    footer={<Footer onClose={onClose} />}
    onCancel={onClose}
    title={<S.Title data-testid="license-expiration-modal">License Expiration Warning</S.Title>}
    visible={isOpen}
  >
    Do you need more time? Don&apos;t skip a beat and get in contact with the{' '}
    <a href={COMMUNITY_SLACK_URL}>Tracetest team to renew your license</a>.
  </S.Modal>
);

export default LicenseExpirationModal;
