import Panel from '@components/molecules/Panel';
import {useMemo} from 'react';
import EnvironmentMember from '@components/organisms/EnvironmentMember';
import {ROLES_AND_PERMISSIONS_DOCUMENTATION} from '@constants/url.constants';
import {useCreateEnvironmentMemberMutation, useGetMembersQuery} from '@redux/apis/TracetestCloud';
import {useEnvironment} from '@providers/Environment.provider';
import DocsBanner from '@core/components/DocsBanner';
import {useNotification} from '@core/providers/Notification/Notification.provider';
import AssignMember from '@models/AssignMember.type';
import EnvironmentService from '@services/Environment.service';
import Member from '@models/Member.model';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {Typography} from 'antd';

interface IProps {
  members: Member[];
}

const AssignMemberPanel = ({members}: IProps) => {
  const {environment} = useEnvironment();
  const {showNotification} = useNotification();

  // create member
  const [createInvite, {isLoading: isCreateMemberLoading}] = useCreateEnvironmentMemberMutation();
  const handleCreateMember = async (values: AssignMember) => {
    await createInvite({envId: environment?.id ?? '', member: values, orgId: environment?.organizationID ?? ''});
    showNotification({
      type: 'success',
      title: 'Member added successfully',
    });
  };

  // fetch organization members
  const {data: {items: organizationMembers} = {items: [], total: 0}} = useGetMembersQuery(
    environment?.organizationID ? {orgId: environment.organizationID} : skipToken
  );

  const assignableMembers = useMemo(
    () => EnvironmentService.getAssignableMembers(organizationMembers, members),
    [members, organizationMembers]
  );

  return (
    <Panel title="Add organization members to this environment">
      <Typography.Paragraph type="secondary">
        <p>
          Environmental members are people in your organization that have rights to take actions in this environment.
        </p>
        <DocsBanner>
          Need more information about Organization Members?{' '}
          <a href={ROLES_AND_PERMISSIONS_DOCUMENTATION} target="__blank">
            Learn more in our docs
          </a>
        </DocsBanner>
      </Typography.Paragraph>
      <EnvironmentMember
        assignableMembers={assignableMembers}
        isLoading={isCreateMemberLoading}
        onSubmit={handleCreateMember}
        orgId={environment?.organizationID ?? ''}
      />
    </Panel>
  );
};

export default AssignMemberPanel;
