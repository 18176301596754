import {BulbFilled} from '@ant-design/icons';
import {Typography} from 'antd';

const Explanation = () => (
  <div>
    <Typography.Title type="secondary" level={3}>
      <BulbFilled /> What are Environments?
    </Typography.Title>
    <Typography.Paragraph type="secondary">
      Environments map to the workspaces used to develop and test code inside your organization.
    </Typography.Paragraph>
    <Typography.Paragraph type="secondary">
      Typical shared environments may be &apos;Test&apos;, &apos;QA&apos;, or &apos;Staging&apos;. Each environment will
      run the Tracetest agent to enable tests to be triggered and traces to be gathered. Read more <a href="">here</a>.
    </Typography.Paragraph>
  </div>
);

export default Explanation;
