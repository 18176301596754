import LocalStorageGateway from '@core/gateways/LocalStorage.gateway';

interface IUserPreferences {
  orgId: string;
  envId: string;
  wasSelectorTooltipShown?: boolean;
  wasDemoSelectorShown?: boolean;
  showCreateOrgBanner?: boolean;
}

const storageKey = 'tc-user-preferences';
const localStorageGateway = LocalStorageGateway<IUserPreferences>(storageKey);
const defaultUserPreferences: IUserPreferences = {
  orgId: '',
  envId: '',
  wasSelectorTooltipShown: false,
  wasDemoSelectorShown: false,
  showCreateOrgBanner: true,
};

const UserPreferencesService = {
  /**
   * @description Get the user preferences collection from local storage
   */
  getCollection(): IUserPreferences {
    const userPreferences = localStorageGateway.get() || defaultUserPreferences;
    return {...defaultUserPreferences, ...userPreferences};
  },

  /**
   * @description Get a user preference by key
   * @param key - User preference key
   */
  get<K extends keyof IUserPreferences>(key: K): IUserPreferences[K] {
    const userPreferences = this.getCollection();
    return userPreferences[key];
  },

  /**
   * @description Set a user preference by key
   * @param key - User preference key
   * @param value - User preference value
   */
  set<K extends keyof IUserPreferences>(key: K, value: IUserPreferences[K]) {
    const userPreferences = this.getCollection();
    const updatedUserPreferences = {...userPreferences, [key]: value};
    localStorageGateway.set(updatedUserPreferences);
    return updatedUserPreferences;
  },

  clear() {
    localStorageGateway.set(defaultUserPreferences);
  },
};

export default UserPreferencesService;
