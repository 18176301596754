import {useWizard} from '@core/providers/Wizard';
import {useEnvironment} from '@providers/Environment.provider';
import {useEffect, useState} from 'react';

const AgentListener = () => {
  const {environment} = useEnvironment();
  const {onCompleteStep} = useWizard();

  // FIXME: This is a hack to prevent the onCompleteStep from being for environments that are not the current one
  const [prev, setPrev] = useState(environment?.id);
  const [prevStatus, setPrevStatus] = useState(environment?.agent.status);

  useEffect(() => {
    if (!!prev && prev === environment?.id && prevStatus === 'Red' && environment?.agent.status === 'Green') {
      onCompleteStep('agent');
    }

    setPrev(environment?.id);
    setPrevStatus(environment?.agent.status);
  }, [environment?.agent.status, environment?.id, onCompleteStep, prev, prevStatus]);

  return null;
};

export default AgentListener;
