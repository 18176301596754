import {TAgentMode} from '@hooks/agent/useAgent';
import Agent from '@models/Agent.model';
import Token from '@models/Token.model';
import ModeSelector from './ModeSelector';
import Private from './Private';
import Public from './Public';

interface IProps {
  agent: Agent;
  apiKey: string;
  environmentId: string;
  isLoading?: boolean;
  isLocal: boolean;
  isServerless: boolean;
  isWizard?: boolean;
  mode: TAgentMode;
  onNext?(): void;
  onStartPublicAgent(): void;
  onStopPublicAgent(): void;
  onToggleAgentMode: (mode: 'public' | 'private') => void;
  organizationId: string;
  token: Token;
}

const Content = ({
  agent,
  apiKey,
  environmentId,
  isLoading = false,
  isLocal,
  isServerless,
  isWizard = false,
  mode,
  onNext,
  onStartPublicAgent,
  onStopPublicAgent,
  onToggleAgentMode,
  organizationId,
  token,
}: IProps) => {
  return (
    <div>
      <ModeSelector isWizard={isWizard} mode={mode} onClick={onToggleAgentMode} />

      {mode === 'public' && (
        <Public
          agent={agent}
          isLoading={isLoading}
          isServerless={isServerless}
          onNext={onNext}
          onStart={onStartPublicAgent}
          onStop={onStopPublicAgent}
        />
      )}

      {mode === 'private' && (
        <Private
          agent={agent}
          apiKey={apiKey}
          environmentId={environmentId}
          isLoading={isLoading}
          isLocal={isLocal}
          isServerless={isServerless}
          onNext={onNext}
          organizationId={organizationId}
          token={token}
        />
      )}
    </div>
  );
};

export default Content;
