import {useConfirmationModal} from '@core/providers/ConfirmationModal/ConfirmationModal.provider';
import {useNotification} from '@core/providers/Notification/Notification.provider';
import Environment from '@models/Environment.model';
import EnvironmentAgent from '@models/EnvironmentAgent.model';
import Token from '@models/Token.model';
import {useEnvironment} from '@providers/Environment.provider';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetEnvironmentAgentQuery, useGetTokenQuery} from '@redux/apis/TracetestCloud';
import {useState} from 'react';

export type TAgentMode = 'public' | 'private' | undefined;

function getModeFromEnvironment(environment?: Environment): TAgentMode {
  if (!environment) return;
  if (environment.agentConfiguration.serverless) return 'public';
  if (environment.agent.status === 'Green') return 'private';
}

export const useAgent = () => {
  const {
    environment: {
      id: environmentId = '',
      organizationID: organizationId = '',
      agentConfiguration: {serverless},
    } = Environment(),
    environment,
    restartAgent,
    updateEnvironment,
  } = useEnvironment();

  const {data: {agent, key} = EnvironmentAgent()} = useGetEnvironmentAgentQuery(
    {
      envId: environmentId,
      orgId: organizationId,
    },
    {pollingInterval: 1000}
  );

  const {data: token = Token()} = useGetTokenQuery(key ? {tokenId: key} : skipToken);

  const [mode, setMode] = useState<TAgentMode>(() => getModeFromEnvironment(environment));

  const {onOpen} = useConfirmationModal();
  const {showNotification} = useNotification();

  const startPublicAgent = async () => {
    if (serverless) {
      await restartAgent();
      return;
    }

    await updateEnvironment({
      ...environment!,
      agentConfiguration: {...environment!.agentConfiguration, serverless: true},
    });
  };

  const stopPublicAgent = (onStop?: () => void) => {
    const onConfirm = async () => {
      await updateEnvironment({
        ...environment!,
        agentConfiguration: {...environment!.agentConfiguration, serverless: false},
      });
      showNotification({
        type: 'success',
        title: 'Public Agent has stopped successfully',
      });
      if (typeof onStop === 'function') {
        onStop?.();
      }
    };

    onOpen({
      heading: 'Stop Public Agent',
      title: 'Are you sure you want to stop the public agent?',
      okText: 'Stop',
      onConfirm,
    });
  };

  const toggleAgentMode = (modeParam: 'public' | 'private') => {
    if (serverless && modeParam === 'private') {
      stopPublicAgent(() => setMode(modeParam));
      return;
    }

    setMode(modeParam);
  };

  return {agent, isServerless: serverless, key, token, mode, startPublicAgent, stopPublicAgent, toggleAgentMode};
};
