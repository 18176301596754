import AgentCommands from '@components/molecules/AgentCommands';
import CliInstallCommands from '@components/molecules/CliInstallCommands';
import Link from '@core/components/Link';
import AgentModel from '@models/Agent.model';
import Token from '@models/Token.model';
import {useLicense} from '@providers/License.provider';
import {Alert, Button, Collapse, Tooltip} from 'antd';
import * as SP from './Private.styled';
import * as S from '../EnvironmentAgent.styled';
import Status, {TConnectionStatus} from '../Status';

function getAgentStatus(status: AgentModel['status'], isServerless: boolean): TConnectionStatus {
  if (status === 'Green' && !isServerless) {
    return 'success';
  }

  if (status === 'Green' && isServerless) {
    return 'conflict';
  }

  return 'loading';
}

interface IProps {
  agent: AgentModel;
  apiKey: string;
  environmentId: string;
  isLoading: boolean;
  isLocal: boolean;
  isServerless: boolean;
  onNext?(skipSave?: boolean): void;
  organizationId: string;
  token: Token;
}

const Private = ({
  agent,
  apiKey,
  environmentId,
  isLoading,
  isLocal,
  isServerless,
  onNext,
  organizationId,
  token,
}: IProps) => {
  const status = getAgentStatus(agent?.status, isServerless);
  const {isCertificateValid} = useLicense();

  return (
    <div>
      <S.Section $marginBottom={16}>
        <S.Title level={3}>Run this command to start the Tracetest Agent</S.Title>
        <S.Text type="secondary">
          If your application is running in a private network, you need to run an agent to enable Tracetest access to
          it. The agent also allows Tracetest to get or receive tracing information from your OTel Collector or tracing
          backend.
        </S.Text>
        <AgentCommands
          isCertificateValid={isCertificateValid}
          apiKey={apiKey}
          envId={environmentId}
          orgId={organizationId}
          isLocal={isLocal}
        />

        {Token.getIsExpired(token) && (
          <Alert
            message={
              <>
                The default agent token has expired. Please go to the{' '}
                <Link reloadDocument to="/settings?tab=tokens">
                  Tokens
                </Link>{' '}
                page to generate a new one.
              </>
            }
            type="error"
            style={{marginTop: 14}}
          />
        )}
      </S.Section>

      <S.Section>
        <SP.CollapseContainer>
          <Collapse ghost>
            <Collapse.Panel
              header={
                <S.Title level={3} $marginBottom={0}>
                  Install the Tracetest CLI
                </S.Title>
              }
              key="1"
            >
              <CliInstallCommands />
            </Collapse.Panel>
          </Collapse>
        </SP.CollapseContainer>
      </S.Section>

      {onNext && (
        <S.Footer>
          <Status status={status} />
          <Tooltip title={status !== 'success' ? 'You need to setup the Tracetest Agent to continue' : ''}>
            <Button disabled={status !== 'success'} loading={isLoading} onClick={() => onNext()} type="primary">
              Continue
            </Button>
          </Tooltip>
        </S.Footer>
      )}
    </div>
  );
};

export default Private;
