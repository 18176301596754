import Content from '@components/organisms/EnvironmentAgent/Content';
import WizardAnalytics from '@core/services/Analytics/Wizard.service';
import {IWizardStepComponentProps} from '@core/types/Wizard.types';
import {useAgent} from '@hooks/agent';
import Environment from '@models/Environment.model';
import {useEnvironment} from '@providers/Environment.provider';

const Agent = ({isLoading, onNext}: IWizardStepComponentProps) => {
  const {environment: {id: environmentId = '', organizationID: organizationId = '', isLocal = false} = Environment()} =
    useEnvironment();
  const {agent, isServerless, key, token, mode, startPublicAgent, stopPublicAgent, toggleAgentMode} = useAgent();

  return (
    <Content
      agent={agent}
      apiKey={key}
      environmentId={environmentId}
      isLoading={isLoading}
      isLocal={isLocal}
      isServerless={isServerless}
      isWizard
      mode={mode}
      onNext={onNext}
      onStartPublicAgent={startPublicAgent}
      onStopPublicAgent={stopPublicAgent}
      onToggleAgentMode={modeParam => {
        WizardAnalytics.onAgentTypeSelect(modeParam);
        toggleAgentMode(modeParam);
      }}
      organizationId={organizationId}
      token={token}
    />
  );
};

export default Agent;
