import useCliCommand from '@components/organisms/CLICommand/hooks/useCliCommand';
import ResourceDefinitionModal from '@components/organisms/ResourceDefinitionModal';
import {getOptions} from '@core/hooks/useCommand';
import useDefinitionFile from '@core/hooks/useDefinitionFile';
import {ResourceName, ResourceType} from '@core/types/Resource.type';
import {downloadFile} from '@core/utils/Common';
import noop from 'lodash/noop';
import snakeCase from 'lodash/snakeCase';
import {createContext, useCallback, useContext, useMemo, useState} from 'react';
import {useLicense} from './License.provider';

interface IContext {
  onOpenDefinition(resourceType: ResourceType, resourceId: string): void;
}

const Context = createContext<IContext>({
  onOpenDefinition: noop,
});

interface IProps {
  children: React.ReactNode;
}

export const ResourceDefinitionProvider = ({children}: IProps) => {
  const {isCertificateValid} = useLicense();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileName, setFileName] = useState('');
  const [title, setTitle] = useState('');
  const [command, setCommand] = useState('');
  const {definition, loadDefinition} = useDefinitionFile();
  const {configureCommand} = useCliCommand();
  const {serverUrl, skipVerify} = getOptions(isCertificateValid);

  const onOpenDefinition = useCallback(
    async (type: ResourceType, id: string) => {
      setIsModalOpen(true);
      loadDefinition(type, id);
      setFileName(`${snakeCase(id)}.yaml`);
      setTitle(`${ResourceName[type]} Definition`);
      const cmd = `tracetest apply ${type.slice(0, -1)} --file ${snakeCase(id)}.yaml`;
      setCommand(`${cmd}${serverUrl}${skipVerify}`);
    },
    [loadDefinition, serverUrl, skipVerify]
  );

  const value: IContext = useMemo(() => ({onOpenDefinition}), [onOpenDefinition]);

  return (
    <>
      <Context.Provider value={value}>{children}</Context.Provider>
      <ResourceDefinitionModal
        command={command}
        configureCommand={configureCommand}
        definition={definition}
        fileName={fileName}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onDownload={() => {
          downloadFile(definition, fileName);
        }}
        onFileNameChange={setFileName}
        title={title}
      />
    </>
  );
};

export const useResourceDefinition = () => useContext(Context);
