import '@utils/tracer';
import {Spin} from 'antd';
import {CustomTranslations, IntlProvider, ThemeProvider as OryThemeProvider} from '@ory/elements';
import DashboardWrapper from '@components/organisms/DashboardWrapper';
import Router from '@components/organisms/Router';
import {theme} from '@core/constants/Theme.constants';
import ConfirmationModalProvider from '@core/providers/ConfirmationModal';
import NotificationProvider from '@core/providers/Notification';
import {ReduxProvider} from '@redux/ReduxProvider';
import {history} from '@redux/store';
import {HistoryRouter} from 'redux-first-history/rr6';
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import LoadingList from '@components/molecules/LoadingList';
import {oryCustomTranslations} from '@constants/ory.constants';
import AnalyticsService from '@core/services/Analytics/Analytics.service';
import {LicenseProvider} from './providers/License.provider';

Spin.setDefaultIndicator(<LoadingList />);
AnalyticsService.load();

const App = () => (
  <StyledThemeProvider theme={theme}>
    <OryThemeProvider themeOverrides={{}}>
      <IntlProvider<CustomTranslations> customTranslations={oryCustomTranslations} defaultLocale="en" locale="en">
        <ReduxProvider>
          <HistoryRouter history={history} basename="">
            <LicenseProvider>
              <NotificationProvider>
                <ConfirmationModalProvider>
                  <DashboardWrapper>
                    <Router />
                  </DashboardWrapper>
                </ConfirmationModalProvider>
              </NotificationProvider>
            </LicenseProvider>
          </HistoryRouter>
        </ReduxProvider>
      </IntlProvider>
    </OryThemeProvider>
  </StyledThemeProvider>
);

export default App;
