import React from 'react';
import {Tooltip} from 'antd';
import useCopy from '@core/hooks/useCopy';
import * as S from './IdContainer.styled';

interface IProps {
  id: string;
  title?: React.ReactNode;
}

const IdContainer = ({id, title = ''}: IProps) => {
  const copy = useCopy();

  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <Tooltip title="Click to copy">
        <S.IDContainer onClick={() => copy(id)}>
          <S.IDText>{id}</S.IDText>
          <S.CopyIcon />
        </S.IDContainer>
      </Tooltip>
    </S.Container>
  );
};

export default IdContainer;
