import Link from '@core/components/Link';
import TooltipQuestion from '@core/components/TooltipQuestion';
import {useVariableSet} from '@core/providers/VariableSet';
import {Form, Select} from 'antd';
import * as S from '../SSL/SSL.styled';

const filterOption = (input: string, option: {children: string; label: string} | undefined) =>
  (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

const SelectVariableSet = () => {
  const {variableSetList} = useVariableSet();

  return (
    <S.SSLVerificationContainer>
      <Form.Item
        name="variableSetId"
        label={
          <>
            Variable Set{' '}
            <TooltipQuestion
              title={
                <span>
                  Create or edit Variable Sets <Link to="/variablesets">here</Link>.
                </span>
              }
            />
          </>
        }
        style={{width: '100%'}}
        valuePropName="value"
      >
        <Select allowClear filterOption={filterOption} placeholder="Select a Variable Set" showSearch>
          {variableSetList.map(({id, name}) => (
            <Select.Option key={id} value={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </S.SSLVerificationContainer>
  );
};

export default SelectVariableSet;
