import {Popover} from 'antd';
import IdContainer from '@components/atoms/IdContainer';
import * as S from './IdPopover.styled';

interface IProps {
  orgId: string;
  envId: string;
}

const IdPopover = ({orgId, envId}: IProps) => {
  const content = (
    <S.ContentContainer>
      <IdContainer id={orgId} title="Organization ID" />
      <IdContainer id={envId} title="Environment ID" />
    </S.ContentContainer>
  );

  return (
    <Popover content={content} trigger="click" placement="bottomLeft">
      <S.Trigger>Show org and env ID</S.Trigger>
    </Popover>
  );
};

export default IdPopover;
