import {Typography} from 'antd';
import styled from 'styled-components';

export const SetupText = styled(Typography.Text)`
  && {
    font-weight: 700;
  }
`;

export const TabsContainer = styled.div`
  .ant-tabs-nav {
    padding: 0 12px;
    margin-bottom: 0;
  }

  .ant-tabs-nav {
    padding: 0;
  }

  > .ant-tabs {
    gap: 24px;
  }
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
`;

export const Title = styled(Typography.Title)<{$marginBottom?: number}>`
  && {
    margin-bottom: ${({$marginBottom}) => $marginBottom ?? 8}px;
  }
`;
