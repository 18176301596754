import {Button} from 'antd';
import * as S from './OrganizationModal.styled';

interface IProps {
  isLoading: boolean;
  onClose(): void;
  onSubmit(): void;
}

const Footer = ({isLoading, onClose, onSubmit}: IProps) => (
  <S.Footer>
    <span>
      <Button type="primary" ghost onClick={onClose}>
        Cancel
      </Button>
    </span>
    <span>
      <Button
        data-testid="organization-modal-button-create"
        htmlType="submit"
        loading={isLoading}
        type="primary"
        onClick={onSubmit}
      >
        Create
      </Button>
    </span>
  </S.Footer>
);

export default Footer;
