import {Keys} from '@core/constants/Metadata.constants';
import {Model} from '@core/types/Common.types';

export type TRawRunMetadata = Record<string, string>;

type RunMetadata = Model<TRawRunMetadata, object>;

function RunMetadata(raw: TRawRunMetadata = {}, createdAt: string = '', executionTime: number = 0): RunMetadata {
  return {
    ...raw,
    ...(!!raw.agentStatus && {[Keys.TracetestEnvironmentAgentStatus]: raw.agentStatus}),
    ...(!!createdAt && {[Keys.TracetestRanAt]: createdAt}),
    ...(!!createdAt && {[Keys.TracetestCreatedAt]: createdAt}),
    ...(!!executionTime && {[Keys.TracetestExecutionTime]: `${executionTime}s`}),
    ...(!!raw.source && {[Keys.TracetestSource]: raw.source}),
    ...(!!raw.url && {[Keys.CIBuildUrl]: raw.url}),
    ...(!!raw.buildNumber && {[Keys.CIBuildNumber]: raw.buildNumber}),
  };
}

export default RunMetadata;
