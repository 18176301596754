import {PlusOutlined, TeamOutlined} from '@ant-design/icons';
import * as S from '@components/molecules/MenuSelector';
import Organization from '@models/Organization.model';
import {useOrganizations} from '@providers/Organizations.provider';
import {Dropdown, Menu} from 'antd';
import {useNavigate} from 'react-router-dom';
import Item from './Item';

interface IProps {
  organizations?: Organization[];
  organization?: Organization;
  onClick?(org: Organization): void;
  onCreateOrganization?(org: Organization): void;
}

const OrganizationSelector = ({organizations, organization, onClick, onCreateOrganization}: IProps) => {
  const {openOrganizationModal} = useOrganizations();
  const navigate = useNavigate();

  const onItemClick = (org: Organization) => {
    navigate(`/organizations/${org.id}`);
  };

  const organizationsMenu = (
    <S.Menu>
      {organizations?.map(org => (
        <Menu.Item key={org.id} onClick={() => (onClick ? onClick(org) : onItemClick(org))}>
          <Item organization={org} isSelected={org.id === organization?.id} />
        </Menu.Item>
      ))}
      <Menu.Divider />
      <Menu.Item key="add" onClick={() => openOrganizationModal(onCreateOrganization)}>
        <S.ItemContainer data-testid="organization-selector-new-org">
          <PlusOutlined />
          Create a New Organization
          <div />
        </S.ItemContainer>
      </Menu.Item>
    </S.Menu>
  );

  return (
    <Dropdown overlay={organizationsMenu} trigger={['click']}>
      <S.Button type="link" data-testid="organization-selector-button">
        <S.MenuSelector
          name={
            <S.SelectedItemText data-testid="menu-selector-name">
              {organization ? organization?.name ?? '' : <>Select Organization</>}
            </S.SelectedItemText>
          }
          icon={<TeamOutlined />}
        />
      </S.Button>
    </Dropdown>
  );
};

export default OrganizationSelector;
