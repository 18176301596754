import OrganizationModal from '@components/organisms/OrganizationModal';
import Organization, {TRawOrganization} from '@models/Organization.model';
import {useNotification} from '@core/providers/Notification/Notification.provider';
import {useGetOrganizationsQuery, useCreateOrganizationMutation} from '@redux/apis/TracetestCloud';
import OrganizationService from '@services/Organization.service';
import {noop} from 'lodash';
import flow from 'lodash/flow';
import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useMatch, useNavigate, useParams} from 'react-router-dom';
import {useLicense} from './License.provider';

interface IContext {
  organizations: Organization[];
  organizationId: string;
  openOrganizationModal(onCreateFn?: (org: Organization) => void): void;
}

const Context = createContext<IContext>({
  organizations: [],
  organizationId: '',
  openOrganizationModal: noop,
});

interface IProps {
  children: React.ReactNode;
}

const defaultName = 'default';

type TOrganizationFlow = (organizations?: Organization[], orgId?: string) => string | undefined;
const organizationFlow: TOrganizationFlow = flow([
  OrganizationService.getTargetOrganization,
  OrganizationService.setOrganizationAsPreferred,
]);

export const OrganizationsProvider = ({children}: IProps) => {
  const {orgId} = useParams();
  const {showNotification} = useNotification();
  const navigate = useNavigate();
  const {isEnterprise, onPlanLimitOpen} = useLicense();
  const [onCreateFn, setOnCreateFn] = useState<(org: Organization) => void | undefined>();
  const orgMatch = useMatch('/organizations/:orgId/:tab') ?? {params: {tab: ''}};
  const envMatch = useMatch('/organizations/:orgId/environments/:envId/:tab') ?? {params: {tab: ''}};

  // fetch organizations
  const {data: organizations = []} = useGetOrganizationsQuery({});

  // get target organization
  const targetOrg = useMemo(() => organizationFlow(organizations, orgId), [organizations, orgId]);

  // create organization modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createOrg, {isLoading: isCreateLoading}] = useCreateOrganizationMutation();
  const handleCreateOrganization = async (values: TRawOrganization) => {
    const org = await createOrg({organization: values}).unwrap();
    showNotification({
      type: 'success',
      title: (
        <>
          Organization <b>{org.name}</b> created successfully
        </>
      ),
    });

    if (onCreateFn) onCreateFn(org);
    else navigate(`/organizations/${org.id}`);
  };

  const onOrganizationModalOpen = useCallback(
    (onCreate?: (org: Organization) => void) => {
      if (isEnterprise) onPlanLimitOpen();
      else {
        setOnCreateFn(() => onCreate);
        setIsModalOpen(true);
      }
    },
    [isEnterprise, onPlanLimitOpen]
  );

  useEffect(() => {
    if (!!targetOrg && orgId === defaultName) {
      navigate(
        `/organizations/${targetOrg}/${orgMatch.params?.tab || `environments/default/${envMatch.params.tab ?? ''}`}${
          window.location.search
        }`,
        {
          replace: true,
        }
      );
    }
  }, [envMatch.params.tab, navigate, orgId, orgMatch.params?.tab, targetOrg]);

  const value = useMemo(
    () => ({
      organizations,
      organizationId: targetOrg || '',
      openOrganizationModal: onOrganizationModalOpen,
    }),
    [onOrganizationModalOpen, organizations, targetOrg]
  );

  return (
    <Context.Provider value={value}>
      <OrganizationModal
        onSubmit={handleCreateOrganization}
        isOpen={isModalOpen}
        isLoading={isCreateLoading}
        onClose={() => setIsModalOpen(false)}
      />
      {children}
    </Context.Provider>
  );
};

export const useOrganizations = () => useContext(Context);
