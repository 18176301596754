import {Typography} from 'antd';
import styled, {css} from 'styled-components';

export const Container = styled.div<{$isSelected?: boolean}>`
  background-color: ${({theme}) => theme.color.white};
  border-radius: 2px;
  border: 1px solid ${({theme}) => theme.color.borderLight};
  cursor: pointer;
  height: 100%;
  padding: 12px 16px;
  padding: 16px 24px;
  transition: 0.3s;

  ${({$isSelected}) =>
    $isSelected &&
    css`
      background-color: ${({theme}) => theme.color.background};
      border: ${({theme}) => `1px solid ${theme.color.primary}`};

      .icon {
        background-color: ${({theme}) => theme.color.primary};

        svg path {
          fill: ${({theme}) => theme.color.white};
        }
      }
    `}

  &:hover {
    background-color: ${({theme}) => theme.color.background};
    border: ${({theme}) => `1px solid ${theme.color.primary}`};

    .icon {
      background-color: ${({theme}) => theme.color.primary};

      svg path {
        fill: ${({theme}) => theme.color.white};
      }
    }
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
`;

export const Icon = styled.div`
  align-items: center;
  background-color: ${({theme}) => theme.color.borderLight};
  border-radius: 4px;
  display: flex;
  height: 36px;
  justify-content: center;
  transition: 0.3s;
  width: 36px;
`;

export const Title = styled(Typography.Title)`
  && {
    font-size: ${({theme}) => theme.size.md};
    margin: 0;
  }
`;

export const Text = styled(Typography.Text)`
  && {
    font-size: ${({theme}) => theme.size.sm};
    margin: 0;
  }
`;
