import {IAuthzService} from './types';

export enum Group {
  Admins = 'admins',
  Billers = 'billers',
  Members = 'members',
  Owners = 'owners',
}

export enum TokenRoles {
  Admin = Group.Admins,
  Agent = 'agent',
}

export enum Operation {
  Bill = 'bill',
  Edit = 'edit',
  View = 'view',
}

export type TRelationshipOrganization = {group: Group; operation: Operation};

const Permissions: Record<Operation, Group[]> = {
  [Operation.Bill]: [Group.Billers, Group.Owners],
  [Operation.Edit]: [Group.Admins, Group.Owners],
  [Operation.View]: [Group.Admins, Group.Billers, Group.Members, Group.Owners],
};

const OrganizationService: IAuthzService<TRelationshipOrganization> = {
  check(relationship: TRelationshipOrganization) {
    return Permissions[relationship.operation]?.includes(relationship.group) ?? false;
  },
};

export default OrganizationService;
