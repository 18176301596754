import { Button } from 'antd';
import styled from 'styled-components';

export const Trigger = styled(Button).attrs({
  ghost: true,
  type: 'text',
})`
  && {
    color: ${({theme}) => theme.color.primary};
    font-weight: 600;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;