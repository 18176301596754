import * as S from './Skeleton.styled';

interface IProps {
  height?: number;
  width?: number;
}

const Skeleton = ({height, width}: IProps) => <S.Container $height={height} $width={width} />;

export default Skeleton;
