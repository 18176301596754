import {HTTP_METHOD} from '@core/constants/Common.constants';
import {SortBy, SortDirection, TracetestApiTags} from '@core/constants/Test.constants';
import {PaginationResponse} from '@core/hooks/usePagination';
import Monitor, {TRawAlert, TRawMonitor, TRawMonitorResource} from '@core/models/Monitor.model';
import {AlertResult, TRawAlertResult} from '@core/models/MonitorRun.model';
import {TBaseFilters, TPaginatedCollection} from '@core/types/Common.types';
import {TTestApiEndpointBuilder} from '../Tracetest.api';

const defaultHeaders = {'content-type': 'application/json', 'X-Tracetest-Augmented': 'true'};

export const monitorEndpoints = (builder: TTestApiEndpointBuilder) => ({
  getMonitors: builder.query<
    PaginationResponse<Monitor>,
    TBaseFilters & {
      sortBy?: SortBy;
      sortDirection?: SortDirection;
    }
  >({
    query: ({take = 25, skip = 0, query = '', sortBy = '', sortDirection = ''}) => ({
      url: `/monitors?${new URLSearchParams({
        take: take.toString(),
        skip: skip.toString(),
        query,
        sortBy,
        sortDirection,
      })}`,
      headers: defaultHeaders,
    }),
    providesTags: () => [{type: TracetestApiTags.MONITOR, id: 'LIST'}],
    transformResponse: ({items = [], count = 0}: TPaginatedCollection<TRawMonitorResource>) => ({
      items: items.map(rawMonitor => Monitor.fromResource(rawMonitor)) ?? [],
      total: count,
    }),
  }),

  getMonitor: builder.query<Monitor, {monitorId: string}>({
    query: ({monitorId}) => ({
      url: `/monitors/${monitorId}`,
      headers: defaultHeaders,
    }),
    providesTags: result => [{type: TracetestApiTags.MONITOR, id: result?.id}],
    transformResponse: (rawMonitor: TRawMonitorResource) => Monitor.fromResource(rawMonitor),
  }),

  getMonitorByVersion: builder.query<Monitor, {monitorId: string; version: number}>({
    query: ({monitorId, version}) => `/monitors/${monitorId}/version/${version}`,
    providesTags: result => [{type: TracetestApiTags.MONITOR, id: `${result?.id}-${result?.version}`}],
    transformResponse: (rawMonitor: TRawMonitor) => Monitor(rawMonitor),
    keepUnusedDataFor: 10,
  }),

  createMonitor: builder.mutation<Monitor, {monitor: TRawMonitor}>({
    query: ({monitor}) => ({
      url: `/monitors`,
      method: HTTP_METHOD.POST,
      body: Monitor.toResource(monitor),
    }),
    transformResponse: (rawMonitor: TRawMonitorResource) => Monitor.fromResource(rawMonitor),
    invalidatesTags: [{type: TracetestApiTags.MONITOR, id: 'LIST'}],
  }),

  editMonitor: builder.mutation<Monitor, {monitor: TRawMonitor; monitorId: string}>({
    query: ({monitor, monitorId}) => ({
      url: `/monitors/${monitorId}`,
      method: HTTP_METHOD.PUT,
      body: Monitor.toResource(monitor),
    }),
    transformResponse: (rawMonitor: TRawMonitorResource) => Monitor.fromResource(rawMonitor),
    invalidatesTags: [{type: TracetestApiTags.MONITOR, id: 'LIST'}],
  }),

  deleteMonitor: builder.mutation<Monitor, {monitorId: string}>({
    query: ({monitorId}) => ({
      url: `/monitors/${monitorId}`,
      method: HTTP_METHOD.DELETE,
    }),
    invalidatesTags: [{type: TracetestApiTags.MONITOR, id: 'LIST'}],
  }),

  testAlert: builder.mutation<AlertResult, {alert: TRawAlert}>({
    query: ({alert}) => ({
      url: `/monitors/alerts`,
      method: HTTP_METHOD.POST,
      body: alert,
    }),
    transformResponse: (rawAlertResult: TRawAlertResult) => AlertResult(rawAlertResult),
  }),
});
