import {Form} from 'antd';
import {useMemo} from 'react';
import DataStoreService from '@core/services/DataStore.service';

const useIsOtlpBased = () => {
  const form = Form.useFormInstance();
  // listens to all form changes
  const data = Form.useWatch([], form);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => DataStoreService.getIsOtlpBased(form.getFieldsValue()), [data]);
};

export default useIsOtlpBased;
