import {Role} from '@core/types/Common.types';
import Environment from '@models/Environment.model';
import Member from '@models/Member.model';
import differenceBy from 'lodash/differenceBy';
import UserPreferencesService from './UserPreferences.service';

const EnvironmentService = {
  /**
   * @description Get the target environment based on the envId in the URL.
   * If the envId is not found in the list of environments, we check if there
   * is a stored envId in localStorage, otherwise we return the first environment
   * in the list.
   * @param environments - List of environments
   * @param envId - Environment ID from URL
   */
  getTargetEnvironment(environments?: Environment[], envId?: string): string | undefined {
    if (envId !== 'default') return envId;
    if (!environments?.length) return;

    const userPrefEnvId = UserPreferencesService.get('envId');
    const target =
      (envId && environments?.find(env => env.id === envId)) ||
      (userPrefEnvId && environments?.find(env => env.id === userPrefEnvId)) ||
      (environments?.length ? environments[0] : undefined);

    return target?.id;
  },

  /**
   * @description Set the environment as preferred in localStorage
   * @param orgId - Environment ID
   */
  setEnvironmentAsPreferred(envId: string): string | undefined {
    if (!envId) return;
    UserPreferencesService.set('envId', envId);
    return envId;
  },

  /**
   * @description Get organization members that can be assigned to an environment.
   * They need to have the member role and not be already assigned to the environment.
   * @param members - List of organization members
   */
  getAssignableMembers(organizationMembers?: Member[], environmentMembers?: Member[]): Member[] {
    if (!organizationMembers?.length) return [];

    const members = organizationMembers.filter(member => member.role === Role.Members);
    return differenceBy(members, environmentMembers ?? [], 'id');
  },
};

export default EnvironmentService;
