import {TInvitesSchemas, Role} from '@core/types/Common.types';
import Environment from './Environment.model';

export type TRawInviteEnvironment = TInvitesSchemas['InviteEnvironment'];
type InviteEnvironment = TRawInviteEnvironment & {environment: Environment};

function InviteEnvironment({id = '', role = Role.Members, environment}: TRawInviteEnvironment): InviteEnvironment {
  return {
    id,
    role,
    environment: Environment(environment),
  };
}

export default InviteEnvironment;
