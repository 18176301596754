import AgentBadge from '@components/molecules/AgentBadge';
import Environment from '@models/Environment.model';
import {useEnvironment} from '@providers/Environment.provider';

const Tab = () => {
  const {environment: {agent, agentConfiguration: {serverless}} = Environment()} = useEnvironment();

  return <AgentBadge isServerless={serverless} status={agent.status} showText />;
};

export default Tab;
