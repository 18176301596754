import {SortBy, SortDirection, TracetestApiTags} from '@core/constants/Test.constants';
import {PaginationResponse} from '@core/hooks/usePagination';
import RunGroup, {TRawRunGroup, TRunGroupStatus} from '@core/models/RunGroup.model';
import {getTotalCountFromHeaders} from '@core/utils/Common';
import {TTestApiEndpointBuilder} from '../Tracetest.api';

export const runGroupEndpoints = (builder: TTestApiEndpointBuilder) => ({
  getRunGroups: builder.query<
    PaginationResponse<RunGroup>,
    {
      take?: number;
      skip?: number;
      sortBy?: SortBy;
      sortDirection?: SortDirection;
      status: TRunGroupStatus;
    }
  >({
    query: ({take = 25, skip = 0, sortBy = '', sortDirection = '', status}) => ({
      url: `/rungroups?take=${take}&skip=${skip}&sortBy=${sortBy}&sortDirection=${sortDirection}&status=${status}`,
    }),
    providesTags: () => [{type: TracetestApiTags.RUN_GROUP, id: 'LIST'}],
    transformResponse: (rawRunGroups: TRawRunGroup[], meta) => {
      return {
        items: rawRunGroups.map(raw => RunGroup(raw)),
        total: getTotalCountFromHeaders(meta),
      };
    },
  }),
  getRunGroupById: builder.query<
    RunGroup,
    {
      id: string;
    }
  >({
    query: ({id}) => ({
      url: `/rungroups/${id}`,
    }),
    providesTags: result => [{type: TracetestApiTags.RUN_GROUP, id: result?.id}],
    transformResponse: (raw: TRawRunGroup) => RunGroup(raw),
  }),
});
