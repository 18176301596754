import { TAgentSchemas } from '@core/types/Common.types';
import Agent from './Agent.model';

export type TRawEnvironmentAgent = TAgentSchemas['EnvironmentAgent'];
type EnvironmentAgent = TRawEnvironmentAgent & {agent: Agent};

const defaultEnvironmentAgent: TRawEnvironmentAgent = {
  key: '',
  agent: Agent(),
};

function EnvironmentAgent({key = '', agent}: TRawEnvironmentAgent = defaultEnvironmentAgent): EnvironmentAgent {
  return {
    key,
    agent: Agent(agent),
  };
}

export default EnvironmentAgent;
