import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background: linear-gradient(180deg, rgba(57, 32, 96, 0.95) 0%, rgba(171, 49, 77, 0.95) 100%);
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const LogoContainer = styled.div`
  align-items: end;
  display: flex;
  flex-direction: column;
  width: 232px;
`;

export const Loader = styled.div`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 2px;
  display: inline-block;
  height: 4px;
  overflow: hidden;
  position: relative;
  width: 160px;

  ::after {
    content: '';
    animation: animationloader 2s linear infinite;
    background: ${({theme}) => theme.color.white};
    box-sizing: border-box;
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100px;
  }

  @keyframes animationloader {
    0% {
      left: 0;
      transform: translateX(-100%);
    }
    100% {
      left: 100%;
      transform: translateX(0%);
    }
  }
`;
