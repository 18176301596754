import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import EnvironmentModal from '@components/organisms/EnvironmentModal';
import Environment from '@models/Environment.model';
import {TracetestApiTagsList} from '@core/constants/Test.constants';
import {useNotification} from '@core/providers/Notification/Notification.provider';
import TracetestAPI from '@core/redux/apis/Tracetest';
import TracetestCloudAPI, {
  useCreateEnvironmentMutation,
  useDeleteEnvironmentMutation,
  useGetEnvironmentsMeQuery,
} from '@redux/apis/TracetestCloud';
import {useAppDispatch} from '@redux/hooks';
import {skipToken} from '@reduxjs/toolkit/query';
import {TAG_TYPES} from '@redux/apis/TracetestCloud/TracetestCloud.api';
import noop from 'lodash/noop';
import {flow} from 'lodash';
import {useConfirmationModal} from '@core/providers/ConfirmationModal/ConfirmationModal.provider';
import {useLocation, useMatch, useNavigate, useParams} from 'react-router-dom';
import EnvironmentService from '@services/Environment.service';
import {useOrganizations} from './Organizations.provider';

interface IContext {
  environments: Environment[];
  environmentId: string;
  isLoading: boolean;
  openEnvironmentModal(onCreateFn?: (org: Environment) => void): void;
  onDelete(environment: Environment): void;
}

const Context = createContext<IContext>({
  environments: [],
  environmentId: '',
  isLoading: false,
  openEnvironmentModal: noop,
  onDelete: noop,
});

interface IProps {
  children: React.ReactNode;
}

type TEnvironmentFlow = (environments?: Environment[], envId?: string) => string | undefined;
const environmentFlow: TEnvironmentFlow = flow([
  EnvironmentService.getTargetEnvironment,
  EnvironmentService.setEnvironmentAsPreferred,
]);

export const EnvironmentsProvider = ({children}: IProps) => {
  const {organizationId} = useOrganizations();
  const {showNotification} = useNotification();
  const {envId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {params = {tab: '', envId}} = useMatch('/organizations/:orgId/environments/:envId/:tab') ?? {params: {tab: ''}};

  // fetch environments
  const {
    data: environments = [],
    isLoading,
    isUninitialized,
  } = useGetEnvironmentsMeQuery(organizationId ? {orgId: organizationId} : skipToken, {
    pollingInterval: 10000,
  });

  useEffect(() => {
    // if user is only a member of the org without any environment assigned, redirect to org page
    if (!isLoading && !environments.length && !isUninitialized) navigate(`/organizations/${organizationId}`);
  }, [environments.length, isLoading, isUninitialized, navigate, organizationId]);

  const targetEnv = useMemo(() => environmentFlow(environments, envId), [environments, envId]);

  // create environment modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onCreateFn, setOnCreateFn] = useState<(env: Environment) => void | undefined>();
  const [createEnvironment, {isLoading: isCreateEnvironmentLoading}] = useCreateEnvironmentMutation();
  const handleCreateEnvironment = async (values: Environment) => {
    const env = await createEnvironment({environment: values}).unwrap();
    showNotification({
      type: 'success',
      title: (
        <>
          Environment <b>{env.name}</b> created successfully
        </>
      ),
    });

    if (onCreateFn) onCreateFn(env);
    else {
      navigate(`/organizations/${organizationId}/environments/${env.id}`);
      dispatch(TracetestAPI.util.invalidateTags(TracetestApiTagsList));
      dispatch(TracetestCloudAPI.util.invalidateTags([TAG_TYPES.TOKEN_LIST]));
    }
  };

  const {onOpen} = useConfirmationModal();
  const [deleteEnv] = useDeleteEnvironmentMutation();
  const onDelete = useCallback(
    (environment: Environment) => {
      const onConfirm = async () => {
        await deleteEnv({envId: environment.id});
        showNotification({
          type: 'success',
          title: (
            <>
              Environment <b>{environment.name}</b> deleted successfully
            </>
          ),
        });
      };

      onOpen({
        heading: 'Delete environment',
        title: `Do you really want to delete "${environment?.name}"?
              All your historical and analytical data will also be removed.
              This action is not reversible.`,
        okText: 'Delete',
        onConfirm,
      });
    },
    [deleteEnv, onOpen, showNotification]
  );

  useEffect(() => {
    if (envId === 'default' && !!targetEnv && targetEnv !== envId) {
      navigate(`/organizations/${organizationId}/environments/${targetEnv}/${params.tab ?? ''}`, {replace: true});
    }
  }, [envId, location.pathname, navigate, organizationId, params, targetEnv]);

  const onEnvironmentModalOpen = useCallback((onCreate?: (env: Environment) => void) => {
    setIsModalOpen(true);
    setOnCreateFn(() => onCreate);
  }, []);

  const value = useMemo(
    () => ({
      environments,
      environmentId: targetEnv || '',
      isLoading,
      onDelete,
      openEnvironmentModal: onEnvironmentModalOpen,
    }),
    [environments, isLoading, onDelete, onEnvironmentModalOpen, targetEnv]
  );

  return (
    <Context.Provider value={value}>
      <EnvironmentModal
        isLoading={isCreateEnvironmentLoading}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleCreateEnvironment}
      />
      {children}
    </Context.Provider>
  );
};

export const useEnvironments = () => useContext(Context);
