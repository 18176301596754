import DocsBanner from '@core/components/DocsBanner';
import {TRACE_MODE_URL} from '@core/constants/Common.constants';
import {Typography} from 'antd';
import * as S from './EnvironmentTraceSetup.styled';

const Header = () => (
  <div>
    <S.TitleContainer>
      <S.Title level={2} $marginBottom={0}>
        Trace Setup
      </S.Title>
    </S.TitleContainer>

    <Typography.Paragraph type="secondary">
      <p>Configure your application to send traces to Tracetest and start visualizing them in real-time.</p>
      <DocsBanner>
        Need more information about Trace Setup?{' '}
        <a href={TRACE_MODE_URL} target="__blank">
          Go to our docs
        </a>
      </DocsBanner>
    </Typography.Paragraph>
  </div>
);

export default Header;
