import Token from '@models/Token.model';
import {Form} from 'antd';
import {useEffect} from 'react';
import * as S from './TokenModal.styled';
import Footer from './Footer';
import FormBody from './FormBody';

const ID_PLACEHOLDER = 'id-placeholder';

interface IProps {
  enabledRoles?: string[];
  initialValues?: Token;
  isLoading: boolean;
  isOpen: boolean;
  onClose(): void;
  onSubmit(values: Token, isEditing: boolean): void;
}

const TokenModal = ({enabledRoles, initialValues, isLoading, isOpen, onClose, onSubmit}: IProps) => {
  const [form] = Form.useForm<Token>();
  const isEditing = !!initialValues && initialValues.id !== ID_PLACEHOLDER;

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues ?? {});
  }, [form, initialValues, isOpen]);

  const handleOnSubmit = (values: Token) => {
    onSubmit(values, isEditing);
    onClose();
  };

  return (
    <S.Modal
      cancelText="Cancel"
      footer={<Footer isEditing={isEditing} isLoading={isLoading} onClose={onClose} onSubmit={() => form.submit()} />}
      onCancel={onClose}
      title={<S.Title>{isEditing ? 'Edit token' : 'Create a new token'} </S.Title>}
      visible={isOpen}
    >
      <FormBody enabledRoles={enabledRoles} form={form} isEditing={isEditing} onSubmit={handleOnSubmit} />
    </S.Modal>
  );
};

export default TokenModal;
