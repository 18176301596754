import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Ory Elements optional global css reset
import '@ory/elements/assets/normalize.css';

// Antd customized theme
import '@core/antd-theme/antd-customized.css';

// Custom global styles
import './index.css';

// Ory Elements optional fontawesome icons
import '@ory/elements/assets/fa-brands.min.css';
import '@ory/elements/assets/fa-solid.min.css';
import '@ory/elements/assets/fontawesome.min.css';

// Fonts
import '@ory/elements/assets/inter-font.css';
import '@ory/elements/assets/jetbrains-mono-font.css';

// Ory Elements required styles
import '@ory/elements/style.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
