const CloudPublic = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="cloud-pod">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.56993 8.30669C5.71883 5.43742 8.09326 3.15625 11 3.15625C13.4783 3.15625 15.5711 4.81481 16.2254 7.08403L16.3778 7.61264L16.9278 7.62603C18.7527 7.67043 20.2188 9.16411 20.2188 11C20.2188 12.1287 19.6655 13.1281 18.812 13.742C18.4758 13.9839 18.3993 14.4526 18.6412 14.7888C18.8831 15.1251 19.3517 15.2016 19.688 14.9597C20.9164 14.076 21.7188 12.6317 21.7188 11C21.7188 8.53077 19.8831 6.49047 17.5018 6.16907C16.5185 3.53385 13.9794 1.65625 11 1.65625C7.53639 1.65625 4.66596 4.19417 4.14637 7.51223C1.98431 7.67683 0.28125 9.48328 0.28125 11.6875C0.28125 13.2384 1.12477 14.5914 2.37446 15.3143C2.733 15.5217 3.1918 15.3992 3.3992 15.0407C3.60661 14.6821 3.48409 14.2233 3.12554 14.0159C2.32029 13.5501 1.78125 12.6813 1.78125 11.6875C1.78125 10.2032 2.98448 9 4.46875 9C4.56225 9 4.6544 9.00475 4.745 9.01397L5.52908 9.09376L5.56993 8.30669ZM11 13.8507L7.7675 12.0036L11 10.1565L14.2325 12.0036L11 13.8507ZM11.75 15.1498L15 13.2926V16.9869L11.75 18.8441V15.1498ZM10.25 15.1498L7 13.2927V16.9869L10.25 18.8441V15.1498ZM10.5039 8.71235C10.8113 8.53667 11.1887 8.53667 11.4961 8.71235L15.9961 11.2838C16.3077 11.4618 16.5 11.7932 16.5 12.152V17.2771C16.5 17.6359 16.3077 17.9673 15.9961 18.1453L11.4961 20.7168C11.1887 20.8924 10.8113 20.8924 10.5039 20.7168L6.00386 18.1453C5.69229 17.9673 5.5 17.6359 5.5 17.2771V12.152C5.5 11.7932 5.69229 11.4618 6.00386 11.2838L10.5039 8.71235Z"
        fill="#031849"
      />
    </g>
  </svg>
);

export default CloudPublic;
