import GraphqlRequest from '@core/models/GraphqlRequest.model';
import {IGraphqlValues, ITriggerService} from '@core/types/Test.types';
import Validator from '@core/utils/Validator';
import {Attributes} from '../../constants/SpanAttribute.constants';

const GraphqlTriggerService = (): ITriggerService => ({
  async getRequest(values) {
    const {url, headers, auth, body, sslVerification, schema: schemaFile} = values as IGraphqlValues;
    const schema = (await schemaFile?.text()) ?? '';

    return GraphqlRequest({url, headers, auth, body, sslVerification, schema});
  },

  async validateDraft(draft): Promise<boolean> {
    const {url, body: {query} = {}} = draft as IGraphqlValues;

    return Validator.required(url) && Validator.required(query);
  },

  getInitialValues(request) {
    const {url, headers, auth, body, sslVerification, schema} = request as GraphqlRequest;
    const schemaFile = schema ? new File([schema], 'schema.graphql') : undefined;

    return {
      url,
      headers,
      auth,
      body,
      sslVerification,
      schema: schemaFile,
    };
  },

  getParsedDefinition(request) {
    const values = request as GraphqlRequest;

    return {
      trigger: {
        type: 'graphql',
        graphql: {
          ...values,
          schema: './schema.graphql',
          body: {
            ...values.body,
            query: './query.graphql',
          },
        },
      },
      assets: [
        {
          name: 'query.graphql',
          content: values.body.query ?? '',
          language: 'graphql',
        },
        {
          name: 'schema.graphql',
          content: values.schema,
          language: 'graphql',
        },
      ],
    };
  },

  getRequestFromSpan(span) {
    const headers = Object.entries(span.attributes).flatMap(([key, value]) => {
      if (key.startsWith('http.request.header.')) {
        const val = (JSON.parse(value.value) as string[]).join(', ');
        return [
          {
            key: key.replace('http.request.header.', '').replace('_', '-'),
            value: val,
          },
        ];
      }

      return [];
    });

    const hostName = span.attributes[Attributes.HTTP_HOST]?.value || `\${var:HOST}`;
    const schema = span.attributes[Attributes.HTTP_SCHEME]?.value || 'http';
    const fullUrl = span.attributes[Attributes.HTTP_URL]?.value;
    const path = span.attributes[Attributes.HTTP_ROUTE]?.value ?? '';

    const url = fullUrl || `${schema}://${hostName}${path}`;

    return GraphqlRequest({
      url,
      headers,
      schema: '',
    });
  },
});

export default GraphqlTriggerService();
