import {ReadOutlined} from '@ant-design/icons';
import {FramedCodeBlock} from '@core/components/CodeBlock';
import {TCliCommandConfig} from '@core/services/CliCommand.service';
import * as S from './CliCommand.styled';
import Controls from './Controls';
import {IMethodChildrenProps} from '../../RunDetailAutomateMethods';

interface IProps extends IMethodChildrenProps {
  command: string;
  configureCommand?: string;
  onGetCommand(config: TCliCommandConfig): void;
}

const Command = ({
  id,
  variableSetId,
  configureCommand = '',
  fileName = '',
  resourceType,
  docsUrl,
  command,
  onGetCommand,
}: IProps) => {
  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title>CLI Configuration</S.Title>
        <a href={docsUrl} target="_blank">
          <ReadOutlined />
        </a>
      </S.TitleContainer>
      {!!configureCommand && (
        <>
          <FramedCodeBlock
            title="Configure Tracetest CLI:"
            language="bash"
            value={configureCommand}
            minHeight="80px"
            maxHeight="80px"
          />
          <br />
        </>
      )}
      <FramedCodeBlock
        title="Tracetest CLI command:"
        language="bash"
        value={command}
        minHeight="80px"
        maxHeight="80px"
      />
      <Controls
        onChange={onGetCommand}
        id={id}
        fileName={fileName}
        variableSetId={variableSetId}
        resourceType={resourceType}
      />
    </S.Container>
  );
};

export default Command;
