import Organization from '@models/Organization.model';
import {Form, FormInstance, Input} from 'antd';
import Explanation from './Explanation';

interface IProps {
  form: FormInstance<Organization>;
  onSubmit(values: Organization): void;
}

const FormBody = ({form, onSubmit}: IProps) => (
  <Form<Organization>
    form={form}
    initialValues={{id: 'id-placeholder'}}
    layout="vertical"
    name="organization"
    onFinish={onSubmit}
  >
    <Form.Item name="id" hidden>
      <Input type="hidden" />
    </Form.Item>

    <Form.Item label="Name" name="name" rules={[{required: true, message: 'Please add a name'}]}>
      <Input placeholder="My Company Name" />
    </Form.Item>

    <Explanation />
  </Form>
);

export default FormBody;
