import Polling from '@core/models/Polling.model';
import {SwitchControl} from '@core/components/Inputs';
import {Form, FormInstance, Input, Select} from 'antd';
import {PollingInfoType} from '@core/constants/TestRunEvents.constants';
import {capitalize} from 'lodash';

interface IProps {
  form: FormInstance<Polling>;
  onSubmit(values: Polling): void;
}

const strategies = Object.values(PollingInfoType);

const FormBody = ({form, onSubmit}: IProps) => {
  const strategy = Form.useWatch<string>('strategy', form);

  return (
    <Form<Polling> form={form} layout="vertical" name="profile" onFinish={onSubmit}>
      <Form.Item name="id" hidden>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item label="Name" name="name" rules={[{required: true, message: 'Please add a name'}]}>
        <Input placeholder="e.g. my-polling-profile" />
      </Form.Item>

      <Form.Item key="default" name="default" noStyle>
        <SwitchControl id="default" text="Default" help="Make this Polling Profile the Default for all Runs" />
      </Form.Item>

      <Form.Item label="Strategy" name="strategy" rules={[{required: true, message: 'Please select a strategy'}]}>
        <Select placeholder="Select a strategy">
          {strategies.map(item => (
            <Select.Option key={item} value={item}>
              {capitalize(item)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {strategy === PollingInfoType.Periodic && (
        <>
          <Form.Item
            label="Max wait time for trace"
            initialValue="1m"
            name={['periodic', 'timeout']}
            rules={[{required: true, message: 'Please add a Max wait time for trace'}]}
          >
            <Input placeholder="1m" />
          </Form.Item>
          <Form.Item
            label="Retry delay"
            initialValue="2s"
            name={['periodic', 'retryDelay']}
            rules={[{required: true, message: 'Please add a Retry delay'}]}
          >
            <Input placeholder="2s" />
          </Form.Item>

          <Form.Item
            label="Selector Match Retries"
            initialValue={3}
            name={['periodic', 'selectorMatchRetries']}
            rules={[{required: true, message: 'Please add a Selector Match Retries'}]}
            normalize={value => parseInt(String(value ?? 0), 10)}
          >
            <Input type="number" placeholder="3" />
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default FormBody;
