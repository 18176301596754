import * as S from './Card.styled';

interface IProps {
  icon: React.ReactNode;
  isSelected?: boolean;
  onClick: () => void;
  title: string;
}

const Card = ({icon, isSelected, onClick, title}: IProps) => (
  <S.Container onClick={onClick} $isSelected={isSelected}>
    <S.Header>
      <S.Icon className="icon">{icon}</S.Icon>
      <S.Title>{title}</S.Title>
    </S.Header>
  </S.Container>
);

Card.Text = S.Text;

export default Card;
