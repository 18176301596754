import AssignMember from '@models/AssignMember.type';
import {TBaseFilters, TCollection, TPaginatedCollection} from '@core/types/Common.types';
import Environment, {TRawEnvironment, TRawEnvironmentResource} from '@models/Environment.model';
import {PaginationResponse} from '@core/hooks/usePagination';
import Member, {TRawMember} from '@models/Member.model';
import Token, {TRawToken, TRawTokenResource} from '@models/Token.model';
import JoinRequest, {TRawJoinRequest} from '@models/JoinRequest.model';
import { PAGE_SIZE } from '@constants/list.constants';
import {HTTP_METHOD} from '@core/constants/Common.constants';
import EnvironmentAgent, {TRawEnvironmentAgent} from '@models/EnvironmentAgent.model';
import TracetestCloudAPI, {TAG_TYPES} from '../TracetestCloud.api';

const EnvironmentEndpoint = TracetestCloudAPI.injectEndpoints({
  endpoints: builder => ({
    /**
     * Environments
     */
    getEnvironment: builder.query<Environment, {envId: string}>({
      query: ({envId}) => ({
        url: `/environments/${envId}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.ENVIRONMENT],
      transformResponse: (raw: TRawEnvironmentResource) => Environment.fromResource(raw),
    }),
    getEnvironments: builder.query<PaginationResponse<Environment>, TBaseFilters & {orgId: string}>({
      query: ({skip = 0, take = 20, query = '', orgId}) => ({
        url: `/environments?${new URLSearchParams({
          skip: skip.toString(),
          take: take.toString(),
          query,
          sortBy: 'createdat',
          sortDirection: 'desc',
        })}`,
        headers: {
          'x-organization-id': orgId,
        },
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.ENVIRONMENT_LIST],
      transformResponse: ({items = [], count = 0}: TPaginatedCollection<TRawEnvironmentResource>) => ({
        items: items?.map(rawEnvironment => Environment.fromResource(rawEnvironment)) ?? [],
        total: count,
      }),
    }),
    getEnvironmentsMe: builder.query<Environment[], TBaseFilters & {orgId: string}>({
      query: ({skip = 0, take = 1000, query = '', orgId}) => ({
        url: `/organizations/${orgId}/environments?${new URLSearchParams({
          skip: skip.toString(),
          take: take.toString(),
          query,
        })}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.ENVIRONMENT_LIST_ME],
      transformResponse: ({elements = []}: TCollection<TRawEnvironment>) =>
        elements.map(rawEnvironment => Environment(rawEnvironment)) ?? [],
    }),
    createEnvironment: builder.mutation<Environment, {environment: TRawEnvironment}>({
      query: ({environment}) => ({
        url: `/environments`,
        method: HTTP_METHOD.POST,
        body: Environment.toResource(environment),
      }),
      invalidatesTags: [{type: TAG_TYPES.ENVIRONMENT_LIST}, {type: TAG_TYPES.ENVIRONMENT_LIST_ME}],
      transformResponse: (raw: TRawEnvironmentResource) => Environment.fromResource(raw),
    }),
    deleteEnvironment: builder.mutation<undefined, {envId: string}>({
      query: ({envId}) => ({
        url: `/environments/${envId}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: [{type: TAG_TYPES.ENVIRONMENT_LIST}, {type: TAG_TYPES.ENVIRONMENT_LIST_ME}],
    }),
    updateEnvironment: builder.mutation<undefined, {envId: string; environment: Environment}>({
      query: ({envId, environment}) => ({
        url: `/environments/${envId}`,
        method: HTTP_METHOD.PUT,
        body: Environment.toResource(environment),
      }),
      invalidatesTags: [
        {type: TAG_TYPES.ENVIRONMENT_LIST},
        {type: TAG_TYPES.ENVIRONMENT},
        {type: TAG_TYPES.ENVIRONMENT_LIST_ME},
      ],
    }),

    /**
     * Members
     */
    getEnvironmentMembers: builder.query<
      PaginationResponse<Member>,
      {envId: string; orgId: string; skip?: number; take?: number}
    >({
      query: ({envId, orgId, take = PAGE_SIZE, skip = 0}) => ({
        url: `/organizations/${orgId}/environments/${envId}/members?${new URLSearchParams({
          skip: skip.toString(),
          take: take.toString(),
        })}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.ENVIRONMENT_MEMBER],
      transformResponse: ({items = [], count}: TPaginatedCollection<TRawMember>) => ({
        items: items.map(rawMember => Member(rawMember)) ?? [],
        total: count,
      }),
    }),
    getEnvironmentMembersMe: builder.query<Member, {envId: string; orgId: string}>({
      query: ({envId, orgId}) => ({
        url: `/organizations/${orgId}/environments/${envId}/members/me`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.ENVIRONMENT_MEMBER_ME],
      transformResponse: (response: TRawMember) => Member(response),
    }),
    deleteEnvironmentMembersMe: builder.mutation<undefined, {envId: string; orgId: string}>({
      query: ({envId, orgId}) => ({
        url: `/organizations/${orgId}/environments/${envId}/members/me`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: [{type: TAG_TYPES.ENVIRONMENT_LIST}],
    }),
    createEnvironmentMember: builder.mutation<undefined, {envId: string; member: AssignMember; orgId: string}>({
      query: ({envId, member, orgId}) => ({
        url: `/organizations/${orgId}/environments/${envId}/members`,
        method: HTTP_METHOD.POST,
        body: member,
      }),
      invalidatesTags: [{type: TAG_TYPES.ENVIRONMENT_MEMBER}],
    }),
    deleteEnvironmentMember: builder.mutation<undefined, {envId: string; memId: string; orgId: string}>({
      query: ({envId, memId, orgId}) => ({
        url: `/organizations/${orgId}/environments/${envId}/members/${memId}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: [{type: TAG_TYPES.ENVIRONMENT_MEMBER}],
    }),
    updateEnvironmentMember: builder.mutation<undefined, {envId: string; memId: string; orgId: string; role: string}>({
      query: ({envId, memId, orgId, role}) => ({
        url: `/organizations/${orgId}/environments/${envId}/members/${memId}`,
        method: HTTP_METHOD.PATCH,
        body: {role},
      }),
      invalidatesTags: [{type: TAG_TYPES.ENVIRONMENT_MEMBER}],
    }),

    /**
     * Tokens
     */
    getTokens: builder.query<PaginationResponse<Token>, TBaseFilters>({
      query: ({query = '', take = 20, skip = 0}) => ({
        url: `/environmenttokens?${new URLSearchParams({
          skip: skip.toString(),
          take: take.toString(),
          query,
          sortBy: 'issuedat',
          sortDirection: 'desc',
        })}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.TOKEN_LIST],
      transformResponse: ({items = [], count = 0}: TPaginatedCollection<TRawTokenResource>) => ({
        items: items.map(rawToken => Token.fromResource(rawToken)) ?? [],
        total: count,
      }),
    }),
    getToken: builder.query<Token, {tokenId: string}>({
      query: ({tokenId}) => ({
        url: `/environmenttokens/${tokenId}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.TOKEN],
      transformResponse: (raw: TRawTokenResource) => Token.fromResource(raw),
    }),
    createToken: builder.mutation<Token, {token: TRawToken}>({
      query: ({token}) => ({
        url: `/environmenttokens`,
        method: HTTP_METHOD.POST,
        body: Token.toResource(token),
      }),
      transformResponse: (raw: TRawTokenResource) => Token.fromResource(raw),
      invalidatesTags: [{type: TAG_TYPES.TOKEN_LIST}],
    }),
    upsertToken: builder.mutation<Token, {token: TRawToken}>({
      query: ({token}) => ({
        url: `/environmenttokens`,
        method: HTTP_METHOD.PUT,
        body: Token.toResource(token),
      }),
      transformResponse: (raw: TRawTokenResource) => Token.fromResource(raw),
      invalidatesTags: [{type: TAG_TYPES.TOKEN_LIST}],
    }),
    deleteToken: builder.mutation<undefined, {tokenId: string}>({
      query: ({tokenId}) => ({
        url: `/environmenttokens/${tokenId}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: [{type: TAG_TYPES.TOKEN_LIST}],
    }),

    /**
     * Agent
     */
    getEnvironmentAgent: builder.query<EnvironmentAgent, {envId: string; orgId: string}>({
      query: ({envId, orgId}) => ({
        url: `/organizations/${orgId}/environments/${envId}/agent`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.ENVIRONMENT_AGENT],
      transformResponse: (raw: TRawEnvironmentAgent) => EnvironmentAgent(raw),
    }),
    restartEnvironmentAgent: builder.mutation<undefined, {envId: string; orgId: string}>({
      query: ({envId, orgId}) => ({
        url: `/organizations/${orgId}/environments/${envId}/agent/restart`,
        method: HTTP_METHOD.POST,
      }),
      invalidatesTags: [{type: TAG_TYPES.ENVIRONMENT_LIST}, {type: TAG_TYPES.ENVIRONMENT}],
    }),

    /**
     * Join Requests
     */
    listJoinRequests: builder.query<
      PaginationResponse<JoinRequest>,
      TBaseFilters & {envId: string; orgId: string; status?: string}
    >({
      query: ({envId, orgId, skip = 0, take = 20, query = '', status = ''}) => ({
        url: `/organizations/${orgId}/environments/${envId}/joinrequests?${new URLSearchParams({
          skip: skip.toString(),
          take: take.toString(),
          query,
          sortBy: 'createdat',
          sortDirection: 'desc',
          status,
        })}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.JOIN_REQUEST_LIST],
      transformResponse: ({items = [], count = 0}: TPaginatedCollection<TRawJoinRequest>) => ({
        items: items.map(rawJoinRequest => JoinRequest(rawJoinRequest)) ?? [],
        total: count,
      }),
    }),
    getJoinRequest: builder.query<JoinRequest, {envId: string; orgId: string; joinRequestId: string}>({
      query: ({envId, orgId, joinRequestId}) => ({
        url: `/organizations/${orgId}/environments/${envId}/joinrequests/${joinRequestId}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: [TAG_TYPES.JOIN_REQUEST],
      transformResponse: (raw: TRawJoinRequest) => JoinRequest(raw),
    }),
    createJoinRequest: builder.mutation<JoinRequest, {envId: string; orgId: string}>({
      query: ({envId, orgId}) => ({
        url: `/organizations/${orgId}/environments/${envId}/joinrequests`,
        method: HTTP_METHOD.POST,
      }),
      transformResponse: (raw: TRawJoinRequest) => JoinRequest(raw),
      invalidatesTags: [{type: TAG_TYPES.JOIN_REQUEST_LIST}],
    }),
    deleteJoinRequest: builder.mutation<undefined, {envId: string; orgId: string; joinRequestId: string}>({
      query: ({envId, orgId, joinRequestId}) => ({
        url: `/organizations/${orgId}/environments/${envId}/joinrequests/${joinRequestId}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: [{type: TAG_TYPES.JOIN_REQUEST_LIST}],
    }),
    updateJoinRequest: builder.mutation<JoinRequest, {envId: string; orgId: string; joinRequest: JoinRequest}>({
      query: ({envId, orgId, joinRequest}) => ({
        url: `/organizations/${orgId}/environments/${envId}/joinrequests/${joinRequest.id}`,
        method: HTTP_METHOD.PUT,
        body: joinRequest,
      }),
      invalidatesTags: [{type: TAG_TYPES.JOIN_REQUEST_LIST}],
      transformResponse: (raw: TRawJoinRequest) => JoinRequest(raw),
    }),
  }),
});

export const {
  useCreateEnvironmentMemberMutation,
  useCreateEnvironmentMutation,
  useCreateTokenMutation,
  useDeleteEnvironmentMemberMutation,
  useDeleteEnvironmentMutation,
  useDeleteTokenMutation,
  useGetEnvironmentMembersMeQuery,
  useLazyGetEnvironmentMembersMeQuery,
  useGetEnvironmentMembersQuery,
  useGetEnvironmentsQuery,
  useGetTokensQuery,
  useLazyGetEnvironmentsQuery,
  useLazyGetEnvironmentsMeQuery,
  useUpdateEnvironmentMemberMutation,
  useGetEnvironmentsMeQuery,
  useGetEnvironmentQuery,
  useGetEnvironmentAgentQuery,
  useDeleteEnvironmentMembersMeMutation,
  useUpdateEnvironmentMutation,
  useRestartEnvironmentAgentMutation,
  useCreateJoinRequestMutation,
  useDeleteJoinRequestMutation,
  useListJoinRequestsQuery,
  useUpdateJoinRequestMutation,
  useGetJoinRequestQuery,
  useLazyGetJoinRequestQuery,
  useGetTokenQuery,
  useUpsertTokenMutation,
} = EnvironmentEndpoint;
