import Command from '@core/components/RunDetailAutomateMethods/methods/CLICommand/Command';
import {IMethodChildrenProps} from '@core/components/RunDetailAutomateMethods/RunDetailAutomateMethods';
import useCliCommand from './hooks/useCliCommand';

const CLiCommand = (props: IMethodChildrenProps) => {
  const {command, onGetCommand, configureCommand} = useCliCommand();

  return <Command configureCommand={configureCommand} command={command} onGetCommand={onGetCommand} {...props} />;
};

export default CLiCommand;
